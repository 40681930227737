import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetIconsByUserIdMeta,
  GetIconsByUserIdPayload
} from './getIconsByUserId.type';

const initialState: AsyncState<GetIconsByUserIdMeta, GetIconsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetIconsByUserIdMeta, GetIconsByUserIdPayload>();

const getIconsByUserIdSlice = createSlice({
  name: 'getIconsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetIconsByUserId = getIconsByUserIdSlice.actions.REQUEST;
export const setGetIconsByUserId = getIconsByUserIdSlice.actions.SET;
export const failGetIconsByUserId = getIconsByUserIdSlice.actions.FAILED;
export const clearGetIconsByUserId = getIconsByUserIdSlice.actions.CLEAR;

export default getIconsByUserIdSlice.reducer;
