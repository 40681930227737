import React from 'react';

import ResultsBox from 'components/module/ResultsBox';

import { StatsSubscribersGeneratedProps } from './StatsSubscribers.props';
import { Container, HeadingTypo, Description } from './StatsSubscribers.style';
const StatsSubscribersView = (
  props: StatsSubscribersGeneratedProps
): JSX.Element => {
  const { subscriberDetails } = props;

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const monthList = [];
  let annualSum = 0;

  for (let x = 1; x <= currentMonth; x++) {
    const formattedMonth = x < 10 ? `0${x}` : x;
    const keyValue = `${currentYear}-${formattedMonth}`;
    monthList.push(`${currentYear}-${formattedMonth}`);

    if (subscriberDetails?.subscriberMap !== undefined) {
      if (subscriberDetails?.subscriberMap[keyValue] !== undefined) {
        annualSum = annualSum + subscriberDetails.subscriberMap[keyValue];
      }
    }
  }

  let monthlyProgress =
    (subscriberDetails?.subscriberMap[monthList[monthList.length - 1]] /
      subscriberDetails?.subscriberMonthlyGoal) *
    100;

  let annualProgress =
    (annualSum / subscriberDetails?.subscriberAnnualGoal) * 100;

  if (isNaN(monthlyProgress) === true) {
    monthlyProgress = 0;
  }

  if (isNaN(annualProgress) === true) {
    annualProgress = 0;
  }

  monthlyProgress = monthlyProgress > 100 ? 100 : monthlyProgress;
  annualProgress = annualProgress > 100 ? 100 : annualProgress;

  return (
    <Container>
      <ResultsBox
        outerProgress={annualProgress}
        midProgress={monthlyProgress}
        innerProgress={0}
      >
        <HeadingTypo variant="title3" color="primary" weight="800">
          Subscribers
        </HeadingTypo>
        <Description variant="widget" color="black" weight="500">
          List: {subscriberDetails?.list || 0}
        </Description>
        <Description variant="widget" color="black" weight="500">
          Open Rate: {subscriberDetails?.openRate.toFixed(2) * 100}%
        </Description>
        <Description variant="widget" color="black" weight="500">
          Click Through: {subscriberDetails?.clickRate.toFixed(2) * 100}%
        </Description>
        <Description variant="widget" color="black" weight="500">
          Unsubscribe: {subscriberDetails?.unsubscribe}
        </Description>
        <Description variant="widget" color="black" weight="500">
          Top Channel: {subscriberDetails?.sourceMaxName}
        </Description>
      </ResultsBox>
    </Container>
  );
};

export default React.memo(StatsSubscribersView);
