import type { PayloadAction } from '@reduxjs/toolkit';
import { getColorsByUserId as getColorsByUserIdApi } from 'api/color';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetColorsByUserId,
  setGetColorsByUserId,
  failGetColorsByUserId,
} from './getColorsByUserId.slice';
import {
  GetColorsByUserIdMeta,
  GetColorsByUserIdPayload,
} from './getColorsByUserId.type';

function* getColorsByUserIdRequest(
  action: PayloadAction<GetColorsByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getColorsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetColorsByUserId(data));
    } catch (err) {
      yield put(failGetColorsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetColorsByUserId('No token provided'));
  }
}

function* getColorsByUserIdSuccess(
  action: PayloadAction<GetColorsByUserIdPayload>
) {
  // yield function here
}

function* getColorsByUserIdFail(
  action: PayloadAction<GetColorsByUserIdPayload>
) {
  yield put(error(String(`Colors - ${action.payload}`)));
}

function* GetColorsByUserIdWatcher() {
  yield takeLatest(requestGetColorsByUserId.type, getColorsByUserIdRequest);
  yield takeLatest(setGetColorsByUserId.type, getColorsByUserIdSuccess);
  yield takeLatest(failGetColorsByUserId.type, getColorsByUserIdFail);
}

export default GetColorsByUserIdWatcher;
