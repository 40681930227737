import React from 'react';

// import { useTheme } from 'utils/Theme';
import ResultsBox from 'components/module/ResultsBox';

import { StatsGaGeneratedProps } from './StatsGa.props';
import { Container, HeadingTypo, Description } from './StatsGa.style';

const StatsGaView = (props: StatsGaGeneratedProps): JSX.Element => {
  const {
    pageViews,
    bounceRate,
    goalConversionRate,
    avgTime,
    topSource,
    shouldGaRender,
    monthlyProgress,
    annualProgress,
  } = props;

  return (
    <Container>
      {shouldGaRender && (
        <ResultsBox
          outerProgress={annualProgress}
          midProgress={monthlyProgress}
          innerProgress={25}
        >
          <HeadingTypo variant="title3" color="primary" weight="800">
            Website
          </HeadingTypo>
          <Description variant="widget" color="black" weight="500">
            Traffic to Site: {pageViews.toLocaleString()}
          </Description>
          <Description variant="widget" color="black" weight="500">
            Bounce Rate: {bounceRate}%
          </Description>
          <Description variant="widget" color="black" weight="500">
            Goal Conversions: {goalConversionRate}
          </Description>
          <Description variant="widget" color="black" weight="500">
            Top Source: {topSource}
          </Description>
          <Description variant="widget" color="black" weight="500">
            Time Spent: {avgTime} seconds
          </Description>
        </ResultsBox>
      )}
    </Container>
  );
};

export default React.memo(StatsGaView);
