import React from 'react';

import { KitLogoProps } from './KitLogo.props';
import { Container, ImageName, ImageContainer, Image } from './KitLogo.style';

const KitLogo = (props: KitLogoProps): JSX.Element => {
  const { name, url, autoWidth = false } = props;
  return (
    <Container onClick={props.onClick}>
      <ImageContainer>
        <Image
          alt={`logo-${name}`}
          src={url}
          width={autoWidth ? 'auto' : '185'}
          height={'95'}
        ></Image>
      </ImageContainer>
      <ImageName color={'primary'}>{name}</ImageName>
    </Container>
  );
};

export default React.memo(KitLogo);
