import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ICON_URL = `${BASE_URL}/icon`;

export const upsertIcon = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${ICON_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const deleteIcon = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${ICON_URL}/deleteIcon`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getIconsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${ICON_URL}/getIconsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};