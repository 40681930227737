import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertPageWriteupMeta,
  UpsertPageWriteupPayload
} from './upsertPageWriteup.type';

const initialState: AsyncState<UpsertPageWriteupMeta, UpsertPageWriteupPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertPageWriteupMeta, UpsertPageWriteupPayload>();

const upsertPageWriteupSlice = createSlice({
  name: 'upsertPageWriteup',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertPageWriteup = upsertPageWriteupSlice.actions.REQUEST;
export const setUpsertPageWriteup = upsertPageWriteupSlice.actions.SET;
export const failUpsertPageWriteup = upsertPageWriteupSlice.actions.FAILED;
export const clearUpsertPageWriteup = upsertPageWriteupSlice.actions.CLEAR;

export default upsertPageWriteupSlice.reducer;
