import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetGaStatsMeta, GetGaStatsPayload } from './getGaStats.type';

const initialState: AsyncState<GetGaStatsMeta, GetGaStatsPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetGaStatsMeta, GetGaStatsPayload>();

const getGaStatsSlice = createSlice({
  name: 'getGaStats',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetGaStats = getGaStatsSlice.actions.REQUEST;
export const setGetGaStats = getGaStatsSlice.actions.SET;
export const failGetGaStats = getGaStatsSlice.actions.FAILED;
export const clearGetGaStats = getGaStatsSlice.actions.CLEAR;

export default getGaStatsSlice.reducer;
