import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetAssetsByUserIdMeta,
  GetAssetsByUserIdPayload
} from './getAssetsByUserId.type';

const initialState: AsyncState<GetAssetsByUserIdMeta, GetAssetsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetAssetsByUserIdMeta, GetAssetsByUserIdPayload>();

const getAssetsByUserIdSlice = createSlice({
  name: 'getAssetsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetAssetsByUserId = getAssetsByUserIdSlice.actions.REQUEST;
export const setGetAssetsByUserId = getAssetsByUserIdSlice.actions.SET;
export const failGetAssetsByUserId = getAssetsByUserIdSlice.actions.FAILED;
export const clearGetAssetsByUserId = getAssetsByUserIdSlice.actions.CLEAR;

export default getAssetsByUserIdSlice.reducer;
