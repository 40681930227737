import type { PayloadAction } from '@reduxjs/toolkit';
import { getClients as getClientsApi } from 'api/client';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetClients,
  setGetClients,
  failGetClients,
} from './getClients.slice';
import { GetClientsMeta, GetClientsPayload } from './getClients.type';

function* getClientsRequest(action: PayloadAction<GetClientsMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getClientsApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetClients(data));
    } catch (err) {
      yield put(failGetClients(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetClients('No token provided'));
  }
}

function* getClientsSuccess(action: PayloadAction<GetClientsPayload>) {
  // yield function here
}

function* getClientsFail(action: PayloadAction<GetClientsPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* GetClientsWatcher() {
  yield takeLatest(requestGetClients.type, getClientsRequest);
  yield takeLatest(setGetClients.type, getClientsSuccess);
  yield takeLatest(failGetClients.type, getClientsFail);
}

export default GetClientsWatcher;
