import styled from '@emotion/styled';
import Typography from 'components/base/Typography/Typography.view';
import Dropdown from 'react-dropdown';
import { pxToRem } from 'utils/Theme';

export const PREFIX = 'dropdownSelect';

export const Container = styled.div<{ label?: string }>`
  width: 100%;
  position: relative;
`;

const font = `
    font-size: ${pxToRem(14)};
    line-height: 21px;
    font-weight: 400;`;

export const StyledDropdown = styled(Dropdown)<{
  dark?: boolean;
  grey?: boolean;
  disabled?: boolean;
}>`
  .${PREFIX}Container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 36px;
    padding: 7px 17px;

    background: ${(props) => props.theme.brand.primary};
    color: ${(props) => props.theme.grey.noshade};
    z-index: 1;
    position: relative;
  }

  .${PREFIX}Placeholder {
    ${font};
    margin-right: 8px;
  }

  .${PREFIX}Menu {
    padding: 0 8.5px;
    border-radius: 0px 0px 20px 20px;

    transform-origin: top;
    // animation: fadeOut 0.26s ease;
    z-index: 2;

    overflow-y: scroll;
    max-height: 100px;

    &::before {
      background: ${(props) => props.theme.brand.lilacLight};
      content: '';
      display: block;
      height: 5px;
      margin-top: -5px;
      position: relative;
    }
  }

  @keyframes fadeOut {
    0% {
      height: auto;
      transform: scaleY(0);
    }

    100% {
      height: 0;
      transform: scaleY(1);
    }
  }

  .Dropdown-option {
    ${font};
    background: ${(props) => props.theme.brand.blushLight};
    padding: 7px 24px;
    cursor: pointer;
    text-wrap: nowrap;

    &:hover {
      background: #ede3e2;
    }

    &:nth-child(odd) {
      background: ${(props) => props.theme.brand.lilacLight};

      &:hover {
        background: #d6dceb;
      }
    }

    &:last-child {
      border-radius: 0px 0px 20px 20px;
    }
  }

  .Dropdown-option.is-selected {
    background: ${(props) => props.theme.brand.blushLight};

    &:nth-child(odd) {
      background: ${(props) => props.theme.brand.lilacLight};
    }
  }
`;

export const ArrowContainer = styled.div<{ size?: string; flipped?: boolean }>`
  color: ${({ theme }) => theme.grey.noshade};
  transition: transform 0.26s ease;
  transform: rotate(${({ flipped }) => (flipped ? '180deg' : '')});
`;

export const Label = styled(Typography)`
  margin-bottom: 4px;
`;

export const Error = styled(Typography)`
  margin-top: 4px;
`;
