import type { PayloadAction } from '@reduxjs/toolkit';
import { listGaWebsites } from 'api/ga';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGaListWebsites,
  setGaListWebsites,
  failGaListWebsites,
} from './gaListWebsites.slice';
import {
  GaListWebsitesMeta,
  GaListWebsitesPayload,
} from './gaListWebsites.type';

function* gaListWebsitesRequest(action: PayloadAction<GaListWebsitesMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        listGaWebsites,
        action.payload,
        state.auth.token
      );
      yield put(setGaListWebsites(data));
    } catch (err) {
      yield put(failGaListWebsites(err.response.data.message));
    }
  } else {
    yield put(failGaListWebsites('No token provided'));
  }
}

function* gaListWebsitesSuccess(action: PayloadAction<GaListWebsitesPayload>) {
  // yield function here
}

function* gaListWebsitesFail(action: PayloadAction<GaListWebsitesPayload>) {
  // yield function here
}

function* GaListWebsitesWatcher() {
  yield takeLatest(requestGaListWebsites.type, gaListWebsitesRequest);
  yield takeLatest(setGaListWebsites.type, gaListWebsitesSuccess);
  yield takeLatest(failGaListWebsites.type, gaListWebsitesFail);
}

export default GaListWebsitesWatcher;
