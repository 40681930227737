import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetFbLikesMeta,
  GetFbLikesPayload
} from './getFbLikes.type';

const initialState: AsyncState<GetFbLikesMeta, GetFbLikesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetFbLikesMeta, GetFbLikesPayload>();

const getFbLikesSlice = createSlice({
  name: 'getFbLikes',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetFbLikes = getFbLikesSlice.actions.REQUEST;
export const setGetFbLikes = getFbLikesSlice.actions.SET;
export const failGetFbLikes = getFbLikesSlice.actions.FAILED;
export const clearGetFbLikes = getFbLikesSlice.actions.CLEAR;

export default getFbLikesSlice.reducer;
