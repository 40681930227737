import React from 'react';

import Typography from 'components/base/Typography';

// import { useTheme } from 'utils/Theme';
import { KeywordsGeneratedProps } from './Keywords.props';
import {
  Container,
  HeadingTypo,
  SubHeading,
  Section,
  TextSection,
} from './Keywords.style';

const KeywordsView = (props: KeywordsGeneratedProps) => {
  const { keywords } = props;

  let top10 = [];
  let top20 = [];
  if (keywords !== undefined) {
    top10 = keywords.slice(0, 10).map((element: any) => {
      return (
        <tr key={element.position}>
          <td>{element.keys[0]}</td>
          <td className="align-center">
            {Math.round(element.position * 100) / 100}
          </td>
          <td className="align-center">{element.impressions}</td>
          <td className="align-center">{element.clicks}</td>
        </tr>
      );
    });

    top20 = keywords.slice(11, 21).map((element: any) => {
      return (
        <tr key={element.position}>
          <td>{element.keys[0]}</td>
          <td className="align-center">
            {Math.round(element.position * 100) / 100}
          </td>
          <td className="align-center">{element.impressions}</td>
          <td className="align-center">{element.clicks}</td>
        </tr>
      );
    });
  }

  return (
    <Container>
      <HeadingTypo variant="title3" color="primary" weight="800">
        Keywords
      </HeadingTypo>
      <Section>
        <SubHeading variant="title4" color="primary">
          Top 10 Keywords
        </SubHeading>
        <table className="table-stats">
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Keyword</th>
              <th className="align-center">Ranking</th>
              <th className="align-center">Impressions</th>
              <th className="align-center">Clicks</th>
            </tr>
          </thead>
          <tbody>{top10}</tbody>
        </table>
      </Section>
      <Section>
        <SubHeading variant="title4" color="primary">
          Top 20 Keywords
        </SubHeading>
        <table className="table-stats">
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Keyword</th>
              <th className="align-center">Ranking</th>
              <th className="align-center">Impressions</th>
              <th className="align-center">Clicks</th>
            </tr>
          </thead>
          <tbody>{top20}</tbody>
        </table>
      </Section>
      {/* <TextSection style={{ marginTop: '14px' }}>
        <SubHeading variant="title4" color="primary">
          Keywords in Top 500
        </SubHeading>
        <Typography
          variant="widget"
          color="black"
          weight="800"
          style={{ marginLeft: '30px' }}
        >
          100
        </Typography>
      </TextSection>
      <TextSection>
        <SubHeading variant="title4" color="primary">
          Keywords in Top 100
        </SubHeading>
        <Typography
          variant="widget"
          color="black"
          weight="800"
          style={{ marginLeft: '30px' }}
        >
          100
        </Typography>
      </TextSection> */}
    </Container>
  );
};

export default KeywordsView;
