import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { requestComputeKpiFans } from 'store/computeKpiFans/computeKpiFans.slice';
import { requestGetFbAttributes } from 'store/getFbAttributes/getFbAttributes.slice';
import { requestGetFbLikes } from 'store/getFbLikes/getFbLikes.slice';
import { requestGetIgAttributes } from 'store/getIgAttributes/getIgAttributes.slice';
import { requestGetLinkedinFollowers } from 'store/getLinkedinFollowers/getLinkedinFollowers.slice';
import { requestTestLinkedinConnection } from 'store/testLinkedinConnection/testLinkedinConnection.slice';

import { FansPublicProps } from './Fans.props';
import FansView from './Fans.view';

const Fans = (props: FansPublicProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const formState =
    useAppSelector((state: RootState) => state.formClient) || undefined;

  const authState =
    useAppSelector((state: RootState) => state.auth) || undefined;

  let userId: string | undefined = undefined;
  if (authState.type === 'admin') {
    userId = formState.user?.id || undefined;
  } else if (authState.type === 'client') {
    userId = authState.auth_id || undefined;
  }

  let fbLikes = 0;
  const fbResponse =
    useAppSelector((state: RootState) => state.getFbLikes.data) || undefined;
  if (fbResponse !== undefined) {
    fbLikes = fbResponse.fan_count;
  }

  const linkedinFollowerCount =
    useAppSelector(
      (state: RootState) => state.getLinkedinFollowers.data?.data.followerCount
    ) || 0;

  const contentStats =
    useAppSelector((state: RootState) => state.getContentStats.data) ||
    undefined;

  const linkedinConnectionStatus =
    useAppSelector(
      (state: RootState) =>
        state.testLinkedinConnection.data?.data.connectionStatus
    ) || false;

  const igFollowers =
    useAppSelector(
      (state: RootState) => state.getIgDetails.data?.followers_count
    ) || 0;

  const kpiFans =
    useAppSelector((state: RootState) => state.computeKpiFans.data) ||
    undefined;

  const fbAttributes =
    useAppSelector((state: RootState) => state.getFbAttributes.data?.data) ||
    undefined;

  const getFbAttributesHandler = () => {
    if (userId !== undefined) {
      dispatch(
        requestGetFbAttributes({
          client_id: userId,
        })
      );
    }
  };

  const getFbLikesHandler = () => {
    dispatch(
      requestGetFbLikes({
        pageId: fbAttributes.pageId,
        accessToken: fbAttributes.accessToken,
      })
    );
  };

  const getIgAttributesHandler = () => {
    if (userId !== undefined) {
      dispatch(
        requestGetIgAttributes({
          client_id: userId,
        })
      );
    }
  };

  const testLinkedinConnectionHandler = () => {
    if (userId !== undefined) {
      dispatch(
        requestTestLinkedinConnection({
          user_id: userId,
        })
      );
    }
  };

  const getLinkedinFollowerCountHandler = () => {
    if (userId !== undefined) {
      dispatch(
        requestGetLinkedinFollowers({
          user_id: userId,
        })
      );
    }
  };

  const getKpiFans = () => {
    if (userId !== undefined) {
      dispatch(
        requestComputeKpiFans({
          user_id: userId,
        })
      );
    }
  };

  useEffect(() => {
    getFbAttributesHandler();
    getIgAttributesHandler();
    getKpiFans();
    testLinkedinConnectionHandler();
  }, []);

  useEffect(() => {
    if (linkedinConnectionStatus === true) {
      getLinkedinFollowerCountHandler();
    }
  }, [linkedinConnectionStatus]);

  useEffect(() => {
    if (fbAttributes !== undefined) {
      if (fbAttributes.isIntegrated === true) {
        getFbLikesHandler();
      }
    }
  }, [fbAttributes]);

  let fanOuterRing = 0;
  let fanMiddleRing = 0;
  if (kpiFans !== undefined) {
    const data = kpiFans.data;
    fanOuterRing =
      ((data.igFollowers + data.fbLikers) /
        (data.fbAnnualGoal + data.igAnnualGoal)) *
      100;

    fanMiddleRing =
      ((data.fbLikersMonth + data.igFollowersMonth) /
        (data.fbMonthlyGoal + data.igMonthlyGoal)) *
      100;

    fanOuterRing = fanOuterRing > 100 ? 100 : fanOuterRing;
  }

  const generatedProps = {
    fbLikes,
    igFollowers,
    linkedinFollowerCount,
    contentStats,
    fanOuterRing,
    fanMiddleRing,
  };
  return <FansView {...generatedProps} />;
};

export default Fans;
