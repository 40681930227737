import axios from 'axios';
import { API, FB } from 'consts/api';
import { FbListPagesMeta } from 'store/fbListPages/fbListPages.type';
import { GetFbAttributesMeta } from 'store/getFbAttributes/getFbAttributes.type';
import { GetFbAttributesByTokenMeta } from 'store/getFbAttributesByToken/getFbAttributesByToken.type';
import { GetFbLikesMeta } from 'store/getFbLikes/getFbLikes.type';
import { IssueFbTokenMeta } from 'store/issueFbToken/issueFbToken.type';
import { UpdateFbPageIdMeta } from 'store/updateFbPageId/updateFbPageId.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const FB_URL = `${BASE_URL}/fb`;

export const issueLongLivedToken = (
  payload: IssueFbTokenMeta,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${FB_URL}/issueLongLivedToken`, payload, config);
};

export const getFbAttributes = (
  payload: GetFbAttributesMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${FB_URL}/getFbAttributes`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFbAttributesByToken = (
  payload: GetFbAttributesByTokenMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${FB_URL}/getFbAttributesByToken`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const listFbPages = (payload: FbListPagesMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${FB_URL}/listFbAssets`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePageId = (payload: UpdateFbPageIdMeta, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`${FB_URL}/updatePageId`, payload, config);
};

export const getFbLikes = (payload: GetFbLikesMeta, token: string) => {
  const params = {
    fields: 'id,fan_count',
    access_token: payload.accessToken,
  };

  return axios({
    method: 'get',
    url: `${FB.FACEBOOK_API}/${payload.pageId}`,
    params: params,
  });
};
