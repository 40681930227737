import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  cursor: pointer;
  display: inline-block;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-conten: flex-start;
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
`;

export const InnerCheck = styled.span`
  width: 6px;
  height: 12px;
  border: solid #070550;
  border-radius: 2px;
  border-width: 0 3px 3px 0;
  position: absolute;
  left: 50%;
  top: 35%;
  color: ${(props) => props.theme.brand.primary};
  transform: translate(-50%, -40%) rotate(45deg);
`;

export const CustomCheckbox = styled.span<{
  checked: boolean;
}>`
  display: block;
  background: rgba(121, 155, 208, 0.18);
  width: 16px;
  height: 18px;
  position: relative;
`;

export const Label = styled(Typography)`
  margin-left: 11px;
`;
