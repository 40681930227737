import type { PayloadAction } from '@reduxjs/toolkit';
import { genericAction as genericActionApi } from 'api/client';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestGetClients } from 'store/getClients/getClients.slice';

import {
  requestClientGenericAction,
  setClientGenericAction,
  failClientGenericAction,
} from './clientGenericAction.slice';
import {
  ClientGenericActionMeta,
  ClientGenericActionPayload,
} from './clientGenericAction.type';

function* clientGenericActionRequest(
  action: PayloadAction<ClientGenericActionMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        genericActionApi,
        action.payload,
        state.auth.token
      );
      yield put(setClientGenericAction(data));
    } catch (err) {
      yield put(failClientGenericAction(err.response?.data?.message || err));
    }
  } else {
    yield put(failClientGenericAction('No token provided'));
  }
}

function* clientGenericActionSuccess(
  action: PayloadAction<ClientGenericActionPayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(requestGetClients({}));
}

function* clientGenericActionFail(
  action: PayloadAction<ClientGenericActionPayload>
) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* ClientGenericActionWatcher() {
  yield takeLatest(requestClientGenericAction.type, clientGenericActionRequest);
  yield takeLatest(setClientGenericAction.type, clientGenericActionSuccess);
  yield takeLatest(failClientGenericAction.type, clientGenericActionFail);
}

export default ClientGenericActionWatcher;
