import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { UpsertClientMeta, UpsertClientPayload } from './upsertClient.type';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertClientMeta, UpsertClientPayload>();

const upsertClientSlice = createSlice({
  name: 'upsertClient',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertClient = upsertClientSlice.actions.REQUEST;
export const setUpsertClient = upsertClientSlice.actions.SET;
export const failUpsertClient = upsertClientSlice.actions.FAILED;
export const clearUpsertClient = upsertClientSlice.actions.CLEAR;

export default upsertClientSlice.reducer;
