import styled from '@emotion/styled';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export const DropSelect = styled.div<{
  shown: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  border-radius: 36px;
  padding: 12px 17px;
  background: ${(props) => props.theme.brand.primary};

  svg {
    margin-left: 8px;
    transition: transform 0.26s ease;
    ${({ shown }) => {
      if (shown) {
        return `
          transform: rotate(180deg);
        `;
      }
    }}
  }
`;

export const DropMenu = styled.div<{
  shown: boolean;
}>`
  padding: 0 8.5px;
  position: absolute;
  z-index: 999999;
  border-radius: 0px 0px 20px 20px;

  overflow: hidden;
  width: auto;

  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;

  ${({ shown }) => {
    if (shown) {
      return `
        height: auto;
        transform: scaleY(1);
      `;
    }
  }}
`;

export const Inner = styled.div`
  padding-top: 5px;
  top: -5px;
  position: relative;
  background: ${(props) => props.theme.brand.lilacLight};
  border-radius: 0px 0px 20px 20px;
`;

export const MenuItem = styled.div`
  padding: 7px 24px;
  background: ${(props) => props.theme.brand.blushLight};
  cursor: pointer;
  text-wrap: nowrap;

  &:hover {
    background: #ede3e2;
  }

  &:nth-child(odd) {
    background: ${(props) => props.theme.brand.lilacLight};

    &:hover {
      background: #d6dceb;
    }
  }

  &:last-child {
    border-radius: 0px 0px 20px 20px;
  }
`;
