import React from 'react';

import { HeaderProps } from './Header.props';
import { Container, Title } from './Header.style';

const Header = (props: HeaderProps): JSX.Element => {
  const { text, width } = props;
  return (
    <Container width={width}>
      <Title variant={'title1'}>{text}</Title>
    </Container>
  );
};

export default React.memo(Header);
