import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetFbAttributesByTokenMeta,
  GetFbAttributesByTokenPayload
} from './getFbAttributesByToken.type';

const initialState: AsyncState<GetFbAttributesByTokenMeta, GetFbAttributesByTokenPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetFbAttributesByTokenMeta, GetFbAttributesByTokenPayload>();

const getFbAttributesByTokenSlice = createSlice({
  name: 'getFbAttributesByToken',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetFbAttributesByToken = getFbAttributesByTokenSlice.actions.REQUEST;
export const setGetFbAttributesByToken = getFbAttributesByTokenSlice.actions.SET;
export const failGetFbAttributesByToken = getFbAttributesByTokenSlice.actions.FAILED;
export const clearGetFbAttributesByToken = getFbAttributesByTokenSlice.actions.CLEAR;

export default getFbAttributesByTokenSlice.reducer;
