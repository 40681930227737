import React, { useState } from 'react';

import Button from 'components/base/Button';
import Modal from 'components/layout/Modal';

import { ModalIntegrationProps } from './ModalIntegration.props';
import { Container, Title, Input } from './ModalIntegration.style';

const ModalIntegration = (props: ModalIntegrationProps): JSX.Element => {
  const { isOpen, onClickClose } = props;

  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Modal isOpen={isOpen} onClickClose={onClickClose}>
      <Container>
        <Title color={'primary'}>Connect a client app</Title>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={'Platform Name'}
        ></Input>
        <Input
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          label={'Login'}
        ></Input>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={'password'}
          label={'Password'}
        ></Input>
        <Button variant={'secondary'} text={'Connect'}></Button>
      </Container>
    </Modal>
  );
};

export default React.memo(ModalIntegration);
