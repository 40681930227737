import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertLogoMeta,
  UpsertLogoPayload
} from './upsertLogo.type';

const initialState: AsyncState<UpsertLogoMeta, UpsertLogoPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertLogoMeta, UpsertLogoPayload>();

const upsertLogoSlice = createSlice({
  name: 'upsertLogo',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertLogo = upsertLogoSlice.actions.REQUEST;
export const setUpsertLogo = upsertLogoSlice.actions.SET;
export const failUpsertLogo = upsertLogoSlice.actions.FAILED;
export const clearUpsertLogo = upsertLogoSlice.actions.CLEAR;

export default upsertLogoSlice.reducer;
