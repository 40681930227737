import React, { useEffect, lazy } from 'react';

import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { MAIN_ROUTES, CLIENT_ROUTES, ADMIN_ROUTES } from '../consts/routes';
import { Routes } from '../types/Routes';
import AdminRoutes from './Admin/admin.routes';
import ClientRoutes from './Client/client.routes';
import Demo from './Demo';
import Login from './Public/Login';
import ResetPassword from './Public/ResetPassword';

const LazyLogin = lazy(() => import('./Public/Login'));

export const ROUTES: Routes = {
  ROOT: {
    path: MAIN_ROUTES.DEMO,
    children: <Demo />,
    protected: false,
  },
  LOGIN: {
    path: MAIN_ROUTES.LOGIN,
    children: <LazyLogin />,
    protected: false,
  },
  RESET_PASSWORD: {
    path: MAIN_ROUTES.RESET_PASSWORD,
    children: <ResetPassword />,
    protected: false,
  },
  CLIENT_ROUTES: {
    path: CLIENT_ROUTES.ROOT,
    children: <ClientRoutes />,
    nested: true,
    protected: true,
  },
  ADMIN_ROUTES: {
    path: ADMIN_ROUTES.ROOT,
    children: <AdminRoutes />,
    nested: true,
    protected: true,
  },
};

const RoutesComponent = () => {
  const history = useHistory();
  const isAuth =
    useAppSelector((state: RootState) => state.auth.token) || false;
  const type = useAppSelector((state: RootState) => state.auth.type) || null;
  const currentPath = location.pathname;
  const { pathname } = location;

  useEffect(() => {
    if (isAuth) {
      // Redirects;
      if (type === 'client' && !currentPath.startsWith('/client')) {
        history.push(CLIENT_ROUTES.DASHBOARD);
      }
      if (type === 'admin' && !currentPath.startsWith('/admin')) {
        history.push(ADMIN_ROUTES.ACCOUNTS);
      }
    } else {
      if (
        !currentPath.startsWith('/forgot-password') &&
        !currentPath.startsWith('/verify') &&
        !currentPath.startsWith('/authenticate') &&
        !currentPath.startsWith('/success-token')
      ) {
        if (pathname.includes('/client')) {
          history.replace('/client/login');
        } else {
          history.replace('/admin/login');
        }
      }
    }
  }, [isAuth, type]);

  return (
    <Switch>
      {Object.values(ROUTES).map((r) => {
        return (
          <Route key={r.path} path={r.path}>
            {!isAuth && r.protected ? (
              <Redirect
                to={type === 'admin' ? '/admin/login' : '/client/login'}
              />
            ) : (
              r.children
            )}
          </Route>
        );
      })}
      <Route>
        <Redirect to="/client/login" />
      </Route>
    </Switch>
  );
};

export default RoutesComponent;
