import type { PayloadAction } from '@reduxjs/toolkit';
import { getDocumentsByUserId as getDocumentsByUserIdApi } from 'api/document';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetDocumentsById,
  setGetDocumentsById,
  failGetDocumentsById,
} from './getDocumentsById.slice';
import {
  GetDocumentsByIdMeta,
  GetDocumentsByIdPayload,
} from './getDocumentsById.type';

function* getDocumentsByIdRequest(action: PayloadAction<GetDocumentsByIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getDocumentsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetDocumentsById(data));
    } catch (err) {
      yield put(failGetDocumentsById(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetDocumentsById('No token provided'));
  }
}

function* getDocumentsByIdSuccess(
  action: PayloadAction<GetDocumentsByIdPayload>
) {
  // yield function here
}

function* getDocumentsByIdFail(action: PayloadAction<GetDocumentsByIdPayload>) {
  // yield function here
  yield put(error(String(`Documents - ${action.payload}`)));
}

function* GetDocumentsByIdWatcher() {
  yield takeLatest(requestGetDocumentsById.type, getDocumentsByIdRequest);
  yield takeLatest(setGetDocumentsById.type, getDocumentsByIdSuccess);
  yield takeLatest(failGetDocumentsById.type, getDocumentsByIdFail);
}

export default GetDocumentsByIdWatcher;
