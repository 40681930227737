import type { PayloadAction } from '@reduxjs/toolkit';
import { getAssetsByUserId as getAssetsByUserIdApi } from 'api/asset';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetAssetsByUserId,
  setGetAssetsByUserId,
  failGetAssetsByUserId,
} from './getAssetsByUserId.slice';
import {
  GetAssetsByUserIdMeta,
  GetAssetsByUserIdPayload,
} from './getAssetsByUserId.type';

function* getAssetsByUserIdRequest(
  action: PayloadAction<GetAssetsByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAssetsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetAssetsByUserId(data));
    } catch (err) {
      yield put(failGetAssetsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetAssetsByUserId('No token provided'));
  }
}

function* getAssetsByUserIdSuccess(
  action: PayloadAction<GetAssetsByUserIdPayload>
) {
  // yield function here
}

function* getAssetsByUserIdFail(
  action: PayloadAction<GetAssetsByUserIdPayload>
) {
  // yield function here
  yield put(error(String(`Assets - ${action.payload}`)));
}

function* GetAssetsByUserIdWatcher() {
  yield takeLatest(requestGetAssetsByUserId.type, getAssetsByUserIdRequest);
  yield takeLatest(setGetAssetsByUserId.type, getAssetsByUserIdSuccess);
  yield takeLatest(failGetAssetsByUserId.type, getAssetsByUserIdFail);
}

export default GetAssetsByUserIdWatcher;
