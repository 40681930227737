import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { saveAs } from 'file-saver';
import jszip from 'jszip';
import {
  Asset,
  Color,
  Icon,
  Logo,
  StyleGuide,
} from 'routes/Admin/DataInput/Forms/Kit/Kit.props';
import * as getAssetsByUserIdSlice from 'store/getAssetsByUserId/getAssetsByUserId.slice';
import * as getColorsByUserIdSlice from 'store/getColorsByUserId/getColorsByUserId.slice';
import { GetColorsByUserIdPayload } from 'store/getColorsByUserId/getColorsByUserId.type';
import * as getIconsByUserIdSlice from 'store/getIconsByUserId/getIconsByUserId.slice';
import * as getLogosByUserIdSlice from 'store/getLogosByUserId/getLogosByUserId.slice';
import * as getMockupsByUserIdSlice from 'store/getMockupsByUserId/getMockupsByUserId.slice';
import * as getStyleGuidesByUserIdSlice from 'store/getStyleGuidesByUserId/getStyleGuidesByUserId.slice';

import KitView from './Kit.view';

const Kit = (): JSX.Element => {
  const JSZip = jszip;
  const dispatch = useAppDispatch();

  const authId: string | null =
    useAppSelector((state: RootState) => state.auth.auth_id) || null;

  const brandName: string =
    useAppSelector(
      (state: RootState) => state.getClientById.data?.data?.account.brand_name
    ) || 'Client';

  useEffect(() => {
    if (authId) {
      dispatch(
        getColorsByUserIdSlice.requestGetColorsByUserId({ user_id: authId })
      );

      dispatch(
        getLogosByUserIdSlice.requestGetLogosByUserId({ user_id: authId })
      );

      dispatch(
        getIconsByUserIdSlice.requestGetIconsByUserId({ user_id: authId })
      );

      dispatch(
        getAssetsByUserIdSlice.requestGetAssetsByUserId({ user_id: authId })
      );

      dispatch(
        getMockupsByUserIdSlice.requestGetMockupsByUserId({ user_id: authId })
      );

      dispatch(
        getStyleGuidesByUserIdSlice.requestGetStyleGuidesByUserId({
          user_id: authId,
        })
      );
    }
  }, []);

  /**START COLORS FUNCTION */
  const getColors =
    useAppSelector((state: RootState) => state.getColorsByUserId) || null;
  const [colors, setColors] = useState<Color[]>([]);

  useEffect(() => {
    if (getColors && getColors.data) {
      setColors(getColors.data.data);
    }
  }, [getColors?.data]);

  /**START LOGOS FUNCTION */
  const getLogos =
    useAppSelector((state: RootState) => state.getLogosByUserId) || null;
  const [logos, setLogos] = useState<Logo[]>([]);

  useEffect(() => {
    if (getLogos && getLogos.data) {
      setLogos(getLogos.data.data);
    }
  }, [getLogos?.data]);

  /**START ICONS FUNCTION */
  const getIcons =
    useAppSelector((state: RootState) => state.getIconsByUserId) || null;
  const [icons, setIcons] = useState<Icon[]>([]);

  useEffect(() => {
    if (getIcons && getIcons.data) {
      setIcons(getIcons.data.data);
    }
  }, [getIcons?.data]);

  /**START ASSETS FUNCTION */
  const getAssets =
    useAppSelector((state: RootState) => state.getAssetsByUserId) || null;
  const [assets, setAssets] = useState<Asset[]>([]);

  useEffect(() => {
    if (getAssets && getAssets.data) {
      setAssets(getAssets.data.data);
    }
  }, [getAssets?.data]);

  /**START MOCKUPS FUNCTION */
  const getMockups =
    useAppSelector((state: RootState) => state.getMockupsByUserId) || null;
  const [mockups, setMockups] = useState<Asset[]>([]);

  useEffect(() => {
    if (getMockups && getMockups.data) {
      setMockups(getMockups.data.data);
    }
  }, [getMockups?.data]);

  /**START STYLE GUIDE FUNCTION */
  const getStyleGuide =
    useAppSelector((state: RootState) => state.getStyleGuidesByUserId) || null;
  const [styleGuides, setStyleGuides] = useState<any[]>([]);

  useEffect(() => {
    if (getStyleGuide && getStyleGuide.data) {
      setStyleGuides(getStyleGuide.data.data);
    }
  }, [getStyleGuide?.data]);

  const handleDownloadZip = (icons: Icon[], type: string) => {
    const zip = new JSZip();
    const photoZip = zip.folder(`files`);
    const isLightOnly = type === 'light' ? true : false;
    const files: any[] = icons
      .filter((icon: Icon) => icon.is_light === isLightOnly)
      .map((icon: Icon) => icon.icon_files)
      .flat();

    const list = files.map(async (file: any) => {
      const response = await fetch(new Request(file.file_path), {
        mode: 'no-cors',
      });
      const data = await response.blob();
      photoZip?.file(file.title, data);

      return data;
    });

    Promise.all(list).then(function (data: any) {
      console.log(data);
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${type}-iconfiles`);
      });
    });
  };

  const generatedProps = {
    getColors,
    colors,
    getLogos,
    logos,
    getIcons,
    icons,
    getAssets,
    assets,
    getMockups,
    mockups,
    getStyleGuide,
    styleGuides,
    handleDownloadZip,
    brandName,
  };
  return <KitView {...generatedProps} />;
};

export default Kit;
