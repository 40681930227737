import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div``;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography)`
  i {
    display: block;
    font-size: .8rem;
  }
`;

export const IconFiles = styled.div`
  margin-top: 25px;

  table {
    width: 100%;

    td,
    th {
      padding: 5px 7px;
      width: 50%;

      svg {
        top: 2px;
        position: relative;
      }
    }

    thead {
      text-align: left;

      tr {
        border-bottom: 2px solid ${(props) => props.theme.brand.twilightLight};
      }
    }

    tbody {
      tr + tr {
        border-top: 0.5px solid ${(props) => props.theme.brand.twilightLight};
      }
    }
  }
`;

export const DownloadableFiles = styled.a`
  font-size: 1rem;
  line-height: 1.5;
  font-family: Avenir;
  font-weight: 400;
  color: ${(props) => props.theme.brand.primary};
  text-align: left;
  margin: 0;
  text-decoration: none;
`;