import React, { useEffect, useState } from 'react';

import AngleDown from 'components/base/SVG/AngleDown';
import Typography from 'components/base/Typography';

// import { useTheme } from 'utils/Theme';
import {
  ButtonDropDownItems,
  ButtonDropDownProps,
} from './ButtonDropDown.props';
import {
  Container,
  DropSelect,
  DropMenu,
  MenuItem,
  Inner,
} from './ButtonDropDown.style';

const ButtonDropDown = (props: ButtonDropDownProps): JSX.Element => {
  // const theme = useTheme();
  const { label, dropdownItems, onItemClicked, style } = props;
  const menuElement: any = React.useRef<HTMLDivElement>(null);

  const [shown, setShown] = useState<boolean>(false);
  const dropdownClick = () => {
    setShown(shown ? false : true);
  };

  const [menuWidth, setMenuWidth] = useState<string>('auto');
  useEffect(() => {
    if (menuElement.current) {
      setMenuWidth(`${menuElement.current.clientWidth}px`);
    }
  }, [menuElement]);

  const itemClicked = (key: string) => {
    setShown(false);
    onItemClicked(key);
  };

  return (
    <Container
      style={{
        width: menuWidth,
        ...style,
      }}
    >
      <DropSelect shown={shown} onClick={dropdownClick}>
        <Typography variant="body" color="noshade">
          {label}
        </Typography>
        <AngleDown width={15} height={15} fill="#fff"></AngleDown>
      </DropSelect>
      <input type="hidden" />
      <DropMenu shown={shown} ref={menuElement}>
        <Inner>
          {dropdownItems &&
            dropdownItems.map((item: ButtonDropDownItems, index: number) => (
              <MenuItem onClick={() => itemClicked(item.key)} key={index}>
                <Typography
                  variant="widget"
                  color="black"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
        </Inner>
      </DropMenu>
    </Container>
  );
};

export default React.memo(ButtonDropDown);
