import styled from '@emotion/styled';
import BorderRadiusBg from 'resources/img/border-radius.png';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  position: relative;
`;

export const NavItems = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const Divider = styled.div`
  box-sizing: border-box;

  &:first-child {
    z-index: 2;
    position: relative;

    @media screen and (max-width: 767px) {
      & > div {
        width: 85%;
      }
    }
  }

  &:nth-child(2) {
    display: flex;
    flex-wrap: wrap;

    left: -8px;
    position: relative;

    @media screen and (max-width: 767px) {
      & {
        margin-left: 13px;

        > div {
          width: 85%;
        }
      }
    }
  }
`;

export const Item = styled.div<{
  checked: boolean;
}>`
  display: flex;
  align-items: center;

  background: ${({ checked }) => {
    if (checked) {
      return `rgba(121, 155, 208, 0.18);`
    } else {
      return `rgba(240, 201, 193, 0.18);`
    }
  }}
  border: 1px solid #799BD0;
  padding: 8px 9px;
  position: relative;

  width: 172px;
  display: inline-block;

  border-left-width: .5px;
  border-right-width: .5px;

  &:only-child {
    border-radius: 5px;
    border-color: #262262;
    border-right: 7px solid transparent;
    
    background: url(${BorderRadiusBg}) no-repeat;
    background-size: auto 100%;
    background-position: 100% 0;
    background-origin: border-box;
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  // flex-wrap: wrap;
`;

export const EditBox = styled.div<{
  wBorder?: boolean;
}>`
  padding: 8px;
  border: 3px solid
    ${(props) => (props.wBorder ? props.theme.brand.blush : 'transparent')};
  border-radius: 100%;
  display: inline-block;

  position: absolute;
  left: -50px;

  svg {
    display: block;
  }
`;
