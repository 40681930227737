import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  TestLinkedinConnectionMeta,
  TestLinkedinConnectionPayload,
} from './testLinkedinConnection.type';

const initialState: AsyncState<
  TestLinkedinConnectionMeta,
  TestLinkedinConnectionPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<TestLinkedinConnectionMeta, TestLinkedinConnectionPayload>();

const testLinkedinConnectionSlice = createSlice({
  name: 'testLinkedinConnection',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestTestLinkedinConnection =
  testLinkedinConnectionSlice.actions.REQUEST;
export const setTestLinkedinConnection =
  testLinkedinConnectionSlice.actions.SET;
export const failTestLinkedinConnection =
  testLinkedinConnectionSlice.actions.FAILED;
export const clearTestLinkedinConnection =
  testLinkedinConnectionSlice.actions.CLEAR;

export default testLinkedinConnectionSlice.reducer;
