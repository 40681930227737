import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeletePostMeta,
  DeletePostPayload
} from './deletePost.type';

const initialState: AsyncState<DeletePostMeta, DeletePostPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeletePostMeta, DeletePostPayload>();

const deletePostSlice = createSlice({
  name: 'deletePost',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeletePost = deletePostSlice.actions.REQUEST;
export const setDeletePost = deletePostSlice.actions.SET;
export const failDeletePost = deletePostSlice.actions.FAILED;
export const clearDeletePost = deletePostSlice.actions.CLEAR;

export default deletePostSlice.reducer;
