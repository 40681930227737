import axios from 'axios';
import { API } from 'consts/api';
import { GenerateReportMeta } from 'store/generateReport/generateReport.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const REPORT_URL = `${BASE_URL}/report`;

export const generateReport = (payload: GenerateReportMeta, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${REPORT_URL}/getReport?client_id=${payload.user_id}`,
    payload,
    config
  );
};
