import React from 'react';

import { SVGProps } from './SVG.props';

const AngleDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 9}
      viewBox="0 0 16 9"
      fill="none"
    >
      <path
        d="M7.293 8.707a1 1 0 001.414 0l6.364-6.364A1 1 0 0013.657.93L8 6.586 2.343.929A1 1 0 00.93 2.343l6.364 6.364zM7 6v2h2V6H7z"
        fill={fill || '#fff'}
      />
    </svg>
  );
};

export default AngleDown;
