import React from 'react';

import { BrandCardProps } from './BrandCard.props';
import {
  Container,
  ContentContainer,
  TitleContainer,
  Title,
  Content,
  Default,
  FloatTitleContainer,
} from './BrandCard.style';

const BrandCard = (props: BrandCardProps): JSX.Element => {
  const { title, content, style, floatTitle, threeRows } = props;

  const noContent = content === '' ? true : false;
  let titleComponent = (
    <TitleContainer>
      <Title variant={'title4'} color={'noshade'}>
        {title}
      </Title>
    </TitleContainer>
  );

  if (floatTitle === true) {
    titleComponent = (
      <FloatTitleContainer>
        <Title variant={'title4'} color={'noshade'}>
          {title}
        </Title>
      </FloatTitleContainer>
    );
  }

  const formattedContent =
    content === '' ? (
      <Default color={'primary'}>No Nonsense Experience</Default>
    ) : (
      <Content color={'primary'}>{content}</Content>
    );

  return (
    <Container style={style} isFloat={floatTitle} threeRows={threeRows}>
      {titleComponent}
      <ContentContainer noContent={noContent} isFloat={floatTitle}>
        {formattedContent}
      </ContentContainer>
    </Container>
  );
};

export default React.memo(BrandCard);
