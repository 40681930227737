import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetStrategyByUserIdMeta,
  GetStrategyByUserIdPayload
} from './getStrategyByUserId.type';

const initialState: AsyncState<GetStrategyByUserIdMeta, GetStrategyByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetStrategyByUserIdMeta, GetStrategyByUserIdPayload>();

const getStrategyByUserIdSlice = createSlice({
  name: 'getStrategyByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetStrategyByUserId = getStrategyByUserIdSlice.actions.REQUEST;
export const setGetStrategyByUserId = getStrategyByUserIdSlice.actions.SET;
export const failGetStrategyByUserId = getStrategyByUserIdSlice.actions.FAILED;
export const clearGetStrategyByUserId = getStrategyByUserIdSlice.actions.CLEAR;

export default getStrategyByUserIdSlice.reducer;
