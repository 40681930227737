import React from 'react';

import { SVGProps } from './SVG.props';

const PanelLoginClient = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 840 1024"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M840 0H0v1024h840V0z" fill="#262262" />
      <path
        d="M573 1024l-62.894-80L449 1024h124zM840 1024V717l-250 307h250z"
        fill="#799CD2"
      />
      <path
        d="M441.831 768.885c-47.735 47.13-138.643 33.779-138.643 33.779l172.855-170.666s13.523 89.756-34.212 136.887z"
        fill="#fff"
      />
      <path
        d="M476.044 631.997L303.189 802.664s-13.523-89.757 34.212-136.887c47.735-47.13 138.643-33.78 138.643-33.78z"
        fill="#F2C8C2"
      />
      <path
        d="M715.043 348.572c0 27.184-22.32 49.382-50.016 49.382-27.533 0-50.015-22.037-50.015-49.382 0-27.185 22.319-49.383 50.015-49.383 27.533.161 50.016 22.198 50.016 49.383z"
        fill="#4A56A6"
      />
      <path
        d="M829.41 0c-3.584 86.54-75.919 155.707-164.546 155.707S504.065 86.701 500.318 0h63.701c3.584 51.956 47.409 92.974 100.845 92.974 53.437 0 97.425-41.018 100.846-92.974h63.7z"
        fill="#F1562D"
      />
    </svg>
  );
};

export default PanelLoginClient;
