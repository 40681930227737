import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GaListWebsitesMeta,
  GaListWebsitesPayload,
} from './gaListWebsites.type';

const initialState: AsyncState<GaListWebsitesMeta, GaListWebsitesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GaListWebsitesMeta, GaListWebsitesPayload>();

const gaListWebsitesSlice = createSlice({
  name: 'gaListWebsites',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGaListWebsites = gaListWebsitesSlice.actions.REQUEST;
export const setGaListWebsites = gaListWebsitesSlice.actions.SET;
export const failGaListWebsites = gaListWebsitesSlice.actions.FAILED;
export const clearGaListWebsites = gaListWebsitesSlice.actions.CLEAR;

export default gaListWebsitesSlice.reducer;
