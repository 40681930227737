import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetLinkedinFollowersMeta,
  GetLinkedinFollowersPayload,
} from './getLinkedinFollowers.type';

const initialState: AsyncState<
  GetLinkedinFollowersMeta,
  GetLinkedinFollowersPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetLinkedinFollowersMeta, GetLinkedinFollowersPayload>();

const getLinkedinFollowersSlice = createSlice({
  name: 'getLinkedinFollowers',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetLinkedinFollowers =
  getLinkedinFollowersSlice.actions.REQUEST;
export const setGetLinkedinFollowers = getLinkedinFollowersSlice.actions.SET;
export const failGetLinkedinFollowers =
  getLinkedinFollowersSlice.actions.FAILED;
export const clearGetLinkedinFollowers =
  getLinkedinFollowersSlice.actions.CLEAR;

export default getLinkedinFollowersSlice.reducer;
