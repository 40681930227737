import type { PayloadAction } from '@reduxjs/toolkit';
import { getIgDetails } from 'api/ig';
import { RootState } from 'app/store';
import { success, error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetIgDetails,
  setGetIgDetails,
  failGetIgDetails,
} from './getIgDetails.slice';
import { GetIgDetailsMeta, GetIgDetailsPayload } from './getIgDetails.type';

function* getIgDetailsRequest(action: PayloadAction<GetIgDetailsMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getIgDetails, action.payload);
      yield put(setGetIgDetails(data));
    } catch (err) {
      yield put(failGetIgDetails(err.response.data.message));
    }
  } else {
    yield put(failGetIgDetails('No token provided'));
  }
}

function* getIgDetailsSuccess(action: PayloadAction<GetIgDetailsPayload>) {
  // yield function here
}

function* getIgDetailsFail(action: PayloadAction<GetIgDetailsPayload>) {
  // yield function here
}

function* GetIgDetailsWatcher() {
  yield takeLatest(requestGetIgDetails.type, getIgDetailsRequest);
  yield takeLatest(setGetIgDetails.type, getIgDetailsSuccess);
  yield takeLatest(failGetIgDetails.type, getIgDetailsFail);
}

export default GetIgDetailsWatcher;
