import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteAssetMeta,
  DeleteAssetPayload
} from './deleteAsset.type';

const initialState: AsyncState<DeleteAssetMeta, DeleteAssetPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteAssetMeta, DeleteAssetPayload>();

const deleteAssetSlice = createSlice({
  name: 'deleteAsset',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteAsset = deleteAssetSlice.actions.REQUEST;
export const setDeleteAsset = deleteAssetSlice.actions.SET;
export const failDeleteAsset = deleteAssetSlice.actions.FAILED;
export const clearDeleteAsset = deleteAssetSlice.actions.CLEAR;

export default deleteAssetSlice.reducer;
