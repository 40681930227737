import type { PayloadAction } from '@reduxjs/toolkit';
import { updateOrganizationUrn } from 'api/linkedin';
import { RootState } from 'app/store';
import { success, info } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestTestLinkedinConnection } from 'store/testLinkedinConnection/testLinkedinConnection.slice';

import {
  requestUpdateOrganizationUrn,
  setUpdateOrganizationUrn,
  failUpdateOrganizationUrn,
} from './updateOrganizationUrn.slice';
import {
  UpdateOrganizationUrnMeta,
  UpdateOrganizationUrnPayload,
} from './updateOrganizationUrn.type';

function* updateOrganizationUrnRequest(
  action: PayloadAction<UpdateOrganizationUrnMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateOrganizationUrn,
        action.payload,
        state.auth.token
      );
      yield put(setUpdateOrganizationUrn(data));
    } catch (err) {
      yield put(failUpdateOrganizationUrn(err.response.data.message));
    }
  } else {
    yield put(failUpdateOrganizationUrn('No token provided'));
  }
}

function* updateOrganizationUrnSuccess(
  action: PayloadAction<UpdateOrganizationUrnPayload>
) {
  const state: RootState = yield select();
  const client_id = state.formClient.user?.id;

  yield put(success('Organization connection updated'));
  yield put(info('Testing connection to Linkedin'));
  if (client_id) {
    yield put(
      requestTestLinkedinConnection({
        user_id: client_id,
      })
    );
  }
}

function* updateOrganizationUrnFail(
  action: PayloadAction<UpdateOrganizationUrnPayload>
) {
  // yield function here
}

function* UpdateOrganizationUrnWatcher() {
  yield takeLatest(
    requestUpdateOrganizationUrn.type,
    updateOrganizationUrnRequest
  );
  yield takeLatest(setUpdateOrganizationUrn.type, updateOrganizationUrnSuccess);
  yield takeLatest(failUpdateOrganizationUrn.type, updateOrganizationUrnFail);
}

export default UpdateOrganizationUrnWatcher;
