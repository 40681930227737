import React from 'react';

// import { useTheme } from 'utils/Theme';
import { GenericLoadingProps } from './GenericLoading.props';
import { Container } from './GenericLoading.style';

const GenericLoading = (props: GenericLoadingProps): JSX.Element => {
  // const theme = useTheme();
  return (
    <Container>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default React.memo(GenericLoading);
