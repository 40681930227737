import styled from '@emotion/styled';

import { Mode } from '../Sidebar/Sidebar.props';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const MobileMenuBar = styled.div<{
  mode: Mode;
}>`
  position: fixed;
  top: 0;
  background: ${(props) =>
    props.mode === 'admin'
      ? props.theme.brand.secondary
      : props.theme.brand.primary};

  width: 100%;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-right: 15px;
  }
`;

export const LogoContainer = styled.div<{
  mode: Mode;
}>`
  padding: 5px;
  background: ${(props) =>
    props.mode === 'admin'
      ? props.theme.brand.lilac
      : props.theme.brand.blush};
`;
