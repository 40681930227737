import type { PayloadAction } from '@reduxjs/toolkit';
import { testMailChimpConnection } from 'api/mail';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestTestMailChimpConnection,
  setTestMailChimpConnection,
  failTestMailChimpConnection,
} from './testMailChimpConnection.slice';
import {
  TestMailChimpConnectionMeta,
  TestMailChimpConnectionPayload,
} from './testMailChimpConnection.type';

function* testMailChimpConnectionRequest(
  action: PayloadAction<TestMailChimpConnectionMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        testMailChimpConnection,
        action.payload,
        state.auth.token
      );
      yield put(setTestMailChimpConnection(data));
    } catch (err) {
      yield put(failTestMailChimpConnection(err.response.data.message));
    }
  } else {
    yield put(failTestMailChimpConnection('No token provided'));
  }
}

function* testMailChimpConnectionSuccess(
  action: PayloadAction<TestMailChimpConnectionPayload>
) {
  // yield function here
}

function* testMailChimpConnectionFail(
  action: PayloadAction<TestMailChimpConnectionPayload>
) {
  // yield function here
}

function* TestMailChimpConnectionWatcher() {
  yield takeLatest(
    requestTestMailChimpConnection.type,
    testMailChimpConnectionRequest
  );
  yield takeLatest(
    setTestMailChimpConnection.type,
    testMailChimpConnectionSuccess
  );
  yield takeLatest(
    failTestMailChimpConnection.type,
    testMailChimpConnectionFail
  );
}

export default TestMailChimpConnectionWatcher;
