import React from 'react';

import { SVGProps } from './SVG.props';

const Dribble = (props: SVGProps): JSX.Element => {
  const { width, height, fill, stroke } = props;

  let circle = <circle cx={56.098} cy={56.098} r={56.098} fill={fill} />;
  if (fill === '#FFFFFF' || fill === '#ffffff') {
    circle = <React.Fragment />;
  }

  return (
    <svg
      width={width || 36}
      height={height || 36}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <circle cx={256} cy={256} r={256} fill={fill} />
      <path
        d="M323.63 354.79A435.87 435.87 0 00301 273.2c20.9-2.62 45-2 73 3.11a120.27 120.27 0 01-50.37 78.48zm-140.27-3.7c20.21-30.46 48.41-60.57 94.15-73.23a409.4 409.4 0 0124.15 88.89 121 121 0 01-45.65 9 119 119 0 01-72.65-24.66zM136.29 256v-3.83h.71c42.65 0 85.08-2.87 123-14 2.76 5.73 5.64 11.59 8.24 17.68-49.93 14.71-81.11 47.55-102.37 78.84A118.63 118.63 0 01136.29 256zm68.21-108.12a588.67 588.67 0 0144.33 68.81c-33.69 9.21-71.32 11.71-109.31 11.83a120.58 120.58 0 0164.98-80.64zm129 17c-16.84 20.55-38 34.64-62 44.2a620.54 620.54 0 00-43.7-69.41 122.11 122.11 0 0128.2-3.37 119.12 119.12 0 0177.55 28.54zM350 182a119.76 119.76 0 0125.67 70.6c-32.25-5.61-60-5.86-83.87-2-3.09-6.93-6.2-13.76-9.43-20.32C308.08 219.8 331.15 204.39 350 182zm-94-69.41A143.36 143.36 0 10399.36 256 143.32 143.32 0 00256 112.64z"
        fill={stroke}
      />
    </svg>
  );
};

export default Dribble;
