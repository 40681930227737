import React from 'react';

import { useTheme } from '@emotion/react';
import Button from 'components/base/Button';
import { TrashCan } from 'components/base/SVG';
import BoxOutlined from 'components/base/SVG/BoxOutlined';
import TrashCanOutlined from 'components/base/SVG/TrashCanOutlined';
import VertEllipsis from 'components/base/SVG/VertEllipsis';
import Typography from 'components/base/Typography';

import ActiveSymbol from '../ActiveSymbol';
import { ClientCardProps } from './ClientCard.props';
import {
  Container,
  DetailsRow,
  Names,
  Menu,
  ActionRow,
  ButtonsPart,
  IconButtons,
  IconTouchable,
  MainTitle,
} from './ClientCard.style';

const ClientCard = (props: ClientCardProps): JSX.Element => {
  const theme = useTheme();
  const {
    style,
    name,
    dateText,
    active,
    archived = false,
    onArchiveClick,
    onDeleteClick,
    onEditClick,
    onMenuClick,
    dragElement,
  } = props;

  return (
    <Container style={style} archived={archived}>
      <DetailsRow>
        <Names>
          <MainTitle variant="title4" color="black" weight="800">
            {name}
          </MainTitle>
          <Typography
            variant="body"
            color="twilight"
            style={{ lineHeight: '22px' }}
          >
            {dateText}
          </Typography>
        </Names>
        <Menu>
          <ActiveSymbol active={active}></ActiveSymbol>
          <IconTouchable style={{ marginLeft: '8px' }}>
            {dragElement}
          </IconTouchable>
        </Menu>
      </DetailsRow>
      <ActionRow>
        <ButtonsPart>
          <Button
            onClick={onEditClick}
            variant={'tertiary'}
            text={'Edit'}
            textColor="twilight"
            textVariant="body"
            textWeight="800"
            style={{
              padding: '11px 29px',
            }}
          ></Button>
        </ButtonsPart>
        <IconButtons>
          <IconTouchable onClick={onArchiveClick}>
            <BoxOutlined fill={theme.brand.twilight}></BoxOutlined>
          </IconTouchable>
          <IconTouchable onClick={onDeleteClick}>
            <TrashCanOutlined
              width={20}
              height={25}
              fill={theme.brand.twilight}
            ></TrashCanOutlined>
          </IconTouchable>
        </IconButtons>
      </ActionRow>
    </Container>
  );
};

export default React.memo(ClientCard);
