import React from 'react';

import NavyStacked from 'resources/logo/PascalSatoriStacked_Navy.png';
import OrangeStacked from 'resources/logo/PascalSatoriStacked_Orange.png';
import WhiteStacked from 'resources/logo/PascalSatoriStacked_White.png';

import { LogoProps } from './Logo.props';
import { Container } from './Logo.style';

const Logo = (props: LogoProps): JSX.Element => {
  const { width, variant } = props;

  let logo = WhiteStacked;
  switch (variant) {
    case 'stacked_white':
      logo = WhiteStacked;
      break;
    case 'stacked_navy':
      logo = NavyStacked;
      break;
    case 'stacked_orange':
      logo = OrangeStacked;
      break;
    default:
      break;
  }

  return (
    <Container>
      <img
        alt={'logo'}
        width={width === undefined ? '250' : width} // Change to width instead
        src={logo}
      ></img>
    </Container>
  );
};

export default React.memo(Logo);
