import styled from '@emotion/styled';
import TextField from 'components/base/TextField';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  margin-bottom: 16px;
`;

export const Input = styled(TextField)`
  width: 100%;
`;
