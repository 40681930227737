import axios from 'axios';
import { API } from 'consts/api';
import { GetGscSitesMeta } from 'store/getGscSites/getGscSites.type';
import { GetGscStatsMeta } from 'store/getGscStats/getGscStats.type';
import { UpdateGscSiteMeta } from 'store/updateGscSite/updateGscSite.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const GSC_URL = `${BASE_URL}/gsc`;

export const listWebsites = (payload: GetGscSitesMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GSC_URL}/listWebsites`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGscStats = (payload: GetGscStatsMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GSC_URL}/getGscStats`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateGscSite = (payload: UpdateGscSiteMeta, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`${GSC_URL}/updateGscSite`, payload, config);
};
