import type { PayloadAction } from '@reduxjs/toolkit';
import { testLinkedinConnection } from 'api/linkedin';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestTestLinkedinConnection,
  setTestLinkedinConnection,
  failTestLinkedinConnection,
} from './testLinkedinConnection.slice';
import {
  TestLinkedinConnectionMeta,
  TestLinkedinConnectionPayload,
} from './testLinkedinConnection.type';

function* testLinkedinConnectionRequest(
  action: PayloadAction<TestLinkedinConnectionMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        testLinkedinConnection,
        action.payload,
        state.auth.token
      );
      yield put(setTestLinkedinConnection(data));
    } catch (err) {
      yield put(failTestLinkedinConnection(err.response.data.message));
    }
  } else {
    yield put(failTestLinkedinConnection('No token provided'));
  }
}

function* testLinkedinConnectionSuccess(
  action: PayloadAction<TestLinkedinConnectionPayload>
) {
  // yield function here
}

function* testLinkedinConnectionFail(
  action: PayloadAction<TestLinkedinConnectionPayload>
) {
  // yield function here
}

function* TestLinkedinConnectionWatcher() {
  yield takeLatest(
    requestTestLinkedinConnection.type,
    testLinkedinConnectionRequest
  );
  yield takeLatest(
    setTestLinkedinConnection.type,
    testLinkedinConnectionSuccess
  );
  yield takeLatest(failTestLinkedinConnection.type, testLinkedinConnectionFail);
}

export default TestLinkedinConnectionWatcher;
