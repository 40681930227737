import styled from '@emotion/styled';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  line-height: 0;
  cursor: pointer;
  input {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
`;

export const CustomSwitch = styled.div<{
  checked: boolean;
  color: string;
}>`
  width: 46px;
  height: 14px;
  padding: 3px;

  background: ${({ color }) => color};
  border-radius: 250px;

  display: inline-block;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::after {
    content: '';
    background: ${(props) => props.theme.brand.lilac};
    border-radius: 250px;

    height: 14px;
    width: 14px;
    position: absolute;
    top: 3px;

    -webkit-transition: 0.4s;
    transition: 0.4s;

    ${({ checked }) => {
      if (checked) {
        return `
      -webkit-transform: translateX(31px);
      -ms-transform: translateX(31px);
      transform: translateX(31px);
      `;
      }
    }}
  }
`;
