import type { PayloadAction } from '@reduxjs/toolkit';
import { rearrangeOrderView } from 'api/rev';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestRearrangeOrderView,
  setRearrangeOrderView,
  failRearrangeOrderView,
} from './rearrangeOrderView.slice';
import {
  RearrangeOrderViewMeta,
  RearrangeOrderViewPayload,
} from './rearrangeOrderView.type';

function* rearrangeOrderViewRequest(
  action: PayloadAction<RearrangeOrderViewMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        rearrangeOrderView,
        action.payload,
        state.auth.token
      );
      yield put(setRearrangeOrderView(data));
    } catch (err) {
      yield put(failRearrangeOrderView(err.response.data.message));
    }
  } else {
    yield put(failRearrangeOrderView('No token provided'));
  }
}

function* rearrangeOrderViewSuccess(
  action: PayloadAction<RearrangeOrderViewPayload>
) {
  // yield function here
}

function* rearrangeOrderViewFail(
  action: PayloadAction<RearrangeOrderViewPayload>
) {
  // yield function here
}

function* RearrangeOrderViewWatcher() {
  yield takeLatest(requestRearrangeOrderView.type, rearrangeOrderViewRequest);
  yield takeLatest(setRearrangeOrderView.type, rearrangeOrderViewSuccess);
  yield takeLatest(failRearrangeOrderView.type, rearrangeOrderViewFail);
}

export default RearrangeOrderViewWatcher;
