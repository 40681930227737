import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetMockupsByUserIdMeta,
  GetMockupsByUserIdPayload
} from './getMockupsByUserId.type';

const initialState: AsyncState<GetMockupsByUserIdMeta, GetMockupsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetMockupsByUserIdMeta, GetMockupsByUserIdPayload>();

const getMockupsByUserIdSlice = createSlice({
  name: 'getMockupsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetMockupsByUserId = getMockupsByUserIdSlice.actions.REQUEST;
export const setGetMockupsByUserId = getMockupsByUserIdSlice.actions.SET;
export const failGetMockupsByUserId = getMockupsByUserIdSlice.actions.FAILED;
export const clearGetMockupsByUserId = getMockupsByUserIdSlice.actions.CLEAR;

export default getMockupsByUserIdSlice.reducer;
