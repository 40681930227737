import styled from '@emotion/styled';
import { pxToRem } from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const InputField = styled.input`
  padding: 2px 15px;
  border-radius: 6px;
  border: 0.5px solid ${(props) => props.theme.brand.lilac};
  outline: 0;
  box-shadow: none;
  background: transparent;
  line-height: 19px;

  font-size: ${pxToRem(14)};
  color: ${(props) => props.theme.brand.primary};

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
  }

  @media screen and (max-width: 465px) {
    max-width: none !important;
    width: 100%;
    padding: 7px 15px;
  }
`;

export const GoButton = styled.button`
  border-radius: 6px;
  background: ${(props) => props.theme.brand.lilac};
  border: 1px solid ${(props) => props.theme.brand.lilac};

  padding: 3.5px 7.5px;
  margin-left: 5px;

  display: flex;
  align-items: center;

  &:hover {
    background: rgba(122, 156, 209, 0.7);
  }

  @media screen and (max-width: 465px) {
    padding: 8.5px 7.5px;
  }
`;
