import React from 'react';

import { SVGProps } from './SVG.props';

const Whatsapp = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      height={height || 36}
      viewBox="0 0 512 512"
      width={width || 36}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm5.426 405.05c-.004 0 .004 0 0 0h-.063c-25.644-.01-50.843-6.44-73.222-18.644l-81.223 21.301 21.738-79.375c-13.41-23.227-20.465-49.578-20.453-76.574.035-84.453 68.77-153.16 153.223-153.16 40.984.015 79.457 15.968 108.383 44.918 28.93 28.953 44.851 67.437 44.836 108.363-.036 84.457-68.778 153.172-153.22 153.172zm0 0"
      />
      <path
        fill={fill}
        d="M261.477 124.469c-70.247 0-127.375 57.105-127.407 127.3-.007 24.055 6.727 47.481 19.473 67.75l3.027 4.817-12.867 46.98 48.2-12.64 4.652 2.758c19.55 11.601 41.965 17.738 64.816 17.746h.05c70.192 0 127.321-57.11 127.352-127.309.012-34.02-13.222-66.004-37.265-90.066-24.043-24.063-56.02-37.325-90.031-37.336zm74.906 182.035c-3.192 8.937-18.485 17.098-25.84 18.2-6.598.983-14.941 1.394-24.113-1.517-5.563-1.765-12.692-4.12-21.828-8.062-38.403-16.578-63.485-55.234-65.399-57.79-1.914-2.554-15.633-20.753-15.633-39.593 0-18.836 9.89-28.097 13.399-31.926 3.511-3.832 7.66-4.789 10.21-4.789 2.551 0 5.106.024 7.337.133 2.351.117 5.507-.894 8.613 6.57 3.191 7.665 10.848 26.5 11.805 28.415.957 1.917 1.593 4.152.32 6.707-1.277 2.554-5.52 8.066-9.57 13.09-1.7 2.105-3.914 3.98-1.68 7.812 2.23 3.828 9.918 16.363 21.297 26.512 14.625 13.039 26.96 17.078 30.789 18.996 3.824 1.914 6.058 1.593 8.293-.957 2.23-2.555 9.57-11.176 12.12-15.008 2.552-3.832 5.106-3.192 8.614-1.914 3.512 1.273 22.332 10.535 26.16 12.449 3.828 1.918 6.38 2.875 7.336 4.473.961 1.597.961 9.258-2.23 18.199zm0 0"
      />
    </svg>
  );
};

export default Whatsapp;
