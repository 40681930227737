import React from 'react';

// import { useTheme } from 'utils/Theme';
import { useTheme } from '@emotion/react';
import Spinner from 'components/base/Spinner';
import { Theme } from 'types/Theme';

import Typography from '../Typography';
import { TypographyProps } from '../Typography/Typography.props';
import { ButtonProps } from './Button.props';
import {
  ButtonContainer,
  LoadingContainer,
  IconContainer,
} from './Button.style';
const Button = (props: ButtonProps): JSX.Element => {
  const theme = useTheme();

  const {
    loading,
    icon,
    text,
    color,
    textWeight,
    iconPosition = 'after',
    variant = 'primary',
    takeFullWidth = false,
    size = 'lg',
    textColor,
    ...buttonProps
  } = props;

  let defaultTextColor: keyof Theme['brand'] | keyof Theme['grey'] | undefined =
    textColor;

  if (!defaultTextColor) {
    if (variant === 'primary') {
      defaultTextColor = 'noshade';
    } else if (variant === 'tertiary') {
      defaultTextColor = 'primary';
    } else if (variant === 'secondary') {
      defaultTextColor = 'noshade';
    } else if (variant === 'quaternary') {
      defaultTextColor = 'noshade';
    } else if (variant === 'unselected') {
      defaultTextColor = 'noshade';
    } else {
      defaultTextColor = 'shade5';
    }
  }

  const textVariant: TypographyProps['variant'] = 'body';
  const defaultTextWeight = textWeight === undefined ? '500' : textWeight;
  // unutilized
  // if (size === 'sm') {
  //   textVariant = 'label';
  //   textWeight = '500';
  // } else if (size === 'md') {
  //   textVariant = 'overline';
  //   textWeight = '900';
  // }

  const hasText = !!(text && text?.length > 0);

  return (
    <ButtonContainer
      size={size}
      color={color}
      variant={variant}
      hasText={hasText}
      iconPosition={iconPosition}
      takeFullWidth={takeFullWidth}
      {...buttonProps}
    >
      {icon && iconPosition === 'before' && (
        <IconContainer hasText={hasText} iconPosition={iconPosition}>
          {icon}
        </IconContainer>
      )}

      {text && (
        <Typography
          variant={props.textVariant || textVariant}
          color={defaultTextColor}
          weight={defaultTextWeight}
        >
          {text}
        </Typography>
      )}

      {loading && (
        <LoadingContainer>
          <Spinner fill={theme.brand.primary} width={24} height={24} />
        </LoadingContainer>
      )}

      {icon && iconPosition === 'after' && !loading && (
        <IconContainer hasText={hasText} iconPosition={iconPosition}>
          {icon}
        </IconContainer>
      )}
    </ButtonContainer>
  );
};

export default Button;
