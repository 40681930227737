import type { PayloadAction } from '@reduxjs/toolkit';
import { getPostsByUserId as getPostsByUserIdApi } from 'api/post';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetPostsByUserId,
  setGetPostsByUserId,
  failGetPostsByUserId,
} from './getPostsByUserId.slice';
import {
  GetPostsByUserIdMeta,
  GetPostsByUserIdPayload,
} from './getPostsByUserId.type';

function* getPostsByUserIdRequest(action: PayloadAction<GetPostsByUserIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getPostsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetPostsByUserId(data));
    } catch (err) {
      yield put(failGetPostsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetPostsByUserId('No token provided'));
  }
}

function* getPostsByUserIdSuccess(
  action: PayloadAction<GetPostsByUserIdPayload>
) {
  // yield function here
}

function* getPostsByUserIdFail(action: PayloadAction<GetPostsByUserIdPayload>) {
  // yield function here
  yield put(error('Updates - ' + String(action.payload)));
}

function* GetPostsByUserIdWatcher() {
  yield takeLatest(requestGetPostsByUserId.type, getPostsByUserIdRequest);
  yield takeLatest(setGetPostsByUserId.type, getPostsByUserIdSuccess);
  yield takeLatest(failGetPostsByUserId.type, getPostsByUserIdFail);
}

export default GetPostsByUserIdWatcher;
