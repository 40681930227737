import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetColorsByUserIdMeta,
  GetColorsByUserIdPayload
} from './getColorsByUserId.type';

const initialState: AsyncState<GetColorsByUserIdMeta, GetColorsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetColorsByUserIdMeta, GetColorsByUserIdPayload>();

const getColorsByUserIdSlice = createSlice({
  name: 'getColorsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetColorsByUserId = getColorsByUserIdSlice.actions.REQUEST;
export const setGetColorsByUserId = getColorsByUserIdSlice.actions.SET;
export const failGetColorsByUserId = getColorsByUserIdSlice.actions.FAILED;
export const clearGetColorsByUserId = getColorsByUserIdSlice.actions.CLEAR;

export default getColorsByUserIdSlice.reducer;
