import type { PayloadAction } from '@reduxjs/toolkit';
import { deletePost as deletePostApi } from 'api/post';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestDeletePost,
  setDeletePost,
  failDeletePost,
} from './deletePost.slice';
import { DeletePostMeta, DeletePostPayload } from './deletePost.type';

function* deletePostRequest(action: PayloadAction<DeletePostMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deletePostApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeletePost(data));
    } catch (err) {
      yield put(failDeletePost(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeletePost('No token provided'));
  }
}

function* deletePostSuccess(action: PayloadAction<DeletePostPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
}

function* deletePostFail(action: PayloadAction<DeletePostPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeletePostWatcher() {
  yield takeLatest(requestDeletePost.type, deletePostRequest);
  yield takeLatest(setDeletePost.type, deletePostSuccess);
  yield takeLatest(failDeletePost.type, deletePostFail);
}

export default DeletePostWatcher;
