import React, { lazy } from 'react';

import { useTheme } from '@emotion/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import AdminDashboardLayout from 'components/layout/AdminDashboard';
import { ADMIN_ROUTES } from 'consts/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import * as getClientsSlice from 'store/getClients/getClients.slice';
import { Routes, Route as TRoute } from 'types/Routes';

// import { Store } from 'types/store/Store';
// import Accounts from './Accounts';
// import ClientUpdates from './ClientUpdates';
// import DataInput from './DataInput';

const LazyAccountsPage = lazy(() => import('./Accounts'));
const LazyDataInputPage = lazy(() => import('./DataInput'));
const LazyClientUpdatesPage = lazy(() => import('./ClientUpdates'));

const ROUTES: Routes = {
  ACCOUNTS: {
    path: ADMIN_ROUTES.ACCOUNTS,
    children: <LazyAccountsPage />,
    title: 'Accounts',
    // icon: HomeIcon,
  },
  DATA_INPUT: {
    path: ADMIN_ROUTES.DATA_INPUT,
    children: <LazyDataInputPage />,
    title: 'Data Input',
    hideFromSidebar: true,
  },
  CLIENT_UPDATES: {
    path: ADMIN_ROUTES.CLIENT_UPDATES,
    children: <LazyClientUpdatesPage />,
    title: 'Client Updates',
    hideFromSidebar: true,
  },
};

const ROUTES_ARRAY: TRoute[] = Object.values(ROUTES).map((value) => value);

const AdminRoutes = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pathname } = location;
  const isAuth =
    useAppSelector((state: RootState) => state.auth.token) || false;

  const getThemeOverride = (): {
    onBack?: () => void;
  } => {
    if (pathname.includes('/admin/accounts')) {
      // dispatch(getClientsSlice.requestGetClients({}));
    }

    return {};
  };

  // auth guard for admin
  const type = useAppSelector((state: RootState) => state.auth.type) || null;
  if (type !== 'admin') {
    history.push('/admin/login');
  }

  return (
    <AdminDashboardLayout
      routes={ROUTES_ARRAY.filter((routes) => !routes.hideFromSidebar)}
      {...getThemeOverride()}
    >
      <Switch>
        {ROUTES_ARRAY.map((r) => (
          <Route
            key={`${r.path}`}
            path={`${r.path}`}
            exact={!r.nested}
            render={({ location }) =>
              isAuth ? (
                r.children
              ) : (
                <Redirect
                  to={{
                    pathname: '/admin/login',
                    state: { from: location },
                  }}
                />
              )
            }
          />
        ))}
        <Route>
          <Redirect to="/admin/accounts" />
        </Route>
      </Switch>
    </AdminDashboardLayout>
  );
};

export default AdminRoutes;
