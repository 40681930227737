import React from 'react';

import { useTheme } from '@emotion/react';
import Exit from 'components/base/SVG/Close';

import { PopupsProps } from './Popups.props';
import {
  Container,
  ExitContainer,
  ExitLogoContainer,
  PopupBody,
  PopupTitle,
  ChildrenContainer,
} from './Popups.style';

const Popups = (props: PopupsProps): JSX.Element => {
  const theme = useTheme();
  const { title, content, children } = props;
  return (
    <Container>
      <ExitContainer>
        <ExitLogoContainer>
          <Exit fill={theme.brand.secondary}></Exit>
        </ExitLogoContainer>
      </ExitContainer>
      <PopupTitle color={'primary'} variant={'title3'}>
        {title}
      </PopupTitle>
      <PopupBody color={'noshade'} variant={'body'}>
        {content}
      </PopupBody>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

export default React.memo(Popups);
