import React from 'react';

import { useAppDispatch } from 'app/hooks';
import { clearAuthentication } from 'store/auth/auth.slice';

import { AdminDashboardPublicProps } from './AdminDashboard.props';
import AdminDashboardView from './AdminDashboard.view';

const AdminDashboard = (props: AdminDashboardPublicProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isInnerRoute = (path: string) =>
    location.pathname.search(path.split('/')[2]) > 0;

  const logout = () => {
    dispatch(clearAuthentication());
  };

  const generatedProps = {
    ...props,
    isInnerRoute,
    logout,
  };
  return <AdminDashboardView {...generatedProps} />;
};

export default AdminDashboard;
