import type { PayloadAction } from '@reduxjs/toolkit';
import { getFbAttributes } from 'api/fb';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetFbAttributes,
  setGetFbAttributes,
  failGetFbAttributes,
} from './getFbAttributes.slice';
import {
  GetFbAttributesMeta,
  GetFbAttributesPayload,
} from './getFbAttributes.type';

function* getFbAttributesRequest(action: PayloadAction<GetFbAttributesMeta>) {
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getFbAttributes,
        action.payload,
        state.auth.token
      );
      yield put(setGetFbAttributes(data));
    } catch (err) {
      yield put(failGetFbAttributes(err.response.data.message));
    }
  } else {
    yield put(failGetFbAttributes('No token provided'));
  }
}

function* getFbAttributesSuccess(
  action: PayloadAction<GetFbAttributesPayload>
) {
  // yield function here
}

function* getFbAttributesFail(action: PayloadAction<GetFbAttributesPayload>) {
  // yield function here
}

function* GetFbAttributesWatcher() {
  yield takeLatest(requestGetFbAttributes.type, getFbAttributesRequest);
  yield takeLatest(setGetFbAttributes.type, getFbAttributesSuccess);
  yield takeLatest(failGetFbAttributes.type, getFbAttributesFail);
}

export default GetFbAttributesWatcher;
