export const BREAKPOINTS = {
  sm: `(max-width: 576px)`,
  md: `(min-width: 568px) and (max-width: 768px)`,
  lg: `(min-width: 769px) and (max-width: 992px)`,
  xl: `(min-width: 993px) and (max-width: 1200px)`,
  xxl: `(min-width: 1201px)`,
};

export interface WindowSpecs {
  width: number | string;
  height: number | string;
  original_height: number | string;
}