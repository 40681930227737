import React, { useState } from 'react';

import Border from 'components/base/Line';
import { ChevronLeft } from 'components/base/SVG';
import Typography from 'components/base/Typography';

import NavItem from '../NavItem';
import { SidebarMobileProps } from './SidebarMobile.props';
import {
  Container,
  InnerBar,
  SidebarHeading,
  NavListContainer,
  FooterContainer,
  FooterItem,
  BorderContainer,
  CloseToggle,
} from './SidebarMobile.style';
import { useEffect } from 'react';

const SidebarMobile = (props: SidebarMobileProps): JSX.Element => {
  // const theme = useTheme();
  const { mode, routes, isInnerRoute, isOpen, onClose, onLogout, permissions } =
    props;

  return (
    <Container isOpen={isOpen} onClick={props.onClick}>
      <InnerBar mode={mode} isOpen={isOpen}>
        <SidebarHeading>
          <CloseToggle onClick={() => props.onClose(false)}>
            <ChevronLeft height={22} width={20} fill="#fff"></ChevronLeft>
            <Typography variant="title4" color="noshade">
              Close
            </Typography>
          </CloseToggle>
          <Border variant={mode === 'admin' ? 'primary' : 'blush'}></Border>
        </SidebarHeading>
        <NavListContainer>
          {routes &&
            routes.map((route, index: number) => {
              if (
                mode === 'admin' ||
                route.title === 'Home' ||
                (route.permissionName &&
                  permissions &&
                  route.permissionName in permissions &&
                  (permissions as any)[route.permissionName])
              )
                return (
                  <NavItem
                    key={index}
                    to={route.path}
                    text={route.title || 'Untitled'}
                    mode={mode}
                    active={isInnerRoute(route.path)}
                    navClicked={() => {
                      onClose(false)
                    }}
                  ></NavItem>
                );
            })}
        </NavListContainer>
        <FooterContainer>
          <BorderContainer>
            <Border variant={mode === 'admin' ? 'primary' : 'blush'}></Border>
          </BorderContainer>
          <FooterItem color={'noshade'} variant={'title4'}>
            Settings
          </FooterItem>
          <FooterItem
            color={mode === 'admin' ? 'primary' : 'secondary'}
            variant={'title4'}
            onClick={onLogout}
          >
            Logout
          </FooterItem>
        </FooterContainer>
      </InnerBar>
    </Container>
  );
};

export default React.memo(SidebarMobile);
