import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteIcon as deleteIconApi } from 'api/icon';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientIcons } from 'store/formClient/formClient.slice';

import {
  requestDeleteIconFile,
  setDeleteIconFile,
  failDeleteIconFile,
} from './deleteIconFile.slice';
import {
  DeleteIconFileMeta,
  DeleteIconFilePayload,
} from './deleteIconFile.type';

function* deleteIconFileRequest(action: PayloadAction<DeleteIconFileMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteIconApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteIconFile(data));
    } catch (err) {
      yield put(failDeleteIconFile(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteIconFile('No token provided'));
  }
}

function* deleteIconFileSuccess(action: PayloadAction<DeleteIconFilePayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientIcons(data));
}

function* deleteIconFileFail(action: PayloadAction<DeleteIconFilePayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteIconFileWatcher() {
  yield takeLatest(requestDeleteIconFile.type, deleteIconFileRequest);
  yield takeLatest(setDeleteIconFile.type, deleteIconFileSuccess);
  yield takeLatest(failDeleteIconFile.type, deleteIconFileFail);
}

export default DeleteIconFileWatcher;
