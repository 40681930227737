import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ASSET_URL = `${BASE_URL}/asset`;

export const upsertAsset = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${ASSET_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const deleteAsset = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${ASSET_URL}/deleteAsset`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getAssetsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${ASSET_URL}/getAssetsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};