import axios from 'axios';
import { API } from 'consts/api';
import { GetRevMeta } from 'store/getRev/getRev.type';
import { RearrangeOrderViewMeta } from 'store/rearrangeOrderView/rearrangeOrderView.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const REV_URL = `${BASE_URL}/rev`;

export const rearrangeOrderView = (
  payload: RearrangeOrderViewMeta,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${REV_URL}/rearrangeOrderView`, payload, config);
};

export const getOrderView = (payload: GetRevMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${REV_URL}/getOrderView`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
