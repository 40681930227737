import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { IntegrateMailMeta, IntegrateMailPayload } from './integrateMail.type';

const initialState: AsyncState<IntegrateMailMeta, IntegrateMailPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<IntegrateMailMeta, IntegrateMailPayload>();

const integrateMailSlice = createSlice({
  name: 'integrateMail',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestIntegrateMail = integrateMailSlice.actions.REQUEST;
export const setIntegrateMail = integrateMailSlice.actions.SET;
export const failIntegrateMail = integrateMailSlice.actions.FAILED;
export const clearIntegrateMail = integrateMailSlice.actions.CLEAR;

export default integrateMailSlice.reducer;
