import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetPermissionsByUserIdMeta,
  GetPermissionsByUserIdPayload
} from './getPermissionsByUserId.type';

const initialState: AsyncState<GetPermissionsByUserIdMeta, GetPermissionsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetPermissionsByUserIdMeta, GetPermissionsByUserIdPayload>();

const getPermissionsByUserIdSlice = createSlice({
  name: 'getPermissionsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetPermissionsByUserId = getPermissionsByUserIdSlice.actions.REQUEST;
export const setGetPermissionsByUserId = getPermissionsByUserIdSlice.actions.SET;
export const failGetPermissionsByUserId = getPermissionsByUserIdSlice.actions.FAILED;
export const clearGetPermissionsByUserId = getPermissionsByUserIdSlice.actions.CLEAR;

export default getPermissionsByUserIdSlice.reducer;
