import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertKpi as upsertKpiApi } from 'api/kpi';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientKpis } from 'store/formClient/formClient.slice';

import {
  requestUpsertKpi,
  setUpsertKpi,
  failUpsertKpi,
} from './upsertKpi.slice';
import { UpsertKpiMeta, UpsertKpiPayload } from './upsertKpi.type';

function* upsertKpiRequest(action: PayloadAction<UpsertKpiMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertKpiApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpsertKpi(data));
    } catch (err) {
      yield put(failUpsertKpi(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertKpi('No token provided'));
  }
}

function* upsertKpiSuccess(action: PayloadAction<UpsertKpiPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientKpis(data));
}

function* upsertKpiFail(action: PayloadAction<UpsertKpiPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertKpiWatcher() {
  yield takeLatest(requestUpsertKpi.type, upsertKpiRequest);
  yield takeLatest(setUpsertKpi.type, upsertKpiSuccess);
  yield takeLatest(failUpsertKpi.type, upsertKpiFail);
}

export default UpsertKpiWatcher;
