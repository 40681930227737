import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetLogosByUserIdMeta,
  GetLogosByUserIdPayload
} from './getLogosByUserId.type';

const initialState: AsyncState<GetLogosByUserIdMeta, GetLogosByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetLogosByUserIdMeta, GetLogosByUserIdPayload>();

const getLogosByUserIdSlice = createSlice({
  name: 'getLogosByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetLogosByUserId = getLogosByUserIdSlice.actions.REQUEST;
export const setGetLogosByUserId = getLogosByUserIdSlice.actions.SET;
export const failGetLogosByUserId = getLogosByUserIdSlice.actions.FAILED;
export const clearGetLogosByUserId = getLogosByUserIdSlice.actions.CLEAR;

export default getLogosByUserIdSlice.reducer;
