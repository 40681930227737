import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteMockup as deleteMockupApi } from 'api/mockup';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientMockups } from 'store/formClient/formClient.slice';

import {
  requestDeleteMockup,
  setDeleteMockup,
  failDeleteMockup,
} from './deleteMockup.slice';
import { DeleteMockupMeta, DeleteMockupPayload } from './deleteMockup.type';

function* deleteMockupRequest(action: PayloadAction<DeleteMockupMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteMockupApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteMockup(data));
    } catch (err) {
      yield put(failDeleteMockup(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteMockup('No token provided'));
  }
}

function* deleteMockupSuccess(action: PayloadAction<DeleteMockupPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientMockups(data));
}

function* deleteMockupFail(action: PayloadAction<DeleteMockupPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteMockupWatcher() {
  yield takeLatest(requestDeleteMockup.type, deleteMockupRequest);
  yield takeLatest(setDeleteMockup.type, deleteMockupSuccess);
  yield takeLatest(failDeleteMockup.type, deleteMockupFail);
}

export default DeleteMockupWatcher;
