import React, { Fragment } from 'react';

// import { useTheme } from 'utils/Theme';
import Line from 'components/base/Line';
import Logo from 'components/base/Logo';
import Attribute from 'components/module/Attribute';
import BrandCard from 'components/module/BrandCard';
import LoadingPlaceholder from 'components/module/LoadingPlaceholder';
import ScrollContainer from 'components/module/ScrollContainer';
import SpeechBubble from 'components/module/SpeechBubble';
import WelcomeView from 'components/module/Welcome';
import theme from 'utils/Theme';

import { StrategyGeneratedProps } from './Strategy.props';
import {
  Container,
  HeadingDiv,
  InnerDiv,
  Caption,
  BrandDiv,
  BusinessDiv,
  UpperCommunicationDiv,
  HeadingTitle,
  BrandListDiv,
  WelcomeDiv,
  BrandCueDiv,
  BrandCueText,
  BrandCue,
  BrandCueDescription,
  CenterCommunicationDiv,
  BrandSummaryDiv,
  BrandContentDiv,
  Title,
  Content,
  LowerCommunicationDiv,
  AttributeList,
  CenterDescriptionDiv,
  BenefitCenterDescription,
  AttributeCenterDescription,
} from './Strategy.style';

const StrategyView = (props: StrategyGeneratedProps) => {
  const { strategy, getStrategy, brandName, permissions } = props;

  const isEnabledPermission = (key: string) => {
    if (permissions && key in permissions && (permissions as any)[key]) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <ScrollContainer className="the-scrollable" withRightPadding={false}>
        <HeadingDiv>
          <WelcomeDiv>
            <WelcomeView name={brandName} title="Welcome"></WelcomeView>
          </WelcomeDiv>
        </HeadingDiv>
        {(getStrategy && getStrategy.pending) ||
        (!getStrategy.pending && getStrategy.data?.data?.length < 1) ? (
          <LoadingPlaceholder
            caption="Fetching strategy content."
            emptyFlag={
              !getStrategy.pending && getStrategy.data?.data.length < 1
            }
            emptyCaption="No records of strategy content found"
          ></LoadingPlaceholder>
        ) : (
          <InnerDiv>
            {isEnabledPermission('brand_strategy') && (
              <BrandDiv>
                <HeadingTitle color={'secondary'} variant={'title3'}>
                  Brand Strategy
                </HeadingTitle>
                <BrandListDiv>
                  <BrandCard
                    threeRows
                    title={'Purpose'}
                    content={strategy?.brand_strategy?.purpose || ''}
                  ></BrandCard>
                  <BrandCard
                    threeRows
                    title={'Promise'}
                    content={strategy?.brand_strategy?.promise || ''}
                  ></BrandCard>
                  <BrandCard
                    threeRows
                    title={'Position'}
                    content={strategy?.brand_strategy?.position || ''}
                  ></BrandCard>
                  <BrandCard
                    threeRows
                    title={'Experience'}
                    content={strategy?.brand_strategy?.experience || ''}
                  ></BrandCard>
                  <BrandCard
                    threeRows
                    title={'Personality'}
                    content={strategy?.brand_strategy?.personality || ''}
                  ></BrandCard>
                  <BrandCard
                    threeRows
                    title={'Tone'}
                    content={strategy?.brand_strategy?.tone || ''}
                  ></BrandCard>
                  <BrandCard
                    style={{ width: '100%' }}
                    floatTitle
                    title={'Culture'}
                    content={strategy?.brand_strategy?.culture || ''}
                  ></BrandCard>
                  {strategy?.brand_strategy?.values &&
                    Object.keys(strategy?.brand_strategy?.values).map(
                      (key: string, index: number) => (
                        <BrandCard
                          key={key}
                          threeRows
                          title={`${strategy.brand_strategy.values[key].heading}`}
                          content={`${strategy.brand_strategy.values[key].value}`}
                        ></BrandCard>
                      )
                    )}
                </BrandListDiv>
              </BrandDiv>
            )}
            {isEnabledPermission('comms_strategy') && (
              <Fragment>
                <HeadingTitle color={'secondary'} variant={'title3'}>
                  Communication Strategy
                </HeadingTitle>
                <UpperCommunicationDiv>
                  <BrandCue>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                    <SpeechBubble position={'bottom-right'} color={'twilight'}>
                      <BrandCueDescription color={'noshade'}>
                        {strategy?.communication_strategy?.attributes.one}
                      </BrandCueDescription>
                    </SpeechBubble>
                  </BrandCue>
                  <BrandCue>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                    <SpeechBubble position={'bottom-right'} color={'twilight'}>
                      <BrandCueDescription color={'noshade'}>
                        {strategy?.communication_strategy?.attributes.two}
                      </BrandCueDescription>
                    </SpeechBubble>
                  </BrandCue>
                  <BrandCue>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                    <SpeechBubble position={'bottom-left'} color={'twilight'}>
                      <BrandCueDescription color={'noshade'}>
                        {strategy?.communication_strategy?.attributes.three}
                      </BrandCueDescription>
                    </SpeechBubble>
                  </BrandCue>
                  <BrandCue>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                    <SpeechBubble position={'bottom-left'} color={'twilight'}>
                      <BrandCueDescription color={'noshade'}>
                        {strategy?.communication_strategy?.attributes.four}
                      </BrandCueDescription>
                    </SpeechBubble>
                  </BrandCue>
                </UpperCommunicationDiv>
                <CenterCommunicationDiv>
                  <BrandSummaryDiv style={{ zIndex: 5 }}>
                    <SpeechBubble position={'center-bottom'} color={'twilight'}>
                      <BrandContentDiv>
                        <Title variant={'title3'} color={'noshade'}>
                          Brand Summary
                        </Title>
                        <Content color={'noshade'}>
                          {strategy?.communication_strategy?.attributes.summary}
                        </Content>
                      </BrandContentDiv>
                    </SpeechBubble>
                    <CenterDescriptionDiv>
                      <AttributeCenterDescription color={'twilight'}>
                        Attributes
                      </AttributeCenterDescription>
                      <Line variant={'primary'} smallMargin></Line>
                      <BenefitCenterDescription color={'twilightLight'}>
                        Benefits
                      </BenefitCenterDescription>
                    </CenterDescriptionDiv>
                  </BrandSummaryDiv>
                  <BrandSummaryDiv
                    style={{
                      top: '10px',
                    }}
                  >
                    <BrandContentDiv
                      style={{
                        backgroundColor: theme.brand.twilightLight,
                        padding: '20px',
                      }}
                    >
                      <Title
                        variant={'title3'}
                        color={'primary'}
                        style={{ textAlign: 'center' }}
                      >
                        Value Proposition
                      </Title>
                      <Content color={'primary'}>
                        {strategy?.communication_strategy?.benefits.summary}
                      </Content>
                    </BrandContentDiv>
                  </BrandSummaryDiv>
                </CenterCommunicationDiv>
                <LowerCommunicationDiv>
                  <BrandCue>
                    <SpeechBubble
                      position={'upper-right'}
                      color={'twilightLight'}
                    >
                      <BrandCueDescription color={'primary'}>
                        {strategy?.communication_strategy?.benefits.one}
                      </BrandCueDescription>
                    </SpeechBubble>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                  </BrandCue>
                  <BrandCue>
                    <SpeechBubble
                      position={'upper-right'}
                      color={'twilightLight'}
                    >
                      <BrandCueDescription color={'primary'}>
                        {strategy?.communication_strategy?.benefits.two}
                      </BrandCueDescription>
                    </SpeechBubble>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                  </BrandCue>
                  <BrandCue>
                    <SpeechBubble
                      position={'upper-left'}
                      color={'twilightLight'}
                    >
                      <BrandCueDescription color={'primary'}>
                        {strategy?.communication_strategy?.benefits.three}
                      </BrandCueDescription>
                    </SpeechBubble>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                  </BrandCue>
                  <BrandCue>
                    <SpeechBubble
                      position={'upper-left'}
                      color={'twilightLight'}
                    >
                      <BrandCueDescription color={'primary'}>
                        {strategy?.communication_strategy?.benefits.four}
                      </BrandCueDescription>
                    </SpeechBubble>
                    <BrandCueDiv>
                      <BrandCueText variant={'title3'} color={'primary'}>
                        Brand Cue
                      </BrandCueText>
                    </BrandCueDiv>
                  </BrandCue>
                </LowerCommunicationDiv>
                <AttributeList>
                  <Attribute
                    color={theme.brand.lilacLight}
                    title={'Pitch'}
                    contentA={
                      strategy?.communication_strategy?.pitch || undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.grey.noshade}
                    title={'Story'}
                    contentA={
                      strategy?.communication_strategy?.story || undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.brand.lilacLight}
                    title={'Audience'}
                    contentA={
                      strategy?.communication_strategy?.audience || undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.grey.noshade}
                    title={'Symbols & Semantics'}
                    contentA={
                      strategy?.communication_strategy?.symbols_semantics ||
                      undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.brand.lilacLight}
                    title={'Customer Needs'}
                    contentA={
                      strategy?.communication_strategy?.customer_needs ||
                      undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.grey.noshade}
                    title={'Communication Channels'}
                    contentA={
                      strategy?.communication_strategy?.communication_channels
                        .one || undefined
                    }
                    contentB={
                      strategy?.communication_strategy?.communication_channels
                        .two || undefined
                    }
                    contentC={
                      strategy?.communication_strategy?.communication_channels
                        .three || undefined
                    }
                  ></Attribute>
                  <Attribute
                    color={theme.brand.lilacLight}
                    title={'Content Categories'}
                    contentA={
                      strategy?.communication_strategy?.content_categories
                        .one || undefined
                    }
                    contentB={
                      strategy?.communication_strategy?.content_categories
                        .two || undefined
                    }
                    contentC={
                      strategy?.communication_strategy?.content_categories
                        .three || undefined
                    }
                  ></Attribute>
                </AttributeList>
              </Fragment>
            )}
            {isEnabledPermission('business_strategy') && (
              <BusinessDiv>
                <HeadingTitle color={'secondary'} variant={'title3'}>
                  Business Strategy
                </HeadingTitle>
                <BrandCard
                  style={{ marginBottom: '64px' }}
                  floatTitle
                  title={'Summary'}
                  content={strategy?.business_strategy?.summary || ''}
                ></BrandCard>
                <BrandCard
                  style={{ marginBottom: '64px' }}
                  floatTitle
                  title={'Mission'}
                  content={strategy?.business_strategy?.mission || ''}
                ></BrandCard>
                <BrandCard
                  floatTitle
                  title={'Vision'}
                  content={strategy?.business_strategy?.vision || ''}
                ></BrandCard>
              </BusinessDiv>
            )}
          </InnerDiv>
        )}
      </ScrollContainer>
    </Container>
  );
};

export default StrategyView;
