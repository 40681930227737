import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteIconFileMeta,
  DeleteIconFilePayload
} from './deleteIconFile.type';

const initialState: AsyncState<DeleteIconFileMeta, DeleteIconFilePayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteIconFileMeta, DeleteIconFilePayload>();

const deleteIconFileSlice = createSlice({
  name: 'deleteIconFile',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteIconFile = deleteIconFileSlice.actions.REQUEST;
export const setDeleteIconFile = deleteIconFileSlice.actions.SET;
export const failDeleteIconFile = deleteIconFileSlice.actions.FAILED;
export const clearDeleteIconFile = deleteIconFileSlice.actions.CLEAR;

export default deleteIconFileSlice.reducer;
