import type { PayloadAction } from '@reduxjs/toolkit';
import { getFbLikes } from 'api/fb';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetFbLikes,
  setGetFbLikes,
  failGetFbLikes,
} from './getFbLikes.slice';
import { GetFbLikesMeta, GetFbLikesPayload } from './getFbLikes.type';

function* getFbLikesRequest(action: PayloadAction<GetFbLikesMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getFbLikes, action.payload, state.auth.token);
      yield put(setGetFbLikes(data));
    } catch (err) {
      yield put(failGetFbLikes(err.response.data.message));
    }
  } else {
    yield put(failGetFbLikes('No token provided'));
  }
}

function* getFbLikesSuccess(action: PayloadAction<GetFbLikesPayload>) {
  // yield function here
}

function* getFbLikesFail(action: PayloadAction<GetFbLikesPayload>) {
  // yield function here
}

function* GetFbLikesWatcher() {
  yield takeLatest(requestGetFbLikes.type, getFbLikesRequest);
  yield takeLatest(setGetFbLikes.type, getFbLikesSuccess);
  yield takeLatest(failGetFbLikes.type, getFbLikesFail);
}

export default GetFbLikesWatcher;
