import React from 'react';

import ResultsBox from 'components/module/ResultsBox';

import { FansGeneratedProps } from './Fans.props';
import { Container, HeadingTypo, Description } from './Fans.style';

const FansView = (props: FansGeneratedProps): JSX.Element => {
  const {
    fbLikes,
    igFollowers,
    fanMiddleRing,
    fanOuterRing,
    linkedinFollowerCount,
  } = props;

  return (
    <Container>
      <ResultsBox
        outerProgress={fanOuterRing}
        midProgress={fanMiddleRing}
        innerProgress={10}
      >
        <HeadingTypo variant="title3" color="primary" weight="800">
          Fans
        </HeadingTypo>
        <Description variant="widget" color="black" weight="500">
          Instagram: {igFollowers}
        </Description>
        <Description variant="widget" color="black" weight="500">
          Facebook: {fbLikes}
        </Description>
        <Description variant="widget" color="black" weight="500">
          Pinterest: 0
        </Description>
        <Description variant="widget" color="black" weight="500">
          LinkedIn: {linkedinFollowerCount}
        </Description>
      </ResultsBox>
    </Container>
  );
};

export default React.memo(FansView);
