import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertMockupMeta,
  UpsertMockupPayload
} from './upsertMockup.type';

const initialState: AsyncState<UpsertMockupMeta, UpsertMockupPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertMockupMeta, UpsertMockupPayload>();

const upsertMockupSlice = createSlice({
  name: 'upsertMockup',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertMockup = upsertMockupSlice.actions.REQUEST;
export const setUpsertMockup = upsertMockupSlice.actions.SET;
export const failUpsertMockup = upsertMockupSlice.actions.FAILED;
export const clearUpsertMockup = upsertMockupSlice.actions.CLEAR;

export default upsertMockupSlice.reducer;
