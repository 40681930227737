import styled from '@emotion/styled';

export const Container = styled.div`
  @media screen and (min-width: 993px) {
    display: table;
    width: 100%;
  }
`;

export const SidebarContainer = styled.div`
  @media screen and (min-width: 993px) {
    display: table-cell;
    vertical-align: top;
  }
`;

export const MainBody = styled.div<{
  showResultsBar?: boolean;
}>`
  margin-top: 100px;
  width: 100%;

  @media screen and (min-width: 1199px) {
    margin-top: 0;
    display: table-cell;
    padding: 49px ${({ showResultsBar }) => (showResultsBar ? '5px' : '32px')}
      15px 62px;
    vertical-align: top;
    width: 100%;
  }
`;

export const ResultsBody = styled.div`
  @media screen and (max-width: 992px) {
    margin-top: 15px;
  }
`;

export const ContentBody = styled.div<{
  scrollableHeight: number | string;
}>`
  .the-scrollable {
    max-height: ${({ scrollableHeight }) =>
      typeof scrollableHeight === 'number' ? scrollableHeight + 'px' : 'none'};
  }
`;

export const MobileBackDrop = styled.div<{
  isOpen: boolean;
}>`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  height: 100vh;

  z-index: 9999;
  top: 0;
  left: 0;

  transform-origin: middle;
  transition: opacity 0.3s ease-out;
  transform: scaleY(${(props) => (props.isOpen ? '1' : '0')});

  opacity: ${(props) => (props.isOpen ? '1' : '0')};
`;
