import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetContentStatsMeta,
  GetContentStatsPayload,
} from './getContentStats.type';

const initialState: AsyncState<GetContentStatsMeta, GetContentStatsPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetContentStatsMeta, GetContentStatsPayload>();

const getContentStatsSlice = createSlice({
  name: 'getContentStats',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetContentStats = getContentStatsSlice.actions.REQUEST;
export const setGetContentStats = getContentStatsSlice.actions.SET;
export const failGetContentStats = getContentStatsSlice.actions.FAILED;
export const clearGetContentStats = getContentStatsSlice.actions.CLEAR;

export default getContentStatsSlice.reducer;
