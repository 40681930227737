import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  TestMailChimpConnectionMeta,
  TestMailChimpConnectionPayload,
} from './testMailChimpConnection.type';

const initialState: AsyncState<
  TestMailChimpConnectionMeta,
  TestMailChimpConnectionPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<
    TestMailChimpConnectionMeta,
    TestMailChimpConnectionPayload
  >();

const testMailChimpConnectionSlice = createSlice({
  name: 'testMailChimpConnection',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestTestMailChimpConnection =
  testMailChimpConnectionSlice.actions.REQUEST;
export const setTestMailChimpConnection =
  testMailChimpConnectionSlice.actions.SET;
export const failTestMailChimpConnection =
  testMailChimpConnectionSlice.actions.FAILED;
export const clearTestMailChimpConnection =
  testMailChimpConnectionSlice.actions.CLEAR;

export default testMailChimpConnectionSlice.reducer;
