import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  position: relative;

  background-color: ${(props) => props.theme.brand.lilac};
  border-radius: 49px 0px 0px 0px;
  padding: 32px;
`;

export const ExitContainer = styled.div`
  position: absolute;
  top: -31px;
  right: 0px;

  background-color: ${(props) => props.theme.brand.lilac};
  border-radius: 50%;

  width: 62px;
  height: 62px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExitLogoContainer = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.brand.blush};
`;

export const PopupTitle = styled(Typography)``;

export const PopupBody = styled(Typography)`
  margin-top: 16px;
`;

export const ChildrenContainer = styled.div`
  margin-top: 16px;
`;
