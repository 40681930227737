import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertAsset as upsertAssetApi } from 'api/asset';
import { uploadFile as uploadFileApi } from 'api/file';
import { RootState } from 'app/store';
import { error, info, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientAssets } from 'store/formClient/formClient.slice';

import {
  requestUpsertAsset,
  setUpsertAsset,
  failUpsertAsset,
} from './upsertAsset.slice';
import { UpsertAssetMeta, UpsertAssetPayload } from './upsertAsset.type';

function* upsertAssetRequest(action: PayloadAction<UpsertAssetMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      yield put(info(String('Uploading...')));
      const actionData = action.payload.asset_files?.slice();
      if (actionData) {
        for (let x = 0; x < actionData.length; x++) {
          if (actionData[x].blob instanceof File) {
            const { data } = yield call(
              uploadFileApi,
              {
                file: actionData[x].blob as File,
                fileName: action.payload.title,
                brandName: state.formClient ? state.formClient.brandName : '',
                userId: state.formClient ? state.formClient.user?.id : '',
              },
              state.auth.token
            );
            const temp = actionData[x];
            actionData[x] = {
              ...temp,
              ...{ img_url: data.status === 200 ? data.data : '' },
            };
          }
        }
      }

      const { data } = yield call(
        upsertAssetApi,
        { ...action.payload, ...{ asset_files: actionData } },
        state.auth.token
      );
      yield put(setUpsertAsset(data));
    } catch (err) {
      yield put(failUpsertAsset(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertAsset('No token provided'));
  }
}

function* upsertAssetSuccess(action: PayloadAction<UpsertAssetPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientAssets(data));
}

function* upsertAssetFail(action: PayloadAction<UpsertAssetPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertAssetWatcher() {
  yield takeLatest(requestUpsertAsset.type, upsertAssetRequest);
  yield takeLatest(setUpsertAsset.type, upsertAssetSuccess);
  yield takeLatest(failUpsertAsset.type, upsertAssetFail);
}

export default UpsertAssetWatcher;
