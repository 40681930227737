import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import Placeholder from 'resources/img/placeholder-img.webp';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const HeadingDiv = styled.div`
  margin-bottom: 30px;
`;

export const BrandColorDiv = styled.div``;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const Caption = styled(Typography)`
  max-width: 395px;
`;

export const TemplateContainer = styled.div`
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
`;

export const TemplateItem = styled.div``;

export const Preview = styled.div`
  min-height: 350px;
  border: 1px solid ${(props) => props.theme.brand.primary};
  border-radius: 15px;

  // Demo purposes only
  background: url('${Placeholder}') no-repeat center center;
  background-size: 50% auto;
`;

export const PreviewTitle = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 45px;
`;