import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertKpiMeta,
  UpsertKpiPayload
} from './upsertKpi.type';

const initialState: AsyncState<UpsertKpiMeta, UpsertKpiPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertKpiMeta, UpsertKpiPayload>();

const upsertKpiSlice = createSlice({
  name: 'upsertKpi',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertKpi = upsertKpiSlice.actions.REQUEST;
export const setUpsertKpi = upsertKpiSlice.actions.SET;
export const failUpsertKpi = upsertKpiSlice.actions.FAILED;
export const clearUpsertKpi = upsertKpiSlice.actions.CLEAR;

export default upsertKpiSlice.reducer;
