import React from 'react';

import { useTheme } from '@emotion/react';

import { ActiveSymbolProps } from './ActiveSymbol.props';
import { Container, InnerCircle } from './ActiveSymbol.style';

const ActiveSymbol = (props: ActiveSymbolProps): JSX.Element => {
  const theme = useTheme();
  const {
    active,
    color = active ? theme.brand.primary : theme.brand.secondary,
  } = props;

  return (
    <Container active={active} color={color}>
      <InnerCircle color={color} isActive={active}></InnerCircle>
    </Container>
  );
};

export default React.memo(ActiveSymbol);
