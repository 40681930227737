import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertIconMeta,
  UpsertIconPayload
} from './upsertIcon.type';

const initialState: AsyncState<UpsertIconMeta, UpsertIconPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertIconMeta, UpsertIconPayload>();

const upsertIconSlice = createSlice({
  name: 'upsertIcon',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertIcon = upsertIconSlice.actions.REQUEST;
export const setUpsertIcon = upsertIconSlice.actions.SET;
export const failUpsertIcon = upsertIconSlice.actions.FAILED;
export const clearUpsertIcon = upsertIconSlice.actions.CLEAR;

export default upsertIconSlice.reducer;
