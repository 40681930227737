import React, { useState } from 'react';

import { useTheme } from '@emotion/react';
import Alert from 'components/base/Alert';
import { Eye, EyeOff } from 'components/base/SVG';
import Touchable from 'components/base/Touchable';
import Typography from 'components/base/Typography';

import { TextFieldProps } from './TextField.props';
import {
  Container,
  LeftComponentContainer,
  RightComponentContainer,
  FieldContainer,
  Field,
  VisibilityContainer,
  Error,
  Prefix,
  Label,
  ErrorContainer,
} from './TextField.style';

const TextField = (props: TextFieldProps): JSX.Element => {
  const theme = useTheme();
  const {
    id,
    type,
    label,
    value = '',
    onChange = () => null,
    onChangeText = () => null,
    placeholder,
    secured,
    error = '',
    prefix,
    LeftComponent = null,
    RightComponent = null,
    className = undefined,
    onBlur = () => null,
    style,
    alert,
    readOnly,
    variant,
    color,
    onKeyUp,
    onKeyDown,
    disabled,
    customRef,
    clickableField,
    handleIconAction,
    handleClickContainer,
    displayError,
  } = props;

  const [showSecuredText, setShowSecuredText] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.readOnly) {
      onChange(event);
      onChangeText(event.target.value);
    }
  };

  let isEmptyString = true;
  if (typeof value === 'string') {
    if (value.length !== 0) {
      isEmptyString = false;
    }
  }

  const leftComponentExists = LeftComponent === null ? false : true;

  const VisibilityIcon = showSecuredText ? EyeOff : Eye;
  const defaultInputType = type || 'text';

  return (
    <Container
      onClick={handleClickContainer}
      className={className}
      style={style}
    >
      {/* <Typography variant={variant || 'body'} color={color || 'shade6'}>
        {label}
      </Typography> */}
      <div className={error !== '' ? 'shake-container' : ''}>
        <FieldContainer
          error={(error || '').length > 0}
          readOnly={props.readOnly}
        >
          {LeftComponent && (
            <LeftComponentContainer
              isClickable={clickableField}
              onClick={handleIconAction}
            >
              {LeftComponent}
            </LeftComponentContainer>
          )}
          {!readOnly && (prefix || '').length > 0 && <Prefix>{prefix}</Prefix>}

          <Field
            id={id}
            ref={customRef}
            type={secured && !showSecuredText ? 'password' : defaultInputType}
            value={
              readOnly && (prefix || '').length > 0
                ? `${prefix} ${value}`
                : value
            }
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            disabled={disabled}
            min={props.min}
            leftComponentExists={leftComponentExists}
            autoComplete={'new-password'}
            isClickable={clickableField}
          />
          <Label
            isEmptyString={isEmptyString}
            leftComponentExists={leftComponentExists}
          >
            {label}
          </Label>
          {RightComponent && (
            <RightComponentContainer>{RightComponent}</RightComponentContainer>
          )}
          {secured && (
            <VisibilityContainer>
              <Touchable onPress={() => setShowSecuredText((v) => !v)}>
                <VisibilityIcon fill={theme.grey.shade7} />
              </Touchable>
            </VisibilityContainer>
          )}
        </FieldContainer>
      </div>

      {(error || '').length > 0 && (
        <ErrorContainer displayError={displayError}>
          <Error variant="widget" color="blush" className="error-box">
            {error}
          </Error>
        </ErrorContainer>
      )}

      {alert && (
        <Alert
          variant="infoAlert"
          content={alert}
          fullWidth
          style={{ marginTop: 8 }}
        />
      )}
    </Container>
  );
};

export default TextField;
