import React from 'react';

import { SVGProps } from './SVG.props';

const CheckFilled = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '28'}
      height={height || '28'}
      viewBox="0 0 28 28"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59175 2.91382C8.78441 1.44873 11.3623 0.666748 13.9994 0.666748C15.7503 0.666748 17.4841 1.01162 19.1018 1.68169C20.7195 2.35175 22.1893 3.33388 23.4274 4.57199C24.6656 5.81011 25.6477 7.27996 26.3177 8.89764C26.9878 10.5153 27.3327 12.2491 27.3327 14.0001C27.3327 16.6372 26.5507 19.215 25.0856 21.4077C23.6205 23.6003 21.5382 25.3093 19.1018 26.3185C16.6655 27.3276 13.9846 27.5917 11.3982 27.0772C8.81174 26.5628 6.43597 25.2929 4.57126 23.4282C2.70656 21.5635 1.43669 19.1877 0.922218 16.6013C0.407748 14.0149 0.671793 11.334 1.68096 8.89764C2.69013 6.46129 4.3991 4.37891 6.59175 2.91382ZM17.6254 9.72009L11.9054 15.4534L9.70543 13.2534C9.5859 13.1138 9.43881 13.0005 9.27339 12.9205C9.10797 12.8404 8.9278 12.7955 8.74417 12.7884C8.56055 12.7813 8.37744 12.8122 8.20634 12.8792C8.03524 12.9463 7.87984 13.048 7.7499 13.1779C7.61996 13.3078 7.51828 13.4632 7.45125 13.6343C7.38422 13.8054 7.35328 13.9885 7.36037 14.1722C7.36746 14.3558 7.41243 14.536 7.49247 14.7014C7.5725 14.8668 7.68586 15.0139 7.82543 15.1334L10.9588 18.2801C11.0834 18.4037 11.2311 18.5014 11.3936 18.5678C11.556 18.6341 11.73 18.6678 11.9054 18.6668C12.2552 18.6653 12.5904 18.5264 12.8388 18.2801L19.5054 11.6134C19.6304 11.4895 19.7296 11.342 19.7973 11.1795C19.865 11.017 19.8998 10.8428 19.8998 10.6668C19.8998 10.4907 19.865 10.3165 19.7973 10.154C19.7296 9.99151 19.6304 9.84404 19.5054 9.72009C19.2556 9.47176 18.9177 9.33237 18.5654 9.33237C18.2132 9.33237 17.8753 9.47176 17.6254 9.72009Z"
        fill={fill || '#00C48C'}
      />
    </svg>
  );
};

export default CheckFilled;
