import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthState } from './auth.type';

const initialState: AuthState = {
  token: null,
  type: null,
  expiry: null,
  auth_id: null,
  account_id: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (_: any, action: PayloadAction<AuthState>) => {
      // overwrite everything; don't consider previous state
      const newAuth = action.payload;
      return { ...newAuth };
    },
    clearAuthentication: () => {
      return { ...initialState };
    },
  },
});

export const { setAuthentication, clearAuthentication } = authSlice.actions;
export default authSlice.reducer;
