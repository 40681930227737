import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteMockupMeta,
  DeleteMockupPayload
} from './deleteMockup.type';

const initialState: AsyncState<DeleteMockupMeta, DeleteMockupPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteMockupMeta, DeleteMockupPayload>();

const deleteMockupSlice = createSlice({
  name: 'deleteMockup',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteMockup = deleteMockupSlice.actions.REQUEST;
export const setDeleteMockup = deleteMockupSlice.actions.SET;
export const failDeleteMockup = deleteMockupSlice.actions.FAILED;
export const clearDeleteMockup = deleteMockupSlice.actions.CLEAR;

export default deleteMockupSlice.reducer;
