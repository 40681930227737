import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const COLOR_URL = `${BASE_URL}/color`;

export const upsertColor = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${COLOR_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getColorsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${COLOR_URL}/getColorsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const deleteColorById = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${COLOR_URL}/deleteColorById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};