import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const InnerDiv = styled.div`
  padding-bottom: 100px;
`;

export const HeadingDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 992px) {
    > div:nth-child(2) {
      display: none;
    }
  }
`;

export const WelcomeDiv = styled.div`
  width: 100%;
`;

export const Caption = styled(Typography)`
  max-width: 395px;
`;

export const BrandDiv = styled.div``;

export const UpperCommunicationDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`;

export const LowerCommunicationDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 30px;
`;

export const BusinessDiv = styled.div`
  margin-top: 18px;
  padding-bottom: 35px;
`;

export const HeadingTitle = styled(Typography)`
  margin-bottom: 12px;
`;

export const BrandListDiv = styled.div`
  display: flex;
  gap: 18px 2%;
  flex-wrap: wrap;
  align-items: items-stretch;

  margin-bottom: 24px;
`;

export const BrandCue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex-basis: 24%;

  @media screen and (max-width: 1708px) {
    flex-basis: 23%;
  }

  @media screen and (max-width: 992px) {
    flex-basis: 100%;
  }

`;

export const BrandCueDiv = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.brand.lilacLight};
  border-radius: 7px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
`;

export const BrandCueText = styled(Typography)`
  font-size: 16px;
`;

export const BrandCueDescription = styled(Typography)``;

export const CenterCommunicationDiv = styled.div`
  margin-top: 24px;
  position: relative;
`;

export const BrandSummaryDiv = styled.div`
  position: relative;
  padding: 0 12.5%;
  margin-left: auto;
  margin-right: auto;
`;

export const BrandContentDiv = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
`;

export const Title = styled(Typography)`
  margin-bottom: 12px;
`;

export const Content = styled(Typography)`
  text-align: center;
`;

export const AttributeList = styled.div`
  margin-top: 18px;
`;

export const CenterDescriptionDiv = styled.div`
  position: absolute;
  bottom: -35px;
  left: 0;
  width: 100%;
  // transform: translateY(-50%);

  @media screen and (max-width: 992px) {
    bottom: -67px;
    > p {
      writing-mode: vertical-lr;
      text-orientation: mixed;
    }
  }
`;

export const AttributeCenterDescription = styled(Typography)`
  // position: absolute;
  // top: -75%;
`;

export const BenefitCenterDescription = styled(Typography)`
  // position: absolute;
  // top: 75%;
`;