import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import {
  Strategy as StrategyType,
  UserPermission,
} from 'store/formClient/formClient.type';
import * as getPermissionsByUserIdSlice from 'store/getPermissionsByUserId/getPermissionsByUserId.slice';
import * as getStrategyByUserIdSlice from 'store/getStrategyByUserId/getStrategyByUserId.slice';

import StrategyView from './Strategy.view';

const Strategy = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const authId: string | null =
    useAppSelector((state: RootState) => state.auth.auth_id) || null;

  const brandName: string =
    useAppSelector(
      (state: RootState) => state.getClientById.data?.data?.account.brand_name
    ) || 'Client';

  const permissions: UserPermission | null =
    useAppSelector(
      (state: RootState) => state.getPermissionsByUserId.data?.data
    ) || null;

  useEffect(() => {
    if (authId) {
      dispatch(
        getPermissionsByUserIdSlice.requestGetPermissionsByUserId({
          user_id: authId,
        })
      );

      dispatch(
        getStrategyByUserIdSlice.requestGetStrategyByUserId({ user_id: authId })
      );
    }
  }, []);

  const getStrategy =
    useAppSelector((state: RootState) => state.getStrategyByUserId) || null;
  const [strategy, setStrategy] = useState<StrategyType | undefined>();

  useEffect(() => {
    if (getStrategy && getStrategy.data) {
      setStrategy(getStrategy.data.data);
    }
  }, [getStrategy?.data]);

  const generatedProps = {
    getStrategy,
    strategy,
    brandName,
    permissions,
  };
  return <StrategyView {...generatedProps} />;
};

export default Strategy;
