import React from 'react';

import { SVGProps } from './SVG.props';

const Linkedin = (props: SVGProps): JSX.Element => {
  const { width, height, fill, stroke } = props;

  return (
    <svg
      width={width || 36}
      height={height || 36}
      viewBox="0 0 291.319 291.319"
    >
      <path
        d="M145.659 0c80.45 0 145.66 65.219 145.66 145.66s-65.21 145.659-145.66 145.659S0 226.1 0 145.66 65.21 0 145.659 0z"
        fill={stroke}
      />
      <path
        d="M82.079 200.136h27.275v-90.91H82.079v90.91zm106.259-94.059c-13.237 0-25.081 4.834-33.483 15.504v-12.654H127.48v91.21h27.375v-49.324c0-10.424 9.55-20.593 21.512-20.593s14.912 10.169 14.912 20.338v49.57h27.275v-51.6c-.001-35.842-16.97-42.451-30.216-42.451zm-92.749-5.936c7.538 0 13.656-6.118 13.656-13.656S103.127 72.83 95.589 72.83s-13.656 6.118-13.656 13.656 6.118 13.655 13.656 13.655z"
        fill={fill}
      />
    </svg>
  );
};

export default Linkedin;
