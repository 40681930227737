import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteLogoFileMeta,
  DeleteLogoFilePayload
} from './deleteLogoFile.type';

const initialState: AsyncState<DeleteLogoFileMeta, DeleteLogoFilePayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteLogoFileMeta, DeleteLogoFilePayload>();

const deleteLogoFileSlice = createSlice({
  name: 'deleteLogoFile',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteLogoFile = deleteLogoFileSlice.actions.REQUEST;
export const setDeleteLogoFile = deleteLogoFileSlice.actions.SET;
export const failDeleteLogoFile = deleteLogoFileSlice.actions.FAILED;
export const clearDeleteLogoFile = deleteLogoFileSlice.actions.CLEAR;

export default deleteLogoFileSlice.reducer;
