import type { PayloadAction } from '@reduxjs/toolkit';
import { getOrganizationList } from 'api/linkedin';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetOrganizationList,
  setGetOrganizationList,
  failGetOrganizationList,
} from './getOrganizationList.slice';
import {
  GetOrganizationListMeta,
  GetOrganizationListPayload,
} from './getOrganizationList.type';

function* getOrganizationListRequest(
  action: PayloadAction<GetOrganizationListMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getOrganizationList,
        action.payload,
        state.auth.token
      );
      yield put(setGetOrganizationList(data));
    } catch (err) {
      yield put(failGetOrganizationList(err.response.data.message));
    }
  } else {
    yield put(failGetOrganizationList('No token provided'));
  }
}

function* getOrganizationListSuccess(
  action: PayloadAction<GetOrganizationListPayload>
) {
  // yield function here
}

function* getOrganizationListFail(
  action: PayloadAction<GetOrganizationListPayload>
) {
  // yield function here
}

function* GetOrganizationListWatcher() {
  yield takeLatest(requestGetOrganizationList.type, getOrganizationListRequest);
  yield takeLatest(setGetOrganizationList.type, getOrganizationListSuccess);
  yield takeLatest(failGetOrganizationList.type, getOrganizationListFail);
}

export default GetOrganizationListWatcher;
