import type { PayloadAction } from '@reduxjs/toolkit';
import { isIntegrated } from 'api/ga';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestIsGaIntegrated,
  setIsGaIntegrated,
  failIsGaIntegrated,
} from './isGaIntegrated.slice';
import {
  IsGaIntegratedMeta,
  IsGaIntegratedPayload,
} from './isGaIntegrated.type';

function* isGaIntegratedRequest(action: PayloadAction<IsGaIntegratedMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        isIntegrated,
        action.payload,
        state.auth.token
      );
      yield put(setIsGaIntegrated(data));
    } catch (err) {
      yield put(failIsGaIntegrated(err.response.data.message));
    }
  } else {
    yield put(failIsGaIntegrated('No token provided'));
  }
}

function* isGaIntegratedSuccess(action: PayloadAction<IsGaIntegratedPayload>) {
  // yield function here
}

function* isGaIntegratedFail(action: PayloadAction<IsGaIntegratedPayload>) {
  // yield function here
}

function* IsGaIntegratedWatcher() {
  yield takeLatest(requestIsGaIntegrated.type, isGaIntegratedRequest);
  yield takeLatest(setIsGaIntegrated.type, isGaIntegratedSuccess);
  yield takeLatest(failIsGaIntegrated.type, isGaIntegratedFail);
}

export default IsGaIntegratedWatcher;
