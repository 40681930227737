import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AsyncAction } from 'types/Action';
import { AsyncState } from 'types/AsyncState';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const getUserSlice = createSlice({
  name: 'getUser',
  initialState,
  reducers: {
    getUser: (state: AsyncState, action: PayloadAction<any>) => {
      state.pending = true;
      state.request = action.payload;
    },
    setUser: (state: AsyncState, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.pending = false;
    },
    // failGetUser: (state: AsyncState, action: PayloadAction<any>) => {
    // }
  },
});

export const { getUser, setUser } = getUserSlice.actions;
export default getUserSlice.reducer;
