import type { PayloadAction } from '@reduxjs/toolkit';
import { getOrderView } from 'api/rev';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { requestGetRev, setGetRev, failGetRev } from './getRev.slice';
import { GetRevMeta, GetRevPayload } from './getRev.type';

function* getRevRequest(action: PayloadAction<GetRevMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getOrderView,
        action.payload,
        state.auth.token
      );
      yield put(setGetRev(data));
    } catch (err) {
      yield put(failGetRev(err.response.data.message));
    }
  } else {
    yield put(failGetRev('No token provided'));
  }
}

function* getRevSuccess(action: PayloadAction<GetRevPayload>) {
  // yield function here
}

function* getRevFail(action: PayloadAction<GetRevPayload>) {
  // yield function here
}

function* GetRevWatcher() {
  yield takeLatest(requestGetRev.type, getRevRequest);
  yield takeLatest(setGetRev.type, getRevSuccess);
  yield takeLatest(failGetRev.type, getRevFail);
}

export default GetRevWatcher;
