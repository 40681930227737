import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { UpdateGscSiteMeta, UpdateGscSitePayload } from './updateGscSite.type';

const initialState: AsyncState<UpdateGscSiteMeta, UpdateGscSitePayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdateGscSiteMeta, UpdateGscSitePayload>();

const updateGscSiteSlice = createSlice({
  name: 'updateGscSite',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdateGscSite = updateGscSiteSlice.actions.REQUEST;
export const setUpdateGscSite = updateGscSiteSlice.actions.SET;
export const failUpdateGscSite = updateGscSiteSlice.actions.FAILED;
export const clearUpdateGscSite = updateGscSiteSlice.actions.CLEAR;

export default updateGscSiteSlice.reducer;
