import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const KPI_URL = `${BASE_URL}/kpi`;

export const upsertKpi = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${KPI_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
