import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetFbAttributesMeta,
  GetFbAttributesPayload,
} from './getFbAttributes.type';

const initialState: AsyncState<GetFbAttributesMeta, GetFbAttributesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetFbAttributesMeta, GetFbAttributesPayload>();

const getFbAttributesSlice = createSlice({
  name: 'getFbAttributes',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetFbAttributes = getFbAttributesSlice.actions.REQUEST;
export const setGetFbAttributes = getFbAttributesSlice.actions.SET;
export const failGetFbAttributes = getFbAttributesSlice.actions.FAILED;
export const clearGetFbAttributes = getFbAttributesSlice.actions.CLEAR;

export default getFbAttributesSlice.reducer;
