import type { PayloadAction } from '@reduxjs/toolkit';
import { getFbAttributesByToken } from 'api/fb';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetFbAttributesByToken,
  setGetFbAttributesByToken,
  failGetFbAttributesByToken,
} from './getFbAttributesByToken.slice';
import {
  GetFbAttributesByTokenMeta,
  GetFbAttributesByTokenPayload,
} from './getFbAttributesByToken.type';

function* getFbAttributesByTokenRequest(
  action: PayloadAction<GetFbAttributesByTokenMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getFbAttributesByToken,
        action.payload,
        state.auth.token
      );
      yield put(setGetFbAttributesByToken(data));
    } catch (err) {
      yield put(failGetFbAttributesByToken(err.response.data.message));
    }
  } else {
    yield put(failGetFbAttributesByToken('No token provided'));
  }
}

function* getFbAttributesByTokenSuccess(
  action: PayloadAction<GetFbAttributesByTokenPayload>
) {
  // yield function here
}

function* getFbAttributesByTokenFail(
  action: PayloadAction<GetFbAttributesByTokenPayload>
) {
  // yield function here
}

function* GetFbAttributesByTokenWatcher() {
  yield takeLatest(
    requestGetFbAttributesByToken.type,
    getFbAttributesByTokenRequest
  );
  yield takeLatest(
    setGetFbAttributesByToken.type,
    getFbAttributesByTokenSuccess
  );
  yield takeLatest(failGetFbAttributesByToken.type, getFbAttributesByTokenFail);
}

export default GetFbAttributesByTokenWatcher;
