import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpdatePermissionMeta,
  UpdatePermissionPayload
} from './updatePermission.type';

const initialState: AsyncState<UpdatePermissionMeta, UpdatePermissionPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdatePermissionMeta, UpdatePermissionPayload>();

const updatePermissionSlice = createSlice({
  name: 'updatePermission',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdatePermission = updatePermissionSlice.actions.REQUEST;
export const setUpdatePermission = updatePermissionSlice.actions.SET;
export const failUpdatePermission = updatePermissionSlice.actions.FAILED;
export const clearUpdatePermission = updatePermissionSlice.actions.CLEAR;

export default updatePermissionSlice.reducer;
