import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetStyleGuidesByUserIdMeta,
  GetStyleGuidesByUserIdPayload
} from './getStyleGuidesByUserId.type';

const initialState: AsyncState<GetStyleGuidesByUserIdMeta, GetStyleGuidesByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetStyleGuidesByUserIdMeta, GetStyleGuidesByUserIdPayload>();

const getStyleGuidesByUserIdSlice = createSlice({
  name: 'getStyleGuidesByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetStyleGuidesByUserId = getStyleGuidesByUserIdSlice.actions.REQUEST;
export const setGetStyleGuidesByUserId = getStyleGuidesByUserIdSlice.actions.SET;
export const failGetStyleGuidesByUserId = getStyleGuidesByUserIdSlice.actions.FAILED;
export const clearGetStyleGuidesByUserId = getStyleGuidesByUserIdSlice.actions.CLEAR;

export default getStyleGuidesByUserIdSlice.reducer;
