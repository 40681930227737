import type { PayloadAction } from '@reduxjs/toolkit';
import { getStyleGuidesByUserId as getStyleGuidesByUserIdApi } from 'api/styleGuide';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetStyleGuidesByUserId,
  setGetStyleGuidesByUserId,
  failGetStyleGuidesByUserId,
} from './getStyleGuidesByUserId.slice';
import {
  GetStyleGuidesByUserIdMeta,
  GetStyleGuidesByUserIdPayload,
} from './getStyleGuidesByUserId.type';

function* getStyleGuidesByUserIdRequest(
  action: PayloadAction<GetStyleGuidesByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStyleGuidesByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetStyleGuidesByUserId(data));
    } catch (err) {
      yield put(failGetStyleGuidesByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetStyleGuidesByUserId('No token provided'));
  }
}

function* getStyleGuidesByUserIdSuccess(
  action: PayloadAction<GetStyleGuidesByUserIdPayload>
) {
  // yield function here
}

function* getStyleGuidesByUserIdFail(
  action: PayloadAction<GetStyleGuidesByUserIdPayload>
) {
  // yield function here
  yield put(error(String(`Style Guide - ${action.payload}`)));
}

function* GetStyleGuidesByUserIdWatcher() {
  yield takeLatest(
    requestGetStyleGuidesByUserId.type,
    getStyleGuidesByUserIdRequest
  );
  yield takeLatest(
    setGetStyleGuidesByUserId.type,
    getStyleGuidesByUserIdSuccess
  );
  yield takeLatest(failGetStyleGuidesByUserId.type, getStyleGuidesByUserIdFail);
}

export default GetStyleGuidesByUserIdWatcher;
