import React, { useState, useEffect } from 'react';

import { useTheme } from '@emotion/react';
import ArrowLeft from 'components/base/SVG/ArrowLeft';
import ArrowRight from 'components/base/SVG/ArrowRight';
import VertEllipsis from 'components/base/SVG/VertEllipsis';
import BarChart from 'components/module/BarChart';
import Fans from 'components/module/Fans';
import StatsGa from 'components/module/StatsGa';
import StatsSubscribers from 'components/module/StatsSubscribers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Keywords from './Keywords';
import { ResultsColumnGeneratedProps } from './ResultsColumn.props';
import {
  Container,
  BoxItem,
  DraggableArea,
  HideButtonDiv,
  ContentContainer,
  DisplayContainer,
} from './ResultsColumn.style';
import StandOut from './StandOut';

const ResultsColumnView = (props: ResultsColumnGeneratedProps) => {
  const {
    contentStats,
    statsLoading,
    orderView,
    reState,
    rearrangeOrderViewHandler,
    handleResultsEngineState,
  } = props;
  const theme = useTheme();

  const [componentList, setComponentList] = useState(orderView);

  useEffect(() => {
    if (orderView !== undefined) {
      setComponentList(orderView);
    }
  }, [orderView]);

  const onDragEnd = (result: any) => {
    if (componentList !== undefined) {
      if (!result.destination) return;
      const items = Array.from(componentList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setComponentList(items);

      const sortedItems = items.map((item, index) => {
        const sortedItem = { ...item };
        sortedItem.sort = index;
        return sortedItem;
      });
      rearrangeOrderViewHandler(sortedItems);
    }
  };

  const mapComponent = (componentName: string) => {
    switch (componentName) {
      case 'ga':
        return <StatsGa />;
      case 'fans':
        return <Fans />;
      case 'subscribers':
        return <StatsSubscribers />;
      case 'content':
        return (
          <BarChart
            statsLoading={statsLoading}
            contentStats={contentStats}
          ></BarChart>
        );
      case 'gsc':
        return <Keywords />;
      case 'email':
        return <StandOut />;
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  return (
    <Container>
      <HideButtonDiv
        onClick={() => {
          handleResultsEngineState();
        }}
      >
        {reState.appear === true && (
          <ArrowRight width={16} height={16}></ArrowRight>
        )}
        {reState.appear === false && (
          <ArrowLeft width={16} height={16}></ArrowLeft>
        )}
      </HideButtonDiv>
      <DisplayContainer appear={reState.appear}>
        <ContentContainer appear={reState.appear}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'dropId'}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {componentList !== undefined &&
                    componentList.map((resultsEngineComponent, index) => {
                      return (
                        <Draggable
                          key={resultsEngineComponent.id}
                          draggableId={resultsEngineComponent.id}
                          index={index}
                        >
                          {(provided) => (
                            <BoxItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {mapComponent(resultsEngineComponent.component)}

                              <DraggableArea {...provided.dragHandleProps}>
                                <VertEllipsis
                                  width={10}
                                  height={33}
                                  fill={theme.brand.twilight}
                                ></VertEllipsis>
                              </DraggableArea>
                            </BoxItem>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ContentContainer>
      </DisplayContainer>
    </Container>
  );
};

export default ResultsColumnView;
