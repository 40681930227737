import styled from '@emotion/styled';

import { ScrollColor } from './ScrollContainer.props';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

// STYLES BELOW DOES NOT SUPPORT FIREFOX
export const Container = styled.div<{
  scrollColor: ScrollColor;
  withRPadding: boolean;
}>`

  @media screen and (min-width: 993px) {
    overflow-y: auto;
  }
  padding-right: 5px;

  ${({ withRPadding }) => {
    if (withRPadding) {
      return `
        @media screen and (min-width: 993px) {
          padding-right: 64px;
        }
    `;
    }
  }}

  @media screen and (max-width: 992px) {
    padding-right: 0;
  }

  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ scrollColor }) => {
      if (scrollColor === 'orange') {
        return `rgba(240, 201, 193, 0.18)`;
      } else {
        return `rgba(121, 155, 208, 0.18)`;
      }
    }};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: ${({ scrollColor }) => {
      if (scrollColor === 'orange') {
        return (props) => props.theme.brand.secondary;
      } else {
        return (props) => props.theme.brand.twilight;
      }
    }};
    border-radius: 20px;
    background-clip: content-box;
  }
`;
