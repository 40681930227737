import type { PayloadAction } from '@reduxjs/toolkit';
import { upsert as upsertApi } from 'api/client';
import { RootState } from 'app/store';
import { push, replace } from 'connected-react-router';
import { ADMIN_ROUTES } from 'consts/routes';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { setFormClient } from 'store/formClient/formClient.slice';

import {
  requestUpsertClient,
  setUpsertClient,
  failUpsertClient,
} from './upsertClient.slice';
import { UpsertClientMeta, UpsertClientPayload } from './upsertClient.type';

function* upsertClientRequest(action: PayloadAction<UpsertClientMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(upsertApi, action.payload, state.auth.token);
      yield put(setUpsertClient(data));
    } catch (err) {
      yield put(failUpsertClient(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertClient('No token provided'));
  }
}

function* upsertClientSuccess(action: PayloadAction<UpsertClientPayload>) {
  // yield function here
  // Enter here to update sync meta of client
  const account = action.payload.data.account;
  yield put(success(action.payload.message))
  yield put(
    setFormClient({
      id: account.id,
      brandName: account.brand_name || '',
      url: account.website_url || '',
      firstName: account.user.first_name || '',
      lastName: account.user.last_name || '',
      phoneNumber: account.phone_number || '',
      email: account.user.email || '',
      isActive: account.is_active || '',
      activatedAt: account.activated_at || '',
      colors: account.user.colors || [],
      logos: account.user.logos || [],
      icons: account.user.icons || [],
      assets: account.user.assets || [],
      mockups: account.user.mockups || [],
      styleGuides: account.user.style_guides || [],
      documents: account.user.documents || [],
      strategy: account.user.strategy || null,
      invitelog: account.user.invite_log || null,
      kpis: account.user.kpis || [],
      writeups: account.user.page_writeup || null,
      user: {
        id: account.user.id || '',
        email: account.user.email || '',
        isArchive: account.user.is_archive || '',
        user_permission: account.user.user_permission || '',
      },
    })
  );

  if (account.user.new_account) {
    yield put(push(`/admin/data-input/${account.id}`));
  }
}

function* upsertClientFail(action: PayloadAction<UpsertClientPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertClientWatcher() {
  yield takeLatest(requestUpsertClient.type, upsertClientRequest);
  yield takeLatest(setUpsertClient.type, upsertClientSuccess);
  yield takeLatest(failUpsertClient.type, upsertClientFail);
}

export default UpsertClientWatcher;
