import React from 'react';

import { DownloadFile } from 'components/base/SVG';
import Typography from 'components/base/Typography';
import Modal from 'components/layout/Modal';
import theme from 'utils/Theme';

// import { useTheme } from 'utils/Theme';
import { ModalIconPreviewProps } from './ModalIconPreview.props';
import {
  Container,
  HeaderDiv,
  TitleDiv,
  Title,
  IconFiles,
  DownloadableFiles,
} from './ModalIconPreview.style';

const ModalIconPreview = (props: ModalIconPreviewProps): JSX.Element => {
  const { isOpen, onClickClose, icon } = props;

  return (
    <Modal isOpen={isOpen} onClickClose={onClickClose}>
      {icon && (
        <Container>
          <HeaderDiv>
            <TitleDiv>
              <Title color={'primary'} variant={'title4'}>
                Downloadable files for icon {icon.title}
                <i>*Click file name to download</i>
              </Title>
            </TitleDiv>
          </HeaderDiv>
          <IconFiles>
            <table>
              <thead>
                <tr>
                  <th>File Type</th>
                </tr>
              </thead>
              <tbody>
                {icon &&
                  icon.icon_files?.map((file: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <DownloadableFiles
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          href={file.file_path}
                          download
                        >
                          {file.file_type}&nbsp;
                          <DownloadFile
                            fill={theme.brand.primary}
                          ></DownloadFile>
                        </DownloadableFiles>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </IconFiles>
        </Container>
      )}
    </Modal>
  );
};

export default React.memo(ModalIconPreview);
