import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const Caption = styled(Typography)`
  margin-right: 5px;
  font-style: italic;
`;