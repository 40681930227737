import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { Theme } from 'types/Theme';
import theme from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{ color: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 42px 24px;
  background-color: ${({ color }) => color};

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const TitleContainer = styled.div`
  width: 300px;
  overflow: hidden;
`;

export const Title = styled(Typography)``;

export const ContentContainer = styled.div`
  padding-left: 64px;
`;

export const Content = styled(Typography)``;
