import React from 'react';

import { Container as GridContainer, Row, Col } from 'react-grid-system';

import { AttributeProps } from './Attribute.props';
import {
  Container,
  TitleContainer,
  Title,
  ContentContainer,
  Content,
} from './Attribute.style';

const Attribute = (props: AttributeProps): JSX.Element => {
  const { color, title, contentA, contentB, contentC } = props;

  return (
    <Container color={color}>
      <TitleContainer>
        <Title color={'primary'} variant={'title3'}>
          {title}
        </Title>
      </TitleContainer>
      <ContentContainer>
        <GridContainer>
          <Row>
            <Col
              sm={12}
              lg={
                contentB === undefined && contentC === undefined
                  ? 12
                  : contentB !== undefined && contentC !== undefined
                  ? 4
                  : 6
              }
            >
              <Content color={'primary'}>{contentA}</Content>
            </Col>
            {contentB === undefined || (
              <Col sm={12} lg={4}>
                <Content color={'primary'}>{contentB}</Content>
              </Col>
            )}
            {contentC === undefined || (
              <Col sm={12} lg={4}>
                <Content color={'primary'}>{contentC}</Content>
              </Col>
            )}
          </Row>
        </GridContainer>
      </ContentContainer>
    </Container>
  );
};

export default React.memo(Attribute);
