import type { PayloadAction } from '@reduxjs/toolkit';
import { uploadFile as uploadFileApi } from 'api/file';
import { upsertStyleGuide as upsertStyleGuideApi } from 'api/styleGuide';
import { RootState } from 'app/store';
import { error, info, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientStyleGuides } from 'store/formClient/formClient.slice';

import {
  requestUpsertStyleGuide,
  setUpsertStyleGuide,
  failUpsertStyleGuide,
} from './upsertStyleGuide.slice';
import {
  UpsertStyleGuideMeta,
  UpsertStyleGuidePayload,
} from './upsertStyleGuide.type';

function* upsertStyleGuideRequest(action: PayloadAction<UpsertStyleGuideMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      yield put(info(String('Uploading...')));
      const actionData = action.payload.document_files?.slice();
      const mergedData = [];
      if (actionData) {
        for (let x = 0; x < actionData.length; x++) {
          if (actionData[x].blob instanceof File) {
            const { data } = yield call(
              uploadFileApi,
              {
                file: actionData[x].blob as File,
                fileName: actionData[x].file_name,
                brandName: state.formClient ? state.formClient.brandName : '',
                userId: state.formClient ? state.formClient.user?.id : '',
              },
              state.auth.token
            );

            if (actionData[x].file_type === 'doc_image') {
              mergedData.map((fileData: any) => {
                if (fileData.file_type !== 'doc_image') {
                  fileData['image_path'] = data.data
                }
                return fileData;
              })
            } else {
              const temp = actionData[x];
              mergedData.push({
                ...temp,
                ...{ url: data.status === 200 ? data.data : '' },
              });
            }
          }
        }
      }

      const { data } = yield call(
        upsertStyleGuideApi,
        { ...action.payload, ...{ document_files: mergedData } },
        state.auth.token
      );
      yield put(setUpsertStyleGuide(data));
    } catch (err) {
      yield put(failUpsertStyleGuide(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertStyleGuide('No token provided'));
  }
}

function* upsertStyleGuideSuccess(
  action: PayloadAction<UpsertStyleGuidePayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientStyleGuides(data));
}

function* upsertStyleGuideFail(action: PayloadAction<UpsertStyleGuidePayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertStyleGuideWatcher() {
  yield takeLatest(requestUpsertStyleGuide.type, upsertStyleGuideRequest);
  yield takeLatest(setUpsertStyleGuide.type, upsertStyleGuideSuccess);
  yield takeLatest(failUpsertStyleGuide.type, upsertStyleGuideFail);
}

export default UpsertStyleGuideWatcher;
