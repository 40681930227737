import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { pxToRem } from 'utils/Theme';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorContainer = styled.div<{ bgColor: string }>`
  border-radius: 25px;
  background-color: ${({ bgColor }) => bgColor};
  width: 145px;
  height: 75px;
`;

export const ColorTitle = styled(Typography)`
  font-size: ${pxToRem(16)};
  text-transform: capitalize;
  position: relative;

  .copy-notif {
    display: block;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    font-size: .7rem;
    padding: 2px 3px;
    background: #dbdbdb;
    line-height: 1.05;
    border-radius: 3px;
    font-weight: bold;
    letter-spacing: 1px;
    top: 4px;
  }
`;

export const ColorDetailContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const ColorDetails = styled(Typography)`
  font-size: ${pxToRem(14)};
  margin-right: 2px;
`;

export const ColorListDetailContainer = styled.div`
  margin-top: 8px;
  margin-left: 18px;
  width: 100%;
`;
