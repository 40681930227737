import { Theme as CustomTheme } from '../types/Theme';

declare module '@emotion/react' {
  export interface Theme extends CustomTheme {}
}

const rootFontSizePx = 16;
export const pxToRem = (n: number): string => `${n / rootFontSizePx}rem`;

export const theme: CustomTheme = {
  appType: '', // should be overridden on provider level
  brand: {
    primary: '#1E1850',
    secondary: '#DE3E1B',
    blush: '#F0C9C1',
    lilac: '#799BD0',
    twilight: '#4757A6',
    info: '#2196f3',
    alert: '#ff9800',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    lilacLight: '#EDF0F9',
    blushLight: '#FCF5F4',
    twilightLight: '#BBCDE8',
  },
  grey: {
    shade1: '#F9FAFF',
    shade2: '#F4F6FF',
    shade3: '#E5E9F5',
    shade4: '#DADFF2',
    shade5: '#BBC2DC',
    shade6: '#7F8498',
    shade7: '#565A6A',
    shade8: '#111E2B',
    shade9: '#09131D',
    shade10: '#050E16',
    noshade: '#FFFFFF',
    black: '#000',
  },
  states: {
    hover: '#F96F43',
    pressed: '#DE5124',
  }
};

export default theme;
