import type { PayloadAction } from '@reduxjs/toolkit';
import { getSubscriberDetails } from 'api/mail';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetSubscriberDetails,
  setGetSubscriberDetails,
  failGetSubscriberDetails,
} from './getSubscriberDetails.slice';
import {
  GetSubscriberDetailsMeta,
  GetSubscriberDetailsPayload,
} from './getSubscriberDetails.type';

function* getSubscriberDetailsRequest(
  action: PayloadAction<GetSubscriberDetailsMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getSubscriberDetails,
        action.payload,
        state.auth.token
      );
      yield put(setGetSubscriberDetails(data));
    } catch (err) {
      yield put(failGetSubscriberDetails(err.response.data.message));
    }
  } else {
    yield put(failGetSubscriberDetails('No token provided'));
  }
}

function* getSubscriberDetailsSuccess(
  action: PayloadAction<GetSubscriberDetailsPayload>
) {
  // yield function here
}

function* getSubscriberDetailsFail(
  action: PayloadAction<GetSubscriberDetailsPayload>
) {
  // yield function here
}

function* GetSubscriberDetailsWatcher() {
  yield takeLatest(
    requestGetSubscriberDetails.type,
    getSubscriberDetailsRequest
  );
  yield takeLatest(setGetSubscriberDetails.type, getSubscriberDetailsSuccess);
  yield takeLatest(failGetSubscriberDetails.type, getSubscriberDetailsFail);
}

export default GetSubscriberDetailsWatcher;
