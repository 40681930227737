import type { PayloadAction } from '@reduxjs/toolkit';
import { getFollowerCount } from 'api/linkedin';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetLinkedinFollowers,
  setGetLinkedinFollowers,
  failGetLinkedinFollowers,
} from './getLinkedinFollowers.slice';
import {
  GetLinkedinFollowersMeta,
  GetLinkedinFollowersPayload,
} from './getLinkedinFollowers.type';

function* getLinkedinFollowersRequest(
  action: PayloadAction<GetLinkedinFollowersMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getFollowerCount,
        action.payload,
        state.auth.token
      );
      yield put(setGetLinkedinFollowers(data));
    } catch (err) {
      yield put(failGetLinkedinFollowers(err.response.data.message));
    }
  } else {
    yield put(failGetLinkedinFollowers('No token provided'));
  }
}

function* getLinkedinFollowersSuccess(
  action: PayloadAction<GetLinkedinFollowersPayload>
) {
  // yield function here
}

function* getLinkedinFollowersFail(
  action: PayloadAction<GetLinkedinFollowersPayload>
) {
  // yield function here
}

function* GetLinkedinFollowersWatcher() {
  yield takeLatest(
    requestGetLinkedinFollowers.type,
    getLinkedinFollowersRequest
  );
  yield takeLatest(setGetLinkedinFollowers.type, getLinkedinFollowersSuccess);
  yield takeLatest(failGetLinkedinFollowers.type, getLinkedinFollowersFail);
}

export default GetLinkedinFollowersWatcher;
