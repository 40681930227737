import React from 'react';

// import { useTheme } from 'utils/Theme';
import { SpeechBubbleProps } from './SpeechBubble.props';
import { Container, Triangle } from './SpeechBubble.style';

const SpeechBubble = (props: SpeechBubbleProps): JSX.Element => {
  const { children, color, position } = props;
  return (
    <Container color={color}>
      <Triangle position={position} color={color}></Triangle>
      {children}
    </Container>
  );
};

export default React.memo(SpeechBubble);
