import React from 'react';

import theme from 'utils/Theme';

import { FileDetailProps } from './FileDetail.props';
import {
  Container,
  Text,
  DeleteIcon,
  DeleteDiv,
  Dot,
} from './FileDetail.style';

const FileDetail = (props: FileDetailProps): JSX.Element => {
  const { title, name, date, withDot, onDeleteHandler } = props;
  return (
    <Container>
      {withDot && <Dot></Dot>}
      {title && <Text color={'primary'}>{title}</Text>}
      {name && <Text color={'primary'}>{name}</Text>}
      {date && <Text color={'primary'}>{date}</Text>}
      <DeleteDiv onClick={onDeleteHandler}>
        <DeleteIcon fill={theme.brand.secondary}></DeleteIcon>
      </DeleteDiv>
    </Container>
  );
};

export default React.memo(FileDetail);
