import axios from 'axios';
import { API } from 'consts/api';
import { GaListWebsitesMeta } from 'store/gaListWebsites/gaListWebsites.type';
import { GetGaStatsMeta } from 'store/getGaStats/getGaStats.type';
import { GetGaUrlMeta } from 'store/getGaUrl/getGaUrl.type';
import { IsGaIntegratedMeta } from 'store/isGaIntegrated/isGaIntegrated.type';
import { UpdateGaViewIdMeta } from 'store/updateGaViewId/updateGaViewId.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const GA_URL = `${BASE_URL}/ga`;

export const getAuthLink = (payload: GetGaUrlMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GA_URL}/getLink`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const isIntegrated = (payload: IsGaIntegratedMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GA_URL}/isIntegrated`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const listGaWebsites = (payload: GaListWebsitesMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GA_URL}/getWebsites`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putViewId = (payload: UpdateGaViewIdMeta, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`${GA_URL}/updateViewId`, payload, config);
};

export const getStats = (payload: GetGaStatsMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${GA_URL}/getStats`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
