import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertStyleGuideMeta,
  UpsertStyleGuidePayload
} from './upsertStyleGuide.type';

const initialState: AsyncState<UpsertStyleGuideMeta, UpsertStyleGuidePayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertStyleGuideMeta, UpsertStyleGuidePayload>();

const upsertStyleGuideSlice = createSlice({
  name: 'upsertStyleGuide',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertStyleGuide = upsertStyleGuideSlice.actions.REQUEST;
export const setUpsertStyleGuide = upsertStyleGuideSlice.actions.SET;
export const failUpsertStyleGuide = upsertStyleGuideSlice.actions.FAILED;
export const clearUpsertStyleGuide = upsertStyleGuideSlice.actions.CLEAR;

export default upsertStyleGuideSlice.reducer;
