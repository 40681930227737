import styled from '@emotion/styled';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{
  active: boolean;
}>`
  border-radius: 100%;
  border: 3px solid ${(props) => props.color};
  display: inline-block;

  width: 30px;
  height: 30px;
`;

export const InnerCircle = styled.div<{
  isActive: boolean;
}>`
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  border-radius: 100%;
  background: ${(props) => props.color};

  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    display: block;

    height: 10px;
    width: 10px;

    border-radius: 100%;
    background: ${({ isActive }) => (isActive ? 'transparent' : '#fff')};
  }
`;
