import type { PayloadAction } from '@reduxjs/toolkit';
import { updateClientOrder as updateClientOrderApi } from 'api/client';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientDocuments } from 'store/formClient/formClient.slice';

import {
  requestUpdateClientOrder,
  setUpdateClientOrder,
  failUpdateClientOrder,
} from './updateClientOrder.slice';
import {
  UpdateClientOrderMeta,
  UpdateClientOrderPayload,
} from './updateClientOrder.type';

function* updateClientOrderRequest(
  action: PayloadAction<UpdateClientOrderMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateClientOrderApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpdateClientOrder(data));
    } catch (err) {
      yield put(failUpdateClientOrder(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpdateClientOrder('No token provided'));
  }
}

function* updateClientOrderSuccess(
  action: PayloadAction<UpdateClientOrderPayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientDocuments(data));
}

function* updateClientOrderFail(
  action: PayloadAction<UpdateClientOrderPayload>
) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpdateClientOrderWatcher() {
  yield takeLatest(requestUpdateClientOrder.type, updateClientOrderRequest);
  yield takeLatest(setUpdateClientOrder.type, updateClientOrderSuccess);
  yield takeLatest(failUpdateClientOrder.type, updateClientOrderFail);
}

export default UpdateClientOrderWatcher;
