import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteDocumentMeta,
  DeleteDocumentPayload
} from './deleteDocument.type';

const initialState: AsyncState<DeleteDocumentMeta, DeleteDocumentPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteDocumentMeta, DeleteDocumentPayload>();

const deleteDocumentSlice = createSlice({
  name: 'deleteDocument',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteDocument = deleteDocumentSlice.actions.REQUEST;
export const setDeleteDocument = deleteDocumentSlice.actions.SET;
export const failDeleteDocument = deleteDocumentSlice.actions.FAILED;
export const clearDeleteDocument = deleteDocumentSlice.actions.CLEAR;

export default deleteDocumentSlice.reducer;
