import styled from '@emotion/styled';
import Button from 'components/base/Button';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const DisplayContainer = styled.div<{ appear: boolean }>`
  // display: ${({ appear }) => (appear === true ? 'auto' : 'none')};
  // transition: display;
  // transition-delay: 0.5s;
`;

export const ContentContainer = styled.div<{ appear: boolean }>`
  max-width: ${({ appear }) => (appear === true ? '800px' : '0px')};
  max-height: ${({ appear }) => (appear === true ? '4200px' : '0px')};
  overflow: hidden;
  transition: max-width 0.5s;
`;

export const BoxItem = styled.div`
  margin-bottom: 14px;
  position: relative;
`;

export const HeadingTypo = styled(Typography)`
  margin-bottom: 4px;
`;

export const Description = styled(Typography)`
  line-height: 19px;
`;

export const DraggableArea = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

export const HideButtonDiv = styled.div`
  position: absolute;
  top: -36px;
  right: 32px;
  cursor: pointer;

  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${(props) => props.theme.brand.blush};
  }
`;
