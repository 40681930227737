import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import theme, { pxToRem } from 'utils/Theme';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{ isFloat?: boolean; threeRows?: boolean }>`
  position: relative;
  margin-top: ${({ isFloat }) => (isFloat === true ? '28px' : '0px')};

  ${({ threeRows }) => {
    if (threeRows) {
      return `
        flex-basis: 32%;

        @media screen and (max-width: 992px) {
          flex-basis: 100%;
        }
      `;
    }
  }}

  border-radius: ${({ isFloat }) =>
    isFloat === true ? '8px' : '0px 0px 8px 8px'};
  background-color: ${(props) => props.theme.brand.lilacLight};

  :hover {
    background-color: ${({ isFloat }) =>
      isFloat === true ? 'none' : theme.grey.shade2};
  }
`;

export const TitleContainer = styled.div`
  background-color: ${(props) => props.theme.brand.twilight};
  display: flex;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0px 0px;
  height: 32px;
`;

export const Title = styled(Typography)``;

export const ContentContainer = styled.div<{
  isFloat?: boolean;
  noContent?: boolean;
}>`
  padding: ${({ isFloat }) =>
    isFloat === true ? '42px 32px 32px 32px' : '21px'};
  height: auto;

  ${({ noContent }) => {
    if (noContent === true) {
      return `
      display: flex;
      justify-content: center;
      align-items: center;
    `;
    }
  }}
`;

export const Content = styled(Typography)`
  font-size: ${pxToRem(14)};
  white-space: pre-line;
`;

export const Default = styled(Typography)`
  text-align: center;
  font-size: ${pxToRem(16)};
`;

export const FloatTitleContainer = styled.div`
  width: 100%;
  max-width: 325px;
  background-color: ${(props) => props.theme.brand.twilight};
  display: flex;
  justify-content: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0px 0px;
  position: absolute;

  top: -30px;
`;
