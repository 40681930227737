import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { saveAs } from 'file-saver';
import { warning } from 'react-toastify-redux';
import {
  requestGenerateReport,
  clearGenerateReport,
} from 'store/generateReport/generateReport.slice';
import { GenerateReportMeta } from 'store/generateReport/generateReport.type';

import { CheckboxOptions } from './Reports.props';
import ReportsView from './Reports.view';

const Reports = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const brandName: string =
    useAppSelector(
      (state: RootState) => state.getClientById.data?.data?.account.brand_name
    ) || 'Client';

  const showWarning = (message: string) => {
    dispatch(warning(message));
  };

  const isGaIntegrated =
    useAppSelector(
      (state: RootState) => state.isGaIntegrated.data?.data?.isIntegrated
    ) || false;

  const report_data =
    useAppSelector((state: RootState) => state.generateReport.data?.data) ||
    undefined;

  const loading =
    useAppSelector((state: RootState) => state.generateReport.pending) || false;

  const user_id =
    useAppSelector((state: RootState) => state.auth.auth_id) || undefined;

  const handleGenerateReport = (
    checkBoxes: CheckboxOptions[],
    fromDate: Date,
    toDate: Date
  ) => {
    let website_traffic_flag = false;
    let fan_flag = false;
    let subscriber_flag = false;
    let content_flag = false;
    let key_words_flag = false;
    let standout_flag = false;

    for (const flag of checkBoxes) {
      if (flag.label === 'Website') website_traffic_flag = flag.flag;
      if (flag.label === 'Fans') fan_flag = flag.flag;
      if (flag.label === 'Subscribers') subscriber_flag = flag.flag;
      if (flag.label === 'Content') content_flag = flag.flag;
      if (flag.label === 'Keywords') key_words_flag = flag.flag;
      if (flag.label === 'Stand Out') standout_flag = flag.flag;
    }

    if (user_id !== undefined) {
      dispatch(
        requestGenerateReport({
          user_id: user_id,
          website_traffic_flag,
          fan_flag,
          subscriber_flag,
          content_flag,
          key_words_flag,
          standout_flag,
          from_date: fromDate,
          to_date: toDate,
        })
      );
    }
  };

  useEffect(() => {
    if (report_data !== null && report_data !== undefined) {
      const bytes = new Uint8Array(report_data.file.data);
      const blob = new Blob([bytes]);
      saveAs(blob, 'Report.pdf');
      window.location.reload();
      dispatch(clearGenerateReport());
    }
  }, [report_data]);

  const generatedProps = {
    brandName,
    loading,
    isGaIntegrated,
    showWarning,
    handleGenerateReport,
  };
  return <ReportsView {...generatedProps} />;
};

export default Reports;
