import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { pxToRem } from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  position: relative;
  padding-bottom: 40px;
`;

export const WelcomeMessage = styled(Typography)`
  font-size: ${pxToRem(36)};
  font-weight: 700;
`;

export const Name = styled(Typography)`
  position: absolute;
  top: 12px;
  left: 78px;

  font-size: ${pxToRem(55)};
`;
