import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpdateClientOrderMeta,
  UpdateClientOrderPayload
} from './updateClientOrder.type';

const initialState: AsyncState<UpdateClientOrderMeta, UpdateClientOrderPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdateClientOrderMeta, UpdateClientOrderPayload>();

const updateClientOrderSlice = createSlice({
  name: 'updateClientOrder',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdateClientOrder = updateClientOrderSlice.actions.REQUEST;
export const setUpdateClientOrder = updateClientOrderSlice.actions.SET;
export const failUpdateClientOrder = updateClientOrderSlice.actions.FAILED;
export const clearUpdateClientOrder = updateClientOrderSlice.actions.CLEAR;

export default updateClientOrderSlice.reducer;
