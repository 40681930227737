import styled from '@emotion/styled';
import CloseIcon from 'components/base/SVG/Close';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3px;
`;

export const Text = styled(Typography)`
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid ${(props) => props.theme.brand.secondary};
  font-size: 14px;
`;

export const DeleteIcon = styled(CloseIcon)``;

export const DeleteDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid;
    padding-bottom: 1px;
  }
`;

export const Dot = styled.span`
  height: 5px;
  width: 5px;
  background-color: ${(props) => props.theme.brand.primary};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;
