import styled from '@emotion/styled/macro';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  border-radius: 28px;
  border: 3px solid #34456b;
  padding: 13px 20px;

  position: relative;
`;

export const HeadingDiv = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 4px;
`;

export const Heading = styled(Typography)`
  margin-right: 8px;
`;

export const Description = styled(Typography)`
  line-height: 19px;
`;

export const ToolTipContainer = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;

  position: absolute;
  bottom: 22px;
  left: -22px;
  z-index: 1;

  background-color: ${(props) => props.theme.brand.primary}EE;
  padding: 14px;
  border-radius: 8px;
`;

export const MetricContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${ToolTipContainer} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ToolTip = styled(Typography)``;

export const SvgToolTip = styled.g`
  pointer-events: none; /*let mouse events pass through*/
  opacity: 0;
  transition: opacity 0.3s;
  text-shadow: 1px 1px 0px gray;
`;

export const SvgGroup = styled.g`
  cursor: pointer;

  &:hover {
    ${SvgToolTip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const SvgToolTipText = styled.text`
  font-size: 10px;
  fill: red;
`;

export const EngagementToolTipContainer = styled.div<{ show: boolean }>`
  position: absolute;
  width: 90%;
  margin: auto;
  left: 0px;

  opacity: ${({ show }) => (show === true ? 1 : 0)};
  visibility: ${({ show }) => (show === true ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out;
  z-index: 1;

  background-color: ${(props) => props.theme.brand.primary}EE;
  padding: 14px;
  border-radius: 8px;
`;
