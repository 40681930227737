import React from 'react';

import { SVGProps } from './SVG.props';

const Copy = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 10}
      height={height ||  10}
      viewBox="0 0  10  10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M3.335 0h4c.064.016.123.027.187.048.428.134.69.509.696.98v4.374c0 .503.006 1.007-.005 1.51a.952.952 0 01-.793.926c-.224.038-.46.016-.69.027h-.166v.096c-.006.595-.434 1.018-1.028 1.023h-3.7c-.085 0-.176-.005-.262-.027-.466-.112-.766-.498-.766-.99v-3.48c0-.798-.005-1.596 0-2.394a.96.96 0 01.803-.947c.236-.038.482-.016.729-.022h.107c.005-.021.01-.021.01-.032V.98c.016-.359.166-.637.471-.82.124-.08.268-.112.407-.16zM1.44 5.043V7.94c0 .252.14.401.386.401H5.54c.188 0 .316-.09.364-.246a.705.705 0 00.022-.182V2.184c0-.294-.134-.428-.429-.428H1.88c-.3 0-.434.134-.434.434-.005.947-.005 1.9-.005 2.853zm5.118 2.174c.022.005.027.005.033.005h.546c.305 0 .439-.133.439-.433V1.07c0-.3-.129-.434-.434-.434H3.485c-.246 0-.385.14-.396.37-.005.085.016.107.107.107.771-.006 1.542-.006 2.313 0 .129 0 .257.016.38.053.423.14.675.509.675.975v4.952c-.006.048-.006.086-.006.123z"
          fill="#262262"
        />
        <path
          d="M3.336-.001c-.134.048-.284.08-.407.155a.938.938 0 00-.472.82v.112c0 .005-.005.01-.01.032H2.34c-.241.005-.488-.016-.728.021a.948.948 0 00-.804.948c-.005.798 0 1.596 0 2.393v3.48c0 .493.3.878.766.99.086.022.171.028.262.028h3.7c.594 0 1.023-.429 1.028-1.023v-.096h.166c.23-.006.466.01.69-.027a.957.957 0 00.793-.926c.005-.504.005-1.007.005-1.51V1.022c0-.472-.267-.841-.696-.98C7.463.02 7.4.01 7.335-.006H9v9H0V-.001h3.336z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h9v9H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Copy;
