import axios from 'axios';
import { API, FB } from 'consts/api';
import { ComputeKpiFansMeta } from 'store/computeKpiFans/computeKpiFans.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const FANS_URL = `${BASE_URL}/fans`;

export const computeKpiSocial = (
  payload: ComputeKpiFansMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${FANS_URL}/computeSocialKpi`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: payload,
  });
};
