import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const HeadingDiv = styled.div`
  margin-bottom: 30px;
`;

export const BrandColorDiv = styled.div`
  padding-bottom: 100px;
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
  display: block;

  i {
    display: block;
    font-size: .8rem;
  }
`;

export const Caption = styled(Typography)`
  max-width: 395px;
`;

export const PrimaryColorDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-bottom: 12px;
`;

export const SecondaryColorDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-bottom: 12px;
`;

export const LogoDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
`;

export const IconDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
`;

export const HeaderIconDiv = styled.div`
  // display: flex;
  // align-items: center;
  // gap: 12px;
`;

export const DownloadAction = styled(Typography)`
  font-weight: 500;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const DownloadActionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 5px;
`;

export const KitDownloadRef = styled.a`
  text-decoration: none;
`;