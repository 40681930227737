export const API = {
  URL: process.env.REACT_APP_API_URL, // 'http://localhost:8080', Temporary till staging has .env
  VERSION: process.env.REACT_APP_API_VERSION || 'v1',
};

export const FB = {
  FACEBOOK_API: 'https://graph.facebook.com',
  FACEBOOK_APP_ID: '1006017893490344',
};

export const LINKEDIN = {
  LINKEDIN_APP_ID: '869z5iflo8qa5e',
  LINKEDIN_SCOPE: 'rw_organization_admin',
  LINKEDIN_REDIRECT: `${process.env.REACT_APP_API_URL}/v1/linkedin/storeToken`,
  LINKEDIN_OAUTH_URL: 'https://www.linkedin.com/oauth/v2/authorization',
};
