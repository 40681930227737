import type { PayloadAction } from '@reduxjs/toolkit';
import { getStrategyByUserId as getStrategyByUserIdApi } from 'api/strategy';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetStrategyByUserId,
  setGetStrategyByUserId,
  failGetStrategyByUserId,
} from './getStrategyByUserId.slice';
import {
  GetStrategyByUserIdMeta,
  GetStrategyByUserIdPayload,
} from './getStrategyByUserId.type';

function* getStrategyByUserIdRequest(
  action: PayloadAction<GetStrategyByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStrategyByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetStrategyByUserId(data));
    } catch (err) {
      yield put(failGetStrategyByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetStrategyByUserId('No token provided'));
  }
}

function* getStrategyByUserIdSuccess(
  action: PayloadAction<GetStrategyByUserIdPayload>
) {
  // yield function here
}

function* getStrategyByUserIdFail(
  action: PayloadAction<GetStrategyByUserIdPayload>
) {
  // yield function here
  yield put(error(String(`Strategy - ${action.payload}`)));
}

function* GetStrategyByUserIdWatcher() {
  yield takeLatest(requestGetStrategyByUserId.type, getStrategyByUserIdRequest);
  yield takeLatest(setGetStrategyByUserId.type, getStrategyByUserIdSuccess);
  yield takeLatest(failGetStrategyByUserId.type, getStrategyByUserIdFail);
}

export default GetStrategyByUserIdWatcher;
