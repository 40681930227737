import type { PayloadAction } from '@reduxjs/toolkit';
import { getStats } from 'api/ga';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetGaStats,
  setGetGaStats,
  failGetGaStats,
} from './getGaStats.slice';
import { GetGaStatsMeta, GetGaStatsPayload } from './getGaStats.type';

function* getGaStatsRequest(action: PayloadAction<GetGaStatsMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStats, action.payload, state.auth.token);
      yield put(setGetGaStats(data));
    } catch (err) {
      yield put(failGetGaStats(err.response.data.message));
    }
  } else {
    yield put(failGetGaStats('No token provided'));
  }
}

function* getGaStatsSuccess(action: PayloadAction<GetGaStatsPayload>) {
  // yield function here
}

function* getGaStatsFail(action: PayloadAction<GetGaStatsPayload>) {
  // yield function here
}

function* GetGaStatsWatcher() {
  yield takeLatest(requestGetGaStats.type, getGaStatsRequest);
  yield takeLatest(setGetGaStats.type, getGaStatsSuccess);
  yield takeLatest(failGetGaStats.type, getGaStatsFail);
}

export default GetGaStatsWatcher;
