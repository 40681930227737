import type { PayloadAction } from '@reduxjs/toolkit';
import { updatePageId } from 'api/fb';
import { RootState } from 'app/store';
import { success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestGetFbAttributes } from 'store/getFbAttributes/getFbAttributes.slice';
import { requestGetIgAttributes } from 'store/getIgAttributes/getIgAttributes.slice';

import {
  requestUpdateFbPageId,
  setUpdateFbPageId,
  failUpdateFbPageId,
} from './updateFbPageId.slice';
import {
  UpdateFbPageIdMeta,
  UpdateFbPageIdPayload,
} from './updateFbPageId.type';

function* updateFbPageIdRequest(action: PayloadAction<UpdateFbPageIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        updatePageId,
        action.payload,
        state.auth.token
      );
      yield put(setUpdateFbPageId(data));
    } catch (err) {
      yield put(failUpdateFbPageId(err.response.data.message));
    }
  } else {
    yield put(failUpdateFbPageId('No token provided'));
  }
}

function* updateFbPageIdSuccess(action: PayloadAction<UpdateFbPageIdPayload>) {
  const state: RootState = yield select();
  const client_id = state.formClient.user?.id;
  yield put(success('Facebook page updated'));

  if (client_id) {
    yield put(
      requestGetFbAttributes({
        client_id: client_id,
      })
    );
    yield put(
      requestGetIgAttributes({
        client_id: client_id,
      })
    );
  }
}

function* updateFbPageIdFail(action: PayloadAction<UpdateFbPageIdPayload>) {
  // yield function here
}

function* UpdateFbPageIdWatcher() {
  yield takeLatest(requestUpdateFbPageId.type, updateFbPageIdRequest);
  yield takeLatest(setUpdateFbPageId.type, updateFbPageIdSuccess);
  yield takeLatest(failUpdateFbPageId.type, updateFbPageIdFail);
}

export default UpdateFbPageIdWatcher;
