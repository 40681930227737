import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { requestGetSubscriberDetails } from 'store/getSubscriberDetails/getSubscriberDetails.slice';

import { StatsSubscribersPublicProps } from './StatsSubscribers.props';
import StatsSubscribersView from './StatsSubscribers.view';

const StatsSubscribers = (props: StatsSubscribersPublicProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const formState =
    useAppSelector((state: RootState) => state.formClient) || undefined;

  const authState =
    useAppSelector((state: RootState) => state.auth) || undefined;

  let idInFocus: string | undefined = undefined;
  if (authState.type === 'admin') {
    idInFocus = formState.user?.id || undefined;
  } else if (authState.type === 'client') {
    idInFocus = authState.auth_id || undefined;
  }

  useEffect(() => {
    getSubscriberDetailsHandler();
  }, []);

  const subscriberDetails =
    useAppSelector(
      (state: RootState) => state.getSubscriberDetails.data?.data
    ) || undefined;

  const getSubscriberDetailsHandler = () => {
    if (idInFocus !== undefined) {
      dispatch(
        requestGetSubscriberDetails({
          user_id: idInFocus,
        })
      );
    }
  };

  const generatedProps = {
    subscriberDetails,
  };
  return <StatsSubscribersView {...generatedProps} />;
};

export default StatsSubscribers;
