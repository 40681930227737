import React from 'react';

import Typography from 'components/base/Typography';

// import { useTheme } from 'utils/Theme';
import { StandOutGeneratedProps } from './StandOut.props';
import {
  Container,
  HeadingTypo,
  ItemsContainer,
  Items,
  Description,
  TextContainer,
  ImageContainer,
  Image,
} from './StandOut.style';

const StandOutView = (props: StandOutGeneratedProps) => {
  const { socialPostData, socialAdData, emailStandOut } = props;

  const handleLink = (isPost: boolean) => {
    if (socialPostData?.url !== undefined && isPost === true) {
      window.open(socialPostData.url);
    }

    if (socialAdData?.url !== undefined && isPost === false) {
      window.open(socialAdData.url);
    }
  };

  return (
    <Container>
      <HeadingTypo variant="title3" color="primary" weight="800">
        Stand Out
      </HeadingTypo>
      <ItemsContainer>
        <Items>
          <Typography variant="title4" color="primary" weight="800">
            Email Campaign
          </Typography>
          <Description variant="widget" color="black" weight="500">
            Campaign title: {emailStandOut?.campaignTitle}
          </Description>
          <Description variant="widget" color="black" weight="500">
            CTR: {emailStandOut?.clickRate.toFixed(2) * 100}%
          </Description>
          <Description variant="widget" color="black" weight="500">
            Open Rate: {emailStandOut?.openRate.toFixed(2) * 100}%
          </Description>
        </Items>
        <Items
          onClick={() => handleLink(true)}
          style={{ cursor: 'pointer' }}
          mode="flex"
        >
          <TextContainer>
            <Typography variant="title4" color="primary" weight="800">
              Social Post
            </Typography>
            <Description variant="widget" color="black" weight="500">
              Likes: {socialPostData?.like}
            </Description>
            <Description variant="widget" color="black" weight="500">
              Comments: {socialPostData?.comments}
            </Description>
            <Description variant="widget" color="black" weight="500">
              Impression: {socialPostData?.impression}
            </Description>
          </TextContainer>
          <ImageContainer>
            <Image
              height={'65px'}
              width={'65px'}
              alt={'social_post'}
              src={socialPostData?.picture}
            ></Image>
          </ImageContainer>
        </Items>
        <Items
          onClick={() => handleLink(false)}
          style={{ cursor: 'pointer' }}
          mode="flex"
        >
          <TextContainer>
            <Typography variant="title4" color="primary" weight="800">
              Social Ad
            </Typography>
            <Description variant="widget" color="black" weight="500">
              Likes: {socialAdData?.like}
            </Description>
            <Description variant="widget" color="black" weight="500">
              Comments: {socialAdData?.comments}
            </Description>
            <Description variant="widget" color="black" weight="500">
              Impression: {socialAdData?.impression}
            </Description>
          </TextContainer>
          <ImageContainer>
            <Image
              height={'65px'}
              width={'65px'}
              alt={'social_post'}
              src={socialAdData?.picture}
            ></Image>
          </ImageContainer>
        </Items>
      </ItemsContainer>
    </Container>
  );
};

export default StandOutView;
