import styled from '@emotion/styled';
import Checkbox from 'components/base/Checkbox';
import TextField from 'components/base/TextField';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const HeadingDiv = styled.div`
  margin-bottom: 30px;
`;

export const InnerDiv = styled.div`
  padding-bottom: 100px;
`;

export const BannerDiv = styled.div``;

export const Caption = styled(Typography)`
  max-width: 395px;
`;

export const FiltersField = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 24px;
  gap: 14px;
`;

export const InputContainer = styled.div`
  margin-right: 9px;
`;

export const BenchmarkToggle = styled(Checkbox)``;

export const CheckboxOptionsContainer = styled.div`
  margin-top: 15px;
`;

export const SelectAll = styled.span`
  color: ${(props) => props.theme.brand.twilight};
  cursor: pointer;
`;

export const ChkOptionItem = styled(Checkbox)``;

export const DateInput = styled(TextField)``;