import styled from '@emotion/styled';
import { Theme } from 'types/Theme';
import theme from 'utils/Theme';

import { Position } from './SpeechBubble.props';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{ color: keyof Theme['brand'] }>`
  padding: 20px;
  position: relative;
  background-color: ${({ color }) => theme.brand[color]};

  height: 100%;
`;

export const Triangle = styled.div<{
  color: keyof Theme['brand'];
  position: Position;
}>`
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;

  z-index: 5;

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: translateX(-50%);
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-top: 10px solid transparent;
  //   border-bottom: 18px solid ${({ color }) => theme.brand[color]};

  ${({ position, color }) => {
    switch (position) {
      case 'center-bottom':
        return `bottom: -28px; 
                left: 50%; 
                border-top: 18px solid ${theme.brand[color]};
                border-bottom: 10px solid transparent`;
      case 'center-top':
        return `top: -28px; 
                left: 50%; 
                border-top: 10px solid transparent;
                border-bottom: 18px solid ${theme.brand[color]}`;
      case 'bottom-left':
        return `bottom: -28px; 
                left: 20%; 
                border-top: 18px solid ${theme.brand[color]};
                border-bottom: 10px solid transparent`;
      case 'upper-left':
        return `top: -28px;
                left: 20%; 
                border-top: 10px solid transparent;
                border-bottom: 18px solid ${theme.brand[color]}`;
      case 'upper-right':
        return `top: -28px;
                right: 20%; 
                border-top: 10px solid transparent;
                border-bottom: 18px solid ${theme.brand[color]}`;
      case 'bottom-right':
        return `bottom: -28px;
                right: 20%; 
                border-top: 18px solid ${theme.brand[color]};
                border-bottom: 10px solid transparent`;
      default:
        return '';
    }
  }}
`;
