import styled from '@emotion/styled';
import { Theme } from 'types/Theme';
import theme from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{
  smallMargin?: boolean;
}>`
  position: relative;
  margin-top: ${(props) => (props.smallMargin ? '7px' : '12px')};
  margin-bottom: ${(props) => (props.smallMargin ? '7px' : '12px')};
`;

export const LineContainer = styled.hr<{ variant: keyof Theme['brand'] }>`
  border: none;
  border-top: 2px solid ${({ variant }) => theme.brand[variant]};
`;

export const StartCircle = styled.div<{ variant: keyof Theme['brand'] }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ variant }) => theme.brand[variant]};

  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
`;

export const EndCircle = styled.div<{ variant: keyof Theme['brand'] }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ variant }) => theme.brand[variant]};

  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
`;
