import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetGaUrlMeta, GetGaUrlPayload } from './getGaUrl.type';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetGaUrlMeta, GetGaUrlPayload>();

const getGaUrlSlice = createSlice({
  name: 'getGaUrl',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetGaUrl = getGaUrlSlice.actions.REQUEST;
export const setGetGaUrl = getGaUrlSlice.actions.SET;
export const failGetGaUrl = getGaUrlSlice.actions.FAILED;
export const clearGetGaUrl = getGaUrlSlice.actions.CLEAR;

export default getGaUrlSlice.reducer;
