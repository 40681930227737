import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { Link } from 'react-router-dom';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{ isactive: boolean | string }>`
  height: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  position: relative;

  p {
    color: ${(props) => props.theme.grey.noshade};
  }

  ${(props) => {
    if (props.isactive === 'true') {
      return `p {
        color: ${props.theme.brand.primary};
      }`;
    }
  }}
  :hover p {
    color: ${(props) => props.theme.brand.primary};
  }
  overflow: hidden;
`;

export const Text = styled(Typography)`
  cursor: pointer;
  position: absolute;
  text-align: center;
  top: 50%;
  left: -46px;
  width: 100%;
  transform: translateY(-50%);
`;

export const Hover = styled(Link)<{ isactive: boolean | string }>`
  position: absolute;
  left: 42px;

  background: linear-gradient(to right, transparent 50%, white 50%) left;
  background-size: 200%;
  transition: 0.3s ease-out;

  cursor: pointer;
  :hover {
    background-position: right;
  }
  ${({ isactive }) => {
    if (isactive === 'true') {
      return `background-position: right;`;
    }
  }}

  border-radius: 35px;

  width: 100%;
  height: 40px;
`;
