import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

const alignCenter = `
  position: absolute;
  margin: auto;
  top: 0; bottom: 0; left: 0; right: 0;

  @media screen and (min-width: 376px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  padding: 13px 20px;

  border: 3px solid #34456B;
  box-sizing: border-box;
  border-radius: 28px;

  @media screen and (max-width: 375px) {
    display: block;
    text-align: center
  }

  .circles {
    > svg {
      vertical-align: top;
    }
  }

  .second-circle {
    ${alignCenter}

    @media screen and (max-width: 375px) {
      width: 100px;
      height: 100px;
    }
  }

  .third-circle {
    ${alignCenter}
  }
`;

export const GraphBox = styled.div`
  position: relative;
  margin-right: 11px;
  max-height: 140px;

  @media screen and (max-width: 375px) {
    margin-right: 0;
  }
`;

export const DetailsBox = styled.div`
  word-break: break-all;
`;

export const HeadingTypo = styled(Typography)`
  margin-bottom: 4px;
`;

export const Description = styled(Typography)``;
