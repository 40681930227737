import React from 'react';

import { DownloadFile } from 'components/base/SVG';
import Typography from 'components/base/Typography';
import Modal from 'components/layout/Modal';
import theme from 'utils/Theme';

import { ModalLogoPreviewProps } from './ModalLogoPreview.props';
import {
  Container,
  HeaderDiv,
  TitleDiv,
  Title,
  LogoFiles,
  DownloadableFiles,
} from './ModalLogoPreview.style';

const ModalLogoPreview = (props: ModalLogoPreviewProps): JSX.Element => {
  const { isOpen, onClickClose, logo } = props;

  return (
    <Modal isOpen={isOpen} onClickClose={onClickClose}>
      {logo && (
        <Container>
          <HeaderDiv>
            <TitleDiv>
              <Title color={'primary'} variant={'title4'}>
                Downloadable files for logo {logo.type}{' '}
                {logo.orientation ? '- ' + logo.orientation : ''}
                <i>*Click file name to download</i>
              </Title>
            </TitleDiv>
          </HeaderDiv>
          <LogoFiles>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {logo &&
                  logo.logo_files?.map((file: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <DownloadableFiles
                          color="primary"
                          style={{ cursor: 'pointer' }}
                          href={file.file_path}
                          download
                        >
                          {file.file_name}&nbsp;
                          <DownloadFile
                            fill={theme.brand.primary}
                          ></DownloadFile>
                        </DownloadableFiles>
                      </td>
                      <td>
                        <Typography
                          color="primary"
                          style={{ textTransform: 'uppercase' }}
                        >
                          {file.file_type}
                        </Typography>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </LogoFiles>
        </Container>
      )}
    </Modal>
  );
};

export default React.memo(ModalLogoPreview);
