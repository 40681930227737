import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
`;

export const HeaderDiv = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 0;
  border-radius: 7px;
  margin-bottom: 15px;

  &:hover {
    // background-color: ${(props) => props.theme.grey.shade2};
  }
  transition: background-color 0.3s ease-in-out;

  svg {
    transition: transform 0.26s ease;
    ${({ visible }) => (visible === false ? 'transform: rotate(-90deg)' : '')};
  }
`;

export const Title = styled(Typography)``;

export const ContentDiv = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
  max-height: ${({ visible }) => (visible === true ? 'none' : '0px')};
  transition: max-height 0.3s ease-in-out;
`;

export const InnerDiv = styled.div`
  padding-bottom: 10px;
`;