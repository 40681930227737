import React from 'react';

// import { useTheme } from 'utils/Theme';
import Border from 'components/base/Line';
import Logo from 'components/base/Logo';
import NavItem from 'components/module/NavItem';

import { SidebarProps } from './Sidebar.props';
import {
  Container,
  LogoContainer,
  SideBarItemContainer,
  FooterContainer,
  NavListContainer,
  FooterItem,
  BorderContainer,
} from './Sidebar.style';

const Sidebar = (props: SidebarProps): JSX.Element => {
  // const theme = useTheme();
  const { routes, mode, isInnerRoute, onLogout, permissions } = props;

  return (
    <Container>
      <LogoContainer mode={mode}>
        <Logo
          width={'176px'}
          variant={mode === 'admin' ? 'stacked_navy' : 'stacked_orange'}
        ></Logo>
      </LogoContainer>
      <SideBarItemContainer mode={mode}>
        <NavListContainer>
          {routes &&
            routes.map((route, index: number) => {
              if (
                mode === 'admin' ||
                route.title === 'Home' ||
                (route.permissionName &&
                  permissions &&
                  route.permissionName in permissions &&
                  (permissions as any)[route.permissionName])
              )
                return (
                  <NavItem
                    key={index}
                    to={route.path}
                    text={route.title || 'Untitled'}
                    mode={mode}
                    active={isInnerRoute(route.path)}
                  ></NavItem>
                );
            })}
        </NavListContainer>
        <FooterContainer>
          <BorderContainer>
            <Border variant={mode === 'admin' ? 'primary' : 'blush'}></Border>
          </BorderContainer>
          <FooterItem
            color={mode === 'admin' ? 'primary' : 'secondary'}
            variant={'title4'}
          >
            Settings
          </FooterItem>
          <FooterItem
            color={mode === 'admin' ? 'primary' : 'secondary'}
            variant={'title4'}
            onClick={onLogout}
          >
            Logout
          </FooterItem>
        </FooterContainer>
      </SideBarItemContainer>
    </Container>
  );
};

export default React.memo(Sidebar);
