import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetIgAttributesMeta,
  GetIgAttributesPayload
} from './getIgAttributes.type';

const initialState: AsyncState<GetIgAttributesMeta, GetIgAttributesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetIgAttributesMeta, GetIgAttributesPayload>();

const getIgAttributesSlice = createSlice({
  name: 'getIgAttributes',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetIgAttributes = getIgAttributesSlice.actions.REQUEST;
export const setGetIgAttributes = getIgAttributesSlice.actions.SET;
export const failGetIgAttributes = getIgAttributesSlice.actions.FAILED;
export const clearGetIgAttributes = getIgAttributesSlice.actions.CLEAR;

export default getIgAttributesSlice.reducer;
