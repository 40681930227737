import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteStyleGuide as deleteStyleGuideApi } from 'api/styleGuide';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientStyleGuides } from 'store/formClient/formClient.slice';

import {
  requestDeleteStyleGuide,
  setDeleteStyleGuide,
  failDeleteStyleGuide,
} from './deleteStyleGuide.slice';
import {
  DeleteStyleGuideMeta,
  DeleteStyleGuidePayload,
} from './deleteStyleGuide.type';

function* deleteStyleGuideRequest(action: PayloadAction<DeleteStyleGuideMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteStyleGuideApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteStyleGuide(data));
    } catch (err) {
      yield put(failDeleteStyleGuide(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteStyleGuide('No token provided'));
  }
}

function* deleteStyleGuideSuccess(
  action: PayloadAction<DeleteStyleGuidePayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientStyleGuides(data));
}

function* deleteStyleGuideFail(action: PayloadAction<DeleteStyleGuidePayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteStyleGuideWatcher() {
  yield takeLatest(requestDeleteStyleGuide.type, deleteStyleGuideRequest);
  yield takeLatest(setDeleteStyleGuide.type, deleteStyleGuideSuccess);
  yield takeLatest(failDeleteStyleGuide.type, deleteStyleGuideFail);
}

export default DeleteStyleGuideWatcher;
