import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const MOCKUP_URL = `${BASE_URL}/mockup`;

export const upsertMockup = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${MOCKUP_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const deleteMockup = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${MOCKUP_URL}/deleteMockup`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getMockupsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${MOCKUP_URL}/getMockupsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};