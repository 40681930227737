import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpdateFbPageIdMeta,
  UpdateFbPageIdPayload,
} from './updateFbPageId.type';

const initialState: AsyncState<UpdateFbPageIdMeta, UpdateFbPageIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdateFbPageIdMeta, UpdateFbPageIdPayload>();

const updateFbPageIdSlice = createSlice({
  name: 'updateFbPageId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdateFbPageId = updateFbPageIdSlice.actions.REQUEST;
export const setUpdateFbPageId = updateFbPageIdSlice.actions.SET;
export const failUpdateFbPageId = updateFbPageIdSlice.actions.FAILED;
export const clearUpdateFbPageId = updateFbPageIdSlice.actions.CLEAR;

export default updateFbPageIdSlice.reducer;
