import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { Mode } from 'components/module/Sidebar/Sidebar.props';
import theme, { pxToRem } from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  width: ${pxToRem(236)};
`;

// variable here
export const LogoContainer = styled.div<{ mode: Mode }>`
  background-color: ${({ mode }) =>
    mode === 'admin' ? theme.brand.lilac : theme.brand.blush};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 29vh;
`;

// variable here
export const SideBarItemContainer = styled.div<{ mode: Mode }>`
  position: relative;

  min-height: 71vh;
  @media screen and (max-height: 620px) {
    min-height: 446px;
  }

  line-height: 1vh;
  background-color: ${({ mode }) =>
    mode === 'admin' ? theme.brand.secondary : theme.brand.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

export const NavListContainer = styled.div`
  margin-top: 58px;
  width: 100%;
`;

export const FooterItem = styled(Typography)`
  margin-top: 12px;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const BorderContainer = styled.div`
  width: 80%;
`;
