import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteColorById as deleteColorByIdApi } from 'api/color';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientColors } from 'store/formClient/formClient.slice';

import {
  requestDeleteColorById,
  setDeleteColorById,
  failDeleteColorById,
} from './deleteColorById.slice';
import {
  DeleteColorByIdMeta,
  DeleteColorByIdPayload,
} from './deleteColorById.type';

function* deleteColorByIdRequest(action: PayloadAction<DeleteColorByIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteColorByIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteColorById(data));
    } catch (err) {
      yield put(failDeleteColorById(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteColorById('No token provided'));
  }
}

function* deleteColorByIdSuccess(
  action: PayloadAction<DeleteColorByIdPayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientColors(data));
}

function* deleteColorByIdFail(action: PayloadAction<DeleteColorByIdPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteColorByIdWatcher() {
  yield takeLatest(requestDeleteColorById.type, deleteColorByIdRequest);
  yield takeLatest(setDeleteColorById.type, deleteColorByIdSuccess);
  yield takeLatest(failDeleteColorById.type, deleteColorByIdFail);
}

export default DeleteColorByIdWatcher;
