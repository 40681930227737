import type { PayloadAction } from '@reduxjs/toolkit';
import { getIconsByUserId as getIconsByUserIdApi } from 'api/icon';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetIconsByUserId,
  setGetIconsByUserId,
  failGetIconsByUserId,
} from './getIconsByUserId.slice';
import {
  GetIconsByUserIdMeta,
  GetIconsByUserIdPayload,
} from './getIconsByUserId.type';

function* getIconsByUserIdRequest(action: PayloadAction<GetIconsByUserIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getIconsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetIconsByUserId(data));
    } catch (err) {
      yield put(failGetIconsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetIconsByUserId('No token provided'));
  }
}

function* getIconsByUserIdSuccess(
  action: PayloadAction<GetIconsByUserIdPayload>
) {
  // yield function here
}

function* getIconsByUserIdFail(action: PayloadAction<GetIconsByUserIdPayload>) {
  // yield function here
  yield put(error(String(`Icons - ${action.payload}`)));
}

function* GetIconsByUserIdWatcher() {
  yield takeLatest(requestGetIconsByUserId.type, getIconsByUserIdRequest);
  yield takeLatest(setGetIconsByUserId.type, getIconsByUserIdSuccess);
  yield takeLatest(failGetIconsByUserId.type, getIconsByUserIdFail);
}

export default GetIconsByUserIdWatcher;
