import React, { Fragment, useState, useEffect } from 'react';

import BrandColor from 'components/module/BrandColor';
import { BrandColorProps } from 'components/module/BrandColor/BrandColor.props';
import KitIcon from 'components/module/KitIcon';
import KitLogo from 'components/module/KitLogo';
import LoadingPlaceholder from 'components/module/LoadingPlaceholder';
import ModalIconPreview from 'components/module/ModalIconPreview';
import ModalLogoPreview from 'components/module/ModalLogoPreview';
import ScrollContainer from 'components/module/ScrollContainer';
import WelcomeView from 'components/module/Welcome';
import ReactTooltip from 'react-tooltip';
import {
  Asset,
  Color,
  Icon,
  Logo,
  Mockup,
} from 'routes/Admin/DataInput/Forms/Kit/Kit.props';

import { KitGeneratedProps } from './Kit.props';
import {
  Container,
  HeadingDiv,
  BrandColorDiv,
  Caption,
  Title,
  PrimaryColorDiv,
  SecondaryColorDiv,
  LogoDiv,
  IconDiv,
  DownloadAction,
  DownloadActionContainer,
  HeaderIconDiv,
  KitDownloadRef,
} from './Kit.style';

const KitView = (props: KitGeneratedProps) => {
  const {
    getColors,
    colors,
    getLogos,
    logos,
    getIcons,
    icons,
    getAssets,
    assets,
    getMockups,
    mockups,
    getStyleGuide,
    styleGuides,
    handleDownloadZip,
    brandName,
  } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const primaryColors = colors.filter(
    (value: Color) => value.type === 'primary'
  );

  const secondaryColors = colors.filter(
    (value: Color) => value.type === 'secondary'
  );

  const primaryHoriLogo: Logo | null =
    logos.filter(
      (value: Logo) =>
        value.type === 'primary' && value.orientation === 'horizontal'
    )[0] || null;

  const primaryVertiLogo: Logo | null =
    logos.filter(
      (value: Logo) =>
        value.type === 'primary' && value.orientation === 'vertical'
    )[0] || null;

  const secondaryLogo: Logo | null =
    logos.filter((value: Logo) => value.type === 'secondary')[0] || null;

  const [selectedLogo, setSelectedLogo] = useState<Logo | null>(null);
  const [logoPreview, setLogoPreview] = useState(false);
  const handleLogoClick = (logo: Logo) => {
    if (logo) {
      setLogoPreview(true);
      setSelectedLogo(logo);
    }
  };

  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null);
  const [iconPreview, setIconPreview] = useState(false);
  const handleIconClick = (icon: Icon) => {
    if (icon) {
      setIconPreview(true);
      setSelectedIcon(icon);
    }
  };

  const downloadLightIcons = () => {
    const lightIcons: Icon[] = icons.filter((icon: Icon) => {
      return icon.is_light;
    });

    handleDownloadZip(lightIcons, 'light');
  };

  const downloadDarkIcons = () => {
    const darkIcons: Icon[] = icons.filter((icon: Icon) => {
      return !icon.is_light;
    });

    handleDownloadZip(darkIcons, 'dark');
  };

  return (
    <Container>
      <ScrollContainer className="the-scrollable">
        <HeadingDiv>
          <WelcomeView name={brandName} title="Welcome"></WelcomeView>
        </HeadingDiv>
        <BrandColorDiv>
          <Title variant={'title3'} color={'shade10'}>
            Brand Colours
          </Title>
          {(getColors && getColors.pending) ||
          (!getColors.pending && getColors.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching colors record."
              emptyFlag={!getColors.pending && getColors.data?.data.length < 1}
              emptyCaption="No records of color found"
            ></LoadingPlaceholder>
          ) : (
            <Fragment>
              <PrimaryColorDiv>
                {primaryColors.map((value: Color, num: number) => {
                  return (
                    <BrandColor
                      name={value.type}
                      key={num}
                      hex={value.hex}
                      rgb={value.rgb}
                      cmyk={value.cmyk}
                      pms={value.pms}
                    ></BrandColor>
                  );
                })}
              </PrimaryColorDiv>
              <SecondaryColorDiv>
                {secondaryColors.map((value: Color, num: number) => {
                  return (
                    <BrandColor
                      name={value.type}
                      key={num}
                      hex={value.hex}
                      rgb={value.rgb}
                      cmyk={value.cmyk}
                      pms={value.pms}
                    ></BrandColor>
                  );
                })}
              </SecondaryColorDiv>
            </Fragment>
          )}
          <Title
            variant={'title3'}
            color={'shade10'}
            style={{ marginTop: '21px' }}
          >
            Logos
            <i>*Click to download</i>
          </Title>
          {(getLogos && getLogos.pending) ||
          (!getLogos.pending && getLogos.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching logo records."
              emptyFlag={!getLogos.pending && getLogos.data?.data.length < 1}
              emptyCaption="No records of logo found"
            ></LoadingPlaceholder>
          ) : (
            <LogoDiv>
              {primaryHoriLogo &&
                primaryHoriLogo.logo_files &&
                primaryHoriLogo.logo_files.map((file: any, index: number) => (
                  <KitDownloadRef
                    key={index}
                    style={{ cursor: 'pointer' }}
                    href={file.file_path}
                    download
                    data-tip="Click to download file"
                  >
                    <KitLogo
                      onClick={() => {
                        // handleLogoClick(primaryHoriLogo)
                      }}
                      url={
                        file
                          ? file.file_path
                          : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                      }
                      name={'Primary (Horizontal)'}
                    ></KitLogo>
                  </KitDownloadRef>
                ))}
              {primaryVertiLogo &&
                primaryVertiLogo.logo_files &&
                primaryVertiLogo.logo_files.map((file: any, index: number) => (
                  <KitDownloadRef
                    key={index}
                    style={{ cursor: 'pointer' }}
                    href={file.file_path}
                    download
                    data-tip="Click to download file"
                  >
                    <KitLogo
                      onClick={() => {
                        // handleLogoClick(primaryVertiLogo)
                      }}
                      url={
                        file
                          ? file.file_path
                          : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                      }
                      name={'Primary (Vertical)'}
                    ></KitLogo>
                  </KitDownloadRef>
                ))}
              {secondaryLogo &&
                secondaryLogo.logo_files &&
                secondaryLogo.logo_files.map((file: any, index: number) => (
                  <KitDownloadRef
                    key={index}
                    style={{ cursor: 'pointer' }}
                    href={file.file_path}
                    download
                    data-tip="Click to download file"
                  >
                    <KitLogo
                      onClick={() => {
                        // handleLogoClick(secondaryLogo)
                      }}
                      url={
                        file
                          ? file.file_path
                          : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                      }
                      name={'Secondary'}
                    ></KitLogo>
                  </KitDownloadRef>
                ))}
            </LogoDiv>
          )}
          <HeaderIconDiv>
            <Title
              variant={'title3'}
              color={'shade10'}
              style={{ marginTop: '21px' }}
            >
              Icons
              <i>*Click to download</i>
            </Title>
            <DownloadActionContainer>
              {icons.filter((icon: Icon) => icon.is_light).length > 0 && (
                <DownloadAction
                  variant={'body'}
                  color={'lilac'}
                  onClick={downloadLightIcons}
                >
                  Download all icons for light background
                </DownloadAction>
              )}
              {icons.filter((icon: Icon) => !icon.is_light).length > 0 && (
                <DownloadAction
                  variant={'body'}
                  color={'blush'}
                  onClick={downloadDarkIcons}
                  data-tip="hello world"
                >
                  Download all icons for dark background
                </DownloadAction>
              )}
            </DownloadActionContainer>
          </HeaderIconDiv>
          {(getIcons && getIcons.pending) ||
          (!getIcons.pending && getIcons.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching icon records."
              emptyFlag={!getIcons.pending && getIcons.data?.data.length < 1}
              emptyCaption="No records of icon found"
            ></LoadingPlaceholder>
          ) : (
            <IconDiv>
              {icons &&
                icons.map(
                  (icon: Icon, index: number) =>
                    icon.icon_files &&
                    icon.icon_files.map((file: any, innerIndex: number) => {
                      return (
                        <KitDownloadRef
                          key={index}
                          style={{ cursor: 'pointer' }}
                          href={file.file_path}
                          download
                          data-tip="Click to download file"
                        >
                          <KitIcon
                            onClick={() => {
                              // handleIconClick(icon)
                            }}
                            url={
                              file
                                ? file.file_path
                                : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                            }
                          ></KitIcon>
                        </KitDownloadRef>
                      );
                    })
                )}
            </IconDiv>
          )}
          <Title
            variant={'title3'}
            color={'shade10'}
            style={{ marginTop: '21px' }}
          >
            Assets
            <i>*Click to download</i>
          </Title>
          {(getAssets && getAssets.pending) ||
          (!getAssets.pending && getAssets.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching assets records."
              emptyFlag={!getAssets.pending && getAssets.data?.data.length < 1}
              emptyCaption="No records of asset found"
            ></LoadingPlaceholder>
          ) : (
            <LogoDiv>
              {assets &&
                assets.map(
                  (asset: Asset, index: number) =>
                    asset.asset_files &&
                    asset.asset_files.map((file: any, innerIndex: number) => {
                      return (
                        <KitDownloadRef
                          key={index}
                          style={{ cursor: 'pointer' }}
                          href={file.file_path}
                          download
                          data-tip="Click to download file"
                        >
                          <KitLogo
                            onClick={() => {
                              // handleLogoClick(primaryHoriLogo)
                            }}
                            url={
                              file
                                ? file.file_path
                                : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                            }
                            name={file.title}
                            autoWidth={true}
                          ></KitLogo>
                        </KitDownloadRef>
                      );
                    })
                )}
            </LogoDiv>
          )}
          <Title
            variant={'title3'}
            color={'shade10'}
            style={{ marginTop: '21px' }}
          >
            Mock Ups
            <i>*Click to download</i>
          </Title>
          {(getMockups && getMockups.pending) ||
          (!getMockups.pending && getMockups.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching mock ups records."
              emptyFlag={
                !getMockups.pending && getMockups.data?.data.length < 1
              }
              emptyCaption="No record of mock ups found"
            ></LoadingPlaceholder>
          ) : (
            <LogoDiv>
              {mockups &&
                mockups.map(
                  (mockup: Mockup, index: number) =>
                    mockup.mockup_files &&
                    mockup.mockup_files.map((file: any, innerIndex: number) => {
                      return (
                        <KitDownloadRef
                          key={index}
                          style={{ cursor: 'pointer' }}
                          href={file.file_path}
                          download
                          data-tip="Click to download file"
                        >
                          <KitLogo
                            onClick={() => {
                              // handleLogoClick(primaryHoriLogo)
                            }}
                            url={
                              file
                                ? file.file_path
                                : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                            }
                            name={file.title}
                            autoWidth={true}
                          ></KitLogo>
                        </KitDownloadRef>
                      );
                    })
                )}
            </LogoDiv>
          )}
          <Title
            variant={'title3'}
            color={'shade10'}
            style={{ marginTop: '21px' }}
          >
            Style Guides
            <i>*Click to download</i>
          </Title>
          {(getStyleGuide && getStyleGuide.pending) ||
          (!getStyleGuide.pending && getStyleGuide.data?.data.length < 1) ? (
            <LoadingPlaceholder
              caption="Fetching style guide records."
              emptyFlag={
                !getStyleGuide.pending && getStyleGuide.data?.data.length < 1
              }
              emptyCaption="No record of style guide found"
            ></LoadingPlaceholder>
          ) : (
            <LogoDiv>
              {styleGuides &&
                styleGuides.map((styleGuide: any, index: number) => (
                  <KitDownloadRef
                    key={index}
                    style={{ cursor: 'pointer' }}
                    href={styleGuide.file_path}
                    download
                    data-tip="Click to download file"
                  >
                    <KitLogo
                      onClick={() => {
                        // handleLogoClick(primaryHoriLogo)
                      }}
                      url={
                        styleGuide
                          ? styleGuide.image_path
                          : 'https://images.squarespace-cdn.com/content/6010b1f03d8f723175883c9e/1611718129301-8FC7UTRSYBFECEHXEYM4/PascalSartori__Horizontal_Navy.png?content-type=image%2Fpng'
                      }
                      name={styleGuide.title}
                      autoWidth={true}
                    ></KitLogo>
                  </KitDownloadRef>
                ))}
            </LogoDiv>
          )}
        </BrandColorDiv>
        <ModalLogoPreview
          isOpen={logoPreview}
          onClickClose={() => setLogoPreview(false)}
          logo={selectedLogo}
        ></ModalLogoPreview>
        <ModalIconPreview
          isOpen={iconPreview}
          onClickClose={() => setIconPreview(false)}
          icon={selectedIcon}
        ></ModalIconPreview>
        <ReactTooltip place="top" type="dark" effect="solid" />
      </ScrollContainer>
    </Container>
  );
};

export default KitView;
