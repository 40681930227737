import type { PayloadAction } from '@reduxjs/toolkit';
import { listWebsites } from 'api/gsc';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetGscSites,
  setGetGscSites,
  failGetGscSites,
} from './getGscSites.slice';
import { GetGscSitesMeta, GetGscSitesPayload } from './getGscSites.type';

function* getGscSitesRequest(action: PayloadAction<GetGscSitesMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        listWebsites,
        action.payload,
        state.auth.token
      );
      yield put(setGetGscSites(data));
    } catch (err) {
      yield put(failGetGscSites(err.response.data.message));
    }
  } else {
    yield put(failGetGscSites('No token provided'));
  }
}

function* getGscSitesSuccess(action: PayloadAction<GetGscSitesPayload>) {
  // yield function here
}

function* getGscSitesFail(action: PayloadAction<GetGscSitesPayload>) {
  // yield function here
}

function* GetGscSitesWatcher() {
  yield takeLatest(requestGetGscSites.type, getGscSitesRequest);
  yield takeLatest(setGetGscSites.type, getGscSitesSuccess);
  yield takeLatest(failGetGscSites.type, getGscSitesFail);
}

export default GetGscSitesWatcher;
