import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div`
  position: relative;
  padding: 22px 31px 16px;

  border: 3px solid #34456b;
  box-sizing: border-box;
  border-radius: 28px;

  .table-stats {
    width: 100%;
    border: none;

    th,
    td {
      text-align: left;
      font-size: 14px;
      font-weight: 800;
      color: ${(props) => props.theme.brand.twilight};
      line-height: 19px;

      border-width: 0;
    }

    th {
      color: #000;
    }

    thead,
    tr {
      border-bottom: 4px solid transparent;
    }

    .align-center {
      text-align: center;
    }
  }
`;

export const HeadingTypo = styled(Typography)`
  font-weight: 800;
  margin-bottom: 24px;
`;

export const Section = styled.div``;

export const TextSection = styled.div`
  display: flex;
  align-items: flex-top;
  justify-content: flex-start;
`;

export const SubHeading = styled(Typography)`
  font-weight: 800;
`;
