import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AsyncAction } from 'types/Action';
import { AsyncState } from 'types/AsyncState';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    request: (state: AsyncState, action: PayloadAction<any>) => {
      state.pending = true;
      state.request = action.payload;
      state.error = null;
      state.data = null;
    },
    set: (state: AsyncState, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.pending = false;
      state.error = null;
    },
    failed: (state: AsyncState, action: PayloadAction<any>) => {
      let errorMessage = action.payload;
      if (typeof action.payload === 'object') {
        errorMessage =
          'message' in action.payload ? action.payload.message : errorMessage;
      }
      state.error = errorMessage;
      state.pending = false;
    },
    clear: (state: AsyncState, action: PayloadAction<any>) => {
      return { ...initialState };
    },
  },
});

export const { request, set, failed, clear } = loginSlice.actions;
export default loginSlice.reducer;
