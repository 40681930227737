import React from 'react';

import { useTheme } from '@emotion/react';

import { CheckboxProps } from './Checkbox.props';
import {
  Container,
  Inner,
  HiddenCheckbox,
  CustomCheckbox,
  InnerCheck,
  Label,
} from './Checkbox.style';

const Checkbox = ({
  checked = false,
  typographyProps,
  label = 'Unlabel',
  disabled = false,
  ...props
}: CheckboxProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Container onClick={props.onClick} style={props.style}>
      <Inner>
        <HiddenCheckbox
          onChange={(event) => event.stopPropagation()}
          checked={checked}
          type="checkbox"
          {...props}
        />
        <CustomCheckbox checked={checked}>
          {checked && !disabled && <InnerCheck />}
        </CustomCheckbox>
        {label && (
          <Label {...typographyProps} variant="body" color="black">
            {label}
          </Label>
        )}
      </Inner>
    </Container>
  );
};

export default React.memo(Checkbox);
