import React, { useState, Suspense } from 'react';

import { ThemeProvider } from '@emotion/react';
import GenericLoading from 'components/module/GenericLoading';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import WebFont from 'webfontloader';

import { store, history, persistor } from './app/store';
import Routes from './routes/index.routes';
import * as serviceWorker from './serviceWorker';
import { theme } from './utils/Theme';

const App = () => {
  const [rehydrated, setRehydrated] = useState(false);
  const onRehydate = async () => {
    setRehydrated(true);
  };

  WebFont.load({
    custom: {
      families: ['Fort Collins', 'Avenir'],
    },
  });

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor(onRehydate)}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<GenericLoading />}>
                <Routes></Routes>
              </Suspense>
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
