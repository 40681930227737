import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div`
  position: relative;
  padding: 22px 31px 16px;

  border: 3px solid #34456b;
  box-sizing: border-box;
  border-radius: 28px;
`;

export const HeadingTypo = styled(Typography)`
  font-weight: 800;
  margin-bottom: 13px;
`;

export const ItemsContainer = styled.div``;

export const Items = styled.div<{
  mode?: string;
}>`
  padding: 9px 22px;
  border-radius: 30px;
  background: ${(props) => props.theme.brand.blush};

  & + div {
    margin-top: 15px;
  }

  ${({ mode }) =>
    mode === 'flex'
      ? `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
      : ''};
`;

export const Description = styled(Typography)`
  line-height: 19px;
`;

export const TextContainer = styled.div``;

export const ImageContainer = styled.div``;

export const Image = styled.img``;
