import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { requestGetGaStats } from 'store/getGaStats/getGaStats.slice';
import { requestIsGaIntegrated } from 'store/isGaIntegrated/isGaIntegrated.slice';

import { StatsGaPublicProps } from './StatsGa.props';
import StatsGaView from './StatsGa.view';

const StatsGa = (props: StatsGaPublicProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const gaStats =
    useAppSelector((state: RootState) => state.getGaStats.data?.data) ||
    undefined;
  const [shouldGaRender, setShouldGaRender] = useState<boolean>(false);

  const formState =
    useAppSelector((state: RootState) => state.formClient) || undefined;

  const authState =
    useAppSelector((state: RootState) => state.auth) || undefined;

  const isGaIntegrated =
    useAppSelector((state: RootState) => state.isGaIntegrated.data) || false;

  let idInFocus: string | undefined = undefined;
  if (authState.type === 'admin') {
    idInFocus = formState.user?.id || undefined;
  } else if (authState.type === 'client') {
    idInFocus = authState.auth_id || undefined;
  }

  const isGaIntegratedHandler = () => {
    if (idInFocus !== undefined) {
      dispatch(
        requestIsGaIntegrated({
          client_id: idInFocus,
        })
      );
    }
  };

  const getGaStatsHandler = () => {
    if (idInFocus !== undefined) {
      dispatch(
        requestGetGaStats({
          client_id: idInFocus,
        })
      );
    }
  };

  useEffect(() => {
    if (gaStats === null || gaStats === undefined) {
      setShouldGaRender(false);
    } else {
      setShouldGaRender(true);
    }
  }, [gaStats]);

  useEffect(() => {
    isGaIntegratedHandler();
  }, []);

  useEffect(() => {
    if (isGaIntegrated?.data?.isIntegrated === true) {
      getGaStatsHandler();
    }
  }, [isGaIntegrated]);

  let pageViews = 0;
  let bounceRate = 0;
  let avgTime = 0;
  let topSource = 'Undertermined';
  let goalConversionRate = 0;
  let monthlyProgress = 0;
  let annualProgress = 0;
  if (gaStats !== undefined) {
    pageViews = gaStats.pageViews;
    bounceRate = gaStats.bounceRate;
    avgTime = gaStats.avgTime;
    goalConversionRate = gaStats.goalConversionRate;
    topSource = gaStats.topSource;
    topSource = topSource.replace(/\w+/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });

    if (Number.isInteger(gaStats.trafficMonthlyGoal) === true) {
      monthlyProgress = (pageViews / gaStats.trafficMonthlyGoal) * 100;
      monthlyProgress = monthlyProgress >= 100 ? 100 : monthlyProgress;
    }

    if (
      Number.isInteger(gaStats.trafficAnnualGoal) === true &&
      Number.isInteger(gaStats.annualPageViews) === true
    ) {
      annualProgress =
        (gaStats.annualPageViews / gaStats.trafficAnnualGoal) * 100;
      annualProgress = annualProgress >= 100 ? 100 : annualProgress;
    }
  }

  const generatedProps = {
    pageViews,
    bounceRate,
    avgTime,
    topSource,
    goalConversionRate,
    shouldGaRender,
    monthlyProgress,
    annualProgress,
  };
  return <StatsGaView {...generatedProps} />;
};

export default StatsGa;
