import React from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';

import StandOutView from './StandOut.view';

const StandOut = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const content =
    useAppSelector((state: RootState) => state.getContentStats.data?.data) ||
    undefined;

  const subscriberDetails =
    useAppSelector(
      (state: RootState) => state.getSubscriberDetails.data?.data
    ) || undefined;

  let socialPostData = undefined;
  let socialAdData = undefined;
  let emailStandOut = undefined;

  if (content !== undefined) {
    const facebookData = content.facebookData;
    const instagramData = content.instagramData;

    const fbEngagementPoints =
      facebookData.mostEngagedPost.like +
      facebookData.mostEngagedPost.shares +
      facebookData.mostEngagedPost.comments;
    const igEngagementPoints =
      instagramData.mostEngagedPost.like +
      facebookData.mostEngagedPost.comments;

    if (fbEngagementPoints > igEngagementPoints) {
      socialPostData = facebookData.mostEngagedPost;
    } else {
      socialPostData = instagramData.mostEngagedPost;
    }

    if (
      facebookData.mostEngagedAd.impression >
      instagramData.mostEngagedAd.impression
    ) {
      socialAdData = facebookData.mostEngagedAd;
    } else {
      socialAdData = instagramData.mostEngagedAd;
    }
  }

  if (subscriberDetails !== undefined) {
    emailStandOut = subscriberDetails.mostEngagedCampaignObject;
  }

  const generatedProps = {
    socialPostData,
    socialAdData,
    emailStandOut,
  };
  return <StandOutView {...generatedProps} />;
};

export default StandOut;
