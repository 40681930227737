import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const POST_URL = `${BASE_URL}/posts`;

export const upsertPost = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${POST_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getPostsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${POST_URL}/getPostsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const deletePost = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${POST_URL}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
