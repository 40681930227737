import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const LINKEDIN_URL = `${BASE_URL}/linkedin`;

import { GetLinkedinFollowersMeta } from 'store/getLinkedinFollowers/getLinkedinFollowers.type';
import { GetOrganizationListMeta } from 'store/getOrganizationList/getOrganizationList.type';
import { TestLinkedinConnectionMeta } from 'store/testLinkedinConnection/testLinkedinConnection.type';
import { UpdateOrganizationUrnMeta } from 'store/updateOrganizationUrn/updateOrganizationUrn.type';

export const testLinkedinConnection = (
  payload: TestLinkedinConnectionMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${LINKEDIN_URL}/testLinkedinConnection`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOrganizationList = (
  payload: GetOrganizationListMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${LINKEDIN_URL}/getOrganizationList`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateOrganizationUrn = (
  payload: UpdateOrganizationUrnMeta,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`${LINKEDIN_URL}/updateOrganizationUrn`, payload, config);
};

export const getFollowerCount = (
  payload: GetLinkedinFollowersMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${LINKEDIN_URL}/getFollowerCount`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
