import React from 'react';

import { useTheme } from '@emotion/react';

import AngleDown from '../SVG/AngleDown';
import { DropDownProps } from './DropDown.props';
import {
  Container,
  Label,
  PREFIX,
  ArrowContainer,
  StyledDropdown,
  Error,
} from './DropDown.style';

const DropDown = ({ label, error, ...props }: DropDownProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Container label={label}>
      {label ? (
        <Label variant="body" color="primary">
          {label}
        </Label>
      ) : null}
      <StyledDropdown
        {...props}
        controlClassName={
          props.size === 'small'
            ? `${PREFIX}ContainerThin`
            : `${PREFIX}Container`
        }
        placeholderClassName={`${PREFIX}Placeholder`}
        menuClassName={`${PREFIX}Menu`}
        arrowClosed={
          <ArrowContainer size={props.size}>
            <AngleDown fill={theme.grey.noshade} />
          </ArrowContainer>
        }
        arrowOpen={
          <ArrowContainer size={props.size} flipped>
            <AngleDown fill={theme.grey.noshade} />
          </ArrowContainer>
        }
      />

      {(error || '').length > 0 && (
        <Error variant="widget" color="error">
          {error}
        </Error>
      )}
    </Container>
  );
};

export default React.memo(DropDown);
