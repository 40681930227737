import type { PayloadAction } from '@reduxjs/toolkit';
import { getClientById as getClientByIdApi } from 'api/client';
import { RootState } from 'app/store';
import { goBack, replace } from 'connected-react-router';
import { ADMIN_ROUTES } from 'consts/routes';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  clearFormClient,
  setFormClient,
} from 'store/formClient/formClient.slice';

import {
  requestGetClientById,
  setGetClientById,
  failGetClientById,
} from './getClientById.slice';
import { GetClientByIdMeta, GetClientByIdPayload } from './getClientById.type';

function* getClientByIdRequest(action: PayloadAction<GetClientByIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getClientByIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetClientById(data));
    } catch (err) {
      yield put(failGetClientById(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetClientById('No token provided'));
  }
}

function* getClientByIdSuccess(action: PayloadAction<GetClientByIdPayload>) {
  // yield function here
  const account = action.payload.data.account;
  yield put(
    setFormClient({
      id: account.id,
      brandName: account.brand_name || '',
      url: account.website_url || '',
      firstName: account.user.first_name || '',
      lastName: account.user.last_name || '',
      phoneNumber: account.phone_number || '',
      email: account.user.email || '',
      isActive: account.is_active || '',
      activatedAt: account.activated_at || '',
      colors: account.user.colors || [],
      logos: account.user.logos || [],
      icons: account.user.icons || [],
      assets: account.user.assets || [],
      mockups: account.user.mockups || [],
      styleGuides: account.user.style_guides || [],
      documents: account.user.documents || [],
      strategy: account.user.strategy || null,
      invitelog: account.user.invite_log || null,
      kpis: account.user.kpis || [],
      writeups: account.user.page_writeup || null,
      user: {
        id: account.user.id || '',
        email: account.user.email || '',
        isArchive: account.user.is_archive || '',
        user_permission: account.user.user_permission || '',
      },
    })
  );
}

function* getClientByIdFail(action: PayloadAction<GetClientByIdPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
  yield put(clearFormClient());
  yield put(goBack());
}

function* GetClientByIdWatcher() {
  yield takeLatest(requestGetClientById.type, getClientByIdRequest);
  yield takeLatest(setGetClientById.type, getClientByIdSuccess);
  yield takeLatest(failGetClientById.type, getClientByIdFail);
}

export default GetClientByIdWatcher;
