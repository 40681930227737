import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  ClientGenericActionMeta,
  ClientGenericActionPayload
} from './clientGenericAction.type';

const initialState: AsyncState<ClientGenericActionMeta, ClientGenericActionPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<ClientGenericActionMeta, ClientGenericActionPayload>();

const clientGenericActionSlice = createSlice({
  name: 'clientGenericAction',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestClientGenericAction = clientGenericActionSlice.actions.REQUEST;
export const setClientGenericAction = clientGenericActionSlice.actions.SET;
export const failClientGenericAction = clientGenericActionSlice.actions.FAILED;
export const clearClientGenericAction = clientGenericActionSlice.actions.CLEAR;

export default clientGenericActionSlice.reducer;
