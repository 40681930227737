import React from 'react';

import { SVGProps } from './SVG.props';

const Pinterest = (props: SVGProps): JSX.Element => {
  const { width, height, fill, stroke } = props;

  let letterP = '#ffffff';
  let circle = <circle cx={56.099} cy={56.1} r={56.098} fill={fill}></circle>;
  if (fill === '#FFFFFF' || fill === '#ffffff') {
    circle = <React.Fragment />;
    letterP = fill;
  }

  return (
    <svg
      width={width || 36}
      height={height || 36}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 112.198 112.198"
      {...props}
    >
      {circle}
      <path
        d="M60.627 75.122c-4.241-.328-6.023-2.431-9.349-4.45-1.828 9.591-4.062 18.785-10.679 23.588-2.045-14.496 2.998-25.384 5.34-36.941-3.992-6.72.48-20.246 8.9-16.913 10.363 4.098-8.972 24.987 4.008 27.596 13.551 2.724 19.083-23.513 10.679-32.047-12.142-12.321-35.343-.28-32.49 17.358.695 4.312 5.151 5.621 1.78 11.571-7.771-1.721-10.089-7.85-9.791-16.021.481-13.375 12.018-22.74 23.59-24.036 14.635-1.638 28.371 5.374 30.267 19.14 2.133 15.537-6.607 32.363-22.255 31.155z"
        fill={stroke}
      />
    </svg>
  );
};

export default Pinterest;
