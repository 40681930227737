import axios from 'axios';
import { API } from 'consts/api';
import { GetContentStatsMeta } from 'store/getContentStats/getContentStats.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CONTENT_URL = `${BASE_URL}/content`;

export const getContentStats = (
  payload: GetContentStatsMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${CONTENT_URL}/aggregateContentData`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: payload,
  });
};
