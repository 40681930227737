import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  SendInvitationMeta,
  SendInvitationPayload
} from './sendInvitation.type';

const initialState: AsyncState<SendInvitationMeta, SendInvitationPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<SendInvitationMeta, SendInvitationPayload>();

const sendInvitationSlice = createSlice({
  name: 'sendInvitation',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestSendInvitation = sendInvitationSlice.actions.REQUEST;
export const setSendInvitation = sendInvitationSlice.actions.SET;
export const failSendInvitation = sendInvitationSlice.actions.FAILED;
export const clearSendInvitation = sendInvitationSlice.actions.CLEAR;

export default sendInvitationSlice.reducer;
