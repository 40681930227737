import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import auth from 'store/auth';
import { setAuthentication, clearAuthentication } from 'store/auth/auth.slice';
import { AuthState } from 'store/auth/auth.type';
import { getUser } from 'store/getUser/getUser.slice';

import DemoView from './Demo.view';

const Demo = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const updateCredentials = () => {
    const sampleAuth: AuthState = {
      token: 'random_token',
      type: 'admin',
      expiry: null,
      auth_id: null,
      account_id: null,
    };

    dispatch(setAuthentication(sampleAuth));
  };

  const test = () => {
    dispatch(
      getUser({
        user_id: '123',
      })
    );
  };

  useEffect(() => {
    updateCredentials();
    test();
    setTimeout(() => {
      dispatch(clearAuthentication());
    }, 5000);
  }, []);

  const generatedProps = {
    // generated props here
  };

  return <DemoView {...generatedProps} />;
};

export default Demo;
