import React from 'react';

// import { useTheme } from 'utils/Theme';
import { Close } from 'components/base/SVG';

import { ModalProps } from './Modal.props';
import { Backdrop, ModalContainer, ExitButton } from './Modal.style';

const Modal = (props: ModalProps): JSX.Element => {
  // const theme = useTheme();

  const { isOpen, onClickClose, children } = props;

  const backdropHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClickClose();
  };

  return (
    <Backdrop onClick={backdropHandler} isOpen={isOpen}>
      <ModalContainer
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        backgroundColor={props.backgroundColor}
        style={props.style}
      >
        <ExitButton
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            onClickClose();
          }}
        >
          <Close fill={'#ffffff'} />
        </ExitButton>
        {children}
      </ModalContainer>
    </Backdrop>
  );
};

export default Modal;
