import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetOrganizationListMeta,
  GetOrganizationListPayload,
} from './getOrganizationList.type';

const initialState: AsyncState<
  GetOrganizationListMeta,
  GetOrganizationListPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetOrganizationListMeta, GetOrganizationListPayload>();

const getOrganizationListSlice = createSlice({
  name: 'getOrganizationList',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetOrganizationList =
  getOrganizationListSlice.actions.REQUEST;
export const setGetOrganizationList = getOrganizationListSlice.actions.SET;
export const failGetOrganizationList = getOrganizationListSlice.actions.FAILED;
export const clearGetOrganizationList = getOrganizationListSlice.actions.CLEAR;

export default getOrganizationListSlice.reducer;
