import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpdateGaViewIdMeta,
  UpdateGaViewIdPayload,
} from './updateGaViewId.type';

const initialState: AsyncState<UpdateGaViewIdMeta, UpdateGaViewIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdateGaViewIdMeta, UpdateGaViewIdPayload>();

const updateGaViewIdSlice = createSlice({
  name: 'updateGaViewId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdateGaViewId = updateGaViewIdSlice.actions.REQUEST;
export const setUpdateGaViewId = updateGaViewIdSlice.actions.SET;
export const failUpdateGaViewId = updateGaViewIdSlice.actions.FAILED;
export const clearUpdateGaViewId = updateGaViewIdSlice.actions.CLEAR;

export default updateGaViewIdSlice.reducer;
