import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { IssueFbTokenMeta, IssueFbTokenPayload } from './issueFbToken.type';

const initialState: AsyncState<IssueFbTokenMeta, IssueFbTokenPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<IssueFbTokenMeta, IssueFbTokenPayload>();

const issueFbTokenSlice = createSlice({
  name: 'issueFbToken',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestIssueFbToken = issueFbTokenSlice.actions.REQUEST;
export const setIssueFbToken = issueFbTokenSlice.actions.SET;
export const failIssueFbToken = issueFbTokenSlice.actions.FAILED;
export const clearIssueFbToken = issueFbTokenSlice.actions.CLEAR;

export default issueFbTokenSlice.reducer;
