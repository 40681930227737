import styled from '@emotion/styled';
import Button from 'components/base/Button';
import Typography from 'components/base/Typography';
import { Col } from 'react-grid-system';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  margin: 16px 16px 380px 16px;
`;

export const Title = styled(Typography)`
  margin-bottom: 16px;
`;

export const TypographyContainer = styled.div``;

export const ColorContainer = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  height: 75px;

  position: relative;
`;

export const ColorText = styled(Typography)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #ffffff;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
`;

export const CustomBt = styled(Button)`
  background-color: ${(props) => props.theme.grey.shade1};
`;

export const CustomColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 32px;
  padding-bottom: 32px;
  justify-content: space-between;
`;

export const SocialIconGroupContainer = styled.div`
  display: flex;
`;

export const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  margin: 8px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const NavyBackground = styled.div`
  background-color: ${(props) => props.theme.brand.primary};
`;

export const TwilightBackground = styled.div`
  background-color: ${(props) => props.theme.brand.twilight};
`;

export const BlushBackground = styled.div`
  background-color: ${(props) => props.theme.brand.blush};
`;

export const LogoGroupContainer = styled.div`
  display: flex;
`;

export const PopupCenterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
