import axios from 'axios';
import { API } from 'consts/api';
import { LoginMeta } from 'store/login/login.type';
import { createBasicToken } from 'utils/Token';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const AUTH_URL = `${BASE_URL}/auth`;

export const login = (data: any) => {
  return axios({
    method: 'POST',
    url: `${AUTH_URL}/login`,
    headers: {
      Authorization: createBasicToken(data),
    },
    data: data,
  });
};

export const verifyEmail = (data: any) => {
  return axios({
    method: 'POST',
    url: `${AUTH_URL}/verify-email`,
    headers: {
      Authorization: createBasicToken(data),
    },
    data: data,
  });
};

export const resetPassword = (data: any) => {
  return axios({
    method: 'POST',
    url: `${AUTH_URL}/reset-password`,
    headers: {
      Authorization: createBasicToken(data),
    },
    data: data,
  });
};