import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertColorMeta,
  UpsertColorPayload
} from './upsertColor.type';

const initialState: AsyncState<UpsertColorMeta, UpsertColorPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertColorMeta, UpsertColorPayload>();

const upsertColorSlice = createSlice({
  name: 'upsertColor',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertColor = upsertColorSlice.actions.REQUEST;
export const setUpsertColor = upsertColorSlice.actions.SET;
export const failUpsertColor = upsertColorSlice.actions.FAILED;
export const clearUpsertColor = upsertColorSlice.actions.CLEAR;

export default upsertColorSlice.reducer;
