import type { PayloadAction } from '@reduxjs/toolkit';
import { issueLongLivedToken } from 'api/fb';
import { RootState } from 'app/store';
import { success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestFbListPages } from 'store/fbListPages/fbListPages.slice';
import { requestGetFbAttributes } from 'store/getFbAttributes/getFbAttributes.slice';

import {
  requestIssueFbToken,
  setIssueFbToken,
  failIssueFbToken,
} from './issueFbToken.slice';
import { IssueFbTokenMeta, IssueFbTokenPayload } from './issueFbToken.type';

function* issueFbTokenRequest(action: PayloadAction<IssueFbTokenMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        issueLongLivedToken,
        action.payload,
        state.auth.token
      );
      yield put(setIssueFbToken(data));
    } catch (err) {
      yield put(failIssueFbToken(err.response.data.message));
    }
  } else {
    yield put(failIssueFbToken('No token provided'));
  }
}

function* issueFbTokenSuccess(action: PayloadAction<IssueFbTokenPayload>) {
  const state: RootState = yield select();
  const client_id = state.formClient.user?.id;

  if (client_id) {
    yield put(
      requestGetFbAttributes({
        client_id: client_id,
      })
    );
    yield put(
      requestFbListPages({
        client_id: client_id,
      })
    );
  }

  yield put(success('Facebook Token Issued'));
}

function* issueFbTokenFail(action: PayloadAction<IssueFbTokenPayload>) {
  // yield function here
}

function* IssueFbTokenWatcher() {
  yield takeLatest(requestIssueFbToken.type, issueFbTokenRequest);
  yield takeLatest(setIssueFbToken.type, issueFbTokenSuccess);
  yield takeLatest(failIssueFbToken.type, issueFbTokenFail);
}

export default IssueFbTokenWatcher;
