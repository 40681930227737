import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertColor as upsertColorApi } from 'api/color';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientColors } from 'store/formClient/formClient.slice';

import {
  requestUpsertColor,
  setUpsertColor,
  failUpsertColor,
} from './upsertColor.slice';
import { UpsertColorMeta, UpsertColorPayload } from './upsertColor.type';

function* upsertColorRequest(action: PayloadAction<UpsertColorMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertColorApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpsertColor(data));
    } catch (err) {
      yield put(failUpsertColor(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertColor('No token provided'));
  }
}

function* upsertColorSuccess(action: PayloadAction<UpsertColorPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(action.payload.message))
  yield put(updateFormClientColors(data));
}

function* upsertColorFail(action: PayloadAction<UpsertColorPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertColorWatcher() {
  yield takeLatest(requestUpsertColor.type, upsertColorRequest);
  yield takeLatest(setUpsertColor.type, upsertColorSuccess);
  yield takeLatest(failUpsertColor.type, upsertColorFail);
}

export default UpsertColorWatcher;
