import React from 'react';

import { LineProps } from './Line.props';
import { Container, LineContainer, StartCircle, EndCircle } from './Line.style';

const Line = (props: LineProps): JSX.Element => {
  const { variant, circle, smallMargin } = props;
  return (
    <Container smallMargin={smallMargin}>
      <LineContainer variant={variant}></LineContainer>
      {circle === true && <StartCircle variant={variant}></StartCircle>}
      {circle === true && <EndCircle variant={variant}></EndCircle>}
    </Container>
  );
};

export default React.memo(Line);
