import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STRATEGY_URL = `${BASE_URL}/strategy`;

export const upsertStrategy = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${STRATEGY_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getStrategyByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${STRATEGY_URL}/getStrategyByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};