import React from 'react';

import { SVGProps } from './SVG.props';

const BoxOutlined = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '22'}
      height={height || '20'}
      viewBox="0 0 22 20"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.925.051v5.296h-1.299v14.576H1.349V5.373H.05V.05h21.875zm-1.274 3.985V1.388H1.348v2.648h19.303zm-1.298 14.576V5.372H2.622v13.24h16.731z"
        fill={fill || "#1E1850"}
      />
      <path
        d="M14.234 6.684c1.423 0 2.572 1.182 2.572 2.648 0 1.465-1.149 2.647-2.572 2.647H7.79c-1.423 0-2.572-1.182-2.572-2.647 0-1.466 1.149-2.648 2.572-2.648h6.443zm1.273 2.648c0-.72-.574-1.337-1.298-1.337H7.79c-.699 0-1.298.591-1.298 1.337 0 .72.574 1.336 1.298 1.336h6.443c.7 0 1.273-.617 1.273-1.336z"
        fill={fill || "#1E1850"}
      />
    </svg>
  );
};

export default BoxOutlined;
