import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteColorByIdMeta,
  DeleteColorByIdPayload
} from './deleteColorById.type';

const initialState: AsyncState<DeleteColorByIdMeta, DeleteColorByIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteColorByIdMeta, DeleteColorByIdPayload>();

const deleteColorByIdSlice = createSlice({
  name: 'deleteColorById',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteColorById = deleteColorByIdSlice.actions.REQUEST;
export const setDeleteColorById = deleteColorByIdSlice.actions.SET;
export const failDeleteColorById = deleteColorByIdSlice.actions.FAILED;
export const clearDeleteColorById = deleteColorByIdSlice.actions.CLEAR;

export default deleteColorByIdSlice.reducer;
