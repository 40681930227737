import type { PayloadAction } from '@reduxjs/toolkit';
import { getContentStats } from 'api/content';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetContentStats,
  setGetContentStats,
  failGetContentStats,
} from './getContentStats.slice';
import {
  GetContentStatsMeta,
  GetContentStatsPayload,
} from './getContentStats.type';

function* getContentStatsRequest(action: PayloadAction<GetContentStatsMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getContentStats,
        action.payload,
        state.auth.token
      );
      yield put(setGetContentStats(data));
    } catch (err) {
      yield put(failGetContentStats(err.response.data.message));
    }
  } else {
    yield put(failGetContentStats('No token provided'));
  }
}

function* getContentStatsSuccess(
  action: PayloadAction<GetContentStatsPayload>
) {
  // yield function here
}

function* getContentStatsFail(action: PayloadAction<GetContentStatsPayload>) {
  // yield function here
}

function* GetContentStatsWatcher() {
  yield takeLatest(requestGetContentStats.type, getContentStatsRequest);
  yield takeLatest(setGetContentStats.type, getContentStatsSuccess);
  yield takeLatest(failGetContentStats.type, getContentStatsFail);
}

export default GetContentStatsWatcher;
