import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertPostMeta,
  UpsertPostPayload
} from './upsertPost.type';

const initialState: AsyncState<UpsertPostMeta, UpsertPostPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertPostMeta, UpsertPostPayload>();

const upsertPostSlice = createSlice({
  name: 'upsertPost',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertPost = upsertPostSlice.actions.REQUEST;
export const setUpsertPost = upsertPostSlice.actions.SET;
export const failUpsertPost = upsertPostSlice.actions.FAILED;
export const clearUpsertPost = upsertPostSlice.actions.CLEAR;

export default upsertPostSlice.reducer;
