import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const DOCUMENT_URL = `${BASE_URL}/document`;

export const upsertDocument = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${DOCUMENT_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const deleteDocument = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${DOCUMENT_URL}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getDocumentsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${DOCUMENT_URL}/getDocumentsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};