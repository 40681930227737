import React, { useState } from 'react';

import Spinner from 'components/base/Spinner';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import theme from 'utils/Theme';

import { BarChartProps } from './BarChart.props';
import {
  Container,
  Heading,
  HeadingDiv,
  Description,
  MetricContainer,
  ToolTipContainer,
  ToolTip,
  SvgGroup,
  SvgToolTip,
  SvgToolTipText,
  EngagementToolTipContainer,
} from './BarChart.style';
const BarChart = (props: BarChartProps): JSX.Element => {
  const fontSize = '7px';
  const monthFontSize = '7px';
  const [engagement, setEngagement] = useState(0);
  const [fbEngagement, setFbEngagement] = useState(0);
  const [igEngagement, setIgEngagement] = useState(0);
  const [engagmentToolTipShow, setEngagementToolTipShow] = useState(false);

  const { contentStats, statsLoading } = props;

  const currentDate = new Date();

  const monthList = [];
  for (let x = 0; x < 6; x++) {
    const currentMonth = currentDate.getMonth() - x;
    if (currentMonth < 0) {
      monthList.push(currentMonth + 12);
    } else {
      monthList.push(currentMonth);
    }
  }

  const aggregatedMap = new Map();
  const maxEngagement = 50; // requested by client
  if (contentStats !== undefined) {
    const fbMap = contentStats.data.facebookData.engagementMap;
    const igMap = contentStats.data.instagramData.engagementMap;

    for (const [key, value] of Object.entries(fbMap)) {
      const aggregatedValue = value + igMap[key];
      // if (maxEngagement < aggregatedValue) {
      //   maxEngagement = aggregatedValue;
      // }
      aggregatedMap.set(key, aggregatedValue);
    }
  }

  const keyList = [];
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  for (let x = 0; x < 6; x++) {
    let month = currentMonth - x;
    let year = currentYear;
    if (month < 0) {
      month = month + 12;
      year = currentYear - 1;
    }

    keyList.unshift(`${month}-${year}`);
  }

  const yMiddlePoints = [];
  const yDistance = maxEngagement / 5;
  for (let x = 1; x <= 4; x++) {
    yMiddlePoints.push(maxEngagement - yDistance * x);
  }

  let totalLikes = 0;
  let totalImpressions = 0;
  let totalComments = 0;

  if (contentStats !== undefined) {
    totalLikes =
      contentStats.data.facebookData.likes +
      contentStats.data.instagramData.likes;
    totalComments =
      contentStats.data.facebookData.comments +
      contentStats.data.instagramData.comments;
    totalImpressions =
      contentStats.data.facebookData.impressions +
      contentStats.data.instagramData.impressions;
  }

  const handleSvgToolTipHover = (value: number, date: string) => {
    setEngagement(value);
    setEngagementToolTipShow(true);
    if (contentStats !== undefined) {
      setFbEngagement(contentStats.data.facebookData.engagementMap[date]);
      setIgEngagement(contentStats.data.instagramData.engagementMap[date]);
    }
  };

  const dynamicBar = [<React.Fragment key={0}></React.Fragment>];
  if (contentStats !== undefined) {
    for (let x = 0; x < keyList.length; x++) {
      const originalValue = aggregatedMap.get(keyList[x]) as number;
      const date = keyList[x] as string;
      let value = (aggregatedMap.get(keyList[x]) / maxEngagement) * 85;
      value = value > 85 ? 85 : value;
      const xPos = (x + 2) * 15;

      dynamicBar.push(
        <SvgGroup
          key={x + 'SvgGroupKey'}
          onMouseEnter={() => handleSvgToolTipHover(originalValue, date)}
          onMouseLeave={() => setEngagementToolTipShow(false)}
        >
          <rect
            fill={theme.brand.blush}
            width={10}
            height={85}
            x={xPos}
            y={20}
            rx={6}
            ry={6}
          ></rect>
          <rect
            fill={theme.brand.secondary}
            width={10}
            height={value}
            x={xPos}
            y={105 - value}
            rx={6}
            ry={6}
          ></rect>

          {value < 15 || (
            <circle
              cx={xPos + 5}
              cy={105 - value + 5}
              r="3.5"
              fill={theme.grey.noshade}
            />
          )}
        </SvgGroup>
        // </React.Fragment>
      );
    }
  }

  return (
    <Container>
      <GridContainer>
        <Row>
          <Col lg={6}>
            <svg viewBox={'0 0 120 105'}>
              <g fill={theme.brand.primary}>
                <text fontSize={fontSize} x="0" y="30">
                  {50}
                </text>
                <text fontSize={fontSize} x="0" y="45">
                  {40}
                </text>
                <text fontSize={fontSize} x="0" y="60">
                  {30}
                </text>
                <text fontSize={fontSize} x="0" y="75">
                  {20}
                </text>
                <text fontSize={fontSize} x="0" y="90">
                  {10}
                </text>
                <text fontSize={fontSize} x="0" y="105">
                  0
                </text>
              </g>
              <g fill={theme.brand.primary}>
                {monthList.reverse().map((value, index) => {
                  const monthShort = new Date(2020, value, 1);
                  const monthAbr = monthShort.toLocaleString('default', {
                    month: 'short',
                  });
                  return (
                    <text
                      key={index}
                      fontSize={monthFontSize}
                      x={(index + 2) * 15}
                      y="15"
                    >
                      {monthAbr}
                    </text>
                  );
                })}
              </g>
              <g>{dynamicBar}</g>
            </svg>
          </Col>
          <Col lg={6}>
            <EngagementToolTipContainer show={engagmentToolTipShow}>
              <ToolTip
                style={{ marginBottom: '4px' }}
                color={'blushLight'}
                weight={'700'}
              >
                Summary
              </ToolTip>
              <ToolTip color={'blushLight'}>Engagement: {engagement}</ToolTip>
              <ToolTip color={'blushLight'}>Facebook: {fbEngagement}</ToolTip>
              <ToolTip color={'blushLight'}>Instagram: {igEngagement}</ToolTip>
            </EngagementToolTipContainer>
            <HeadingDiv>
              <Heading variant="title3" color="primary" weight="800">
                Content
              </Heading>
              {statsLoading && <Spinner width={24} height={24}></Spinner>}
            </HeadingDiv>
            <MetricContainer>
              <Description variant="widget" color="black" weight="500">
                Likes: {totalLikes.toLocaleString()}
              </Description>
              <ToolTipContainer>
                <ToolTip
                  style={{ marginBottom: '4px' }}
                  color={'blushLight'}
                  weight={'700'}
                >
                  Summary
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Facebook:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.facebookData.likes.toLocaleString()
                    : 0}
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Instagram:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.instagramData.likes.toLocaleString()
                    : 0}
                </ToolTip>
              </ToolTipContainer>
            </MetricContainer>
            <MetricContainer>
              <Description variant="widget" color="black" weight="500">
                Shares:{' '}
                {contentStats !== undefined
                  ? contentStats.data.facebookData.shares.toLocaleString()
                  : 0}
              </Description>
              <ToolTipContainer>
                <ToolTip
                  style={{ marginBottom: '4px' }}
                  color={'blushLight'}
                  weight={'700'}
                >
                  Summary
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Facebook:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.facebookData.shares.toLocaleString()
                    : 0}
                </ToolTip>
              </ToolTipContainer>
            </MetricContainer>
            <MetricContainer>
              <Description variant="widget" color="black" weight="500">
                Comments:{' '}
                {contentStats !== undefined
                  ? totalComments.toLocaleString()
                  : 0}
              </Description>
              <ToolTipContainer>
                <ToolTip
                  style={{ marginBottom: '4px' }}
                  color={'blushLight'}
                  weight={'700'}
                >
                  Summary
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Facebook:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.facebookData.comments.toLocaleString()
                    : 0}
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Instagram:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.instagramData.comments.toLocaleString()
                    : 0}
                </ToolTip>
              </ToolTipContainer>
            </MetricContainer>
            <MetricContainer>
              <Description variant="widget" color="black" weight="500">
                Reactions:{' '}
                {contentStats !== undefined
                  ? contentStats.data.facebookData.reactions.toLocaleString()
                  : 0}
              </Description>
              <ToolTipContainer>
                <ToolTip
                  style={{ marginBottom: '4px' }}
                  color={'blushLight'}
                  weight={'700'}
                >
                  Summary
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Facebook:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.facebookData.reactions.toLocaleString()
                    : 0}
                </ToolTip>
              </ToolTipContainer>
            </MetricContainer>
            <MetricContainer>
              <Description variant="widget" color="black" weight="500">
                Impressions:{' '}
                {contentStats !== undefined
                  ? totalImpressions.toLocaleString()
                  : 0}
              </Description>
              <ToolTipContainer>
                <ToolTip
                  style={{ marginBottom: '4px' }}
                  color={'blushLight'}
                  weight={'700'}
                >
                  Summary
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Facebook:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.facebookData.impressions.toLocaleString()
                    : 0}
                </ToolTip>
                <ToolTip color={'blushLight'}>
                  Instagram:{' '}
                  {contentStats !== undefined
                    ? contentStats.data.instagramData.impressions.toLocaleString()
                    : 0}
                </ToolTip>
              </ToolTipContainer>
            </MetricContainer>
          </Col>
        </Row>
      </GridContainer>
    </Container>
  );
};

export default React.memo(BarChart);
