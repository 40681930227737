import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetDocumentsByIdMeta,
  GetDocumentsByIdPayload,
} from './getDocumentsById.type';

const initialState: AsyncState<GetDocumentsByIdMeta, GetDocumentsByIdPayload> =
  {
    request: null,
    pending: null,
    error: null,
    data: null,
  };

const defaultReducers =
  createAsyncSlice<GetDocumentsByIdMeta, GetDocumentsByIdPayload>();

const getDocumentsByIdSlice = createSlice({
  name: 'getDocumentsById',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetDocumentsById = getDocumentsByIdSlice.actions.REQUEST;
export const setGetDocumentsById = getDocumentsByIdSlice.actions.SET;
export const failGetDocumentsById = getDocumentsByIdSlice.actions.FAILED;
export const clearGetDocumentsById = getDocumentsByIdSlice.actions.CLEAR;

export default getDocumentsByIdSlice.reducer;
