import React, { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';

import { DoughnutGraphProps } from './DoughnutGraph.props';
import { Container } from './DoughnutGraph.style';

const DoughnutGraph = (props: DoughnutGraphProps): JSX.Element => {
  const theme = useTheme();

  const { radius, stroke, progress, lineOrigin, className, fill } = props;

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [maxStrokeDashoffset, setMaxStrokeDashoffset] = useState(0);

  useEffect(() => {
    setStrokeDashoffset(circumference - (0 / 100) * circumference);
    setMaxStrokeDashoffset(circumference - (0 / 100) * circumference);
  }, []);

  setTimeout(() => {
    setStrokeDashoffset(circumference - (progress / 100) * circumference);
    setMaxStrokeDashoffset(circumference - (100 / 100) * circumference);
  }, 1000);

  return (
    <Container origin={lineOrigin} className={className}>
      <svg
        height={(radius - stroke) * 2}
        width={(radius - stroke) * 2}
        preserveAspectRatio="xMidYMid meet"
      >
        <circle
          stroke={fill}
          fill="transparent"
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset: strokeDashoffset }}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius - stroke}
          cy={radius - stroke}
        />
        <circle
          strokeOpacity={0.2}
          stroke={fill}
          fill="transparent"
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset: maxStrokeDashoffset }}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius - stroke}
          cy={radius - stroke}
        />
      </svg>
    </Container>
  );
};

export default React.memo(DoughnutGraph);
