import { getUser as getUserApi } from 'api/user';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/Store';

import { getUser, setUser } from './getUser.slice';
import { GetUserMeta, GetUserPayload } from './getUser.type';

function* getUserRequest(action: AsyncAction<GetUserMeta, GetUserPayload>) {
  try {
    const { data } = yield call(getUserApi);
    yield put(setUser({ ...data }));
  } catch (err) {
    //handle fail
    console.log(err);
  }
  //   const state: Store = yield select();
  //   if (state.auth.token) {
  //     try {
  //       const { data } = yieldcall(getUser, state.auth.token);
  //       yield put(getUserActions.success(data));
  //     } catch (e) {
  //       yield put(getUserActions.failed(e.message));
  //     }
  //   } else {
  //     yield put(getUserActions.failed('Token not found'));
  //   }
}

function* getUserSuccess(action: AsyncAction<GetUserMeta, GetUserPayload>) {
  //   yield call(() => undefined);
  // TODO: if buyer set to true
  //   const state: Store = yield select();
  //   if (state.auth.type === 'buyer') {
  //     const { companies } = action.payload.data.user;
  //     const companyId: string = pathOr('', ['0', 'id'], companies);
  //     if (companyId) {
  //       if (
  //         (state.getAddresses.data?.data.addresses || []).length === 0 ||
  //         state.getAddresses.request?.companyId !== companyId
  //       ) {
  //         yield put(getAddressesActions.request({ companyId }));
  //       }
  //     }
  //   }
}

function* getUserFailedHandler(
  action: AsyncAction<GetUserMeta, GetUserPayload>
) {
  //   if (action.error === 'Request failed with status code 401') {
  //     yield put(authActions.clear());
  //   }
}

function* getUserWatcher() {
  yield takeLatest(getUser.type, getUserRequest);

  // success and failure handlers
  // yield takeLatest(setUser.type, getUserSuccess);
  // yield takeLatest(getUserActions.FAILED, getUserFailed);
}

export default getUserWatcher;
