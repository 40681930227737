import type { PayloadAction } from '@reduxjs/toolkit';
import { putViewId } from 'api/ga';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestIsGaIntegrated } from 'store/isGaIntegrated/isGaIntegrated.slice';

import {
  requestUpdateGaViewId,
  setUpdateGaViewId,
  failUpdateGaViewId,
} from './updateGaViewId.slice';
import {
  UpdateGaViewIdMeta,
  UpdateGaViewIdPayload,
} from './updateGaViewId.type';

function* updateGaViewIdRequest(action: PayloadAction<UpdateGaViewIdMeta>) {
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(putViewId, action.payload, state.auth.token);
      yield put(setUpdateGaViewId(data));
    } catch (err) {
      yield put(failUpdateGaViewId(err.response.data.message));
    }
  } else {
    yield put(failUpdateGaViewId('No token provided'));
  }
}

function* updateGaViewIdSuccess(action: PayloadAction<UpdateGaViewIdPayload>) {
  // refetch - can be refactored
  yield put(
    requestIsGaIntegrated({
      client_id: action.payload.data.updatedId,
    })
  );
}

function* updateGaViewIdFail(action: PayloadAction<UpdateGaViewIdPayload>) {
  // yield function here
}

function* UpdateGaViewIdWatcher() {
  yield takeLatest(requestUpdateGaViewId.type, updateGaViewIdRequest);
  yield takeLatest(setUpdateGaViewId.type, updateGaViewIdSuccess);
  yield takeLatest(failUpdateGaViewId.type, updateGaViewIdFail);
}

export default UpdateGaViewIdWatcher;
