import { verifyEmail as verifyEmailApi } from 'api/auth';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/Store';

import { request, set, failed, clear } from './resetVerifyEmail.slice';
import {
  ResetVerifyEmailMeta,
  ResetVerifyEmailPayload,
} from './resetVerifyEmail.type';

function* resetVerifyEmailRequest(
  action: AsyncAction<ResetVerifyEmailMeta, ResetVerifyEmailPayload>
) {
  try {
    const { data } = yield call(verifyEmailApi, { ...action.payload });
    yield put(set(data));
  } catch (e) {
    yield put(failed(e.response?.data || e));
  }
}

function* resetVerifyEmailSuccess(
  action: AsyncAction<ResetVerifyEmailMeta, ResetVerifyEmailPayload>
) {
  //
}

function* resetVerifyEmailFailed(
  action: AsyncAction<ResetVerifyEmailMeta, ResetVerifyEmailPayload>
) {
  if (action.error === 'Request failed with status code 401') {
    yield put(clear);
  }
}

function* ResetVerifyEmailWatcher() {
  yield takeLatest(request.type, resetVerifyEmailRequest);
  yield takeLatest(set.type, resetVerifyEmailSuccess);
  yield takeLatest(failed.type, resetVerifyEmailFailed);
}

export default ResetVerifyEmailWatcher;
