import React from 'react';

import Copy from 'components/base/SVG/Copy';
import { hexToCmyk, hexToRgb } from 'utils/Color';

import { BrandColorProps } from './BrandColor.props';
import {
  Container,
  ColorContainer,
  ColorTitle,
  ColorDetails,
  ColorListDetailContainer,
  ColorDetailContainer,
} from './BrandColor.style';
import { useState } from 'react';

const BrandColor = (props: BrandColorProps): JSX.Element => {
  const { hex, rgb, cmyk, pms, name } = props;

  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 1500);
  };

  const formattedHex = hex === undefined ? '' : hex;
  const formattedRgb = rgb === undefined ? '' : rgb;
  const formattedCmyk = cmyk === undefined ? '' : cmyk;
  const formattedPms = pms === undefined ? '' : pms;

  const [showCopied, setShowCopied] = useState(false);

  return (
    <Container>
      <ColorContainer bgColor={formattedHex}></ColorContainer>
      <ColorListDetailContainer>
        <ColorTitle color={'primary'} variant={'title3'}>
          {name}
          {showCopied && <span className="copy-notif">Copied</span>}
        </ColorTitle>
        <ColorDetailContainer
          onClick={() => {
            copyToClipBoard(formattedHex);
          }}
        >
          <ColorDetails color={'primary'}>HEX: {formattedHex}</ColorDetails>
          <Copy height={14} width={14}></Copy>
        </ColorDetailContainer>
        <ColorDetailContainer
          onClick={() => {
            copyToClipBoard(formattedRgb);
          }}
        >
          <ColorDetails color={'primary'}>RGB: {formattedRgb}</ColorDetails>
          <Copy height={14} width={14}></Copy>
        </ColorDetailContainer>
        <ColorDetailContainer
          onClick={() => {
            copyToClipBoard(formattedCmyk);
          }}
        >
          <ColorDetails color={'primary'}>CMYK: ({formattedCmyk})</ColorDetails>
          <Copy height={14} width={14}></Copy>
        </ColorDetailContainer>
        <ColorDetailContainer
          onClick={() => {
            copyToClipBoard(formattedPms);
          }}
        >
          <ColorDetails color={'primary'}>PMS: {formattedPms}</ColorDetails>
          <Copy height={14} width={14}></Copy>
        </ColorDetailContainer>
      </ColorListDetailContainer>
    </Container>
  );
};

export default React.memo(BrandColor);
