import React from 'react';

import { SVGProps } from './SVG.props';

const Youtube = (props: SVGProps): JSX.Element => {
  const { width, height, fill, stroke } = props;

  return (
    <svg
      width={width || 36}
      height={height || 36}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={24} cy={24} fill={fill} r={24} />
      <path
        d="M35.2 18.5c0-.1 0-.2-.1-.3v-.1c-.3-.9-1.1-1.5-2.1-1.5h.2S29.3 16 24 16c-5.2 0-9.2.6-9.2.6h.2c-1 0-1.8.6-2.1 1.5v.1c0 .1 0 .2-.1.3-.1 1-.4 3.1-.4 5.5s.3 4.5.4 5.5c0 .1 0 .2.1.3v.1c.3.9 1.1 1.5 2.1 1.5h-.2s3.9.6 9.2.6c5.2 0 9.2-.6 9.2-.6H33c1 0 1.8-.6 2.1-1.5v-.1c0-.1 0-.2.1-.3.1-1 .4-3.1.4-5.5s-.2-4.5-.4-5.5zm-7.8 6l-4.7 3.4c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1-.2-.1-.3-.3-.3-.5v-6.8c0-.2.1-.4.3-.5.2-.1.4-.1.6 0l4.7 3.4c.1.1.2.3.2.5.1.2 0 .4-.2.5z"
        fill={stroke}
      />
    </svg>
  );
};

export default Youtube;
