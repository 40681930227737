import React from 'react';

import { SVGProps } from './SVG.props';

const VertEllipsis = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '40'}
      height={height || '159'}
      viewBox="0 0 40 159"
      fill={fill || "#4757A6"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 2.3C8.1 3.9 2.3 10.1 1 15.1c-3.5 12.8 5.5 24.4 19 24.4 7 0 12.5-2.8 16.5-8.4 2.6-3.7 3-5.2 3-10.6 0-5.4-.4-6.9-3-10.6C31.4 2.7 21.4-.6 13 2.3zM13 61.3C8.1 62.9 2.3 69.1 1 74.1c-3.5 12.8 5.5 24.4 19 24.4 7 0 12.5-2.8 16.5-8.4 2.6-3.7 3-5.2 3-10.6 0-5.4-.4-6.9-3-10.6-5.1-7.2-15.1-10.5-23.5-7.6zM13 121.3c-4.9 1.6-10.7 7.8-12 12.8-3.5 12.8 5.5 24.4 19 24.4 7 0 12.5-2.8 16.5-8.4 2.6-3.7 3-5.2 3-10.6 0-5.4-.4-6.9-3-10.6-5.1-7.2-15.1-10.5-23.5-7.6z" />
    </svg>
  );
};

export default VertEllipsis;
