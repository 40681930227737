import React from 'react';

// import { useTheme } from 'utils/Theme';
import { ScrollContainerProps } from './ScrollContainer.props';
import { Container } from './ScrollContainer.style';

const ScrollContainer = (props: ScrollContainerProps): JSX.Element => {
  // const theme = useTheme();
  const {
    children,
    style,
    className,
    scrollColor = 'default',
    withRightPadding = true,
  } = props;

  return (
    <Container
      className={className}
      style={style}
      scrollColor={scrollColor}
      withRPadding={withRightPadding}
    >
      {children}
    </Container>
  );
};

export default React.memo(ScrollContainer);
