import React, { forwardRef, useState } from 'react';

// import { useTheme } from 'utils/Theme';
import Button from 'components/base/Button';
import Input from 'components/base/TextField';
import Typography from 'components/base/Typography';
import ScrollContainer from 'components/module/ScrollContainer';
import WelcomeView from 'components/module/Welcome';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { CheckboxOptions, ReportsGeneratedProps } from './Reports.props';
import {
  Container,
  HeadingDiv,
  InnerDiv,
  FiltersField,
  BenchmarkToggle,
  CheckboxOptionsContainer,
  SelectAll,
  ChkOptionItem,
  DateInput,
} from './Reports.style';

const ReportsView = (props: ReportsGeneratedProps) => {
  const {
    brandName,
    loading,
    isGaIntegrated,
    showWarning,
    handleGenerateReport,
  } = props;
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [benchmarkToggle, setBenhmeckToggle] = useState(false);

  const fromDateWrapper = ({ value, onClick }: any, ref: any) => {
    return (
      <div onClick={onClick} ref={ref}>
        <DateInput label={'From Date'} value={value}></DateInput>
      </div>
    );
  };

  const handleGetReport = () => {
    let shouldRender = false;
    for (const options of checkOptions) {
      if (options.flag === true) {
        shouldRender = true;
        break;
      }
    }

    if (shouldRender === false) {
      showWarning('Please select at least one data to include');
    }

    if (startDate === null || endDate === null) {
      showWarning('Please select From and To Dates');
    }

    if (startDate !== null && endDate !== null && shouldRender) {
      handleGenerateReport(checkOptions, startDate, endDate);
    }
  };

  const toDateWrapper = ({ value, onClick }: any, ref: any) => {
    return (
      <div
        onClick={() => {
          if (startDate === null) {
            showWarning('Select From Date First');
            return;
          } else {
            onClick();
          }
        }}
        ref={ref}
      >
        <DateInput label={'To Date'} value={value}></DateInput>
      </div>
    );
  };

  const FromDateComponent = forwardRef(fromDateWrapper);
  const ToDateComponent = forwardRef(toDateWrapper);

  const selectAll = () => {
    setCheckOptions([
      ...checkOptions.map((item: CheckboxOptions) => {
        item.flag = true;
        return item;
      }),
    ]);
  };

  const options: CheckboxOptions[] = [
    { label: 'Website', flag: false },
    { label: 'Fans', flag: false },
    { label: 'Subscribers', flag: false },
    { label: 'Content', flag: false },
    { label: 'Keywords', flag: false },
    { label: 'Stand Out', flag: false },
  ];
  const [checkOptions, setCheckOptions] = useState<CheckboxOptions[]>(options);
  const toggleOption = (label: string) => {
    if (label === 'Website' && isGaIntegrated === false) {
      showWarning('Please integrate Google Analytics');
      return;
    }

    setCheckOptions([
      ...checkOptions.map((item: CheckboxOptions) => {
        if (item.label === label) {
          item.flag = item.flag ? false : true;
        }
        return item;
      }),
    ]);
  };

  return (
    <Container>
      <ScrollContainer className="the-scrollable">
        <HeadingDiv>
          <WelcomeView name={brandName} title="Welcome"></WelcomeView>
        </HeadingDiv>
        <InnerDiv>
          <Typography variant="title3" color="black">
            Reports
          </Typography>
          <Typography variant="body" color="black" style={{ marginTop: '8px' }}>
            Please select your date range
          </Typography>
          <FiltersField>
            <DatePicker
              dateFormat={'dd/MM/yyyy'}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={startDate}
              onChange={(date) => {
                if (date instanceof Date) {
                  const offsetDate = new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  );
                  setStartDate(offsetDate);
                }
              }}
              customInput={<FromDateComponent />}
            />
            <DatePicker
              dateFormat={'dd/MM/yyyy'}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={endDate}
              minDate={startDate}
              onChange={(date) => {
                if (date instanceof Date) {
                  const offsetDate = new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  );
                  setEndDate(offsetDate);
                }
              }}
              customInput={<ToDateComponent />}
            />
          </FiltersField>
          <BenchmarkToggle
            checked={benchmarkToggle}
            label="Include my benchmark data"
            onClick={() => {
              setBenhmeckToggle(benchmarkToggle ? false : true);
            }}
            style={{ marginTop: '3px', display: 'block', width: 'auto' }}
          ></BenchmarkToggle>
          <CheckboxOptionsContainer>
            <Typography
              variant="widget"
              color="black"
              style={{ marginBottom: '10px' }}
            >
              Data to include &nbsp;&nbsp;
              <SelectAll onClick={selectAll}>Select All</SelectAll>
            </Typography>
            <ul>
              {checkOptions &&
                checkOptions.map((item: CheckboxOptions, index: number) => (
                  <li key={index}>
                    <ChkOptionItem
                      checked={item.flag}
                      label={item.label}
                      onClick={() => toggleOption(item.label)}
                      style={{ marginBottom: '10px' }}
                    ></ChkOptionItem>
                  </li>
                ))}
            </ul>
          </CheckboxOptionsContainer>
          <Button
            loading={loading}
            style={{ marginTop: '18px' }}
            text={'Download Report'}
            variant={'secondary'}
            size="smWide"
            onClick={() => {
              handleGetReport();
            }}
          ></Button>
        </InnerDiv>
      </ScrollContainer>
    </Container>
  );
};

export default ReportsView;
