import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ORDER_URL = `${BASE_URL}/getUser`;

// export const getUser = (data: GetUserMeta, token: string) => {
//   return axios({
//     method: 'post',
//     url: `${ORDER_URL}`,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     data,
//   });
// };

export const getUser = () => {
  return axios({
    method: 'get',
    url: `https://jsonplaceholder.typicode.com/todos/1`,
  });
};
