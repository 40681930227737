import type { PayloadAction } from '@reduxjs/toolkit';
import { sendInvite as sendInviteApi } from 'api/client';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientInviteLogs } from 'store/formClient/formClient.slice';

import {
  requestSendInvitation,
  setSendInvitation,
  failSendInvitation,
} from './sendInvitation.slice';
import {
  SendInvitationMeta,
  SendInvitationPayload,
} from './sendInvitation.type';

function* sendInvitationRequest(action: PayloadAction<SendInvitationMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        sendInviteApi,
        action.payload,
        state.auth.token
      );
      yield put(setSendInvitation(data));
    } catch (err) {
      yield put(failSendInvitation(err.response?.data?.message || err));
    }
  } else {
    yield put(failSendInvitation('No token provided'));
  }
}

function* sendInvitationSuccess(action: PayloadAction<SendInvitationPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientInviteLogs(data));
}

function* sendInvitationFail(action: PayloadAction<SendInvitationPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* SendInvitationWatcher() {
  yield takeLatest(requestSendInvitation.type, sendInvitationRequest);
  yield takeLatest(setSendInvitation.type, sendInvitationSuccess);
  yield takeLatest(failSendInvitation.type, sendInvitationFail);
}

export default SendInvitationWatcher;
