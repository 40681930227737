import React, { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';

import DoughnutGraph from '../DoughnutGraph';
import { ResultsBoxProps } from './ResultsBox.props';
import {
  Container,
  GraphBox,
  DetailsBox,
  HeadingTypo,
  Description,
} from './ResultsBox.style';

const ResultsBox = (props: ResultsBoxProps): JSX.Element => {
  const theme = useTheme();
  const { outerProgress, midProgress, innerProgress, children } = props;

  return (
    <Container>
      <GraphBox>
        <DoughnutGraph
          radius={83}
          stroke={13}
          progress={outerProgress}
          lineOrigin="-90"
          className="first-circle circles"
          fill="#F0582C"
        />
        <DoughnutGraph
          radius={63}
          stroke={13}
          progress={midProgress}
          lineOrigin="90"
          className="second-circle circles"
          fill="#262361"
        />
        {/* <DoughnutGraph
          radius={43}
          stroke={13}
          progress={innerProgress}
          lineOrigin="-20"
          className="third-circle circles"
          fill="#EEC8C0"
        /> */}
      </GraphBox>
      <DetailsBox>{children}</DetailsBox>
    </Container>
  );
};

export default React.memo(ResultsBox);
