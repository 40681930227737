import React from 'react';

import { SVGProps } from './SVG.props';

const TrashCanOutlined = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '20'}
      height={height || '25'}
      viewBox="0 0 20 25"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)" fill={fill || '#1E1850'}>
        <path d="M5.764 10.921a.513.513 0 00-.446.565l1.418 9.274a.528.528 0 00.589.427.514.514 0 00.446-.565l-1.418-9.274a.528.528 0 00-.589-.427zM9.379 11.5v9.275a.52.52 0 00.526.503.52.52 0 00.525-.503V11.5a.52.52 0 00-.525-.503.52.52 0 00-.526.503zM13.662 11.348l-1.417 9.274a.494.494 0 00.446.565.508.508 0 00.59-.427l1.416-9.274a.494.494 0 00-.445-.565.528.528 0 00-.59.427z" />
        <path d="M18.583 6.483v-.016a.484.484 0 00-.064-.259.65.65 0 00-.255-.29.655.655 0 00-.382-.107H2.118a.773.773 0 00-.27.046.665.665 0 00-.303.244 1 1 0 00-.096.183c-.016.046-.016.107-.016.183v.076L3.44 23.11c.032.274.128.533.271.762.223.351.526.626.908.824.366.198.796.305 1.258.305h8.28a2.613 2.613 0 001.91-.808c.144-.153.256-.32.351-.504.096-.183.16-.381.175-.595L17.866 12.6a.503.503 0 00-.462-.549.514.514 0 00-.573.442l-1.274 10.495a1.118 1.118 0 01-.127.366 1.325 1.325 0 01-.51.457 1.548 1.548 0 01-.748.183h-8.28c-.175 0-.35-.03-.51-.091a1.351 1.351 0 01-.605-.382.685.685 0 01-.175-.259.601.601 0 01-.08-.274L2.516 6.803H17.5l-.255 2.15a.503.503 0 00.462.55.514.514 0 00.573-.443l.303-2.516v-.061z" />
        <path d="M6.067 4.21l7.993-.061 4.618-.03c.032 0 .048 0 .08.015a.247.247 0 01.127.091.114.114 0 01.032.076c.016.03.016.046.016.077l.016 1.037c0 .03 0 .06-.016.076a.36.36 0 01-.096.137.124.124 0 01-.08.03c-.031.016-.047.016-.079.016l-17.213.122c-.032 0-.064 0-.08-.015a.247.247 0 01-.127-.092.114.114 0 01-.032-.076c-.016-.03-.016-.046-.016-.076L1.194 4.5c0-.03 0-.046.016-.077a.36.36 0 01.096-.137s.032-.015.063-.015c.032-.015.048-.015.08-.015l4.618-.046zm1.035-2.913c.223-.153.493-.26.796-.26l4.284-.03c.143 0 .286.015.413.06.271.077.51.245.67.458.159.214.27.473.27.763v.885l-6.959.045v-.884c0-.138.016-.275.064-.397.08-.26.239-.488.462-.64zM1.433 3.249c-.143 0-.287.03-.414.076a1.192 1.192 0 00-.51.32 1.202 1.202 0 00-.318.504c-.048.122-.064.26-.064.381l.016 1.037c0 .138.032.275.08.397.064.183.175.35.334.488.144.137.319.244.526.305.127.046.27.061.398.061l17.23-.122c.142 0 .286-.03.413-.076.191-.061.366-.168.51-.305.143-.137.255-.305.318-.504.048-.122.064-.259.064-.38L20 4.392c0-.137-.032-.275-.08-.397a1.251 1.251 0 00-.318-.488 1.274 1.274 0 00-.526-.305 1.204 1.204 0 00-.398-.06l-4.092.03v-.885c0-.503-.175-.976-.478-1.358a2.463 2.463 0 00-1.194-.808A2.08 2.08 0 0012.166 0L7.897.03c-.525 0-1.02.168-1.417.458-.398.29-.685.687-.844 1.144-.08.214-.112.458-.112.702v.884l-4.092.03z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h20v25H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashCanOutlined;
