import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  IsGaIntegratedMeta,
  IsGaIntegratedPayload,
} from './isGaIntegrated.type';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<IsGaIntegratedMeta, IsGaIntegratedPayload>();

const isGaIntegratedSlice = createSlice({
  name: 'isGaIntegrated',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestIsGaIntegrated = isGaIntegratedSlice.actions.REQUEST;
export const setIsGaIntegrated = isGaIntegratedSlice.actions.SET;
export const failIsGaIntegrated = isGaIntegratedSlice.actions.FAILED;
export const clearIsGaIntegrated = isGaIntegratedSlice.actions.CLEAR;

export default isGaIntegratedSlice.reducer;
