import type { PayloadAction } from '@reduxjs/toolkit';
import { getLogosByUserId as getLogosByUserIdApi } from 'api/logo';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetLogosByUserId,
  setGetLogosByUserId,
  failGetLogosByUserId,
} from './getLogosByUserId.slice';
import {
  GetLogosByUserIdMeta,
  GetLogosByUserIdPayload,
} from './getLogosByUserId.type';

function* getLogosByUserIdRequest(action: PayloadAction<GetLogosByUserIdMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getLogosByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetLogosByUserId(data));
    } catch (err) {
      yield put(failGetLogosByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetLogosByUserId('No token provided'));
  }
}

function* getLogosByUserIdSuccess(
  action: PayloadAction<GetLogosByUserIdPayload>
) {
  // yield function here
}

function* getLogosByUserIdFail(action: PayloadAction<GetLogosByUserIdPayload>) {
  // yield function here
  yield put(error(String(`Logo - ${action.payload}`)));
}

function* GetLogosByUserIdWatcher() {
  yield takeLatest(requestGetLogosByUserId.type, getLogosByUserIdRequest);
  yield takeLatest(setGetLogosByUserId.type, getLogosByUserIdSuccess);
  yield takeLatest(failGetLogosByUserId.type, getLogosByUserIdFail);
}

export default GetLogosByUserIdWatcher;
