import styled from '@emotion/styled';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{
  origin: string;
}>`
  display: inline-block;

  circle {
    transition: stroke-dashoffset 1.25s;
    transform: rotate(${({ origin }) => origin}deg);
    transform-origin: 50% 50%;
  }
`;

export const OuterBox = styled.div`
  width: 127px;
  height: 80px;

  border-radius: 100%;
  border: 13px solid #f0582c;
`;
