import type { PayloadAction } from '@reduxjs/toolkit';
import { updatePermission as updatePermissionApi } from 'api/permission';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientPermissions } from 'store/formClient/formClient.slice';
import { FormClientState } from 'store/formClient/formClient.type';

import {
  requestUpdatePermission,
  setUpdatePermission,
  failUpdatePermission,
} from './updatePermission.slice';
import {
  UpdatePermissionMeta,
  UpdatePermissionPayload,
} from './updatePermission.type';

function* updatePermissionRequest(action: PayloadAction<UpdatePermissionMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updatePermissionApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpdatePermission(data));
    } catch (err) {
      yield put(failUpdatePermission(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpdatePermission('No token provided'));
  }
}

function* updatePermissionSuccess(
  action: PayloadAction<UpdatePermissionPayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(action.payload.message));
  yield put(updateFormClientPermissions(data));
}

function* updatePermissionFail(action: PayloadAction<UpdatePermissionPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpdatePermissionWatcher() {
  yield takeLatest(requestUpdatePermission.type, updatePermissionRequest);
  yield takeLatest(setUpdatePermission.type, updatePermissionSuccess);
  yield takeLatest(failUpdatePermission.type, updatePermissionFail);
}

export default UpdatePermissionWatcher;
