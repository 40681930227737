import styled from '@emotion/styled';
import { BREAKPOINTS } from 'consts/breakpoints';

export const Backdrop = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(9, 19, 29, 0.4);
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 1000;
`;

export const ModalContainer = styled.div<{ backgroundColor?: string }>`
  position: relative;
  padding: 56px 32px;
  width: 438px;
  background-color: ${(props) => props.theme.grey.noshade};
  border: 2px solid ${(props) => props.theme.brand.secondary};

  @media ${BREAKPOINTS.sm} {
    max-width: 90%;
    padding: 40px 15px;
  }
`;

export const ExitButton = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  background: ${(props) => props.theme.brand.primary};
  box-shadow: 0px 12px 24px rgba(41, 43, 50, 0.25);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: none;
  }

  @media ${BREAKPOINTS.sm} {
    top: 0px;
    right: 0px;
    border-radius: 0;
  }
`;
