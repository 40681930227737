import { resetPassword as resetPasswordApi } from 'api/auth';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/Store';

import { request, set, failed, clear } from './resetPassword.slice';
import { ResetPasswordMeta, ResetPasswordPayload } from './resetPassword.type';

function* resetPasswordRequest(action: AsyncAction<ResetPasswordMeta, ResetPasswordPayload>) {
  try {
    const { data } = yield call(resetPasswordApi, { ...action.payload });
    yield put(set(data));
  } catch (e) {
    yield put(failed(e.response?.data || e));
  }
}

function* resetPasswordSuccess(
  action: AsyncAction<ResetPasswordMeta, ResetPasswordPayload>
) {
  //
}

function* resetPasswordFailed(
  action: AsyncAction<ResetPasswordMeta, ResetPasswordPayload>
) {
  if (action.error === 'Request failed with status code 401') {
    yield put(clear);
  }
}

function* ResetPasswordWatcher() {
  yield takeLatest(request.type, resetPasswordRequest);
  yield takeLatest(set.type, resetPasswordSuccess);
  yield takeLatest(failed.type, resetPasswordFailed);
}

export default ResetPasswordWatcher;
