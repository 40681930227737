import styled from '@emotion/styled';
import { pxToRem } from 'utils/Theme';

import { Variants, TypographyProps } from './Typography.props';

const styles: Record<Variants, string> = {
  title1: `
        font-size: ${pxToRem(36)};
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-family: Avenir;
    `,
  title2: `
        font-size: ${pxToRem(55)};
        line-height: 1.5;
        font-family: Fort Collins;
    `,
  title3: `
        font-size: ${pxToRem(26)};
        line-height: 1.5;
        font-family: Avenir;
    `,
  title4: `
        font-size: ${pxToRem(20)};
        line-height: 1.5;
        font-family: Avenir;
    `,
  body: `
        font-size: ${pxToRem(16)};
        line-height: 1.5;
        font-family: Avenir;
    `,
  widget: `
        font-size: ${pxToRem(14)};
        line-height: 1.5;
        font-family: Avenir;
    `,
};

const weights: Record<string, string> = {
  Regular: '400',
  Medium: '500',
  Bold: '700',
  Black: '900',
};

const font: Record<string, string> = {
  title1: weights.Medium,
  title2: weights.Regular,
  title3: weights.Medium,
  title4: weights.Medium,
  body: weights.Regular,
  widget: weights.Regular,
};

export const P = styled.p<TypographyProps>`
  ${({ variant }) => styles[variant || 'body']};
  font-weight: ${({ variant, weight }) => weight || font[variant || 'body']};
  color: ${({ theme, color = 'shade9' }) =>
    ({ ...theme.grey, ...theme.brand }[color])};
  text-align: ${({ align }) => align || 'left'};
  margin: 0;
`;
