import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  IntegrateMailChimpMeta,
  IntegrateMailChimpPayload,
} from './integrateMailChimp.type';

const initialState: AsyncState<
  IntegrateMailChimpMeta,
  IntegrateMailChimpPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<IntegrateMailChimpMeta, IntegrateMailChimpPayload>();

const integrateMailChimpSlice = createSlice({
  name: 'integrateMailChimp',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestIntegrateMailChimp =
  integrateMailChimpSlice.actions.REQUEST;
export const setIntegrateMailChimp = integrateMailChimpSlice.actions.SET;
export const failIntegrateMailChimp = integrateMailChimpSlice.actions.FAILED;
export const clearIntegrateMailChimp = integrateMailChimpSlice.actions.CLEAR;

export default integrateMailChimpSlice.reducer;
