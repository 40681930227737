import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { requestGetContentStats } from 'store/getContentStats/getContentStats.slice';
import { requestGetRev } from 'store/getRev/getRev.slice';
import { ResultsEngineView } from 'store/getRev/getRev.type';
import { requestRearrangeOrderView } from 'store/rearrangeOrderView/rearrangeOrderView.slice';
import { setResultsEngine } from 'store/resultsEngine/resultsEngine.slice';

import ResultsColumnView from './ResultsColumn.view';

const ResultsColumn = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const rearrangeOrderViewHandler = (list: ResultsEngineView[]) => {
    dispatch(
      requestRearrangeOrderView({
        orderView: list,
      })
    );
  };

  const formState =
    useAppSelector((state: RootState) => state.formClient) || undefined;

  const authState =
    useAppSelector((state: RootState) => state.auth) || undefined;

  const reState =
    useAppSelector((state: RootState) => state.resultsEngine) || undefined;

  let idInFocus: string | undefined = undefined;
  if (authState.type === 'admin') {
    idInFocus = formState.user?.id || undefined;
  } else if (authState.type === 'client') {
    idInFocus = authState.auth_id || undefined;
  }

  const getContentStatsHandler = () => {
    if (idInFocus !== undefined) {
      dispatch(
        requestGetContentStats({
          user_id: idInFocus,
        })
      );
    }
  };

  const orderView =
    useAppSelector((state: RootState) => state.getRev.data?.data.orderView) ||
    undefined;

  const handleResultsEngineState = () => {
    dispatch(
      setResultsEngine({
        appear: !reState.appear,
      })
    );
  };

  const getRevHandler = () => {
    if (authState !== undefined && idInFocus !== undefined) {
      dispatch(
        requestGetRev({
          user_id: idInFocus,
        })
      );
    }
  };

  const contentStats =
    useAppSelector((state: RootState) => state.getContentStats.data) ||
    undefined;

  const statsLoading =
    useAppSelector((state: RootState) => state.getContentStats.pending) ||
    false;

  useEffect(() => {
    getContentStatsHandler();
    getRevHandler();
  }, []);

  const generatedProps = {
    statsLoading,
    contentStats,
    orderView,
    reState,
    handleResultsEngineState,
    rearrangeOrderViewHandler,
  };
  return <ResultsColumnView {...generatedProps} />;
};

export default ResultsColumn;
