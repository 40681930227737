import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetClientByIdMeta,
  GetClientByIdPayload
} from './getClientById.type';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetClientByIdMeta, GetClientByIdPayload>();

const getClientByIdSlice = createSlice({
  name: 'getClientById',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetClientById = getClientByIdSlice.actions.REQUEST;
export const setGetClientById = getClientByIdSlice.actions.SET;
export const failGetClientById = getClientByIdSlice.actions.FAILED;
export const clearGetClientById = getClientByIdSlice.actions.CLEAR;

export default getClientByIdSlice.reducer;
