import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteAsset as deleteAssetApi } from 'api/asset';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientAssets } from 'store/formClient/formClient.slice';

import {
  requestDeleteAsset,
  setDeleteAsset,
  failDeleteAsset,
} from './deleteAsset.slice';
import { DeleteAssetMeta, DeleteAssetPayload } from './deleteAsset.type';

function* deleteAssetRequest(action: PayloadAction<DeleteAssetMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteAssetApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteAsset(data));
    } catch (err) {
      yield put(failDeleteAsset(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteAsset('No token provided'));
  }
}

function* deleteAssetSuccess(action: PayloadAction<DeleteAssetPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientAssets(data));
}

function* deleteAssetFail(action: PayloadAction<DeleteAssetPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteAssetWatcher() {
  yield takeLatest(requestDeleteAsset.type, deleteAssetRequest);
  yield takeLatest(setDeleteAsset.type, deleteAssetSuccess);
  yield takeLatest(failDeleteAsset.type, deleteAssetFail);
}

export default DeleteAssetWatcher;
