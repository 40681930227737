import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { useHistory } from 'react-router-dom';
import { clearAuthentication } from 'store/auth/auth.slice';
import { UserPermission } from 'store/formClient/formClient.type';
import * as getClientByIdSlice from 'store/getClientById/getClientById.slice';
import * as getPermissionsByUserIdSlice from 'store/getPermissionsByUserId/getPermissionsByUserId.slice';

import { ClientDashboardPublicProps } from './ClientDashboard.props';
import ClientDashboardView from './ClientDashboard.view';

const ClientDashboard = (props: ClientDashboardPublicProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isInnerRoute = (path: string) =>
    location.pathname.search(path.split('/')[2]) > 0;

  const authId: string | null =
    useAppSelector((state: RootState) => state.auth.auth_id) || null;

  const clientId: string | null =
    useAppSelector((state: RootState) => state.auth.account_id) || null;

  const permissions: UserPermission | null =
    useAppSelector(
      (state: RootState) => state.getPermissionsByUserId.data?.data
    ) || null;

  const reState =
    useAppSelector((state: RootState) => state.resultsEngine) || undefined;

  const logout = () => {
    dispatch(clearAuthentication());
  };

  useEffect(() => {
    if (authId && clientId) {
      dispatch(
        getPermissionsByUserIdSlice.requestGetPermissionsByUserId({
          user_id: authId,
        })
      );

      dispatch(
        getClientByIdSlice.requestGetClientById({
          id: clientId,
          basic: true,
        })
      );
    } else {
      history.push('/client/login');
    }
  }, []);

  const generatedProps = {
    ...props,
    isInnerRoute,
    logout,
    reState,
    permissions,
  };
  return <ClientDashboardView {...generatedProps} />;
};

export default ClientDashboard;
