import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PAGEWRITE_UPS_URL = `${BASE_URL}/pageWriteups`;

export const upsertPageWriteup = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${PAGEWRITE_UPS_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};