import React from 'react';

// import { useTheme } from 'utils/Theme';
import { WelcomeProps } from './Welcome.props';
import { Container, Name, WelcomeMessage } from './Welcome.style';

const Welcome = (props: WelcomeProps): JSX.Element => {
  // const theme = useTheme();
  const { name, title } = props;
  return (
    <Container>
      <WelcomeMessage color={'primary'} variant={'title3'}>
        {title}
      </WelcomeMessage>
      <Name color={'secondary'} variant={'title2'}>
        {name}
      </Name>
    </Container>
  );
};

export default React.memo(Welcome);
