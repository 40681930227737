import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import React from 'react';

import TemplateView from './Template.view';

const Template = (): JSX.Element => {
  
  const brandName: string =
    useAppSelector(
      (state: RootState) => state.getClientById.data?.data?.account.brand_name
    ) || 'Client';

  const generatedProps = {
    brandName,
  };
  return <TemplateView {...generatedProps} />;
};

export default Template;
