import React from 'react';

import ScrollContainer from 'components/module/ScrollContainer';
import WelcomeView from 'components/module/Welcome';

// import { useTheme } from 'utils/Theme';
import { Col, Row } from 'react-grid-system';

import { TemplateGeneratedProps } from './Template.props';
import {
  Container,
  HeadingDiv,
  Caption,
  TemplateItem,
  Preview,
  PreviewTitle,
  TemplateContainer,
} from './Template.style';

const TemplateView = (props: TemplateGeneratedProps) => {
  const { brandName } = props;
  return (
    <Container>
      <HeadingDiv className="not-scrollable">
        <WelcomeView name={brandName} title="Welcome"></WelcomeView>
      </HeadingDiv>
      <ScrollContainer className="the-scrollable">
        <TemplateContainer>
          <Row>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  Proposal Template
                </PreviewTitle>
              </TemplateItem>
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  Basic Letterhead
                </PreviewTitle>
              </TemplateItem>
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  Basic Powerpoint
                </PreviewTitle>
              </TemplateItem>
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  Night Mode Powerpoint
                </PreviewTitle>
              </TemplateItem>
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  Training Manual
                </PreviewTitle>
              </TemplateItem>
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <TemplateItem>
                <Preview></Preview>
                <PreviewTitle variant="title3" color="primary" weight="800">
                  OS & S Manual
                </PreviewTitle>
              </TemplateItem>
            </Col>
          </Row>
        </TemplateContainer>
      </ScrollContainer>
    </Container>
  );
};

export default TemplateView;
