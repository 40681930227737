import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  RearrangeOrderViewMeta,
  RearrangeOrderViewPayload,
} from './rearrangeOrderView.type';

const initialState: AsyncState<
  RearrangeOrderViewMeta,
  RearrangeOrderViewPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<RearrangeOrderViewMeta, RearrangeOrderViewPayload>();

const rearrangeOrderViewSlice = createSlice({
  name: 'rearrangeOrderView',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestRearrangeOrderView =
  rearrangeOrderViewSlice.actions.REQUEST;
export const setRearrangeOrderView = rearrangeOrderViewSlice.actions.SET;
export const failRearrangeOrderView = rearrangeOrderViewSlice.actions.FAILED;
export const clearRearrangeOrderView = rearrangeOrderViewSlice.actions.CLEAR;

export default rearrangeOrderViewSlice.reducer;
