import styled from '@emotion/styled';
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Typography from 'components/base/Typography';
import FormikTextField from 'components/module/FormikTextField';
import { BREAKPOINTS } from 'consts/breakpoints';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled(GridContainer)`
  height: 100vh;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
`;

export const RightContainer = styled.div`
  width: 100%;
  height: 100vh;

  background-color: red;
  overflow: hidden;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 32px;
  overflow: hidden;
`;

export const Title = styled(Typography)`
  font-size: 36px;
  font-weight: 700;
`;

export const Password = styled(FormikTextField)`
  width: 360px;

  @media ${BREAKPOINTS['sm']} {
    width: 320px;
  }
`;

export const ResetButton = styled(Button)``;

export const ErrorContainer = styled.div`
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.brand.secondary};
  box-sizing: border-box;
`;

export const SuccessContainer = styled.div`
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.brand.secondary};
  box-sizing: border-box;
`;