import type { PayloadAction } from '@reduxjs/toolkit';
import { getMockupsByUserId as getMockupsByUserIdApi } from 'api/mockup';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetMockupsByUserId,
  setGetMockupsByUserId,
  failGetMockupsByUserId,
} from './getMockupsByUserId.slice';
import {
  GetMockupsByUserIdMeta,
  GetMockupsByUserIdPayload,
} from './getMockupsByUserId.type';

function* getMockupsByUserIdRequest(
  action: PayloadAction<GetMockupsByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getMockupsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetMockupsByUserId(data));
    } catch (err) {
      yield put(failGetMockupsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetMockupsByUserId('No token provided'));
  }
}

function* getMockupsByUserIdSuccess(
  action: PayloadAction<GetMockupsByUserIdPayload>
) {
  // yield function here
}

function* getMockupsByUserIdFail(
  action: PayloadAction<GetMockupsByUserIdPayload>
) {
  // yield function here
  yield put(error(String(`Mockups - ${action.payload}`)));
}

function* GetMockupsByUserIdWatcher() {
  yield takeLatest(requestGetMockupsByUserId.type, getMockupsByUserIdRequest);
  yield takeLatest(setGetMockupsByUserId.type, getMockupsByUserIdSuccess);
  yield takeLatest(failGetMockupsByUserId.type, getMockupsByUserIdFail);
}

export default GetMockupsByUserIdWatcher;
