import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { pxToRem } from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{ width?: string }>`
  border-bottom: 5px solid ${(props) => props.theme.brand.lilac};
  width: ${({ width }) => (width === undefined ? '250px' : width)};
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme.brand.primary};
  font-size: ${pxToRem(18)};
  margin-bottom: 8px;
`;
