import React from 'react';

// import { useTheme } from 'utils/Theme';
import { NavItemProps } from './NavItem.props';
import { Container, Text, Hover } from './NavItem.style';

const NavItem = (props: NavItemProps): JSX.Element => {
  // const theme = useTheme();
  const { text, mode, to, active = false, navClicked } = props;
  return (
    <Container
      isactive={active.toString()}
      onClick={navClicked}
    >
      <Hover to={to} isactive={active.toString()}>
        <Text variant={'title4'}>{text}</Text>
      </Hover>
    </Container>
  );
};

export default React.memo(NavItem);
