import React from 'react';

import { useTheme } from '@emotion/react';

import { RadioProps } from './Radio.props';
import { Container, HiddenRadio, CustomRadio, Label } from './Radio.style';

const Radio = ({
  checked = false,
  label,
  size = 36,
  typographyProps,
  disabledColor,
  ...props
}: RadioProps): JSX.Element => {
  const theme = useTheme();

  return (
    //@ts-ignore
    <Container onClick={props.onClick}>
      <HiddenRadio
        onChange={(event) => event.stopPropagation()}
        checked={checked}
        type="radio"
        {...props}
      />
      <CustomRadio
        checked={checked}
        color={
          checked
            ? theme.brand.primary
            : disabledColor
            ? theme.brand.secondary
            : theme.brand.blush
        }
        size={size}
      />
      {label && (
        <Label {...typographyProps} variant="title1">
          {label}
        </Label>
      )}
    </Container>
  );
};

export default React.memo(Radio);
