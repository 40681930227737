import React, { useState, useEffect } from 'react';

import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import Typography from 'components/base/Typography';
import Modal from 'components/layout/Modal';
import FileDetail from 'components/module/FileDetail';
import moment from 'moment';

import { ModalFileUploadProps } from './ModalFileUpload.props';
import {
  Container,
  Title,
  FieldDiv,
  InputName,
  Input,
  ButtonDiv,
  SubmitDiv,
  UploadButton,
} from './ModalFileUpload.style';

const ModalFileUpload = (props: ModalFileUploadProps): JSX.Element => {
  const {
    isOpen,
    onClickClose,
    title,
    isDocument,
    goSave,
    fileData,
    type,
    iconType,
  } = props;

  let fileElement: any = React.createRef();
  let imgElement: any = React.createRef();
  const [errors, setErrors] = useState<any>({});
  const [name, setName] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [docImgfiles, setDocImgFiles] = useState<any[]>([]);
  const [showFrontend, setShowFrontend] = useState<boolean>(true);

  useEffect(() => {
    if (fileData && fileData.logo_files) {
      setFiles(fileData.logo_files);
    }
  }, [fileData]);

  const isIcon = () => {
    return type === 'icon' ? true : false;
  };

  const isAsset = () => {
    return type === 'asset' ? true : false;
  };

  const isMockup = () => {
    return type === 'mockup' ? true : false;
  };

  const [acceptFile, setAcceptFiles] = useState<any>('image/*');
  // useEffect(() => {
  //   if (acceptFile) {
  //     fileElement.click();
  //   }
  // }, [acceptFile]);
  
  const handleClick = (acceptFile: string | null = null) => {
    if (acceptFile === 'docs') {
      fileElement.click();
      setAcceptFiles({
        type: 'docs',
        // accept: '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf',
      });
    } else {
      imgElement.click();
      setAcceptFiles({
        type: 'image',
        // accept: 'image/*',
      });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors(null);
    const now = moment();
    const upFiles = Array.from(e.target.files || []);

    let updatedFiles = [
      ...files,
      ...[
        {
          blob: upFiles[0],
          file_name: name,
          icon_title: upFiles[0].name,
          file_type: upFiles[0].type,
          created_at: now.format('MMMM D, YYYY'),
        },
      ],
    ];

    if (isDocument) {
      if (acceptFile.type === 'image') {
        setDocImgFiles([
          {
            blob: upFiles[0],
            file_name: name,
            icon_title: upFiles[0].name,
            file_type: 'doc_image',
            created_at: now.format('MMMM D, YYYY'),
          },
        ]);
        updatedFiles = files;
      } else {
        updatedFiles = [
          {
            blob: upFiles[0],
            file_name: name,
            icon_title: upFiles[0].name,
            file_type: upFiles[0].type,
            created_at: now.format('MMMM D, YYYY'),
          },
        ];
      }
    }

    fileElement.value = null;
    imgElement.value = null;
    if (isDocument) {
      setFiles(updatedFiles);
    } else if (name !== '' && upFiles) {
      setFiles(updatedFiles);
      if (!isIcon() && !isAsset() && !isMockup()) {
        setName('');
      }
    } else {
      imgElement.value = null;
      fileElement.value = null;
      setErrors({ name: 'Provide name for your file' });
    }
  };

  const handleRemove = (key: number) => {
    const newFiles = files.filter((file, index: number) => key !== index);
    setFiles(newFiles);
  };

  const hasError = (key: string) => {
    if (errors && key in errors) {
      return errors[key];
    }
  };

  const handleSave = () => {
    if ((isIcon() || isAsset() || isMockup()) && name === '') {
      setErrors({ name: 'Provide name for your file' });
    } else {
      if ((isIcon() || isAsset() || isMockup()) && files && files.length < 1) {
        onClickClose();
      } else {
        fileElement.value = null;
        let data: any = files;
        if (isDocument) {
          data = [...data, ...docImgfiles];
        }
        if (isIcon() || isAsset() || isMockup()) {
          data = {
            title: name,
            files: files,
            show_frontend: showFrontend,
            is_light: iconType === 'light' ? true : false,
          };
        }
        setName('');
        setFiles([]);
        setDocImgFiles([]);
        return goSave ? goSave(data) : {};
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClickClose={onClickClose}>
      <Container>
        <Title>{title}</Title>
        <FieldDiv>
          <InputName>Display Name:</InputName>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={'Name'}
            error={hasError('name')}
            required={true}
          ></Input>
        </FieldDiv>
        {type === 'icon' && (
          <FieldDiv style={{ marginBottom: '5px' }}>
            <Checkbox
              checked={showFrontend}
              onClick={(flag) => {
                setShowFrontend(showFrontend ? false : true);
              }}
              label="Show in client dashboard"
            ></Checkbox>
          </FieldDiv>
        )}
        <ButtonDiv>
          <UploadButton
            variant={'primary'}
            text={'Upload File'}
            size="smWide"
            onClick={() => handleClick(isDocument ? 'docs' : 'image')}
          ></UploadButton>
          <input
            ref={(input) => {
              fileElement = input;
            }}
            name="file"
            onChange={handleFileChange}
            type="file"
            style={{ display: 'none' }}
            accept={'.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf'}
          ></input>
          <input
            ref={(input) => {
              imgElement = input;
            }}
            name="file"
            onChange={handleFileChange}
            type="file"
            style={{ display: 'none' }}
            accept={'image/*'}
          ></input>
          {isDocument && (
            <UploadButton
              variant={'primary'}
              text={'Upload Screenshot'}
              onClick={() => handleClick('image')}
              size="smWide"
            ></UploadButton>
          )}
        </ButtonDiv>
        {files &&
          files.map((file: Record<string, any>, index: number) => (
            <FileDetail
              key={index}
              title={
                (file.blob?.name || file.title).substr(0, 25) +
                ((file.blob?.name || file.title).length > 25 ? '...' : '')
              }
              name={!isIcon() ? file.file_name : null}
              date={moment(file.created_at || '').format('MMMM D, YYYY')}
              onDeleteHandler={() => handleRemove(index)}
            ></FileDetail>
          ))}
        {isDocument &&
          docImgfiles &&
          docImgfiles.map((file: Record<string, any>, index: number) => (
            <FileDetail
              key={index}
              title={
                (file.blob?.name || file.title).substr(0, 25) +
                ((file.blob?.name || file.title).length > 25 ? '...' : '')
              }
              name={!isIcon() ? file.file_name : null}
              date={moment(file.created_at || '').format('MMMM D, YYYY')}
              onDeleteHandler={() => handleRemove(index)}
            ></FileDetail>
          ))}
        <SubmitDiv>
          <Button
            variant={'secondary'}
            text={'Save'}
            size="smWide"
            onClick={handleSave}
          ></Button>
        </SubmitDiv>
      </Container>
    </Modal>
  );
};

export default React.memo(ModalFileUpload);
