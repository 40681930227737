import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteLogo as deleteLogoApi } from 'api/logo';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientLogos } from 'store/formClient/formClient.slice';

import {
  requestDeleteLogoFile,
  setDeleteLogoFile,
  failDeleteLogoFile,
} from './deleteLogoFile.slice';
import {
  DeleteLogoFileMeta,
  DeleteLogoFilePayload,
} from './deleteLogoFile.type';

function* deleteLogoFileRequest(action: PayloadAction<DeleteLogoFileMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteLogoApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteLogoFile(data));
    } catch (err) {
      yield put(failDeleteLogoFile(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteLogoFile('No token provided'));
  }
}

function* deleteLogoFileSuccess(action: PayloadAction<DeleteLogoFilePayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientLogos(data));
}

function* deleteLogoFileFail(action: PayloadAction<DeleteLogoFilePayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteLogoFileWatcher() {
  yield takeLatest(requestDeleteLogoFile.type, deleteLogoFileRequest);
  yield takeLatest(setDeleteLogoFile.type, deleteLogoFileSuccess);
  yield takeLatest(failDeleteLogoFile.type, deleteLogoFileFail);
}

export default DeleteLogoFileWatcher;
