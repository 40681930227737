import React, { useState } from 'react';

import AlertView from 'components/base/Alert';
import Logo from 'components/base/Logo';
import PanelLoginAdmin from 'components/base/SVG/PanelLoginAdmin';
import PanelLoginClient from 'components/base/SVG/PanelLoginClient';
import Typography from 'components/base/Typography';
import { Formik, Form } from 'formik';
import { Row, Col, Visible } from 'react-grid-system';

// import { useTheme } from 'utils/Theme';
import {
  ResetPasswordForm,
  ResetPasswordGeneratedProps,
} from './ResetPassword.props';
import {
  Container,
  LeftContainer,
  RightContainer,
  FormContainer,
  Title,
  ResetButton,
  Password,
  ErrorContainer,
} from './ResetPassword.style';

const ResetPasswordView = (props: ResetPasswordGeneratedProps) => {
  // const theme = useTheme();
  const { isAdmin, resetPassword, resetPasswordReq, isInvite } = props;
  const [isReset, setIsReset] = useState<boolean>(false);

  const [customError, setCustomError] = useState<string | null>(null);
  const handleSubmitPassword = (data: ResetPasswordForm) => {
    setCustomError(null);
    if (data.password === '') {
      setCustomError('Provide your new password');
    } else if (data.password !== data.confirm_password) {
      setCustomError('Password do not match');
    } else {
      resetPassword(data);
    }
  };

  const formikProps = {
    initialValues: {
      password: '',
      confirm_password: '',
    },
    onSubmit: handleSubmitPassword,
  };

  return (
    <Container style={{ margin: '0px', padding: '0px', maxWidth: 'auto' }}>
      <Row nogutter>
        <Col sm={12} lg={5}>
          <LeftContainer>
            <Formik {...formikProps}>
              <Form>
                <FormContainer>
                  <Visible xs sm md>
                    <Logo
                      variant={
                        isAdmin === true ? 'stacked_orange' : 'stacked_navy'
                      }
                    ></Logo>
                  </Visible>
                  <Title variant={'title3'}>
                    {isAdmin === true ? 'Admin Hub' : 'Brand Hub'}
                  </Title>
                  <Password secured name="password" label={'Password'} />
                  <Password
                    secured
                    name="confirm_password"
                    label={'Confirm Password'}
                  />
                  {(resetPasswordReq.error !== null ||
                    customError !== null) && (
                    <ErrorContainer>
                      <Typography variant="widget" color="noshade">
                        {resetPasswordReq.error || customError}
                      </Typography>
                    </ErrorContainer>
                  )}
                  {resetPasswordReq.error === null &&
                    resetPasswordReq.data !== null && (
                      <Typography
                        variant="widget"
                        color="success"
                        style={{ marginBottom: '10px' }}
                      >
                        {resetPasswordReq.data?.message || 'Success'}
                      </Typography>
                    )}
                  <ResetButton
                    style={{ padding: '8px 48px' }}
                    variant={isAdmin === true ? 'primary' : 'secondary'}
                    text={isInvite ? 'Save Password' : 'Reset Password'}
                    type="submit"
                    disabled={resetPasswordReq.pending || false}
                  ></ResetButton>
                </FormContainer>
              </Form>
            </Formik>
          </LeftContainer>
        </Col>
        <Col lg={7}>
          <Visible lg xl xxl>
            <RightContainer>
              {isAdmin === true ? <PanelLoginAdmin /> : <PanelLoginClient />}
            </RightContainer>
          </Visible>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordView;
