import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  DeleteStyleGuideMeta,
  DeleteStyleGuidePayload
} from './deleteStyleGuide.type';

const initialState: AsyncState<DeleteStyleGuideMeta, DeleteStyleGuidePayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<DeleteStyleGuideMeta, DeleteStyleGuidePayload>();

const deleteStyleGuideSlice = createSlice({
  name: 'deleteStyleGuide',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestDeleteStyleGuide = deleteStyleGuideSlice.actions.REQUEST;
export const setDeleteStyleGuide = deleteStyleGuideSlice.actions.SET;
export const failDeleteStyleGuide = deleteStyleGuideSlice.actions.FAILED;
export const clearDeleteStyleGuide = deleteStyleGuideSlice.actions.CLEAR;

export default deleteStyleGuideSlice.reducer;
