import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetIgDetailsMeta, GetIgDetailsPayload } from './getIgDetails.type';

const initialState: AsyncState<GetIgDetailsMeta, GetIgDetailsPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetIgDetailsMeta, GetIgDetailsPayload>();

const getIgDetailsSlice = createSlice({
  name: 'getIgDetails',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetIgDetails = getIgDetailsSlice.actions.REQUEST;
export const setGetIgDetails = getIgDetailsSlice.actions.SET;
export const failGetIgDetails = getIgDetailsSlice.actions.FAILED;
export const clearGetIgDetails = getIgDetailsSlice.actions.CLEAR;

export default getIgDetailsSlice.reducer;
