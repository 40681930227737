import styled from '@emotion/styled';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  border: 1px solid ${(props) => props.theme.brand.primary};
  border-radius: 25px;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  object-fit: contain;
  padding: 18px;
`;
