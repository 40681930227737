import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import theme from 'utils/Theme';

export const Container = styled.div`
  margin-bottom: 16px;

  .shake-container {
    animation: shake 0.5s;
    animation-iteration-count: 1;
  }

  .error-box {
    position: absolute;
    margin-top: -7px;
    margin-left: 13px;
    background: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.brand.blush};
    line-height: 1;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const fontStyle = `
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05rem;
`;

export const FieldContainer = styled.div<{
  error: boolean;
  readOnly?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 36px;
  background-color: ${({ error }) =>
    error === true ? theme.brand.blush : 'rgba(121, 155, 208, 0.18)'};
  overflow: hidden;
`;

export const Label = styled.label<{
  isEmptyString: boolean;
  leftComponentExists: boolean;
}>`
  position: absolute;
  transition: 0.2s ease all;
  pointer-events: none;
  color: ${({ theme }) => theme.brand.lilac};

  top: ${({ isEmptyString }) => (isEmptyString === true ? '50%' : '8px')};
  left: ${({ isEmptyString, leftComponentExists }) =>
    isEmptyString === true
      ? '50%'
      : leftComponentExists === true
      ? '52px'
      : '16px'};
  font-size: ${({ isEmptyString }) =>
    isEmptyString === true ? '16px' : '12px'};
  transform: ${({ isEmptyString }) =>
    isEmptyString === true
      ? 'translateY(-50%) translateX(-50%)'
      : 'translateY(0%) translateX(0%)'};
`;

export const Field = styled.input<{
  leftComponentExists: boolean;
  isClickable?: boolean;
}>`
  padding-top: 16px;
  cursor: ${({ isClickable }) => (isClickable === true ? 'pointer' : 'auto')};
  margin-left: ${({ leftComponentExists }) =>
    leftComponentExists === true ? '0px' : '16px'};
  background-color: transparent;
  display: flex;
  flex: 1;
  border-radius: 36px;
  border: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.brand.lilac};
  :focus {
    outline: none;
  }
  ${fontStyle};

  ::placeholder {
    color: ${({ theme }) => theme.brand.lilac};
    opacity: 1;
  }

  :focus ~ label {
    top: 8px;
    left: ${({ leftComponentExists }) =>
      leftComponentExists === true ? '52px' : '16px'};
    font-size: 12px;
    transform: translateY(0%) translateX(0%);
  }
`;

export const LeftComponentContainer = styled.div<{ isClickable?: boolean }>`
  display: flex;
  min-width: 48px;
  padding: 0px 2px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: ${({ isClickable }) => (isClickable === true ? 'pointer' : 'auto')};
`;

export const RightComponentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.grey.shade5};
`;

export const VisibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-right: 10px;
  padding-left: 4px;
`;

export const Error = styled(Typography)`
  margin-top: 4px;
  margin-left: 4px;
  font-weight: 500;

  animation: 0.2s ease-out 0s 1 appearFromTop;
  @keyframes appearFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const Prefix = styled.span`
  padding-left: 12px;
  color: ${(props) => props.theme.grey.shade8};
  ${fontStyle};
`;

export const ErrorContainer = styled.div<{ displayError?: boolean }>`
  display: ${({ displayError }) => (displayError === false ? 'none' : 'auto')};
`;
