import type { PayloadAction } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';

export const createAsyncSlice = <Meta = any, Payload = any>() => {
  return {
    REQUEST: (state: AsyncState, action: PayloadAction<Meta>) => {
      state.pending = true;
      state.request = action.payload;
      state.error = null;
      state.data = null;
    },
    SET: (state: AsyncState, action: PayloadAction<Payload>) => {
      state.pending = false;
      state.data = action.payload;
      state.error = null;
    },
    FAILED: (state: AsyncState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.pending = false;
    },
    CLEAR: (state: AsyncState) => {
      state.data = null;
      state.error = null;
      state.pending = false;
      state.request = null;
    },
  };
};
