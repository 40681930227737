import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ResultsEngineState } from './resultsEngine.type';

const initialState: ResultsEngineState = {
  appear: true,
};

export const resultsEngineSlice = createSlice({
  name: 'resultsEngine',
  initialState,
  reducers: {
    setResultsEngine: (_, action: PayloadAction<ResultsEngineState>) => {
      // overwrite everything; don't consider previous state
      const newState = action.payload;
      return { ...newState };
    },
  },
});

export const { setResultsEngine } = resultsEngineSlice.actions;
export default resultsEngineSlice.reducer;
