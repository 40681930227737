import type { PayloadAction } from '@reduxjs/toolkit';
import { getGscStats } from 'api/gsc';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetGscStats,
  setGetGscStats,
  failGetGscStats,
} from './getGscStats.slice';
import { GetGscStatsMeta, GetGscStatsPayload } from './getGscStats.type';

function* getGscStatsRequest(action: PayloadAction<GetGscStatsMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getGscStats,
        action.payload,
        state.auth.token
      );
      yield put(setGetGscStats(data));
    } catch (err) {
      yield put(failGetGscStats(err.response.data.message));
    }
  } else {
    yield put(failGetGscStats('No token provided'));
  }
}

function* getGscStatsSuccess(action: PayloadAction<GetGscStatsPayload>) {
  // yield function here
}

function* getGscStatsFail(action: PayloadAction<GetGscStatsPayload>) {
  // yield function here
}

function* GetGscStatsWatcher() {
  yield takeLatest(requestGetGscStats.type, getGscStatsRequest);
  yield takeLatest(setGetGscStats.type, getGscStatsSuccess);
  yield takeLatest(failGetGscStats.type, getGscStatsFail);
}

export default GetGscStatsWatcher;
