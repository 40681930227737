import axios from 'axios';
import { API } from 'consts/api';
import { GetSubscriberDetailsMeta } from 'store/getSubscriberDetails/getSubscriberDetails.type';
import { IntegrateMailMeta } from 'store/integrateMail/integrateMail.type';
import { IntegrateMailChimpMeta } from 'store/integrateMailChimp/integrateMailChimp.type';
import { TestMailChimpConnectionMeta } from 'store/testMailChimpConnection/testMailChimpConnection.type';
import { TestMailConnectionMeta } from 'store/testMailConnection/testMailConnection.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const MAIL_URL = `${BASE_URL}/mail`;

export const integrateMailchimp = (
  payload: IntegrateMailChimpMeta,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${MAIL_URL}/integrateMailchimp`, payload, config);
};

export const testMailChimpConnection = (
  payload: TestMailChimpConnectionMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${MAIL_URL}/testMailchimpConnection`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSubscriberDetails = (
  payload: GetSubscriberDetailsMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${MAIL_URL}/getSubscriberDetails`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const integrateMail = (payload: IntegrateMailMeta, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${MAIL_URL}/integrateMail`, payload, config);
};

export const testMailConnection = (
  payload: TestMailConnectionMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${MAIL_URL}/testMailConnection`,
    params: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
