import React, { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import MobileBarView from 'components/module/MobileBar';
import ScrollContainer from 'components/module/ScrollContainer';
import SidebarView from 'components/module/Sidebar';
import SidebarMobileView from 'components/module/SidebarMobile';
import { WindowSpecs } from 'consts/breakpoints';
import _ from 'lodash';
import {
  Container as GridContainer,
  Row,
  Col,
  Visible,
  Hidden,
  setConfiguration,
} from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify-redux';
import 'react-toastify/dist/ReactToastify.css';

import { AdminDashboardGeneratedProps } from './AdminDashboard.props';
import {
  Container,
  SidebarContainer,
  MainBody,
  ContentBody,
  MobileBackDrop,
} from './AdminDashboard.style';
import 'react-confirm-alert/src/react-confirm-alert.css';

const AdminDashboardView = (
  props: AdminDashboardGeneratedProps
): JSX.Element => {
  const { routes, children, isInnerRoute, logout } = props;
  const theme = useTheme();
  const isSingleColumnView = useMediaQuery({
    query: '(min-width: 993px)',
  });

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1199px)',
  });
  const tabletMobileTopMargin = 60;
  const genericTopPadding = 70;

  setConfiguration({ gutterWidth: 20 });
  const [windowSize, setWindowSize] = useState<WindowSpecs>({
    width: 'none',
    height: 'none',
    original_height: 'none',
  });

  const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout>();
  const handleWindowResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height:
        (window.innerHeight < 620 ? 620 : window.innerHeight) -
        genericTopPadding, // 70 is the total padding of the MainBody
      original_height: window.innerHeight,
    });
  };
  window.addEventListener('resize', _.debounce(handleWindowResize));

  useEffect(() => {
    handleWindowResize();
  }, []);

  const [scrollableHeight, setScrollableHeight] =
    useState<number | string>('none');
  const getScrollableHeight = () => {
    const elementsArray = document.getElementsByClassName('not-scrollable');
    let totalSubtrahend = 0;
    if (elementsArray.length > 0) {
      Array.prototype.filter.call(elementsArray, function (element) {
        const style = getComputedStyle(element);
        totalSubtrahend +=
          element.offsetHeight +
          parseInt(style.marginTop) +
          parseInt(style.marginBottom);
      });
    } else {
      totalSubtrahend = 120;
    }

    setScrollableHeight(
      Number(windowSize.height) -
        totalSubtrahend -
        (isTabletOrMobile ? tabletMobileTopMargin : 0)
    );
  };

  useEffect(() => {
    // mobileToggle(false);
    if (!isSingleColumnView) {
      setScrollableHeight('none');
    } else {
      setTimeout(() => {
        getScrollableHeight();
      }, 500);
    }
  }, [windowSize]);

  const [mobSidebarOpen, setMobSidebarOpen] = useState<boolean>(false);
  const mobileToggle = (flag: boolean) => {
    setMobSidebarOpen(flag);
  };

  return (
    <Container>
      {/* <Visible xs sm md lg>
      SIDEMENU FOR SMALL SCREENS AND MOBILE GOES HERE 
    </Visible> */}
      <Hidden xs sm md lg>
        <SidebarContainer>
          <SidebarView
            routes={routes}
            mode={'admin'}
            isInnerRoute={isInnerRoute}
            onLogout={logout}
          ></SidebarView>
        </SidebarContainer>
      </Hidden>

      <Visible xs sm md lg>
        <MobileBarView
          mode="admin"
          onClick={() => mobileToggle(true)}
        ></MobileBarView>
      </Visible>

      <MobileBackDrop isOpen={mobSidebarOpen}></MobileBackDrop>
      <SidebarMobileView
        onClick={() => {
          mobileToggle(false);
        }}
        routes={routes}
        mode={'admin'}
        isInnerRoute={isInnerRoute}
        isOpen={mobSidebarOpen}
        onClose={mobileToggle}
        onLogout={logout}
      ></SidebarMobileView>

      <MainBody
        className="main-body"
        style={{
          position: mobSidebarOpen ? 'fixed' : 'static',
        }}
      >
        <GridContainer>
          <Row>
            <Col>
              <ContentBody scrollableHeight={scrollableHeight}>
                {children}
              </ContentBody>
            </Col>
          </Row>
        </GridContainer>
      </MainBody>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Container>
  );
};

export default React.memo(AdminDashboardView);
