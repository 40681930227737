import axios from 'axios';
import { API } from 'consts/api';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const FILE_URL = `${BASE_URL}/file`;

export const uploadFile = (data: any, token: string) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const file = data.file as File;
  const fd = new FormData();
  fd.append('file', file);
  fd.append('brandName', data.brandName);
  fd.append('fileName', data.fileName);
  fd.append('userId', data.userId);
  return axios.post(`${FILE_URL}/create`, fd, config);
};

export const deleteFile = (key: string, token: string) => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${FILE_URL}/delete`,
    data: {
      key: key,
    },
  });
};
