import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetSubscriberDetailsMeta,
  GetSubscriberDetailsPayload,
} from './getSubscriberDetails.type';

const initialState: AsyncState<
  GetSubscriberDetailsMeta,
  GetSubscriberDetailsPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetSubscriberDetailsMeta, GetSubscriberDetailsPayload>();

const getSubscriberDetailsSlice = createSlice({
  name: 'getSubscriberDetails',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetSubscriberDetails =
  getSubscriberDetailsSlice.actions.REQUEST;
export const setGetSubscriberDetails = getSubscriberDetailsSlice.actions.SET;
export const failGetSubscriberDetails =
  getSubscriberDetailsSlice.actions.FAILED;
export const clearGetSubscriberDetails =
  getSubscriberDetailsSlice.actions.CLEAR;

export default getSubscriberDetailsSlice.reducer;
