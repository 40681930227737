import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowRight = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3277 6.25643C11.395 6.09341 11.395 5.91034 11.3277 5.74732C11.2951 5.66649 11.2471 5.59282 11.1862 5.53047L6.47218 0.816423C6.34716 0.691399 6.17759 0.621161 6.00078 0.621161C5.82397 0.621161 5.6544 0.691399 5.52938 0.816423C5.40435 0.941447 5.33411 1.11102 5.33411 1.28783C5.33411 1.46464 5.40435 1.63421 5.52938 1.75923L9.10734 5.33719L1.28673 5.33248C1.19873 5.33212 1.11152 5.34919 1.03014 5.3827C0.948765 5.41621 0.874828 5.4655 0.812597 5.52774C0.750366 5.58997 0.701073 5.6639 0.66756 5.74528C0.634048 5.82666 0.61698 5.91387 0.617341 6.00187C0.61698 6.08988 0.634047 6.17709 0.66756 6.25847C0.701072 6.33984 0.750366 6.41378 0.812597 6.47601C0.874828 6.53824 0.948765 6.58754 1.03014 6.62105C1.11152 6.65456 1.19873 6.67163 1.28674 6.67127L9.10734 6.66655L5.52938 10.2445C5.40435 10.3695 5.33411 10.5391 5.33411 10.7159C5.33411 10.8927 5.40435 11.0623 5.52938 11.1873C5.6544 11.3123 5.82397 11.3826 6.00078 11.3826C6.17759 11.3826 6.34716 11.3123 6.47218 11.1873L11.1862 6.47328C11.2471 6.41093 11.2951 6.33726 11.3277 6.25643Z"
        fill={fill || '#111E2B'}
      />
    </svg>
  );
};

export default ArrowRight;
