import React from 'react';

import { useTheme } from '@emotion/react';

import { SwitchProps } from './Switch.props';
import { Container, CustomSwitch } from './Switch.style';

const Switch = (props: SwitchProps): JSX.Element => {
  const { checked, onChange, disabled, id } = props;
  let inputElement: any = React.createRef();

  const theme = useTheme();

  return (
    <Container
      onClick={() => {
        if (inputElement) {
          inputElement.click();
        }
      }}
    >
      <input
        ref={(input) => {
          inputElement = input;
        }}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <CustomSwitch
        checked={checked}
        color={checked ? theme.brand.twilight : '#F0582C'}
      ></CustomSwitch>
    </Container>
  );
};

export default React.memo(Switch);
