import styled from '@emotion/styled';
import Button from 'components/base/Button';
import TextField from 'components/base/TextField';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(Typography)`
  font-size: 18px;
`;

export const FieldDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const InputName = styled(Typography)`
  font-size: 18px;
  margin-right: 12px;
  margin-bottom: 16px;
`;

export const Input = styled(TextField)`
  width: 70%;
`;

export const ButtonDiv = styled.div`
  margin-bottom: 8px;
  gap: 12px;
  display: flex;
`;

export const SubmitDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const UploadButton = styled(Button)`
  // width: 45%;
`;
