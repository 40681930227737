import React, { Fragment } from 'react';

import { useTheme } from '@emotion/react';
import Pencil from 'components/base/SVG/Pencil';
import Switch from 'components/base/Switch';
import Typography from 'components/base/Typography';

import { SwitchItems, SwitchMenuProps } from './SwitchMenu.props';
import {
  Container,
  NavItems,
  Item,
  Inner,
  EditBox,
  Divider,
} from './SwitchMenu.style';

const SwitchMenu = (props: SwitchMenuProps): JSX.Element => {
  const theme = useTheme();
  const { onToggle, editted = false, items } = props;

  const handleChange = (key: string, flag: boolean) => {
    onToggle(key, flag);
  };

  return (
    <Container>
      <EditBox wBorder={editted}>
        <Pencil></Pencil>
      </EditBox>
      <NavItems>
        {items && items.length > 0 && (
          <Fragment>
            <Divider>
              <Item checked={items[0].state}>
                <Inner>
                  <Typography variant="widget" color="black">
                    {items[0].label}
                  </Typography>
                  <Switch
                    checked={items[0].state}
                    onChange={(flag) => handleChange(items[0].key, flag)}
                  ></Switch>
                </Inner>
              </Item>
            </Divider>
            <Divider>
              {items.map(
                (item: any, index: number) =>
                  index > 0 && (
                    <Item key={index} checked={item.state}>
                      <Inner>
                        <Typography variant="widget" color="black">
                          {item.label}
                        </Typography>
                        <Switch
                          checked={item.state}
                          onChange={(flag) => handleChange(item.key, flag)}
                        ></Switch>
                      </Inner>
                    </Item>
                  )
              )}
            </Divider>
          </Fragment>
        )}
      </NavItems>
    </Container>
  );
};

export default React.memo(SwitchMenu);
