import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import type { ThunkAction, Action } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { toastsReducer } from 'react-toastify-redux';
import { combineReducers } from 'redux';
import type { Persistor } from 'redux-persist';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import authReducer from 'store/auth';
import clientGenericActionReducer from 'store/clientGenericAction';
import computeKpiFansReducer from 'store/computeKpiFans';
import counterReducer from 'store/counter/counterSlice';
import deleteAssetReducer from 'store/deleteAsset';
import deleteColorByIdReducer from 'store/deleteColorById';
import deleteDocumentReducer from 'store/deleteDocument';
import deleteIconFileReducer from 'store/deleteIconFile';
import deleteLogoFileReducer from 'store/deleteLogoFile';
import deleteMockupReducer from 'store/deleteMockup';
import deletePostReducer from 'store/deletePost';
import deleteStyleGuideReducer from 'store/deleteStyleGuide';
import fbListPagesReducer from 'store/fbListPages';
import formClientReducer from 'store/formClient';
import gaListWebsitesReducer from 'store/gaListWebsites';
import generateReportReducer from 'store/generateReport';
import getAssetsByUserIdReducer from 'store/getAssetsByUserId';
import getClientByIdReducer from 'store/getClientById';
import getClientsReducer from 'store/getClients';
import getColorsByUserIdReducer from 'store/getColorsByUserId';
import getContentStatsReducer from 'store/getContentStats';
import getDocumentsByIdReducer from 'store/getDocumentsById';
import getFbAttributesReducer from 'store/getFbAttributes';
import getFbAttributesByTokenReducer from 'store/getFbAttributesByToken';
import getFbLikesReducer from 'store/getFbLikes';
import getGaStatsReducer from 'store/getGaStats';
import getGaUrlReducer from 'store/getGaUrl';
import getGscSitesReducer from 'store/getGscSites';
import getGscStatsReducer from 'store/getGscStats';
import getIconsByUserIdReducer from 'store/getIconsByUserId';
import getIgAttributesReducer from 'store/getIgAttributes';
import getIgDetailsReducer from 'store/getIgDetails';
import getLinkedinFollowersReducer from 'store/getLinkedinFollowers';
import getLogosByUserIdReducer from 'store/getLogosByUserId';
import getMockupsByUserIdReducer from 'store/getMockupsByUserId';
import getOrganizationListReducer from 'store/getOrganizationList';
import getPermissionsByUserIdReducer from 'store/getPermissionsByUserId';
import getPostsByUserIdReducer from 'store/getPostsByUserId';
import getRevReducer from 'store/getRev';
import getStrategyByUserIdReducer from 'store/getStrategyByUserId';
import getStyleGuidesByUserIdReducer from 'store/getStyleGuidesByUserId';
import getSubscriberDetailsReducer from 'store/getSubscriberDetails';
import userReducer from 'store/getUser';
import integrateMailReducer from 'store/integrateMail';
import integrateMailChimpReducer from 'store/integrateMailChimp';
import isGaIntegratedReducer from 'store/isGaIntegrated';
import issueFbTokenReducer from 'store/issueFbToken';
import loginReducer from 'store/login';
import rearrangeOrderViewReducer from 'store/rearrangeOrderView';
import resetPasswordReducer from 'store/resetPassword';
import resetVerifyEmailReducer from 'store/resetVerifyEmail';
import resultsEngineReducer from 'store/resultsEngine';
import sendInvitationReducer from 'store/sendInvitation';
import testLinkedinConnectionReducer from 'store/testLinkedinConnection';
import testMailChimpConnectionReducer from 'store/testMailChimpConnection';
import testMailConnectionReducer from 'store/testMailConnection';
import updateClientOrderReducer from 'store/updateClientOrder';
import updateFbPageIdReducer from 'store/updateFbPageId';
import updateGaViewIdReducer from 'store/updateGaViewId';
import updateGscSiteReducer from 'store/updateGscSite';
import updateOrganizationUrnReducer from 'store/updateOrganizationUrn';
import updatePermissionReducer from 'store/updatePermission';
import upsertAssetReducer from 'store/upsertAsset';
import upsertClientReducer from 'store/upsertClient';
import upsertColorReducer from 'store/upsertColor';
import upsertDocumentReducer from 'store/upsertDocument';
import upsertIconReducer from 'store/upsertIcon';
import upsertKpiReducer from 'store/upsertKpi';
import upsertLogoReducer from 'store/upsertLogo';
import upsertMockupReducer from 'store/upsertMockup';
import upsertPageWriteupReducer from 'store/upsertPageWriteup';
import upsertPostReducer from 'store/upsertPost';
import upsertStrategyReducer from 'store/upsertStrategy';
import upsertStyleGuideReducer from 'store/upsertStyleGuide';

import sagas from './sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const reducers = combineReducers({
  toasts: toastsReducer,
  router: connectRouter(history),
  auth: authReducer,
  counter: counterReducer,
  user: userReducer,
  login: loginReducer,
  resetVerifyEmail: resetVerifyEmailReducer,
  resetPassword: resetPasswordReducer,
  getGaUrl: getGaUrlReducer,
  upsertClient: upsertClientReducer,
  formClient: formClientReducer,
  getClients: getClientsReducer,
  getClientById: getClientByIdReducer,
  isGaIntegrated: isGaIntegratedReducer,
  gaListWebsites: gaListWebsitesReducer,
  updateGaViewId: updateGaViewIdReducer,
  getGaStats: getGaStatsReducer,
  issueFbToken: issueFbTokenReducer,
  getFbAttributes: getFbAttributesReducer,
  getFbAttributesToken: getFbAttributesByTokenReducer,
  getFbLikes: getFbLikesReducer,
  fbListPages: fbListPagesReducer,
  updateFbPageId: updateFbPageIdReducer,
  updatePermission: updatePermissionReducer,
  upsertColor: upsertColorReducer,
  upsertLogo: upsertLogoReducer,
  deleteLogoFile: deleteLogoFileReducer,
  upsertIcon: upsertIconReducer,
  deleteIconFile: deleteIconFileReducer,
  upsertStrategy: upsertStrategyReducer,
  upsertDocument: upsertDocumentReducer,
  deleteDocument: deleteDocumentReducer,
  sendInvitation: sendInvitationReducer,
  clientGenericAction: clientGenericActionReducer,
  getIgAttributes: getIgAttributesReducer,
  getIgDetails: getIgDetailsReducer,
  getContentStats: getContentStatsReducer,
  upsertKpi: upsertKpiReducer,
  computeKpiFans: computeKpiFansReducer,
  getGscSites: getGscSitesReducer,
  updateGscSite: updateGscSiteReducer,
  getGscStats: getGscStatsReducer,
  updateClientOrder: updateClientOrderReducer,
  getColorsByUserId: getColorsByUserIdReducer,
  getLogosByUserId: getLogosByUserIdReducer,
  getIconsByUserId: getIconsByUserIdReducer,
  deleteColorById: deleteColorByIdReducer,
  getStrategyByUserId: getStrategyByUserIdReducer,
  getPermissionsByUserId: getPermissionsByUserIdReducer,
  integrateMailChimp: integrateMailChimpReducer,
  testMailChimpConnection: testMailChimpConnectionReducer,
  getSubscriberDetails: getSubscriberDetailsReducer,
  integrateMail: integrateMailReducer,
  testMailConnection: testMailConnectionReducer,
  upsertPost: upsertPostReducer,
  getPostsByUserId: getPostsByUserIdReducer,
  deletePost: deletePostReducer,
  getDocumentsById: getDocumentsByIdReducer,
  getRev: getRevReducer,
  rearrangeOrderView: rearrangeOrderViewReducer,
  testLinkedinConnection: testLinkedinConnectionReducer,
  getOrganizationList: getOrganizationListReducer,
  updateOrganizationUrn: updateOrganizationUrnReducer,
  getLinkedinFollowers: getLinkedinFollowersReducer,
  upsertPageWriteup: upsertPageWriteupReducer,
  resultsEngine: resultsEngineReducer,
  upsertAsset: upsertAssetReducer,
  deleteAsset: deleteAssetReducer,
  getAssetsByUserId: getAssetsByUserIdReducer,
  upsertMockup: upsertMockupReducer,
  deleteMockup: deleteMockupReducer,
  getMockupsByUserId: getMockupsByUserIdReducer,
  upsertStyleGuide: upsertStyleGuideReducer,
  deleteStyleGuide: deleteStyleGuideReducer,
  getStyleGuidesByUserId: getStyleGuidesByUserIdReducer,
  generateReport: generateReportReducer,
  // list of reducers here
});

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    // ...getDefaultMiddleware({ thunk: false }),
    sagaMiddleware,
    routeMiddleware,
  ],
});

export const persistor = (callback?: () => void): Persistor =>
  persistStore(store, undefined, callback);

sagaMiddleware.run(sagas);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
