import type { PayloadAction } from '@reduxjs/toolkit';
import { updateGscSite } from 'api/gsc';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestUpdateGscSite,
  setUpdateGscSite,
  failUpdateGscSite,
} from './updateGscSite.slice';
import { UpdateGscSiteMeta, UpdateGscSitePayload } from './updateGscSite.type';

function* updateGscSiteRequest(action: PayloadAction<UpdateGscSiteMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateGscSite,
        action.payload,
        state.auth.token
      );
      yield put(setUpdateGscSite(data));
    } catch (err) {
      yield put(failUpdateGscSite(err.response.data.message));
    }
  } else {
    yield put(failUpdateGscSite('No token provided'));
  }
}

function* updateGscSiteSuccess(action: PayloadAction<UpdateGscSitePayload>) {
  // yield function here
}

function* updateGscSiteFail(action: PayloadAction<UpdateGscSitePayload>) {
  // yield function here
}

function* UpdateGscSiteWatcher() {
  yield takeLatest(requestUpdateGscSite.type, updateGscSiteRequest);
  yield takeLatest(setUpdateGscSite.type, updateGscSiteSuccess);
  yield takeLatest(failUpdateGscSite.type, updateGscSiteFail);
}

export default UpdateGscSiteWatcher;
