import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { requestGetGscStats } from 'store/getGscStats/getGscStats.slice';

import KeywordsView from './Keywords.view';

const Keywords = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const formState =
    useAppSelector((state: RootState) => state.formClient) || undefined;

  const authState =
    useAppSelector((state: RootState) => state.auth) || undefined;

  let userId: string | undefined = undefined;
  if (authState.type === 'admin') {
    userId = formState.user?.id || undefined;
  } else if (authState.type === 'client') {
    userId = authState.auth_id || undefined;
  }

  const keywords =
    useAppSelector(
      (state: RootState) => state.getGscStats.data?.data.keyWords
    ) || undefined;

  useEffect(() => {
    getGscStatsHandler();
  }, []);

  const getGscStatsHandler = () => {
    if (userId !== undefined) {
      dispatch(
        requestGetGscStats({
          client_id: userId,
        })
      );
    }
  };

  const generatedProps = {
    keywords,
  };
  return <KeywordsView {...generatedProps} />;
};

export default Keywords;
