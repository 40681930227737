import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { FormClientState } from './formClient.type';

const initialState: FormClientState = {
  id: null,
  user: null,
  brandName: '',
  url: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  facebook: '',
  instagram: '',
  twitter: '',
  pinterest: '',
  youtube: '',
  linkedin: '',
  isActive: true,
};

export const formClientSlice = createSlice({
  name: 'formClient',
  initialState,
  reducers: {
    setFormClient: (_, action: PayloadAction<FormClientState>) => {
      // overwrite everything; don't consider previous state
      const newFormClient = action.payload;
      return { ...newFormClient };
    },
    updateFormClientPermissions: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{
            user: {
              ...current(state).user,
              ...{
                user_permission: {
                  ...current(state).user?.user_permission,
                  ...action.payload,
                },
              },
            },
          },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientColors: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ colors: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientLogos: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ logos: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientIcons: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ icons: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientDocuments: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ documents: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientInviteLogs: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ invitelog: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientStrategy: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ strategy: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientKpis: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ kpis: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientWriteups: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ writeups: action.payload },
        };
        return { ...newFormClient };
      }
    },
    clearFormClient: () => {
      return { ...initialState };
    },
    updateFormClientAssets: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ assets: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientMockups: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ mockups: action.payload },
        };
        return { ...newFormClient };
      }
    },
    updateFormClientStyleGuides: (
      // TO-DO REFRACTOR
      state,
      action: PayloadAction<FormClientState | any>
    ) => {
      if (current(state)) {
        const newFormClient = {
          ...current(state),
          ...{ styleGuides: action.payload },
        };
        return { ...newFormClient };
      }
    },
  },
});

export const {
  setFormClient,
  clearFormClient,
  updateFormClientPermissions,
  updateFormClientLogos,
  updateFormClientIcons,
  updateFormClientStrategy,
  updateFormClientDocuments,
  updateFormClientColors,
  updateFormClientInviteLogs,
  updateFormClientKpis,
  updateFormClientWriteups,
  updateFormClientAssets,
  updateFormClientMockups,
  updateFormClientStyleGuides,
} = formClientSlice.actions;
export default formClientSlice.reducer;
