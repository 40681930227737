import type { PayloadAction } from '@reduxjs/toolkit';
import { uploadFile as uploadFileApi } from 'api/file';
import { upsertIcon as upsertIconApi } from 'api/icon';
import { RootState } from 'app/store';
import { error, info, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientIcons } from 'store/formClient/formClient.slice';

import {
  requestUpsertIcon,
  setUpsertIcon,
  failUpsertIcon,
} from './upsertIcon.slice';
import { UpsertIconMeta, UpsertIconPayload } from './upsertIcon.type';

function* upsertIconRequest(action: PayloadAction<UpsertIconMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      yield put(info(String('Uploading...')));
      const actionData = action.payload.icon_files?.slice();
      if (actionData) {
        for (let x = 0; x < actionData.length; x++) {
          if (actionData[x].blob instanceof File) {
            const { data } = yield call(
              uploadFileApi,
              {
                file: actionData[x].blob as File,
                fileName: action.payload.title,
                brandName: state.formClient ? state.formClient.brandName : '',
                userId: state.formClient ? state.formClient.user?.id : '',
              },
              state.auth.token
            );
            const temp = actionData[x];
            actionData[x] = {
              ...temp,
              ...{ img_url: data.status === 200 ? data.data : '' },
            };
          }
        }
      }

      const { data } = yield call(
        upsertIconApi,
        { ...action.payload, ...{ icon_files: actionData } },
        state.auth.token
      );
      yield put(setUpsertIcon(data));
    } catch (err) {
      yield put(failUpsertIcon(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertIcon('No token provided'));
  }
}

function* upsertIconSuccess(action: PayloadAction<UpsertIconPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientIcons(data));
}

function* upsertIconFail(action: PayloadAction<UpsertIconPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertIconWatcher() {
  yield takeLatest(requestUpsertIcon.type, upsertIconRequest);
  yield takeLatest(setUpsertIcon.type, upsertIconSuccess);
  yield takeLatest(failUpsertIcon.type, upsertIconFail);
}

export default UpsertIconWatcher;
