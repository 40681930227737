import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import * as getDocumentsByIdSlice from 'store/getDocumentsById/getDocumentsById.slice';

import DocumentView from './Document.view';
import { useState } from 'react';
import { AccountDocument } from 'routes/Admin/DataInput/Forms/Documents/Documents.props';

const Document = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const authId: string | null =
    useAppSelector((state: RootState) => state.auth.auth_id) || null;

  const brandName: string =
    useAppSelector(
      (state: RootState) => state.getClientById.data?.data?.account.brand_name
    ) || 'Client';

  useEffect(() => {
    if (authId) {
      dispatch(
        getDocumentsByIdSlice.requestGetDocumentsById({ user_id: authId })
      );
    }
  }, []);

  const getDocuments =
    useAppSelector((state: RootState) => state.getDocumentsById) || null;
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    if (getDocuments && getDocuments.data) {
      setDocuments(getDocuments.data.data);
    }
  }, [getDocuments?.data]);

  const generatedProps = {
    brandName,
    documents,
    getDocuments,
  };
  return <DocumentView {...generatedProps} />;
};

export default Document;
