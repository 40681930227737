import React from 'react';

import TextField from 'components/base/TextField';
import { useField } from 'formik';

import { FormikTextFieldProps } from './FormikTextField.props';

const FormikTextField = (props: FormikTextFieldProps): JSX.Element => {
  const { name, onFocus = () => null, ...textFieldProps } = props;
  const [field, meta] = useField<string>(name);

  const onChange = (event: React.ChangeEvent<any>) => {
    if (!props.readOnly) {
      field.onChange(event);
    }
  };

  return (
    <TextField
      {...field}
      {...textFieldProps}
      onChange={onChange}
      onFocus={onFocus}
      id={name}
      error={meta.touched ? meta.error : undefined}
    />
  );
};

export default React.memo(FormikTextField);
