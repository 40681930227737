import axios from 'axios';
import { API } from 'consts/api';
import { GetUserMeta } from 'store/getUser/getUser.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PERMISSION_URL = `${BASE_URL}/permission`;

export const updatePermission = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${PERMISSION_URL}/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getPermissionsByUserId = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${PERMISSION_URL}/getPermissionsByUserId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};