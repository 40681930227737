import type { PayloadAction } from '@reduxjs/toolkit';
import { uploadFile as uploadFileApi } from 'api/file';
import { upsertMockup as upsertMockupApi } from 'api/mockup';
import { RootState } from 'app/store';
import { error, info, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientMockups } from 'store/formClient/formClient.slice';

import {
  requestUpsertMockup,
  setUpsertMockup,
  failUpsertMockup,
} from './upsertMockup.slice';
import { UpsertMockupMeta, UpsertMockupPayload } from './upsertMockup.type';

function* upsertMockupRequest(action: PayloadAction<UpsertMockupMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      yield put(info(String('Uploading...')));
      const actionData = action.payload.mockup_files?.slice();
      if (actionData) {
        for (let x = 0; x < actionData.length; x++) {
          if (actionData[x].blob instanceof File) {
            const { data } = yield call(
              uploadFileApi,
              {
                file: actionData[x].blob as File,
                fileName: action.payload.title,
                brandName: state.formClient ? state.formClient.brandName : '',
                userId: state.formClient ? state.formClient.user?.id : '',
              },
              state.auth.token
            );
            const temp = actionData[x];
            actionData[x] = {
              ...temp,
              ...{ img_url: data.status === 200 ? data.data : '' },
            };
          }
        }
      }

      const { data } = yield call(
        upsertMockupApi,
        { ...action.payload, ...{ mockup_files: actionData } },
        state.auth.token
      );
      yield put(setUpsertMockup(data));
    } catch (err) {
      yield put(failUpsertMockup(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertMockup('No token provided'));
  }
}

function* upsertMockupSuccess(action: PayloadAction<UpsertMockupPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientMockups(data));
}

function* upsertMockupFail(action: PayloadAction<UpsertMockupPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertMockupWatcher() {
  yield takeLatest(requestUpsertMockup.type, upsertMockupRequest);
  yield takeLatest(setUpsertMockup.type, upsertMockupSuccess);
  yield takeLatest(failUpsertMockup.type, upsertMockupFail);
}

export default UpsertMockupWatcher;
