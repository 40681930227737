import React from 'react';

import { useTheme } from '@emotion/react';
import Typography from 'components/base/Typography';

import { ArticlePreviewProps } from './ArticlePreview.props';
import {
  Container,
  Headings,
  Caption,
  AuthorDetails,
} from './ArticlePreview.style';

const ArticlePreview = (props: ArticlePreviewProps): JSX.Element => {
  const theme = useTheme();
  const { heading, time, caption, author, className = '' } = props;

  return (
    <Container className={className}>
      <Headings>
        <Typography variant="title4" color="primary" weight="800">
          {heading}
        </Typography>
        <Typography variant="widget" color="secondary">
          {time}
        </Typography>
      </Headings>
      <Caption variant="body" color="black">
        {caption}
      </Caption>
      <AuthorDetails variant="widget" color="twilight">
        Posted by {author}
      </AuthorDetails>
    </Container>
  );
};

export default React.memo(ArticlePreview);
