import React, { lazy } from 'react';

// import DashboardLayout from 'components/layout/Dashboard';
import { useTheme } from '@emotion/react';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import ClientDashboardLayout from 'components/layout/ClientDashboard';
import { CLIENT_ROUTES } from 'consts/routes';
import { useSelector } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Routes, Route as TRoute } from 'types/Routes';

// import { Store } from 'types/store/Store';
import Dashboard from './Dashboard';
import Document from './Document';
import Kit from './Kit';
import Reports from './Reports';
import Strategy from './Strategy';
import Template from './Template';

const LazyDashboard = lazy(() => import('./Dashboard'));
// const LazyDataInputPage = lazy(() => import('./DataInput'));
// const LazyClientUpdatesPage = lazy(() => import('./ClientUpdates'));

const ROUTES: Routes = {
  DASHBOARD: {
    path: CLIENT_ROUTES.DASHBOARD,
    children: <LazyDashboard />,
    title: 'Home',
    // icon: HomeIcon,
  },
  KIT: {
    path: CLIENT_ROUTES.KIT,
    children: <Kit />,
    title: 'Kit',
    permissionName: 'kit',
  },
  TEMPLATES: {
    path: CLIENT_ROUTES.TEMPLATES,
    children: <Template />,
    title: 'Template',
    permissionName: 'template',
  },
  STRATEGY: {
    path: CLIENT_ROUTES.STRATEGY,
    children: <Strategy />,
    title: 'Strategy',
    permissionName: 'strategy',
  },
  REPORTS: {
    path: CLIENT_ROUTES.REPORTS,
    children: <Reports />,
    title: 'Reports',
    permissionName: 'reports',
  },
  DOCUMENTS: {
    path: CLIENT_ROUTES.DOCUMENTS,
    children: <Document />,
    title: 'Documents',
    permissionName: 'document',
  },
};

const ROUTES_ARRAY: TRoute[] = Object.values(ROUTES).map((value) => value);

const ClientRoutes = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const isAuth =
    useAppSelector((state: RootState) => state.auth.token) || false;

  const getThemeOverride = (): {
    showResultsBar?: boolean;
    onBack?: () => void;
  } => {
    // Include here client pages without results bar
    if (pathname.includes('/client/strategy')) {
      return {
        showResultsBar: false,
      };
    }

    return {};
  };

  // auth guard for client
  const type = useAppSelector((state: RootState) => state.auth.type) || null;
  if (type !== 'client') {
    history.push('/client/login');
  }

  return (
    <ClientDashboardLayout routes={ROUTES_ARRAY} {...getThemeOverride()}>
      <Switch>
        {ROUTES_ARRAY.map((r) => (
          <Route
            key={`${r.path}`}
            path={`${r.path}`}
            exact={!r.nested}
            render={({ location }) =>
              isAuth ? (
                r.children
              ) : (
                <Redirect
                  to={{
                    pathname: '/client/login',
                    state: { from: location },
                  }}
                />
              )
            }
          />
        ))}
        <Route>
          <Redirect to="/client/dashboard" />
        </Route>
      </Switch>
    </ClientDashboardLayout>
  );
};

export default ClientRoutes;
