import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { push, replace } from 'connected-react-router';
import { ADMIN_ROUTES, CLIENT_ROUTES } from 'consts/routes';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/index.routes';
import * as resetPasswordSlice from 'store/resetPassword/resetPassword.slice';
import { ResetPasswordMeta } from 'store/resetPassword/resetPassword.type';
import { AsyncState } from 'types/AsyncState';

import { ResetPasswordParams, ResetPasswordForm } from './ResetPassword.props';
import ResetPasswordView from './ResetPassword.view';

const ResetPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params: ResetPasswordParams = useParams();

  const resetPasswordReq: AsyncState<any, any> =
    useAppSelector((state: RootState) => state.resetPassword) || null;

  const resetPassword = (formValues: ResetPasswordForm) => {
    const data: ResetPasswordMeta = {
      password: formValues.password,
      token: params.token,
      reference: params.reference,
      invite_id: params.invite_id || null,
    };
    dispatch(resetPasswordSlice.request(data));
  };

  useEffect(() => {
    if (
      resetPasswordReq.data &&
      'data' in resetPasswordReq.data &&
      resetPasswordReq.data.data.success === true
    ) {
      setTimeout(() => {
        history.push(`/login/${params.type}`);
      }, 2000);
    }
  }, [resetPasswordReq]);

  const [isInvite, setIsInvite] = useState(params.invite_id ? true : false);

  const generatedProps = {
    isAdmin: params.type === 'admin' ? true : false,
    resetPasswordReq,
    resetPassword,
    isInvite,
  };
  return <ResetPasswordView {...generatedProps} />;
};

export default ResetPassword;
