import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertStrategy as upsertStrategyApi } from 'api/strategy';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientStrategy } from 'store/formClient/formClient.slice';

import {
  requestUpsertStrategy,
  setUpsertStrategy,
  failUpsertStrategy,
} from './upsertStrategy.slice';
import {
  UpsertStrategyMeta,
  UpsertStrategyPayload,
} from './upsertStrategy.type';

function* upsertStrategyRequest(action: PayloadAction<UpsertStrategyMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertStrategyApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpsertStrategy(data));
    } catch (err) {
      yield put(failUpsertStrategy(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertStrategy('No token provided'));
  }
}

function* upsertStrategySuccess(action: PayloadAction<UpsertStrategyPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientStrategy(data));
}

function* upsertStrategyFail(action: PayloadAction<UpsertStrategyPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertStrategyWatcher() {
  yield takeLatest(requestUpsertStrategy.type, upsertStrategyRequest);
  yield takeLatest(setUpsertStrategy.type, upsertStrategySuccess);
  yield takeLatest(failUpsertStrategy.type, upsertStrategyFail);
}

export default UpsertStrategyWatcher;
