import React from 'react';

import Logo from 'components/base/Logo';
import BurgerMenu from 'components/base/SVG/BurgerMenu';

import { MobileBarProps } from './MobileBar.props';
import { MobileMenuBar, LogoContainer } from './MobileBar.style';

const MobileBar = (props: MobileBarProps): JSX.Element => {
  const { mode } = props;

  return (
    <MobileMenuBar onClick={props.onClick} mode={mode}>
      <LogoContainer mode={mode}>
        <Logo
          width={'76px'}
          variant={mode === 'admin' ? 'stacked_navy' : 'stacked_orange'}
        ></Logo>
      </LogoContainer>
      <BurgerMenu fill="#fff" height={32} width={32}></BurgerMenu>
    </MobileMenuBar>
  );
};

export default React.memo(MobileBar);
