import type { PayloadAction } from '@reduxjs/toolkit';
import { computeKpiSocial } from 'api/fans';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestComputeKpiFans,
  setComputeKpiFans,
  failComputeKpiFans,
} from './computeKpiFans.slice';
import {
  ComputeKpiFansMeta,
  ComputeKpiFansPayload,
} from './computeKpiFans.type';

function* computeKpiFansRequest(action: PayloadAction<ComputeKpiFansMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        computeKpiSocial,
        action.payload,
        state.auth.token
      );
      yield put(setComputeKpiFans(data));
    } catch (err) {
      yield put(failComputeKpiFans(err.response.data.message));
    }
  } else {
    yield put(failComputeKpiFans('No token provided'));
  }
}

function* computeKpiFansSuccess(action: PayloadAction<ComputeKpiFansPayload>) {
  // yield function here
}

function* computeKpiFansFail(action: PayloadAction<ComputeKpiFansPayload>) {
  // yield function here
}

function* ComputeKpiFansWatcher() {
  yield takeLatest(requestComputeKpiFans.type, computeKpiFansRequest);
  yield takeLatest(setComputeKpiFans.type, computeKpiFansSuccess);
  yield takeLatest(failComputeKpiFans.type, computeKpiFansFail);
}

export default ComputeKpiFansWatcher;
