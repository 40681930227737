import axios from 'axios';
import { API } from 'consts/api';
import { LoginMeta } from 'store/login/login.type';
import { createBasicToken } from 'utils/Token';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CLIENT_URL = `${BASE_URL}/client`;

export const upsert = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${CLIENT_URL}/upsert`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getClients = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${CLIENT_URL}/getClients`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getClientById = (data: any, token: string) => {
  return axios({
    method: 'GET',
    url: `${CLIENT_URL}/getClientById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const sendInvite = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${CLIENT_URL}/sendInvite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const genericAction = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${CLIENT_URL}/action`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateClientOrder = (data: any, token: string) => {
  return axios({
    method: 'POST',
    url: `${CLIENT_URL}/updateClientOrder`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};