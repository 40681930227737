import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertStrategyMeta,
  UpsertStrategyPayload
} from './upsertStrategy.type';

const initialState: AsyncState<UpsertStrategyMeta, UpsertStrategyPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertStrategyMeta, UpsertStrategyPayload>();

const upsertStrategySlice = createSlice({
  name: 'upsertStrategy',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertStrategy = upsertStrategySlice.actions.REQUEST;
export const setUpsertStrategy = upsertStrategySlice.actions.SET;
export const failUpsertStrategy = upsertStrategySlice.actions.FAILED;
export const clearUpsertStrategy = upsertStrategySlice.actions.CLEAR;

export default upsertStrategySlice.reducer;
