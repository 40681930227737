import React, { useEffect } from 'react';

import Typography from 'components/base/Typography';
import LoadingPlaceholder from 'components/module/LoadingPlaceholder';
import ScrollContainer from 'components/module/ScrollContainer';
import WelcomeView from 'components/module/Welcome';
import ReactTooltip from 'react-tooltip';

// import { useTheme } from 'utils/Theme';
import { AccountDocument } from 'routes/Admin/DataInput/Forms/Documents/Documents.props';

import { DocumentGeneratedProps } from './Document.props';
import {
  Container,
  HeadingDiv,
  InnerDiv,
  Caption,
  TemplatesRow,
  TemplateItem,
  GraphicalDisplay,
  GraphDescription,
} from './Document.style';

const DocumentView = (props: DocumentGeneratedProps) => {
  const { brandName, documents, getDocuments } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getFileType = (fileCode: string) => {
    if (fileCode.indexOf('image') > -1) {
      return 'image';
    } else if (fileCode.indexOf('word') > -1) {
      return 'Word';
    } else if (
      fileCode.indexOf('presentation') > -1 ||
      fileCode.indexOf('powerpoint') > -1
    ) {
      return 'PowerPoint';
    } else if (fileCode.indexOf('pdf') > -1) {
      return 'PDF';
    } else {
      return 'default';
    }
  };

  return (
    <Container>
      <ScrollContainer className="the-scrollable">
        <HeadingDiv>
          <WelcomeView name={brandName} title="Welcome"></WelcomeView>
        </HeadingDiv>
        <InnerDiv>
          <Typography
            variant="title3"
            color="secondary"
            weight="800"
            style={{ marginBottom: '13px' }}
          >
            Document Templates
          </Typography>
          <TemplatesRow>
            {(getDocuments && getDocuments.pending) ||
            (!getDocuments.pending && getDocuments.data?.data.length < 1) ? (
              <LoadingPlaceholder
                caption="Fetching documents record."
                emptyFlag={
                  !getDocuments.pending && getDocuments.data?.data.length < 1
                }
                emptyCaption="No record of documents found"
              ></LoadingPlaceholder>
            ) : (
              documents &&
              documents.map((docu: any, index: number) => {
                const fileType = getFileType(docu.file_type);
                return (
                  <TemplateItem
                    data-tip="Click to download file"
                    key={index}
                    href={docu.file_path}
                    target="_blank"
                    download
                  >
                    <Typography
                      variant="body"
                      color="primary"
                      style={{ marginBottom: '13px' }}
                    >
                      {docu.title}
                    </Typography>
                    <GraphicalDisplay
                      bgImage={docu.image_path || docu.file_path}
                      bgType={docu.image_path ? 'image' : fileType}
                    >
                      {!docu.image_path && fileType !== 'image' && (
                        <GraphDescription variant="body">
                          {fileType}
                          <span>Click to download</span>
                        </GraphDescription>
                      )}
                    </GraphicalDisplay>
                  </TemplateItem>
                );
              })
            )}
          </TemplatesRow>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </InnerDiv>
      </ScrollContainer>
    </Container>
  );
};

export default DocumentView;
