import type { PayloadAction } from '@reduxjs/toolkit';
import { listFbPages } from 'api/fb';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestFbListPages,
  setFbListPages,
  failFbListPages,
} from './fbListPages.slice';
import { FbListPagesMeta, FbListPagesPayload } from './fbListPages.type';

function* fbListPagesRequest(action: PayloadAction<FbListPagesMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        listFbPages,
        action.payload,
        state.auth.token
      );
      yield put(setFbListPages(data));
    } catch (err) {
      yield put(failFbListPages(err.response.data.message));
    }
  } else {
    yield put(failFbListPages('No token provided'));
  }
}

function* fbListPagesSuccess(action: PayloadAction<FbListPagesPayload>) {
  // yield function here
}

function* fbListPagesFail(action: PayloadAction<FbListPagesPayload>) {
  // yield function here
}

function* FbListPagesWatcher() {
  yield takeLatest(requestFbListPages.type, fbListPagesRequest);
  yield takeLatest(setFbListPages.type, fbListPagesSuccess);
  yield takeLatest(failFbListPages.type, fbListPagesFail);
}

export default FbListPagesWatcher;
