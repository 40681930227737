import React from 'react';

import { useTheme } from '@emotion/react';
import Search from 'components/base/SVG/Search';

import { SearchBarProps } from './SearchBar.props';
import { Container, InputField, GoButton } from './SearchBar.style';

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const theme = useTheme();
  const {
    value,
    resetValue,
    className,
    withButton = true,
    ...inputProps
  } = props;

  return (
    <Container className={className}>
      <InputField type="text" {...inputProps} value={value}></InputField>
      {withButton && (
        <GoButton>
          <Search fill={'#fff'} width={17} height={16}></Search>
        </GoButton>
      )}
    </Container>
  );
};

export default React.memo(SearchBar);
