import type { PayloadAction } from '@reduxjs/toolkit';
import { testMailConnection } from 'api/mail';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestTestMailConnection,
  setTestMailConnection,
  failTestMailConnection,
} from './testMailConnection.slice';
import {
  TestMailConnectionMeta,
  TestMailConnectionPayload,
} from './testMailConnection.type';

function* testMailConnectionRequest(
  action: PayloadAction<TestMailConnectionMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        testMailConnection,
        action.payload,
        state.auth.token
      );
      yield put(setTestMailConnection(data));
    } catch (err) {
      yield put(failTestMailConnection(err.response.data.message));
    }
  } else {
    yield put(failTestMailConnection('No token provided'));
  }
}

function* testMailConnectionSuccess(
  action: PayloadAction<TestMailConnectionPayload>
) {
  // yield function here
}

function* testMailConnectionFail(
  action: PayloadAction<TestMailConnectionPayload>
) {
  // yield function here
}

function* TestMailConnectionWatcher() {
  yield takeLatest(requestTestMailConnection.type, testMailConnectionRequest);
  yield takeLatest(setTestMailConnection.type, testMailConnectionSuccess);
  yield takeLatest(failTestMailConnection.type, testMailConnectionFail);
}

export default TestMailConnectionWatcher;
