import type { PayloadAction } from '@reduxjs/toolkit';
import { generateReport } from 'api/report';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGenerateReport,
  setGenerateReport,
  failGenerateReport,
} from './generateReport.slice';
import {
  GenerateReportMeta,
  GenerateReportPayload,
} from './generateReport.type';

function* generateReportRequest(action: PayloadAction<GenerateReportMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        generateReport,
        action.payload,
        state.auth.token
      );
      yield put(setGenerateReport(data));
    } catch (err) {
      yield put(failGenerateReport(err.response.data.message));
    }
  } else {
    yield put(failGenerateReport('No token provided'));
  }
}

function* generateReportSuccess(action: PayloadAction<GenerateReportPayload>) {
  // yield function here
}

function* generateReportFail(action: PayloadAction<GenerateReportPayload>) {
  // yield function here
}

function* GenerateReportWatcher() {
  yield takeLatest(requestGenerateReport.type, generateReportRequest);
  yield takeLatest(setGenerateReport.type, generateReportSuccess);
  yield takeLatest(failGenerateReport.type, generateReportFail);
}

export default GenerateReportWatcher;
