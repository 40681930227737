import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

export const HiddenRadio = styled.input`
  position: absolute;
  opacity: 0;
`;

export const CustomRadio = styled.span<{
  size: number;
  checked: boolean;
  color: string;
}>`
  border-radius: 100%;
  border: 3px solid ${({ color }) => color};
  display: inline-block;

  &::after {
    width: 24px;
    height: 24px;
    border: 3px solid #fff;
    border-radius: 100%;
    background: ${({ color }) => color};

    content: '';
    display: block;
  }
`;

export const Label = styled(Typography)``;
