import { login as loginApi } from 'api/auth';
import { push, replace } from 'connected-react-router';
import { ADMIN_ROUTES, CLIENT_ROUTES } from 'consts/routes';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { setAuthentication } from 'store/auth/auth.slice';
import { AsyncAction } from 'types/Action';

import { request, set, failed, clear } from './login.slice';
import { LoginMeta, LoginPayload } from './login.type';

function* loginRequest(action: AsyncAction<LoginMeta, LoginPayload>) {
  try {
    const { data } = yield call(loginApi, { ...action.payload });
    yield put(set(data));
  } catch (e) {
    yield put(failed(e.response?.data || e));
  }
}

function* loginSuccess(action: AsyncAction<LoginMeta, LoginPayload>) {
  yield put(
    setAuthentication({
      token: action.payload.data.token,
      type: action.payload.data.user_type,
      expiry: null,
      auth_id: action.payload.data.auth_id,
      account_id: action.payload.data.account_id || null,
    })
  );
  if (action.payload.data.user_type === 'admin') {
    yield put(replace(ADMIN_ROUTES.ACCOUNTS));
  } else if (action.payload.data.user_type === 'client') {
    yield put(replace(CLIENT_ROUTES.DASHBOARD));
  }
}

function* loginFailed(action: AsyncAction<LoginMeta, LoginPayload>) {
  if (action.error === 'Request failed with status code 401') {
    yield put(clear);
  }
}

function* loginWatcher() {
  yield takeLatest(request.type, loginRequest);
  yield takeLatest(set.type, loginSuccess);
  yield takeLatest(failed.type, loginFailed);
}

export default loginWatcher;
