import type { PayloadAction } from '@reduxjs/toolkit';
import { getPermissionsByUserId as getPermissionsByUserIdApi } from 'api/permission';
import { RootState } from 'app/store';
import { error } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestGetPermissionsByUserId,
  setGetPermissionsByUserId,
  failGetPermissionsByUserId,
} from './getPermissionsByUserId.slice';
import {
  GetPermissionsByUserIdMeta,
  GetPermissionsByUserIdPayload,
} from './getPermissionsByUserId.type';

function* getPermissionsByUserIdRequest(
  action: PayloadAction<GetPermissionsByUserIdMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getPermissionsByUserIdApi,
        action.payload,
        state.auth.token
      );
      yield put(setGetPermissionsByUserId(data));
    } catch (err) {
      yield put(failGetPermissionsByUserId(err.response?.data?.message || err));
    }
  } else {
    yield put(failGetPermissionsByUserId('No token provided'));
  }
}

function* getPermissionsByUserIdSuccess(
  action: PayloadAction<GetPermissionsByUserIdPayload>
) {
  // yield function here
}

function* getPermissionsByUserIdFail(
  action: PayloadAction<GetPermissionsByUserIdPayload>
) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* GetPermissionsByUserIdWatcher() {
  yield takeLatest(
    requestGetPermissionsByUserId.type,
    getPermissionsByUserIdRequest
  );
  yield takeLatest(
    setGetPermissionsByUserId.type,
    getPermissionsByUserIdSuccess
  );
  yield takeLatest(failGetPermissionsByUserId.type, getPermissionsByUserIdFail);
}

export default GetPermissionsByUserIdWatcher;
