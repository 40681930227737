import React, { useState } from 'react';

import { useTheme } from '@emotion/react';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import DropDown from 'components/base/DropDown';
import Line from 'components/base/Line';
import Logo from 'components/base/Logo';
import Radio from 'components/base/Radio';
import RightIcon from 'components/base/SVG/ArrowRight';
import DribbleIcon from 'components/base/SVG/Dribble';
import FacebookIcon from 'components/base/SVG/Facebook';
import InstagramIcon from 'components/base/SVG/Instagram';
import LinkedinIcon from 'components/base/SVG/Linkedin';
import PinterestIcon from 'components/base/SVG/Pinterest';
import TwitterIcon from 'components/base/SVG/Twitter';
import WhatsappIcon from 'components/base/SVG/Whatsapp';
import WhatsappInverseIcon from 'components/base/SVG/WhatsappInverse';
import YoutubeIcon from 'components/base/SVG/Youtube';
import Switch from 'components/base/Switch';
import Input from 'components/base/TextField';
import Modal from 'components/layout/Modal';
import Accordion from 'components/module/Accordion';
import ActiveSymbol from 'components/module/ActiveSymbol';
import ArticlePreview from 'components/module/ArticlePreview';
import Attribute from 'components/module/Attribute';
import Banner from 'components/module/Banner';
import BrandCard from 'components/module/BrandCard';
import BrandColor from 'components/module/BrandColor';
import BrandStrategyField from 'components/module/BrandStrategyField';
import ButtonDropDown from 'components/module/ButtonDropDown';
import { ButtonDropDownItems } from 'components/module/ButtonDropDown/ButtonDropDown.props';
import ClientCard from 'components/module/ClientCard';
import FileDetail from 'components/module/FileDetail';
import Header from 'components/module/Header';
import ModalFileUpload from 'components/module/ModalFileUpload';
import ModalIntegration from 'components/module/ModalIntegration';
import Palette from 'components/module/Palette';
import Popups from 'components/module/Popups';
import ResultsBox from 'components/module/ResultsBox';
import ScrollContainer from 'components/module/ScrollContainer';
import SearchBar from 'components/module/SearchBar';
import SpeechBubble from 'components/module/SpeechBubble';
import StackedInput from 'components/module/StackedInput';
import SwitchMenu from 'components/module/SwitchMenu';
import { SwitchItems } from 'components/module/SwitchMenu/SwitchMenu.props';
import Welcome from 'components/module/Welcome';
import { Container as GridContainer, Row, Col } from 'react-grid-system';

import {
  Container,
  Title,
  TypographyContainer,
  ColorContainer,
  ColorText,
  ButtonContainer,
  CustomColumn,
  CustomBt,
  SocialIconGroupContainer,
  SocialIconContainer,
  LogoContainer,
  NavyBackground,
  TwilightBackground,
  BlushBackground,
  LogoGroupContainer,
  PopupCenterButtonContainer,
} from './Demo.style';

const DemoView = () => {
  const [sampleField, setSampleField] = useState('');
  const [sampleFieldTwo, setSampleFieldTwo] = useState('');
  const [sampleFieldThree, setSampleFieldThree] = useState('');
  const [sampleError, setSampleError] = useState('');
  const theme = useTheme();
  const [radioToggle, setRadioToggle] = useState<boolean>(false);
  const [switchToggle, setSwitchToggle] = useState<boolean>(false);

  //modal states
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [integrationModal, setIntegrationModal] = useState<boolean>(false);
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [iconModal, setIconModal] = useState<boolean>(false);
  const [documentModal, setDocumentModal] = useState<boolean>(false);

  const [switchItems, setSwitchItems] = useState<SwitchItems[]>([
    { label: 'Kit', key: 'kit', state: true },
    { label: 'Colour Pallete', key: 'colour_palette', state: false },
    { label: 'Logo Suite', key: 'logo_suite', state: false },
    { label: 'Icons', key: 'icons', state: false },
    { label: 'Mock Ups', key: 'mock_ups', state: false },
  ]);

  const handleSwitchItems = (key: string, flag: boolean) => {
    setSwitchItems([
      ...switchItems.map((item) => {
        if (item.key === key) {
          item.state = flag;
        }
        return item;
      }),
    ]);
  };

  const [switchItemsSec, setSwitchItemsSec] = useState<SwitchItems[]>([
    { label: 'KPIs', key: 'kpis', state: true },
    { label: 'Website', key: 'webiste', state: false },
    { label: 'Socials', key: 'socials', state: false },
    { label: 'Email', key: 'email', state: false },
  ]);

  const handleSwitchItemsSec = (key: string, flag: boolean) => {
    setSwitchItemsSec([
      ...switchItemsSec.map((item) => {
        if (item.key === key) {
          item.state = flag;
        }
        return item;
      }),
    ]);
  };

  const buttonItems: ButtonDropDownItems[] = [
    { label: 'Primary', key: 'primary' },
    { label: 'Secondary', key: 'secondary' },
  ];

  const buttonItemsSec: ButtonDropDownItems[] = [
    { label: 'Primary Vertical', key: 'primary_vertical' },
    { label: 'Primary Horizontal', key: 'primary_horizontal' },
    { label: 'Secondary Vertical', key: 'secondary_vertical' },
    { label: 'Secondary Horizontal', key: 'secondary_horizontal' },
  ];

  // version bump
  const adminNavList = ['Accounts', 'APPs', 'Invoices'];
  const clientNavList = ['Home', 'Kit', 'Strategy', 'Docs', 'Reports', 'KPIs'];

  const bannerMessage =
    'Here is the introductory paragraph that will explain to the client what they’re doing in here, and what to focus on. Something Fun. Here is the introductory paragraph that will explain to the client what they’re doing in here, and what to focus on. Something Fun.';

  const brandCardContent =
    'Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact your business strategy and decision making.';

  const businessAttribute =
    'Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact  Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact';

  return (
    <Container>
      <GridContainer style={{ margin: 0, maxWidth: 'auto' }}>
        <Row>
          <Col lg={6} sm={12}>
            <TypographyContainer>
              <Header text={'Font Usage'} />
              <Title variant={'title1'}>h1 Headline | Avenir HEAVY 36PT</Title>
              <Title variant={'title2'}>
                H2 Subheading | Fort Collins 55pt
              </Title>
              <Title variant={'title3'}>
                H3 Stand Alone | Avenir Heavy, 26pt
              </Title>
              <Title variant={'title4'}>
                H4 Section Header | Avenir Heavy, 20pt
              </Title>
              <Title variant={'body'}>Body Copy | Avenir Book, 16pt</Title>
              <Title variant={'widget'}>Widget Copy | Avenir Book 14pt</Title>
            </TypographyContainer>
          </Col>
          <Col lg={6} sm={12}>
            <Header text={'colour'} />
            <Row style={{ marginTop: '16px' }}>
              <Col>
                <ColorContainer bgColor={theme.brand.primary}>
                  <ColorText variant={'body'}>#1E1850</ColorText>
                </ColorContainer>
              </Col>
              <Col>
                <ColorContainer bgColor={theme.brand.secondary}>
                  <ColorText variant={'body'}>#DE3E1B</ColorText>
                </ColorContainer>
              </Col>
              <Col>
                <ColorContainer bgColor={theme.brand.blush}>
                  <ColorText variant={'body'}>#F0C9C1</ColorText>
                </ColorContainer>
              </Col>
              <Col>
                <ColorContainer bgColor={theme.brand.lilac}>
                  <ColorText variant={'body'}>#799BD0</ColorText>
                </ColorContainer>
              </Col>
              <Col>
                <ColorContainer bgColor={theme.brand.twilight}>
                  <ColorText variant={'body'}>#4757A6</ColorText>
                </ColorContainer>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={2} sm={12}>
            <Header text={'Scroll bar'} />
            <br />
            {/* Inline styles here are needed for demo purposes only */}
            <ScrollContainer
              style={{
                height: '250px',
                width: '30px',
                overflowY: 'scroll',
                display: 'inline-block',
              }}
            >
              <div style={{ height: '400px' }}></div>
            </ScrollContainer>
            <ScrollContainer
              style={{
                height: '250px',
                width: '30px',
                overflowY: 'scroll',
                display: 'inline-block',
              }}
              scrollColor={'orange'}
            >
              <div style={{ height: '400px' }}></div>
            </ScrollContainer>
          </Col>
          <Col lg={3} sm={12}>
            <Header text={'Search Bar'} />
            <br />
            <SearchBar
              value=""
              placeholder="Search"
              style={{ width: '91px' }}
            ></SearchBar>
          </Col>
          <Col lg={3} sm={12}>
            <Header text={'Radio & Active Symbol'} />
            <br />
            <span style={{ marginRight: '5px' }}>
              <ActiveSymbol active={true}></ActiveSymbol>
            </span>
            <span style={{ marginRight: '5px' }}>
              <ActiveSymbol active={false}></ActiveSymbol>
            </span>
            <div style={{ marginTop: '5px' }}>
              <Radio
                checked={radioToggle}
                onClick={() => {
                  setRadioToggle(radioToggle ? false : true);
                }}
              />
            </div>
            <div style={{ marginTop: '5px' }}>
              <Switch
                checked={switchToggle}
                onChange={() => {
                  setSwitchToggle(switchToggle ? false : true);
                }}
              />
            </div>
          </Col>
          <Col lg={3} sm={12}>
            <Header text={'Social Icons'} />
            <br />
            <SocialIconGroupContainer>
              <SocialIconContainer>
                <FacebookIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></FacebookIcon>
                <FacebookIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.primary}
                ></FacebookIcon>
                <FacebookIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></FacebookIcon>
                <FacebookIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.blush}
                ></FacebookIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <TwitterIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></TwitterIcon>
                <TwitterIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.primary}
                ></TwitterIcon>
                <TwitterIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></TwitterIcon>
                <TwitterIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.blush}
                ></TwitterIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <InstagramIcon
                  fill={theme.brand.primary}
                  stroke={theme.brand.primary}
                ></InstagramIcon>
                <InstagramIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></InstagramIcon>
                <InstagramIcon
                  fill={theme.brand.blush}
                  stroke={theme.brand.blush}
                ></InstagramIcon>
                <InstagramIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></InstagramIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <PinterestIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></PinterestIcon>
                <PinterestIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.primary}
                ></PinterestIcon>
                <PinterestIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></PinterestIcon>
                <PinterestIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.blush}
                ></PinterestIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <WhatsappIcon fill={theme.brand.primary}></WhatsappIcon>
                <WhatsappInverseIcon
                  fill={theme.brand.primary}
                ></WhatsappInverseIcon>
                <WhatsappIcon fill={theme.brand.blush}></WhatsappIcon>
                <WhatsappInverseIcon
                  fill={theme.brand.blush}
                ></WhatsappInverseIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <DribbleIcon
                  stroke={theme.grey.noshade}
                  fill={theme.brand.primary}
                ></DribbleIcon>
                <DribbleIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.primary}
                ></DribbleIcon>
                <DribbleIcon
                  stroke={theme.grey.noshade}
                  fill={theme.brand.blush}
                ></DribbleIcon>
                <DribbleIcon
                  fill={theme.grey.noshade}
                  stroke={theme.brand.blush}
                ></DribbleIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <YoutubeIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></YoutubeIcon>
                <YoutubeIcon
                  stroke={theme.brand.primary}
                  fill={theme.grey.noshade}
                ></YoutubeIcon>
                <YoutubeIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></YoutubeIcon>
                <YoutubeIcon
                  stroke={theme.brand.blush}
                  fill={theme.grey.noshade}
                ></YoutubeIcon>
              </SocialIconContainer>
              <SocialIconContainer>
                <LinkedinIcon
                  stroke={theme.brand.primary}
                  fill={theme.grey.noshade}
                ></LinkedinIcon>
                <LinkedinIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></LinkedinIcon>
                <LinkedinIcon
                  stroke={theme.brand.blush}
                  fill={theme.grey.noshade}
                ></LinkedinIcon>
                <LinkedinIcon
                  fill={theme.brand.blush}
                  stroke={theme.grey.noshade}
                ></LinkedinIcon>
              </SocialIconContainer>
            </SocialIconGroupContainer>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Header text={'BUTTONS'}></Header>
            <ButtonContainer>
              <Row>
                <CustomColumn lg={4}>
                  <Button
                    variant={'secondary'}
                    text={'Click me'}
                    style={{ marginBottom: '16px' }}
                  ></Button>
                  <Button
                    variant={'tertiary'}
                    text={'Click me'}
                    style={{ marginBottom: '16px' }}
                  ></Button>
                  <Button
                    variant={'nobg'}
                    text={'Click me'}
                    textColor={'secondary'}
                    icon={<RightIcon fill={theme.brand.secondary} />}
                  ></Button>
                </CustomColumn>
                <CustomColumn lg={4}>
                  <Button
                    variant={'primary'}
                    text={'Click me'}
                    style={{ marginBottom: '16px' }}
                  ></Button>
                  <Button
                    variant={'quaternary'}
                    text={'Click me'}
                    style={{ marginBottom: '16px' }}
                  ></Button>
                  <Button
                    variant={'nobg'}
                    text={'Click me'}
                    textColor={'lilac'}
                    icon={<RightIcon fill={theme.brand.lilac} />}
                  ></Button>
                </CustomColumn>
                <CustomColumn
                  lg={4}
                  style={{ backgroundColor: theme.brand.primary }}
                >
                  <Button variant={'secondary'} text={'Click me'}></Button>
                  <CustomBt
                    textColor={'secondary'}
                    text={'Click me'}
                  ></CustomBt>
                  <Button
                    variant={'nobg'}
                    text={'Click me'}
                    textColor={'shade1'}
                    icon={<RightIcon fill={theme.grey.shade1} />}
                  ></Button>
                </CustomColumn>
              </Row>
            </ButtonContainer>
          </Col>
          <Col lg={6} sm={12}>
            <Header text={'Logo'}></Header>
            <LogoContainer>
              <LogoGroupContainer>
                <NavyBackground>
                  <Logo variant={'stacked_white'}></Logo>
                </NavyBackground>
                <Logo variant={'stacked_navy'}></Logo>
              </LogoGroupContainer>
              <LogoGroupContainer>
                <BlushBackground>
                  <Logo variant={'stacked_orange'}></Logo>
                </BlushBackground>
                <TwilightBackground>
                  <Logo variant={'stacked_navy'}></Logo>
                </TwilightBackground>
              </LogoGroupContainer>
            </LogoContainer>
          </Col>
          <Col sm={12}>
            <Header text={'Switch Menu'}></Header>
            <br />
            <div style={{ paddingLeft: '30px' }}>
              <SwitchMenu
                onToggle={handleSwitchItems}
                editted={true}
                items={switchItems}
              />
              <br />
              <SwitchMenu
                onToggle={handleSwitchItemsSec}
                editted={false}
                items={switchItemsSec}
              />
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            style={{ marginTop: '35px', marginBottom: '45px' }}
          >
            <Header text={'Add Button Dropdown'}></Header>
            <br />
            <ButtonDropDown
              label="Add Colour"
              dropdownItems={buttonItems}
              onItemClicked={(key) => {
                return;
              }}
            />
            <ButtonDropDown
              label="Add Logo"
              dropdownItems={buttonItemsSec}
              onItemClicked={(key) => {
                return;
              }}
              style={{ marginLeft: '15px' }}
            />
          </Col>
          <Col lg={6} sm={12} style={{ marginTop: '35px' }}>
            <Header text={'Select Dropdown'}></Header>
            <br />
            <DropDown
              options={[
                { value: 'Option 1', label: 'Option 1' },
                { value: 'Option 2', label: 'Option 2' },
              ]}
            ></DropDown>
          </Col>
          <Col lg={6} sm={12} style={{ marginTop: '35px' }}>
            <Header text={'Checkbox'}></Header>
            <br />
            <Checkbox
              checked={true}
              label="Test"
              onClick={() => {
                return;
              }}
            ></Checkbox>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Header text={'Input Boxes'}></Header>
            <br />
            <Input
              onChange={(event) => {
                setSampleField(event?.target.value);
              }}
              value={sampleField}
              label={'Brand Name'}
              variant={'body'}
              LeftComponent={
                <FacebookIcon
                  fill={theme.brand.primary}
                  stroke={theme.grey.noshade}
                ></FacebookIcon>
              }
            ></Input>
            <Input
              onChange={(event) => {
                setSampleFieldTwo(event?.target.value);
              }}
              value={sampleFieldTwo}
              label={'Name'}
              variant={'body'}
            ></Input>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
              }}
            >
              <div style={{ width: '100%' }}>
                <Input
                  onChange={(event) => {
                    setSampleFieldThree(event?.target.value);
                  }}
                  value={sampleFieldThree}
                  label={'Brand Name'}
                  variant={'body'}
                  LeftComponent={
                    <FacebookIcon
                      fill={theme.brand.primary}
                      stroke={theme.grey.noshade}
                    ></FacebookIcon>
                  }
                  error={sampleError}
                ></Input>
              </div>
              <Button
                style={{ marginLeft: '10px' }}
                text={'Show Error'}
                variant={'primary'}
                onClick={() => {
                  setSampleError('Invalid Input');
                }}
              ></Button>
              <Button
                style={{ marginLeft: '10px' }}
                text={'Reset'}
                variant={'quaternary'}
                onClick={() => {
                  setSampleError('');
                }}
              ></Button>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <Header text={'Attention!'}></Header>
          </Col>
        </Row>
        <Header text={'Pop ups'}></Header>
        <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Col lg={3} sm={12}>
            <Popups
              title={'This is a heading'}
              content={
                'This is a whole heap of text that relates to the above heading and will talk to any details around the heading. This is a whole heap of text that relates to the above heading.'
              }
            >
              <PopupCenterButtonContainer>
                <Button
                  takeFullWidth
                  variant={'secondary'}
                  text={'Click Me'}
                ></Button>
              </PopupCenterButtonContainer>
            </Popups>
          </Col>
          <Col lg={3}>
            <Popups
              title={'Happy Anniversary!'}
              content={
                'It’s been one whole year since you started working with us.'
              }
            ></Popups>
          </Col>
          <Col lg={4}>
            <Popups
              title={'This is a heading'}
              content={
                'This is a whole heap of text that relates to the above heading and will talk to any details around the heading. This is a whole heap of text that relates to the above heading.'
              }
            >
              <Button
                size={'sm'}
                variant={'nobg'}
                text={'Click Me'}
                textColor={'shade1'}
                icon={<RightIcon fill={theme.grey.shade1} />}
              />
            </Popups>
          </Col>
        </Row>
        <Row>
          <Col>
            <Header text={'Banner'}></Header>
            <br />
            {/* <Banner
              title={'Important Information & Notifications'}
              content={bannerMessage}
              subcontent={bannerMessage}
            ></Banner> */}
          </Col>
          <Col>
            <Header text={'Lines and Borders'}></Header>
            <Line variant={'primary'}></Line>
            <Line variant={'secondary'}></Line>
            <Line variant={'blush'}></Line>
            <Line circle variant={'primary'}></Line>
            <Line circle variant={'secondary'}></Line>
            <Line circle variant={'blush'}></Line>
          </Col>
          <Col>
            <Header text={'Header'}></Header>
            <Welcome title={'Welcome'} name={'Client Name'}></Welcome>
            <br />
            <Welcome title={'Brand Hub'} name={'Accounts'}></Welcome>
          </Col>
        </Row>
        <br />
        <Header text={'Client Cards'}></Header>
        <Row style={{ marginTop: '35px', marginBottom: '45px' }}>
          <Col xxl={2} xl={3} lg={4} md={6} sm={12}>
            <ClientCard
              name="Client Name"
              dateText="Est. May 2021"
              active={true}
            ></ClientCard>
          </Col>
          <Col xxl={2} xl={3} lg={4} md={6} sm={12}>
            <ClientCard
              name="Client Name"
              dateText="Est. May 2021"
              active={false}
            ></ClientCard>
          </Col>
        </Row>
        <Header text={'Results Box'}></Header>
        <Row style={{ marginTop: '35px', marginBottom: '45px' }}>
          <Col xxl={3} xl={3} lg={4} md={6} sm={12}>
            <ResultsBox
              outerProgress={90}
              midProgress={70}
              innerProgress={50}
            ></ResultsBox>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={12}>
            <ResultsBox
              outerProgress={80}
              midProgress={55}
              innerProgress={25}
            ></ResultsBox>
          </Col>
        </Row>
        <Header text={'Side Bar'}></Header>
        <br />
        {/* NEED TO COMMENT OUT, ADJUST TO USED ACTUAL ROUTES */}
        {/* <Row>
          <Col>
            <Sidebar mode={'admin'} items={adminNavList}></Sidebar>
          </Col>
          <Col>
            <Sidebar mode={'client'} items={clientNavList}></Sidebar>
          </Col>
        </Row> */}
        <Header text={'Brand Colours'}></Header>
        <Row>
          <Col>
            <BrandColor name={'Primary'} hex={'#1E1850'}></BrandColor>
          </Col>
          <Col>
            <BrandColor
              name={'Primary'}
              rgb={'126, 12, 33'}
              hex={'#DE3E1B'}
              cmyk={'35 35 35 35'}
              pms={'4563C'}
            ></BrandColor>
          </Col>
          <Col>
            <BrandColor
              name={'Primary'}
              rgb={'126, 12, 33'}
              cmyk={'35 35 35 35'}
              pms={'4563C'}
              hex={'#F0C9C1'}
            ></BrandColor>
          </Col>
          <Col>
            <BrandColor name={'Primary'} hex={'#799BD0'}></BrandColor>
          </Col>
          <Col>
            <BrandColor name={'Primary'} hex={'#4757A6'}></BrandColor>
          </Col>
        </Row>
        <Header text={'Brand Cards'}></Header>
        <br />
        <Row>
          <Col>
            <BrandCard title={'Purpose'} content={brandCardContent}></BrandCard>
          </Col>
          <Col>
            <BrandCard title={'Promise'} content={''}></BrandCard>
          </Col>
          <Col lg={6}>
            <BrandCard
              floatTitle
              title={'Culture'}
              content={
                'Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact your business strategy and decision making.Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact your business strategy and decision making.Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact your business strategy and decision making.Your brand purpose is the ‘why’- it’s why your brand exists at its purest form. It should be inspirational, and directly impact your business strategy and decision making.'
              }
            ></BrandCard>
          </Col>
        </Row>
        <Header text={'Article Preview'}></Header>
        <br />
        <Row>
          <Col lg={6}>
            <ArticlePreview
              heading="Heading 5"
              time="Monday 4th April  4:30pm"
              caption="Here is the introductory paragraph that will explain to the client what they’re doing in here, and what to focus on. Something Fun. "
              author="Paris Young"
            ></ArticlePreview>
          </Col>
        </Row>
        <br />
        <Header text={'Speech Bubbles'}></Header>
        <br />
        <Row>
          <Col lg={2}>
            <SpeechBubble position={'bottom-left'} color={'secondary'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
          <Col lg={2}>
            <SpeechBubble position={'upper-left'} color={'secondary'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
          <Col lg={2}>
            <SpeechBubble position={'center-top'} color={'secondary'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
          <Col lg={2}>
            <SpeechBubble position={'center-bottom'} color={'twilight'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
          <Col lg={2}>
            <SpeechBubble position={'upper-right'} color={'primary'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
          <Col lg={2}>
            <SpeechBubble position={'bottom-right'} color={'secondary'}>
              <p>Testing</p>
            </SpeechBubble>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <Attribute
              color={theme.brand.lilacLight}
              title={'Pitch'}
              contentA={businessAttribute}
              contentB={businessAttribute}
            ></Attribute>
            <Attribute
              color={theme.grey.noshade}
              title={'Communication Channel'}
              contentA={businessAttribute}
            ></Attribute>
          </Col>
        </Row>
        <Header text={'Accordion and Colour Palette'}></Header>
        <br />
        <Row>
          <Col lg={8}>
            <Accordion title={'Colour Palette'}>
              <div style={{ display: 'flex' }}>
                {/* Comment out for integration */}
                {/* <Palette type={'Primary'}></Palette>
                <Palette type={'Secondary'}></Palette>
                <Palette type={'Secondary'}></Palette> */}
              </div>
            </Accordion>
          </Col>
          <Col>
            <Header text={'File Details'}></Header>
            <br />
            <FileDetail
              withDot
              title={'Icon Title'}
              name={'File Name'}
              date={'October 10, 2021'}
              onDeleteHandler={() => console.log('Handle delete item here...')}
            ></FileDetail>
            <br />
            <FileDetail
              title={'Icon Title'}
              name={'File Name'}
              date={'October 10, 2021'}
              onDeleteHandler={() => console.log('Handle delete item here...')}
            ></FileDetail>
          </Col>
        </Row>
        <Header text={'Modals'}></Header>
        <br />
        <Row>
          <Col>
            <Button
              variant={'primary'}
              text={'Show Generic Modal'}
              onClick={() => setOpenModal(true)}
            ></Button>
          </Col>
          <Col>
            <Button
              variant={'primary'}
              text={'Show Integration Modal'}
              onClick={() => setIntegrationModal(true)}
            ></Button>
          </Col>
          <Col>
            <Button
              variant={'primary'}
              text={'Show Upload Logo Modal'}
              onClick={() => setFileUploadModal(true)}
            ></Button>
          </Col>
          <Col>
            <Button
              variant={'primary'}
              text={'Show Upload Icon Modal'}
              onClick={() => setIconModal(true)}
            ></Button>
          </Col>
          <Col>
            <Button
              variant={'primary'}
              text={'Show Upload Document Modal'}
              onClick={() => setDocumentModal(true)}
            ></Button>
          </Col>
        </Row>
        <br />
        <Header text={'Brand Strategy Field'}></Header>
        <br />
        <Row>
          <Col>
          {/* Comment out for integration purpose */}
            {/* <BrandStrategyField title={'Purpose'}></BrandStrategyField>
            <br />
            <BrandStrategyField title={'Promise'}></BrandStrategyField> */}
          </Col>
          <Col>
          {/* Comment out for integration purpose */}
            {/* <StackedInput title={'Benchmark'}></StackedInput> */}
          </Col>
        </Row>
        <Modal isOpen={openModal} onClickClose={() => setOpenModal(false)}>
          <Title variant={'title2'}>Children Contents go Here</Title>
        </Modal>
        <ModalIntegration
          isOpen={integrationModal}
          onClickClose={() => setIntegrationModal(false)}
        ></ModalIntegration>
        <ModalFileUpload
          title={'Upload a logo'}
          isOpen={fileUploadModal}
          onClickClose={() => setFileUploadModal(false)}
        ></ModalFileUpload>
        <ModalFileUpload
          title={'Upload an icon'}
          isOpen={iconModal}
          onClickClose={() => setIconModal(false)}
        ></ModalFileUpload>
        <ModalFileUpload
          title={'Upload an document'}
          isOpen={documentModal}
          onClickClose={() => setDocumentModal(false)}
          isDocument
        ></ModalFileUpload>
      </GridContainer>
    </Container>
  );
};

export default DemoView;
