import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetGscSitesMeta, GetGscSitesPayload } from './getGscSites.type';

const initialState: AsyncState<GetGscSitesMeta, GetGscSitesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetGscSitesMeta, GetGscSitesPayload>();

const getGscSitesSlice = createSlice({
  name: 'getGscSites',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetGscSites = getGscSitesSlice.actions.REQUEST;
export const setGetGscSites = getGscSitesSlice.actions.SET;
export const failGetGscSites = getGscSitesSlice.actions.FAILED;
export const clearGetGscSites = getGscSitesSlice.actions.CLEAR;

export default getGscSitesSlice.reducer;
