import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertPageWriteup as upsertPageWriteupApi } from 'api/pageWriteup';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientWriteups } from 'store/formClient/formClient.slice';

import {
  requestUpsertPageWriteup,
  setUpsertPageWriteup,
  failUpsertPageWriteup,
} from './upsertPageWriteup.slice';
import {
  UpsertPageWriteupMeta,
  UpsertPageWriteupPayload,
} from './upsertPageWriteup.type';

function* upsertPageWriteupRequest(
  action: PayloadAction<UpsertPageWriteupMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertPageWriteupApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpsertPageWriteup(data));
    } catch (err) {
      yield put(failUpsertPageWriteup(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertPageWriteup('No token provided'));
  }
}

function* upsertPageWriteupSuccess(
  action: PayloadAction<UpsertPageWriteupPayload>
) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientWriteups(data));
}

function* upsertPageWriteupFail(
  action: PayloadAction<UpsertPageWriteupPayload>
) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertPageWriteupWatcher() {
  yield takeLatest(requestUpsertPageWriteup.type, upsertPageWriteupRequest);
  yield takeLatest(setUpsertPageWriteup.type, upsertPageWriteupSuccess);
  yield takeLatest(failUpsertPageWriteup.type, upsertPageWriteupFail);
}

export default UpsertPageWriteupWatcher;
