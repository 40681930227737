import React, { useState } from 'react';

import Icon from 'components/base/SVG/AngleDown';
import theme from 'utils/Theme';

import { AccordionProps } from './Accordion.props';
import { Container, HeaderDiv, Title, ContentDiv, InnerDiv } from './Accordion.style';

const Accordion = (props: AccordionProps): JSX.Element => {
  const { children, title } = props;
  const [visible, setVisible] = useState(true);

  const toggle = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  return (
    <Container>
      <HeaderDiv onClick={toggle} visible={visible}>
        <Title color={'black'} variant={'body'} weight="800">
          {title}
        </Title>
        <Icon fill={theme.brand.secondary}></Icon>
      </HeaderDiv>
      <ContentDiv visible={visible}>
        <InnerDiv>{children}</InnerDiv>
      </ContentDiv>
    </Container>
  );
};

export default React.memo(Accordion);
