import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronLeft = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '6'}
      height={height || '8'}
      viewBox="0 0 6 8"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.88622 7.33334C5.01038 7.20843 5.08008 7.03946 5.08008 6.86334C5.08008 6.68722 5.01038 6.51825 4.88622 6.39334L2.52622 4.00001L4.88622 1.64001C5.01038 1.5151 5.08008 1.34613 5.08008 1.17001C5.08008 0.993883 5.01038 0.824915 4.88622 0.700006C4.82424 0.637521 4.75051 0.587925 4.66927 0.554079C4.58803 0.520233 4.50089 0.502808 4.41288 0.502808C4.32488 0.502808 4.23774 0.520233 4.1565 0.554079C4.07526 0.587924 4.00153 0.637521 3.93955 0.700006L1.11288 3.52667C1.0504 3.58865 1.0008 3.66238 0.966956 3.74362C0.93311 3.82486 0.915684 3.912 0.915684 4.00001C0.915684 4.08801 0.93311 4.17515 0.966955 4.25639C1.0008 4.33763 1.0504 4.41136 1.11288 4.47334L3.93955 7.33334C4.00152 7.39583 4.07526 7.44542 4.1565 7.47927C4.23774 7.51311 4.32487 7.53054 4.41288 7.53054C4.50089 7.53054 4.58803 7.51311 4.66927 7.47927C4.75051 7.44542 4.82424 7.39583 4.88622 7.33334Z"
        fill={fill || '#E35D32'}
      />
    </svg>
  );
};

export default ChevronLeft;
