import styled from '@emotion/styled';
import Typography from 'components/base/Typography';
import { Link } from 'react-router-dom';
import { pxToRem } from 'utils/Theme';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{
  isOpen: boolean;
}>`
  position: fixed;

  width: 100%;
  height: 100vh;

  transition: left 0.3s ease-out;
  left: ${({ isOpen }) => (isOpen ? '0px' : '-100%')};
  top: 0;
  z-index: 99999;
`;

export const InnerBar = styled.div<{
  mode: string;
  isOpen: boolean;
}>`
  background: ${(props) =>
    props.mode === 'admin'
      ? props.theme.brand.secondary
      : props.theme.brand.primary};

  max-width: ${pxToRem(236)};
  height: 100vh;
  position: relative;
`;

export const SidebarHeading = styled.div`
  padding: 15px 10px;
`;

export const CloseToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  width: 100%;

  svg {
    margin-right: 5px;
  }
`;

export const NavListContainer = styled.div``;

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vh;
`;

export const FooterItem = styled(Typography)`
  margin-top: 12px;
`;

export const BorderContainer = styled.div`
  width: 80%;
`;
