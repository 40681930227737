const ADMIN_ROOT = '/admin';
const CLIENT_ROOT = '/client';

export const MAIN_ROUTES = {
  LANDING: '/',
  LOGIN: '/:type/login',
  VERIFY: '/verify',
  // FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/forgot-password/:type/:token/:reference/:invite_id?',
  AUTHENTICATE: '/authenticate/:token',
  DEMO: '/demo',
  SUCCESS_TOKEN: '/success-token',
};

export const CLIENT_ROUTES = {
  ROOT: `${CLIENT_ROOT}`,
  DASHBOARD: `${CLIENT_ROOT}/dashboard`,
  KIT: `${CLIENT_ROOT}/kit`,
  TEMPLATES: `${CLIENT_ROOT}/templates`,
  STRATEGY: `${CLIENT_ROOT}/strategy`,
  REPORTS: `${CLIENT_ROOT}/reports`,
  DOCUMENTS: `${CLIENT_ROOT}/documents`,
};

export const ADMIN_ROUTES = {
  ROOT: `${ADMIN_ROOT}`,
  ACCOUNTS: `${ADMIN_ROOT}/accounts`,
  DATA_INPUT: `${ADMIN_ROOT}/data-input/:client_id?`,
  CLIENT_UPDATES: `${ADMIN_ROOT}/data-input/:client_id?/updates`,
};
