import React from 'react';

import { KitIconProps } from './KitIcon.props';
import { Container, ImageContainer, Image } from './KitIcon.style';

const KitIcon = (props: KitIconProps): JSX.Element => {
  const { url } = props;
  return (
    <Container onClick={props.onClick}>
      <ImageContainer>
        <Image alt={`icon`} src={url} width={'40'} height={'40'}></Image>
      </ImageContainer>
    </Container>
  );
};

export default React.memo(KitIcon);
