import type { PayloadAction } from '@reduxjs/toolkit';
import { deleteDocument as deleteDocumentApi } from 'api/document';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateFormClientDocuments } from 'store/formClient/formClient.slice';

import {
  requestDeleteDocument,
  setDeleteDocument,
  failDeleteDocument,
} from './deleteDocument.slice';
import {
  DeleteDocumentMeta,
  DeleteDocumentPayload,
} from './deleteDocument.type';

function* deleteDocumentRequest(action: PayloadAction<DeleteDocumentMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteDocumentApi,
        action.payload,
        state.auth.token
      );
      yield put(setDeleteDocument(data));
    } catch (err) {
      yield put(failDeleteDocument(err.response?.data?.message || err));
    }
  } else {
    yield put(failDeleteDocument('No token provided'));
  }
}

function* deleteDocumentSuccess(action: PayloadAction<DeleteDocumentPayload>) {
  // yield function here
  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  yield put(updateFormClientDocuments(data));
}

function* deleteDocumentFail(action: PayloadAction<DeleteDocumentPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* DeleteDocumentWatcher() {
  yield takeLatest(requestDeleteDocument.type, deleteDocumentRequest);
  yield takeLatest(setDeleteDocument.type, deleteDocumentSuccess);
  yield takeLatest(failDeleteDocument.type, deleteDocumentFail);
}

export default DeleteDocumentWatcher;
