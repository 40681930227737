import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

export const Container = styled.div``;

export const Headings = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: 8px;
`;

export const Caption = styled(Typography)`
  text-align: justify;
  margin-bottom: 8px;
  white-space: pre-line;
`;

export const AuthorDetails = styled(Typography)``;
