import type { PayloadAction } from '@reduxjs/toolkit';
import { upsertPost as upsertPostApi } from 'api/post';
import { RootState } from 'app/store';
import { error, success } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import {
  requestUpsertPost,
  setUpsertPost,
  failUpsertPost,
} from './upsertPost.slice';
import { UpsertPostMeta, UpsertPostPayload } from './upsertPost.type';

function* upsertPostRequest(action: PayloadAction<UpsertPostMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertPostApi,
        action.payload,
        state.auth.token
      );
      yield put(setUpsertPost(data));
    } catch (err) {
      yield put(failUpsertPost(err.response?.data?.message || err));
    }
  } else {
    yield put(failUpsertPost('No token provided'));
  }
}

function* upsertPostSuccess(action: PayloadAction<UpsertPostPayload>) {
  // yield function here

  const data = action.payload.data;
  yield put(success(String(action.payload.message)));
  // yield put(updateFormClientLogos(data));
}

function* upsertPostFail(action: PayloadAction<UpsertPostPayload>) {
  // yield function here
  yield put(error(String(action.payload)));
}

function* UpsertPostWatcher() {
  yield takeLatest(requestUpsertPost.type, upsertPostRequest);
  yield takeLatest(setUpsertPost.type, upsertPostSuccess);
  yield takeLatest(failUpsertPost.type, upsertPostFail);
}

export default UpsertPostWatcher;
