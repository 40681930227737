import React, { Fragment } from 'react';

import Spinner from 'components/base/Spinner';

// import { useTheme } from 'utils/Theme';
import { LoadingPlaceholderProps } from './LoadingPlaceholder.props';
import { Container, Caption } from './LoadingPlaceholder.style';

const LoadingPlaceholder = (props: LoadingPlaceholderProps): JSX.Element => {
  const { caption, emptyFlag, emptyCaption } = props;
  return (
    <Container>
      {emptyFlag ? (
        <Caption variant="body">&nbsp;&nbsp;{emptyCaption}</Caption>
      ) : (
        <Fragment>
          <Caption variant="body">{caption}</Caption>
          <Spinner height={15} width={15}></Spinner>
        </Fragment>
      )}
    </Container>
  );
};

export default React.memo(LoadingPlaceholder);
