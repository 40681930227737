import styled from '@emotion/styled';
import Checkbox from 'components/base/Checkbox';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const HeadingDiv = styled.div`
  margin-bottom: 30px;
`;

export const InnerDiv = styled.div`
  padding-bottom: 100px;
`;

export const BannerDiv = styled.div``;

export const Caption = styled(Typography)`
  max-width: 395px;
`;

export const TemplatesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TemplateItem = styled.a`
  margin-bottom: 24px;
  margin-right: 29px;

  cursor: pointer;
  text-decoration: none !important;
`;

export const GraphicalDisplay = styled.div<{
  bgImage?: string;
  bgType?: string;
}>`
  background: ${({bgImage, bgType}) => {
    if(bgType === 'image') {
      return `url(${bgImage}) no-repeat center center`
    } else {
      return `transparent`;
    }
  }};
  background-size: contain;

  min-width: 155px;
  min-height: 211px;

  border: 1px solid #1E1850;
  box-sizing: border-box;
  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GraphDescription = styled(Typography)`
  font-size: 25px;
  font-weight: 800;

  color: #bdbdbe;

  span {
    display: block;
    font-size: .7rem
  }
`;