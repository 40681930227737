import type { PayloadAction } from '@reduxjs/toolkit';
import { getAuthLink } from 'api/ga';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { requestGetGaUrl, setGetGaUrl, failGetGaUrl } from './getGaUrl.slice';
import { GetGaUrlMeta, GetGaUrlPayload } from './getGaUrl.type';

function* getGaUrlRequest(action: PayloadAction<GetGaUrlMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAuthLink,
        action.payload,
        state.auth.token
      );
      window.open(data.url);
      yield put(setGetGaUrl(data));
    } catch (err) {
      yield put(failGetGaUrl(err.response.data.message));
    }
  } else {
    yield put(failGetGaUrl('No token provided'));
  }
}

function* getGaUrlSuccess(action: PayloadAction<GetGaUrlPayload>) {
  // yield function here
}

function* getGaUrlFail(action: PayloadAction<GetGaUrlPayload>) {
  // yield function here
}

function* GetGaUrlWatcher() {
  yield takeLatest(requestGetGaUrl.type, getGaUrlRequest);
  yield takeLatest(setGetGaUrl.type, getGaUrlSuccess);
  yield takeLatest(failGetGaUrl.type, getGaUrlFail);
}

export default GetGaUrlWatcher;
