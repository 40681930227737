import { fork, all } from 'redux-saga/effects';
import clientGenericActionSaga from 'store/clientGenericAction/clientGenericAction.saga';
import computeKpiFansSaga from 'store/computeKpiFans/computeKpiFans.saga';
import deleteAssetSaga from 'store/deleteAsset/deleteAsset.saga';
import deleteColorByIdSaga from 'store/deleteColorById/deleteColorById.saga';
import deleteDocumentSaga from 'store/deleteDocument/deleteDocument.saga';
import deleteIconFileSaga from 'store/deleteIconFile/deleteIconFile.saga';
import deleteLogoFileSaga from 'store/deleteLogoFile/deleteLogoFile.saga';
import deleteMockupSaga from 'store/deleteMockup/deleteMockup.saga';
import deletePostSaga from 'store/deletePost/deletePost.saga';
import deleteStyleGuideSaga from 'store/deleteStyleGuide/deleteStyleGuide.saga';
import fbListPages from 'store/fbListPages/fbListPages.saga';
import gaListWebsites from 'store/gaListWebsites/gaListWebsites.saga';
import generateReportSaga from 'store/generateReport/generateReport.saga';
import getAssetsByUserIdSaga from 'store/getAssetsByUserId/getAssetsByUserId.saga';
import getClientById from 'store/getClientById/getClientById.saga';
import getClients from 'store/getClients/getClients.saga';
import getColorsByUserIdSaga from 'store/getColorsByUserId/getColorsByUserId.saga';
import getContentStats from 'store/getContentStats/getContentStats.saga';
import getDocumentsByIdSaga from 'store/getDocumentsById/getDocumentsById.saga';
import getFbAttributes from 'store/getFbAttributes/getFbAttributes.saga';
import getFbAttributesByToken from 'store/getFbAttributesByToken/getFbAttributesByToken.saga';
import getFbLikes from 'store/getFbLikes/getFbLikes.saga';
import getGaStats from 'store/getGaStats/getGaStats.saga';
import getGaUrl from 'store/getGaUrl/getGaUrl.saga';
import getGscSitesSaga from 'store/getGscSites/getGscSites.saga';
import getGscStatsSaga from 'store/getGscStats/getGscStats.saga';
import getIconsByUserIdSaga from 'store/getIconsByUserId/getIconsByUserId.saga';
import getIgAttributesSaga from 'store/getIgAttributes/getIgAttributes.saga';
import getIgDetails from 'store/getIgDetails/getIgDetails.saga';
import getLinkedinFollowersSaga from 'store/getLinkedinFollowers/getLinkedinFollowers.saga';
import getLogosByUserIdSaga from 'store/getLogosByUserId/getLogosByUserId.saga';
import getMockupsByUserIdSaga from 'store/getMockupsByUserId/getMockupsByUserId.saga';
import getOrganizationListSaga from 'store/getOrganizationList/getOrganizationList.saga';
import getPermissionsByUserIdSaga from 'store/getPermissionsByUserId/getPermissionsByUserId.saga';
import getPostsByUserIdSaga from 'store/getPostsByUserId/getPostsByUserId.saga';
import getRevSaga from 'store/getRev/getRev.saga';
import getStrategyByUserIdSaga from 'store/getStrategyByUserId/getStrategyByUserId.saga';
import getStyleGuidesByUserIdSaga from 'store/getStyleGuidesByUserId/getStyleGuidesByUserId.saga';
import getSubscriberDetailsSaga from 'store/getSubscriberDetails/getSubscriberDetails.saga';
import getUser from 'store/getUser/getUser.saga';
import integrateMailSaga from 'store/integrateMail/integrateMail.saga';
import integrateMailChimpSaga from 'store/integrateMailChimp/integrateMailChimp.saga';
import isIntegrated from 'store/isGaIntegrated/isGaIntegrated.saga';
import issueFbToken from 'store/issueFbToken/issueFbToken.saga';
import login from 'store/login/login.saga';
import rearrangeOrderViewSaga from 'store/rearrangeOrderView/rearrangeOrderView.saga';
import resetPassword from 'store/resetPassword/resetPassword.saga';
import resetVerifyEmail from 'store/resetVerifyEmail/resetVerifyEmail.saga';
import sendInvitationSaga from 'store/sendInvitation/sendInvitation.saga';
import testLinkedinConnectionSaga from 'store/testLinkedinConnection/testLinkedinConnection.saga';
import testMailChimpConnectionSaga from 'store/testMailChimpConnection/testMailChimpConnection.saga';
import testMailConnectionSaga from 'store/testMailConnection/testMailConnection.saga';
import updateClientOrder from 'store/updateClientOrder/updateClientOrder.saga';
import updateFbPageId from 'store/updateFbPageId/updateFbPageId.saga';
import updateGaViewId from 'store/updateGaViewId/updateGaViewId.saga';
import updateGscSiteSaga from 'store/updateGscSite/updateGscSite.saga';
import updateOrganizationUrnSaga from 'store/updateOrganizationUrn/updateOrganizationUrn.saga';
import updatePermission from 'store/updatePermission/updatePermission.saga';
import upsertAssetSaga from 'store/upsertAsset/upsertAsset.saga';
import upsertClient from 'store/upsertClient/upsertClient.saga';
import upsertColor from 'store/upsertColor/upsertColor.saga';
import upsertDocumentSaga from 'store/upsertDocument/upsertDocument.saga';
import upsertIconSaga from 'store/upsertIcon/upsertIcon.saga';
import upsertKpiSaga from 'store/upsertKpi/upsertKpi.saga';
import upsertLogoSaga from 'store/upsertLogo/upsertLogo.saga';
import upsertMockupSaga from 'store/upsertMockup/upsertMockup.saga';
import upsertPageWriteupSaga from 'store/upsertPageWriteup/upsertPageWriteup.saga';
import upsertPostSaga from 'store/upsertPost/upsertPost.saga';
import upsertStrategySaga from 'store/upsertStrategy/upsertStrategy.saga';
import upsertStyleGuideSaga from 'store/upsertStyleGuide/upsertStyleGuide.saga';

const sagas = [
  getUser,
  login,
  resetVerifyEmail,
  resetPassword,
  getGaUrl,
  upsertClient,
  getClients,
  getClientById,
  isIntegrated,
  gaListWebsites,
  updateGaViewId,
  getGaStats,
  issueFbToken,
  getFbAttributes,
  fbListPages,
  updateFbPageId,
  updatePermission,
  upsertColor,
  getFbLikes,
  getFbAttributesByToken,
  upsertLogoSaga,
  deleteLogoFileSaga,
  upsertIconSaga,
  deleteIconFileSaga,
  upsertStrategySaga,
  upsertDocumentSaga,
  deleteDocumentSaga,
  sendInvitationSaga,
  clientGenericActionSaga,
  getIgAttributesSaga,
  getIgDetails,
  getContentStats,
  upsertKpiSaga,
  computeKpiFansSaga,
  getGscSitesSaga,
  updateGscSiteSaga,
  getGscStatsSaga,
  updateClientOrder,
  getColorsByUserIdSaga,
  getLogosByUserIdSaga,
  getIconsByUserIdSaga,
  deleteColorByIdSaga,
  getStrategyByUserIdSaga,
  getPermissionsByUserIdSaga,
  integrateMailChimpSaga,
  testMailChimpConnectionSaga,
  getSubscriberDetailsSaga,
  integrateMailSaga,
  testMailConnectionSaga,
  upsertPostSaga,
  getPostsByUserIdSaga,
  deletePostSaga,
  getDocumentsByIdSaga,
  getRevSaga,
  rearrangeOrderViewSaga,
  testLinkedinConnectionSaga,
  getOrganizationListSaga,
  updateOrganizationUrnSaga,
  getLinkedinFollowersSaga,
  generateReportSaga,
  upsertPageWriteupSaga,
  upsertAssetSaga,
  deleteAssetSaga,
  getAssetsByUserIdSaga,
  upsertMockupSaga,
  deleteMockupSaga,
  getMockupsByUserIdSaga,
  upsertStyleGuideSaga,
  deleteStyleGuideSaga,
  getStyleGuidesByUserIdSaga,
];

export default function* root() {
  yield all(sagas.map(fork));
}
