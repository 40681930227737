import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetRevMeta, GetRevPayload } from './getRev.type';

const initialState: AsyncState<GetRevMeta, GetRevPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetRevMeta, GetRevPayload>();

const getRevSlice = createSlice({
  name: 'getRev',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetRev = getRevSlice.actions.REQUEST;
export const setGetRev = getRevSlice.actions.SET;
export const failGetRev = getRevSlice.actions.FAILED;
export const clearGetRev = getRevSlice.actions.CLEAR;

export default getRevSlice.reducer;
