import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GetPostsByUserIdMeta,
  GetPostsByUserIdPayload
} from './getPostsByUserId.type';

const initialState: AsyncState<GetPostsByUserIdMeta, GetPostsByUserIdPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GetPostsByUserIdMeta, GetPostsByUserIdPayload>();

const getPostsByUserIdSlice = createSlice({
  name: 'getPostsByUserId',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetPostsByUserId = getPostsByUserIdSlice.actions.REQUEST;
export const setGetPostsByUserId = getPostsByUserIdSlice.actions.SET;
export const failGetPostsByUserId = getPostsByUserIdSlice.actions.FAILED;
export const clearGetPostsByUserId = getPostsByUserIdSlice.actions.CLEAR;

export default getPostsByUserIdSlice.reducer;
