import type { PayloadAction } from '@reduxjs/toolkit';
import { integrateMailchimp } from 'api/mail';
import { RootState } from 'app/store';
import { success, info } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestTestMailConnection } from 'store/testMailConnection/testMailConnection.slice';

import {
  requestIntegrateMailChimp,
  setIntegrateMailChimp,
  failIntegrateMailChimp,
} from './integrateMailChimp.slice';
import {
  IntegrateMailChimpMeta,
  IntegrateMailChimpPayload,
} from './integrateMailChimp.type';

function* integrateMailChimpRequest(
  action: PayloadAction<IntegrateMailChimpMeta>
) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        integrateMailchimp,
        action.payload,
        state.auth.token
      );
      yield put(setIntegrateMailChimp(data));
    } catch (err) {
      yield put(failIntegrateMailChimp(err.response.data.message));
    }
  } else {
    yield put(failIntegrateMailChimp('No token provided'));
  }
}

function* integrateMailChimpSuccess(
  action: PayloadAction<IntegrateMailChimpPayload>
) {
  const state: RootState = yield select();
  const client_id = state.formClient.user?.id;

  yield put(success('Mailchimp API connected'));
  yield put(info('Testing Connection'));

  if (client_id) {
    yield put(
      requestTestMailConnection({
        user_id: client_id,
      })
    );
  }
}

function* integrateMailChimpFail(
  action: PayloadAction<IntegrateMailChimpPayload>
) {
  // yield function here
}

function* IntegrateMailChimpWatcher() {
  yield takeLatest(requestIntegrateMailChimp.type, integrateMailChimpRequest);
  yield takeLatest(setIntegrateMailChimp.type, integrateMailChimpSuccess);
  yield takeLatest(failIntegrateMailChimp.type, integrateMailChimpFail);
}

export default IntegrateMailChimpWatcher;
