import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertAssetMeta,
  UpsertAssetPayload
} from './upsertAsset.type';

const initialState: AsyncState<UpsertAssetMeta, UpsertAssetPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertAssetMeta, UpsertAssetPayload>();

const upsertAssetSlice = createSlice({
  name: 'upsertAsset',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertAsset = upsertAssetSlice.actions.REQUEST;
export const setUpsertAsset = upsertAssetSlice.actions.SET;
export const failUpsertAsset = upsertAssetSlice.actions.FAILED;
export const clearUpsertAsset = upsertAssetSlice.actions.CLEAR;

export default upsertAssetSlice.reducer;
