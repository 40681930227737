import type { PayloadAction } from '@reduxjs/toolkit';
import { integrateMail } from 'api/mail';
import { RootState } from 'app/store';
import { success, info } from 'react-toastify-redux';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestTestMailConnection } from 'store/testMailConnection/testMailConnection.slice';

import {
  requestIntegrateMail,
  setIntegrateMail,
  failIntegrateMail,
} from './integrateMail.slice';
import { IntegrateMailMeta, IntegrateMailPayload } from './integrateMail.type';

function* integrateMailRequest(action: PayloadAction<IntegrateMailMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        integrateMail,
        action.payload,
        state.auth.token
      );
      yield put(setIntegrateMail(data));
    } catch (err) {
      yield put(failIntegrateMail(err.response.data.message));
    }
  } else {
    yield put(failIntegrateMail('No token provided'));
  }
}

function* integrateMailSuccess(action: PayloadAction<IntegrateMailPayload>) {
  yield put(success('Mail API connected'));
  yield put(info('Testing Connection'));

  const state: RootState = yield select();
  const client_id = state.formClient.user?.id;
  if (client_id) {
    yield put(
      requestTestMailConnection({
        user_id: client_id,
      })
    );
  }
}

function* integrateMailFail(action: PayloadAction<IntegrateMailPayload>) {
  // yield function here
}

function* IntegrateMailWatcher() {
  yield takeLatest(requestIntegrateMail.type, integrateMailRequest);
  yield takeLatest(setIntegrateMail.type, integrateMailSuccess);
  yield takeLatest(failIntegrateMail.type, integrateMailFail);
}

export default IntegrateMailWatcher;
