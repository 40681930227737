import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetGscStatsMeta, GetGscStatsPayload } from './getGscStats.type';

const initialState: AsyncState<GetGscStatsMeta, GetGscStatsPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetGscStatsMeta, GetGscStatsPayload>();

const getGscStatsSlice = createSlice({
  name: 'getGscStats',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetGscStats = getGscStatsSlice.actions.REQUEST;
export const setGetGscStats = getGscStatsSlice.actions.SET;
export const failGetGscStats = getGscStatsSlice.actions.FAILED;
export const clearGetGscStats = getGscStatsSlice.actions.CLEAR;

export default getGscStatsSlice.reducer;
