import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpdateOrganizationUrnMeta,
  UpdateOrganizationUrnPayload,
} from './updateOrganizationUrn.type';

const initialState: AsyncState<
  UpdateOrganizationUrnMeta,
  UpdateOrganizationUrnPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpdateOrganizationUrnMeta, UpdateOrganizationUrnPayload>();

const updateOrganizationUrnSlice = createSlice({
  name: 'updateOrganizationUrn',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpdateOrganizationUrn =
  updateOrganizationUrnSlice.actions.REQUEST;
export const setUpdateOrganizationUrn = updateOrganizationUrnSlice.actions.SET;
export const failUpdateOrganizationUrn =
  updateOrganizationUrnSlice.actions.FAILED;
export const clearUpdateOrganizationUrn =
  updateOrganizationUrnSlice.actions.CLEAR;

export default updateOrganizationUrnSlice.reducer;
