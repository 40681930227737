import type { PayloadAction } from '@reduxjs/toolkit';
import { getIgAttributes } from 'api/ig';
import { RootState } from 'app/store';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { requestGetIgDetails } from 'store/getIgDetails/getIgDetails.slice';

import {
  requestGetIgAttributes,
  setGetIgAttributes,
  failGetIgAttributes,
} from './getIgAttributes.slice';
import {
  GetIgAttributesMeta,
  GetIgAttributesPayload,
} from './getIgAttributes.type';

function* getIgAttributesRequest(action: PayloadAction<GetIgAttributesMeta>) {
  // Usual Template for Get Request
  const state: RootState = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getIgAttributes,
        action.payload,
        state.auth.token
      );
      yield put(setGetIgAttributes(data));
    } catch (err) {
      yield put(failGetIgAttributes(err.response.data.message));
    }
  } else {
    yield put(failGetIgAttributes('No token provided'));
  }
}

function* getIgAttributesSuccess(
  action: PayloadAction<GetIgAttributesPayload>
) {
  yield put(
    requestGetIgDetails({
      accessToken: action.payload.data.accessToken,
      ibaId: action.payload.data.ibaId,
    })
  );
}

function* getIgAttributesFail(action: PayloadAction<GetIgAttributesPayload>) {
  // yield function here
}

function* GetIgAttributesWatcher() {
  yield takeLatest(requestGetIgAttributes.type, getIgAttributesRequest);
  yield takeLatest(setGetIgAttributes.type, getIgAttributesSuccess);
  yield takeLatest(failGetIgAttributes.type, getIgAttributesFail);
}

export default GetIgAttributesWatcher;
