import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { GetClientsMeta, GetClientsPayload } from './getClients.type';

const initialState: AsyncState = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<GetClientsMeta, GetClientsPayload>();

const getClientsSlice = createSlice({
  name: 'getClients',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGetClients = getClientsSlice.actions.REQUEST;
export const setGetClients = getClientsSlice.actions.SET;
export const failGetClients = getClientsSlice.actions.FAILED;
export const clearGetClients = getClientsSlice.actions.CLEAR;

export default getClientsSlice.reducer;
