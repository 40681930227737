import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div``;

export const HeadingTypo = styled(Typography)`
  margin-bottom: 4px;
`;

export const Description = styled(Typography)`
  line-height: 19px;
`;
