import styled from '@emotion/styled';
import Typography from 'components/base/Typography';

// Sample Syntax
// export const Container = styled.div<{ customProps: string }>`
// 	border: ${({ customProps }) => customProps};
// 	color: ${(props) => props.theme.brand.primary};
// `;

export const Container = styled.div<{
  archived: boolean;
}>`
  background: #fff;
  padding: 10px;
  border: 3px solid
    ${(props) => {
      if (props.archived) {
        return props.theme.brand.twilightLight;
      } else {
        return props.theme.brand.twilight;
      }
    }};
  border-radius: 5px;
  box-sizing: border-box;

  height: 100%;
  position: relative;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
`;
export const ActionRow = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;

  // margin-top: 27px;

  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
`;

export const Names = styled.div`
  padding-left: 13px;
  max-width: 63%;
`;
export const Menu = styled.div`
  > div {
    vertical-align: top;
  }
`;

export const ButtonsPart = styled.div`
  padding-left: 13px;
`;
export const IconButtons = styled.div`
  padding-right: 13px;
  display: flex;
  align-items: flex-end;

  a + a {
    margin-left: 10px;
  }
`;

export const IconTouchable = styled.a`
  cursor: grab;
`;

export const MainTitle = styled(Typography)`
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
`;