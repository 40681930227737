import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  GenerateReportMeta,
  GenerateReportPayload
} from './generateReport.type';

const initialState: AsyncState<GenerateReportMeta, GenerateReportPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<GenerateReportMeta, GenerateReportPayload>();

const generateReportSlice = createSlice({
  name: 'generateReport',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestGenerateReport = generateReportSlice.actions.REQUEST;
export const setGenerateReport = generateReportSlice.actions.SET;
export const failGenerateReport = generateReportSlice.actions.FAILED;
export const clearGenerateReport = generateReportSlice.actions.CLEAR;

export default generateReportSlice.reducer;
