import axios from 'axios';
import { API, FB } from 'consts/api';
import { GetIgAttributesMeta } from 'store/getIgAttributes/getIgAttributes.type';
import { GetIgDetailsMeta } from 'store/getIgDetails/getIgDetails.type';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const IG_URL = `${BASE_URL}/ig`;

export const getIgAttributes = (
  payload: GetIgAttributesMeta,
  token: string
) => {
  return axios({
    method: 'get',
    url: `${IG_URL}/getIgAttributes`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: payload,
  });
};

export const getIgDetails = (payload: GetIgDetailsMeta) => {
  const params = {
    fields: 'id,username,followers_count',
    access_token: payload.accessToken,
  };

  return axios({
    method: 'get',
    url: `${FB.FACEBOOK_API}/${payload.ibaId}`,
    params: params,
  });
};
