import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  TestMailConnectionMeta,
  TestMailConnectionPayload,
} from './testMailConnection.type';

const initialState: AsyncState<
  TestMailConnectionMeta,
  TestMailConnectionPayload
> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<TestMailConnectionMeta, TestMailConnectionPayload>();

const testMailConnectionSlice = createSlice({
  name: 'testMailConnection',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestTestMailConnection =
  testMailConnectionSlice.actions.REQUEST;
export const setTestMailConnection = testMailConnectionSlice.actions.SET;
export const failTestMailConnection = testMailConnectionSlice.actions.FAILED;
export const clearTestMailConnection = testMailConnectionSlice.actions.CLEAR;

export default testMailConnectionSlice.reducer;
