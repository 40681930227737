import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import { FbListPagesMeta, FbListPagesPayload } from './fbListPages.type';

const initialState: AsyncState<FbListPagesMeta, FbListPagesPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers = createAsyncSlice<FbListPagesMeta, FbListPagesPayload>();

const fbListPagesSlice = createSlice({
  name: 'fbListPages',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestFbListPages = fbListPagesSlice.actions.REQUEST;
export const setFbListPages = fbListPagesSlice.actions.SET;
export const failFbListPages = fbListPagesSlice.actions.FAILED;
export const clearFbListPages = fbListPagesSlice.actions.CLEAR;

export default fbListPagesSlice.reducer;
