import React from 'react';

import { SVGProps } from './SVG.props';

const Pencil = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '17'}
      height={height || '17'}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)" fill="#799BD0">
        <path d="M10.441 3.096l3.568 3.568a93.717 93.717 0 00-2.36 2.256 1056.09 1056.09 0 01-7.714 7.66c-.262.21-.63.42-.944.42-.945.052-1.942 0-2.991 0v-3.41c0-.105.157-.263.21-.368C3.62 9.864 6.926 6.506 10.284 3.148c.052 0 .105-.052.157-.052zM15.059 5.562c-1.207-1.207-2.361-2.361-3.516-3.568.577-.577 1.102-1.154 1.732-1.732.42-.367.892-.314 1.259.053.734.734 1.47 1.416 2.204 2.151.367.42.42.892.052 1.312-.577.63-1.207 1.207-1.731 1.784z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h17v17H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pencil;
