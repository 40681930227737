import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'types/AsyncState';
import { createAsyncSlice } from 'utils/AsyncSlicer';

import {
  UpsertDocumentMeta,
  UpsertDocumentPayload,
} from './upsertDocument.type';

const initialState: AsyncState<UpsertDocumentMeta, UpsertDocumentPayload> = {
  request: null,
  pending: null,
  error: null,
  data: null,
};

const defaultReducers =
  createAsyncSlice<UpsertDocumentMeta, UpsertDocumentPayload>();

const upsertDocumentSlice = createSlice({
  name: 'upsertDocument',
  initialState,
  reducers: { ...defaultReducers },
});

export const requestUpsertDocument = upsertDocumentSlice.actions.REQUEST;
export const setUpsertDocument = upsertDocumentSlice.actions.SET;
export const failUpsertDocument = upsertDocumentSlice.actions.FAILED;
export const clearUpsertDocument = upsertDocumentSlice.actions.CLEAR;

export default upsertDocumentSlice.reducer;
